/**
 * Logout component
 * which will redirect to login page
 */

import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router-dom";

import Cookie from 'js-cookie';
import AuthAPI from '../../http/auth';
import { useDispatch, useSelector } from 'react-redux';
import { signinAuth } from '../../redux/actions/UserAuthAction';
import Constants from '../../resource/Constants';
import { CircularProgress } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import UserSession from "../../resource/UserSession";

function Alert2(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DoLogin = () => {
    const [email, setEmail] = useState("test.usr.8@k2p.co");
    const [passw, setPassw] = useState("1234");
    const [token, setToken] = useState('abcxyz123');
    const [isLoading, setIsLoading] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)

    const history = useHistory();

    const navigateToPublisher = (role, userData) => history.push({ pathname: '/publisher-ebook/dashboard', state: { role: role, roleData: userData } });//eg.history.push('/login');
    const navigateToPublisherEbooks = (role, userData) => history.push({ pathname: '/publisher-ebook/books', state: { role: role, roleData: userData } });//eg.history.push('/login');
    const navigateToPublisherCurriculum = (role, userData) => history.push({ pathname: '/publisher-ebook/curriculum-map', state: { role: role, roleData: userData } });//eg.history.push('/login');

    const navigateToContent = (role, userData) => history.push({ pathname: '/publisher-content/dashboard', state: { role: role, roleData: userData } });//eg.history.push('/login');
    const navigateToContUpload = (role, userData) => history.push({ pathname: '/publisher-content/my-media/instructor-led-content', state: { role: role, roleData: userData } });//eg.history.push('/login');
    const navigateToReg = () => history.push({ pathname: '/user/register', state: { foo: 'baz' } });//eg.history.push('/login');

    const doLogin = async () => {
        const query = new URLSearchParams(window.location.search)
        const userToken = query.get('token')

        setIsLoading(true)
        if (!email || !passw) {

            setIsLoading(false)
            return;
        }

        const body1 = {
            user_email: email,
            user_password: passw
        }

        const body = {
            user_token: userToken
        }

        try {
            const response = await AuthAPI.doLoginToken(body)
            console.log(`response after login  ${JSON.stringify(response)}`);


            if(response.data.message == 'fail') {
                // take him to register
                navigateToReg()

                return;
            }




            // console.log(`response after login after authentication  ${JSON.stringify(resp)}`);
            console.log("response after login authentication", response)

            const roles = Cookie.get('econtent.user.Roles')
            console.log("roles at login screen", roles)
            const CONTENTUPLOAD = (roles && roles.includes("CONTENTUPLOAD"))
            const QUALITYASSURANCE = (roles && roles.includes("QUALITYASSURANCE"))
            const SUPERADMIN = (roles && roles.includes("SUPERADMIN"))
            const CONTENTMAP = (roles && roles.includes("CONTENTMAP"))
            const CURRICULUMMAP = (roles && roles.includes("CURRICULUMMAP"))
            const EBOOKUPLOAD = (roles && roles.includes("EBOOKUPLOAD"))
            const TRAINER = (roles && roles.includes("TRAINER"))

            console.log("role status CONTENT UPLOAD at login", CONTENTUPLOAD)
            console.log("role status QUALITYASSURANCE at login ", QUALITYASSURANCE)
            console.log("role status SUPERADMIN at login ", SUPERADMIN)
            console.log("role status CONTENTMAP at login ", CONTENTMAP)
            console.log("role status CURRICULUMMAP at login", CURRICULUMMAP)
            console.log("role status EBOOKUPLOAD at login ", EBOOKUPLOAD)
            console.log("role status TRAINER at login", TRAINER)

            if (response && response.data && response.data.role == "EBOOK_PROVIDER") {
                if (SUPERADMIN) {
                    navigateToPublisher(response.data.role, response.data)
                } else if (EBOOKUPLOAD) {
                    navigateToPublisherEbooks(response.data.role, response.data)
                } else if (CONTENTMAP) {
                    navigateToPublisherCurriculum(response.data.role, response.data)
                } else if (CURRICULUMMAP) {
                    navigateToPublisherCurriculum(response.data.role, response.data)
                }
            }

            if (response && response.data && response.data.role == "CONTENT_PROVIDER") {
                console.log("entering in content provider SUPERADMIN", SUPERADMIN)
                console.log("entering in content provider CONTENTUPLOAD", CONTENTUPLOAD)

                if (SUPERADMIN) {
                    console.log("entering in content provider dashboard ")
                    navigateToContent(response.data.role, response.data)

                } else if (CONTENTUPLOAD) {
                    console.log("entering in content provider content upload ")
                    navigateToContUpload(response.data.role, response.data)
                } else if (QUALITYASSURANCE) {
                    console.log("entering in content provider QualityAssurance ")
                    navigateToContUpload(response.data.role, response.data)
                }
            }

        } catch (e) {
            console.log(`exception authenticating user ${e.message}`)
        }

        setIsLoading(false)


    }


    const tryLogin = async () => {
        const query = new URLSearchParams(window.location.search)
        const token = query.get('token')

        setToken(token)
    }

    // simply navigate 
    useEffect(() => {

        console.log(`doing login..`)
        // tryLogin()
        doLogin()
        return <></>
    }, [])

    return null
}

export default DoLogin