import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Divider, Grid, List, Toolbar } from "@material-ui/core";
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { useDispatch, useSelector } from "react-redux";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase";
import Constants from "../../../resource/Constants";
import FileUploader from "react-firebase-file-uploader";
import { listBoardGet } from "../../../redux/actions/boardDetailsAction";
import { listInstGet } from "../../../redux/actions/InstituteDetailsAction";
import { listSubGet } from "../../../redux/actions/subjectsAction";
import { listStdGet } from "../../../redux/actions/standardAction";
import { listChpGet } from "../../../redux/actions/chapterAction";
import { listTopGet } from "../../../redux/actions/topicAction";
// import { Alert } from "@material-ui/lab";
import { Snackbar, TextField } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Cookie from "js-cookie";
import Checkbox from '@material-ui/core/Checkbox';

import API from "../../../http/http";
import PARTNERAPI from "../../../http/httppartner";
import PUBLISHERAPI from "../../../http/publisher";
import { listContTypeGet } from "../../../redux/actions/contentTypeAction";
import CircularProgress from "@material-ui/core/CircularProgress";
// import image from "../../../assets/book-na-1.jpg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import ChipInput from 'material-ui-chip-input'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import uploadScreen from '../../../assets/bulk_upload_screen.png';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';

const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabPanel1(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

TabPanel1.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function a11yProps1(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
    minHeight: 30,
  },
  formControl1: {
    margin: theme.spacing(1),
    minWidth: 350,
    minHeight: 30,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  toolbar: {
    marginRight: 24,
    justifyContent: "space-between",
  },
  button: {
    margin: theme.spacing(1),
    justifyContent: "flex-end",
    alignSelf: "center",
  },
  button1: {
    margin: theme.spacing(1),
    justifyContent: "flex-end",
    justifyItems: "flex-end",
    justifySelf: "flex-end",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    alignContent: "flex-end",
  },
}));

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  { title: "The Lord of the Rings: The Return of the King", year: 2003 },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  { title: "The Lord of the Rings: The Fellowship of the Ring", year: 2001 },
  { title: "Star Wars: Episode V - The Empire Strikes Back", year: 1980 },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  { title: "The Lord of the Rings: The Two Towers", year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  { title: "Star Wars: Episode IV - A New Hope", year: 1977 },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
  { title: "Casablanca", year: 1942 },
  { title: "City Lights", year: 1931 },
  { title: "Psycho", year: 1960 },
  { title: "The Green Mile", year: 1999 },
  { title: "The Intouchables", year: 2011 },
  { title: "Modern Times", year: 1936 },
  { title: "Raiders of the Lost Ark", year: 1981 },
  { title: "Rear Window", year: 1954 },
  { title: "The Pianist", year: 2002 },
  { title: "The Departed", year: 2006 },
  { title: "Terminator 2: Judgment Day", year: 1991 },
  { title: "Back to the Future", year: 1985 },
  { title: "Whiplash", year: 2014 },
  { title: "Gladiator", year: 2000 },
  { title: "Memento", year: 2000 },
  { title: "The Prestige", year: 2006 },
  { title: "The Lion King", year: 1994 },
  { title: "Apocalypse Now", year: 1979 },
  { title: "Alien", year: 1979 },
  { title: "Sunset Boulevard", year: 1950 },
  {
    title:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { title: "The Great Dictator", year: 1940 },
  { title: "Cinema Paradiso", year: 1988 },
  { title: "The Lives of Others", year: 2006 },
  { title: "Grave of the Fireflies", year: 1988 },
  { title: "Paths of Glory", year: 1957 },
  { title: "Django Unchained", year: 2012 },
  { title: "The Shining", year: 1980 },
  { title: "WALL·E", year: 2008 },
  { title: "American Beauty", year: 1999 },
  { title: "The Dark Knight Rises", year: 2012 },
  { title: "Princess Mononoke", year: 1997 },
  { title: "Aliens", year: 1986 },
  { title: "Oldboy", year: 2003 },
  { title: "Once Upon a Time in America", year: 1984 },
  { title: "Witness for the Prosecution", year: 1957 },
  { title: "Das Boot", year: 1981 },
  { title: "Citizen Kane", year: 1941 },
  { title: "North by Northwest", year: 1959 },
  { title: "Vertigo", year: 1958 },
  { title: "Star Wars: Episode VI - Return of the Jedi", year: 1983 },
  { title: "Reservoir Dogs", year: 1992 },
  { title: "Braveheart", year: 1995 },
  { title: "M", year: 1931 },
  { title: "Requiem for a Dream", year: 2000 },
  { title: "Amélie", year: 2001 },
  { title: "A Clockwork Orange", year: 1971 },
  { title: "Like Stars on Earth", year: 2007 },
  { title: "Taxi Driver", year: 1976 },
  { title: "Lawrence of Arabia", year: 1962 },
  { title: "Double Indemnity", year: 1944 },
  { title: "Eternal Sunshine of the Spotless Mind", year: 2004 },
  { title: "Amadeus", year: 1984 },
  { title: "To Kill a Mockingbird", year: 1962 },
  { title: "Toy Story 3", year: 2010 },
  { title: "Logan", year: 2017 },
  { title: "Full Metal Jacket", year: 1987 },
  { title: "Dangal", year: 2016 },
  { title: "The Sting", year: 1973 },
  { title: "2001: A Space Odyssey", year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: "Toy Story", year: 1995 },
  { title: "Bicycle Thieves", year: 1948 },
  { title: "The Kid", year: 1921 },
  { title: "Inglourious Basterds", year: 2009 },
  { title: "Snatch", year: 2000 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];

const boardDetailsMock = [
  {
    institute_board_id: 10001,
    institute_board_name: "CBSE",
    institute_board_f_desc: "cbse board",
    institute_board_status: 1,
    created_date: "2020-05-27",
  },
  {
    institute_board_id: 10002,
    institute_board_name: "Andhra Pradesh State Board",
    institute_board_f_desc: "AP state board",
    institute_board_status: 1,
    created_date: "2020-05-27",
  },
  {
    institute_board_id: 10003,
    institute_board_name: "ICSE",
    institute_board_f_desc: "ICSE board",
    institute_board_status: 1,
    created_date: "2020-05-27",
  },
  {
    institute_board_id: 10004,
    institute_board_name: "Bihar State Board",
    institute_board_f_desc: "Bihar state board",
    institute_board_status: 1,
    created_date: "2020-05-27",
  },
  {
    institute_board_id: 10005,
    institute_board_name: "Cambridge International",
    institute_board_f_desc: "Cambridge International board",
    institute_board_status: 1,
    created_date: "2020-05-27",
  },
  {
    institute_board_id: 10006,
    institute_board_name: "Chhattisgarh State Board",
    institute_board_f_desc: "Chhattisgarh state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10007,
    institute_board_name: "Gujarat State Board",
    institute_board_f_desc: "Gujarat state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10008,
    institute_board_name: "International Baccalaureate Board",
    institute_board_f_desc: "International Baccalaureate board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10009,
    institute_board_name: "Jharkhand State Board",
    institute_board_f_desc: "Jharkhand state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10010,
    institute_board_name: "J&K State Board",
    institute_board_f_desc: "J&K state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10011,
    institute_board_name: "Karnataka State Board",
    institute_board_f_desc: "Karnataka state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10012,
    institute_board_name: "Kerala State Board",
    institute_board_f_desc: "Kerala state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10013,
    institute_board_name: "Madhya Pradesh State Board",
    institute_board_f_desc: "Madhya Pradesh state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10014,
    institute_board_name: "Maharashtra State Board",
    institute_board_f_desc: "Maharashtra state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10015,
    institute_board_name: "Punjab State Board",
    institute_board_f_desc: "Punjab state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10016,
    institute_board_name: "Rajasthan State Board",
    institute_board_f_desc: "Rajasthan state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10017,
    institute_board_name: "Tamil Nadu State Board",
    institute_board_f_desc: "Tamil Nadu state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10018,
    institute_board_name: "Telangana State Board",
    institute_board_f_desc: "Telangana state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10019,
    institute_board_name: "Uttarakhand State Board",
    institute_board_f_desc: "Uttarakhan state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10020,
    institute_board_name: "Uttar Pradesh State Board",
    institute_board_f_desc: "Uttar Pradesh state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
  {
    institute_board_id: 10021,
    institute_board_name: "West Bengal State Board",
    institute_board_f_desc: "West Bengal state board",
    institute_board_status: 1,
    created_date: "2020-05-30",
  },
];

const instituteMockData = [
  { inst_id: 1, inst_type: "School" },
  { inst_id: 2, inst_type: "College" },
  { inst_id: 3, inst_type: "University" },
  { inst_id: 4, inst_type: "Institute" },
];

const standardsMockData = [
  { stand_id: 1, stand_name: 1 },
  { stand_id: 2, stand_name: 2 },
  { stand_id: 3, stand_name: 3 },
  { stand_id: 4, stand_name: 4 },
  { stand_id: 5, stand_name: 5 },
  { stand_id: 6, stand_name: 6 },
  { stand_id: 7, stand_name: 7 },
  { stand_id: 8, stand_name: 8 },
  { stand_id: 8, stand_name: 9 },
  { stand_id: 9, stand_name: 9 },
  { stand_id: 10, stand_name: 10 },
  { stand_id: 11, stand_name: 11 },
  { stand_id: 12, stand_name: 12 },
];

const subjectsMockData = [
  { subj_id: 1, subj_name: "English" },
  { subj_id: 2, subj_name: "Maths" },
  { subj_id: 3, subj_name: "Social" },
  { subj_id: 4, subj_name: "Physics" },
  { subj_id: 5, subj_name: "Chemistry" },
  { subj_id: 6, subj_name: "Biology" },
];

const chaptersMockData = [
  { chap_id: 1, chap_name: "A Letter to God." },
  { chap_id: 2, chap_name: "Polynomials" },
  { chap_id: 3, chap_name: "Federalism" },
  { chap_id: 4, chap_name: " Light-Reflection and Refraction. " },
  { chap_id: 5, chap_name: " Acids, Bases and Salts" },
  { chap_id: 6, chap_name: "Life Processes" },
];

const TopicsMockData = [
  { topic_id: 1, topic_name: "How Do Organisms Reproduce" },
  { topic_id: 2, topic_name: "Our Environment" },
  { topic_id: 3, topic_name: " Sources Of Energy." },
  { topic_id: 4, topic_name: " Coordinate Geometry. " },
  { topic_id: 5, topic_name: " Acids, Bases and Salts" },
  { topic_id: 6, topic_name: "Metals and Non Metals." },
];

const contentTypeMockData = [
  { type_id: 1, content_type_name: "Video" },
  { type_id: 2, content_type_name: "Image" },
  { type_id: 3, content_type_name: "Pdf" },
];

export default function UpdatePrice(props) {

  console.log(`props in upload content modal ${JSON.stringify(props)}`)
  const { actvRow, allSubjects } = props

  const classes = useStyles();
  const classes2 = useStyles2();
  const [value, setValue] = React.useState(0);
  const [age, setAge] = React.useState("");
  const [showInputField, SetShowInputField] = useState(false);
  const [selectBoardDetails, setSelectedBoardDetails] = useState("");
  const [boardDetailsState, setBoardDetailsState] = useState(null);
  const [filenames, setFileNames] = useState([]);
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [instType, setInstType] = useState((actvRow) ? actvRow.institute_type_id : 0);
  const [boardType, setBoardType] = useState((actvRow) ? actvRow.institute_board_id : 0);
  const [subjectType, setSubjectType] = useState((actvRow) ? actvRow.subject_id : "");
  const [standardType, setStandardType] = useState((actvRow) ? actvRow.classroom_std : '0');
  const [contType, setContType] = useState("");
  const [ImageDownloadUrls, setImageDownloadUrls] = useState((actvRow) ? actvRow.image_cover_url : "");
  console.log(`actvRow image url ${(actvRow) ? actvRow.image_cover_url : 'n/a'}`)

  const [chap, setChap] = useState((actvRow) ? actvRow.chapter_id : 0);
  const [tpc, setTpc] = useState((actvRow) ? actvRow.topic_id : 0);
  const [title, setTitle] = useState((actvRow) ? actvRow.content_name : "");
  const [video, setVideo] = useState((actvRow) ? actvRow.content_location : "");
  const [snackOpen, setSnackOpen] = useState(false);
  const [chaptername, setChapterName] = useState((actvRow) ? actvRow.chapter_name : "n/a");
  const [topicname, setTopicName] = useState((actvRow) ? actvRow.topic_name : "n/a");
  const [progress, setProgress] = useState(0);
  const [bulkProgress, setBulkProgress] = useState(0);
  const [bulkIsUploading, setBulkIsUploading] = useState(false);
  const [agree, setAgree] = useState(false)

  const [tags, setTags] = useState('')
  const [yourChips, setYourChips] = useState((actvRow) ? actvRow.tags.split(',') : [])
  const [excel, setExcel] = useState(false)
  const [selectedFile, setSelectedFile] = useState("")
  const [open, setOpen] = React.useState(false);

  const [teachPrice, setTeachPrice] = useState('')
  const [learnPrice, setLearnPrice] = useState('')

  const handleTeachPriceChange = (event) => {
    const {value} = event.target

    setTeachPrice(value)
  }

  const handleLearnPriceChange = (event) => {
    const {value} = event.target

    setLearnPrice(value)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const handleSnackOpen = (open) => {
    setSnackOpen(open);
  };

  const handleChipChange = (chips) => {
    console.log('chip change called ', chips)
    setYourChips(chips)
  }

  const handleAddChip = (chip) => {
    console.log('onAdd chip called')
    let chips = yourChips
    chips.push(chip)
    setYourChips(chips)
  }

  const handleDeleteChip = (chip, index) => {
    console.log('onDelete chip called')

    // let chips = yourChips.filter(item => item != chip)
    let chips = yourChips.filter((item, idx) => idx !== index)
    setYourChips(chips)
  }

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleTagsChange = (e) => {
    const { name, value } = e.target

    setTags(value)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAgreeToggle = (e) => {
    console.log(e.target.checked)

    setAgree(e.target.checked)
  }

  const handleTitleChange = (event) => {
    console.log(`new title ${event}`);
    const { name, value } = event.target;
    setTitle(value);
  };

  const handleVideoChange = (event) => {
    console.log(`new video ${event}`);

    const { name, value } = event.target;
    setVideo(value);
  };

  const onPrefillData = () => {
    dispatch(listBoardGet(actvRow.institute_type_id)); // school boards
    dispatch(listStdGet(actvRow.institute_type_id));

    dispatch(listSubGet(actvRow.institute_board_id));
    dispatch(listChpGet(actvRow.subject_id, actvRow.institute_board_id));

    dispatch(listTopGet(actvRow.chapter_id));
  }

  const handleInstChange = (event) => {
    console.log("called handleInstChange");
    console.log(`new institute type ${event}`);
    const name = event.target.name;
    const value = event.target.value;
    // setState({
    // ...state,
    // [name]: event.target.value,
    // });

    setInstType(value);
    dispatch(listBoardGet(value));
    dispatch(listStdGet(value));

  };

  const handleBoardChange = (event) => {
    console.log("called handleBoardChange");
    console.log(`new board  ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log(`board selected value ${value}`);

    setBoardType(value);
    // get subjects
    dispatch(listSubGet(value));
  };

  const handleStdChange = (event) => {
    console.log("called handleStdChange");
    console.log(`new standard ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setStandardType(value);
  };

  const handleSubChange = (event) => {
    console.log("called handleSubChange");
    console.log(`new subject ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setSubjectType(value);
    dispatch(listChpGet(value, boardType));
  };

  const handleChpChange = (event, chaptername) => {
    console.log("called handleChpChange");
    console.log(`new chapter ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log("chapter name", chaptername);
    setChap(value);

    dispatch(listTopGet(value));
  };

  const handleTpcChange = (event) => {
    console.log("called handleTpcChange");
    console.log(`new topic ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setTpc(value);
  };

  const handleContTypeChange = (event) => {
    console.log("called handleTpcChange");
    console.log(`new topic ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setContType(value);
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      height: 30,
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      width: 310,
      padding: "10px 26px 10px 12px",
      placeholder: "Title",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",

        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const showInput = () => {
    SetShowInputField(true);
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadStartBulk = () => {
    setBulkIsUploading(true);
    setBulkProgress(0);
  };

  const handleProgressBulk = (progress) => {
    setBulkProgress(progress);
  };

  const handleUploadErrorBulk = (error) => {
    setBulkIsUploading(false);
  };

  const boardDetailsGet = useSelector((state) => state.boardDetails);
  const { loading, boardDetails, error } = boardDetailsGet;

  const instDetailsGet = useSelector((state) => state.instDetails);
  const { loadingInst, instDetails, errorInst } = instDetailsGet;

  const subDetailsGet = useSelector((state) => state.subDetails);
  const { loadingSub, subDetails, errorSub } = subDetailsGet;

  const stdDetailsGet = useSelector((state) => state.stdDetails);
  const { loadingStd, stdDetails, errorStd } = stdDetailsGet;

  const chpDetailsGet = useSelector((state) => state.chpDetails);
  const { loadingChap, chapDetails, errorChp } = chpDetailsGet;

  const topDetailsGet = useSelector((state) => state.topDetails);
  const { loadingTop, TopDetails, errorTop } = topDetailsGet;

  const contTypeDetailsGet = useSelector((state) => state.contType);
  const { loadingCont, contTypeDetails, errorCont } = contTypeDetailsGet;

  const userId = Cookie.get("econtent.cont.id");

  console.log("chapterDetails", chpDetailsGet);
  console.log("TopicDetails", topDetailsGet);
  console.log("contentDetailsget", contTypeDetails);

  /* called after render completed.. */
  useEffect(() => {
    dispatch(listInstGet());

    if (actvRow) {
      onPrefillData()
    }

    getAllDetails();
    return () => {
      //
    };
  }, []);

  const getAllDetails = async () => {
    await new Promise((resolve, reject) => {
      dispatch(listBoardGet(10001));
      resolve();
    });

    const firstBoardId =
      boardDetails && boardDetails.data && boardDetails.data.length
        ? boardDetails.data[0].institute_board_id
        : 0;

    // we got boards
    await new Promise((resolve, reject) => {
      dispatch(listSubGet(firstBoardId));
      resolve();
    });

    dispatch(listContTypeGet());
  };

  const handleUploadSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("ebook")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    // setFileNames(filename)
    setDownloadUrls(downloadURL);
    if (downloadURL) {
      setBulkProgress(0);
      setBulkIsUploading(false);
    }
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleBulkContentUpload = async () => {
    console.log(`ebook url ${downloadUrls}`);

    if (
      !excel ||
      // !instType ||
      // !boardType ||
      !subjectType ||
      // !standardType
      !selectedFile
      // ||
      // !agree
    ) {
      handleSnackOpen(true);
    } else {
      // send data

      // const body = {
      //     institute_type_id: 10001,
      //     institute_board_id: 1001,
      //     classroom_std: 10,
      //     course: 'M.P.C',
      //     subject_id: 108,

      //     content_location: downloadUrls
      // }

      const body2 = {
        institute_type_id: instType,
        institute_board_id: boardType,
        classroom_std: standardType,
        course: "",
        subject_id: subjectType,
        content_name: video,
        chapter_id: chap,
        chapter_name: chaptername,
        topic_id: tpc,
        topic_name: topicname,
        content_f_desc: topicname,
        content_length: "3",
        content_location:
          "https://www.youtube.com/watch?v=TMubSggUOVE&ab_channel=TheOrganicChemistryTutor",
        content_size: 300,
      };


      const body = new FormData()
      body.append('institute_type_id', instType)
      body.append('institute_board_id', boardType)
      body.append('classroom_std', standardType)
      body.append('course', "")
      body.append('subject_id', subjectType)
      // body.append('file', selectedFile)
      body.append('excel_file', selectedFile)
      body.append('content_prov_id', userId)

      const resp = await PUBLISHERAPI.postSaveBulkContent2(body);
      console.log(`response ${JSON.stringify(resp)}`);


      // close the dialog..
      console.log(`props anchor ${props.anchor}`);
      console.log(`props oncloseclicked ${props.onCloseClicked}`);
      props.onCloseClicked(props.anchor);

      // also call search content
      // props.onSearchContent("");

      // call reload
      props.onReload()
    }
  };

  const handleExcelChange = (event) => {
    setProgress(0)
    console.log("selected file in handleExcelChange target", event.target.files[0])
    setSelectedFile(event.target.files[0])
    if (event.target.files[0] && selectedFile != null) {
      setExcel(true)
      setProgress(100)
      console.log(`set excel true`)

    }

    console.log("selected file in handleExcelChange", selectedFile, event.target.files[0])
  }

  const handleSingleContentUpload = async () => {
    console.log(`ebook url ${ImageDownloadUrls}`);
    console.log(`chapter name ${chaptername}`)
    console.log(`video ${video}`)
    console.log(`chap ${chap}`)
    console.log(`tpc ${tpc}`)
    console.log(`instType ${instType}`)
    console.log(`boardType ${boardType}`)
    console.log(`subjectType ${subjectType}`)
    console.log(`stdType ${standardType}`)
    console.log(`title ${title}`)
    console.log(`contType ${contType}`)

    console.log(`checking params...`)
    if (
      // !instType ||
      // !boardType ||
      !subjectType ||
      // !standardType ||
      // !chap ||
      // !tpc ||
      // !contType ||
      !title ||
      !ImageDownloadUrls ||
      // !chaptername ||
      !yourChips ||
      !video ||
      (!video.includes('.mp4') && !video.includes('.mp3')  // video or audio not?
        && (!video.includes('.jpg') && !video.includes('.jpeg') && !video.includes('.png') && !video.includes('.gif')) // or image not?
        && (!video.includes('vimeo')) // not vimeo?
      )
    ) {
      console.log(`showing snackbar..`)
      handleSnackOpen(true);
    } else {
      // send data

      console.log("calling api =========================================");
      // const body = {
      //     institute_type_id: 10001,
      //     institute_board_id: 1001,
      //     classroom_std: 10,
      //     course: 'M.P.C',
      //     subject_id: 108,
      //     content_location: downloadUrls
      // }

      console.log(
        "parameters",
        instType,
        boardType,
        subjectType,
        standardType,
        chap,
        tpc,
        contType,
        title,
        video,
        ImageDownloadUrls,
        chaptername
      );


      const body = {
        institute_type_id: instType,
        institute_board_id: boardType,
        classroom_std: standardType,
        course: `class ${standardType}`,
        subject_id: subjectType,
        content_name: title,
        chapter_id: chap,
        chapter_name: chaptername,
        topic_id: tpc,
        topic_name: topicname,
        content_f_desc: topicname,
        content_length: 0,
        content_location: video,
        content_size: 0,
        // content_type_id: contType,
        content_prov_id: userId,
        tags: yourChips.join(','),
        // image_cover_url: encodeURI(ImageDownloadUrls),
        image_cover_url: ImageDownloadUrls
      };

      console.log(`content api body ${JSON.stringify(body)}`)
      let resp;
      if (!actvRow) {
        resp = await new Promise(async (resolve, reject) => {
          await PUBLISHERAPI.postSaveSingleContent(body);

          resolve();
        });
      } else {
        resp = await new Promise(async (resolve, reject) => {
          await PUBLISHERAPI.updateSingleContent(actvRow.id, body);

          resolve();
        });
      }

      console.log(`response ${JSON.stringify(resp)}`);

      // close the dialog..
      console.log(`props anchor ${props.anchor}`);
      console.log(`props oncloseclicked ${props.onCloseClicked}`);
      props.onCloseClicked(props.anchor);

      // also call search content
      // props.onSearchContent("");

      // call reload
      props.onReload()
    }
  };

  console.log(`actvRow image ${(ImageDownloadUrls)}`)
  console.log(`instType ${instType}`)
  console.log(`boardType ${boardType}`)
  console.log(`subjectType ${subjectType}`)
  console.log(`standardType ${standardType}`)
  console.log(`chap ${chap}`)
  console.log(`tpc ${tpc}`)
  console.log(`title ${title}`)
  console.log(`ImageDownloadUrls ${ImageDownloadUrls}`)
  console.log(`chaptername ${chaptername}`)
  console.log(`video ${video}`)
  console.log(`agree ${agree}`)

  let singleUploadDisabled = false;
  let bulkUploadDisabled = false;
  let openUploadDisabled = false;

  if (
    // !instType ||
    // !boardType ||
    // !subjectType ||
    // !standardType ||
    // !chap ||
    // !tpc ||
    // !contType ||
    !title ||
    // ((actvRow && !actvRow.image_cover_url) || 
    !ImageDownloadUrls ||
    // !chaptername ||
    !yourChips ||
    !video
    // ||
    // !agree
  )
    singleUploadDisabled = true;


  if (
    !subjectType ||

    !title ||
    // ((actvRow && !actvRow.image_cover_url) || 
    !ImageDownloadUrls ||
    !video
    // ||
    // !agree
  )
    openUploadDisabled = true;


  if (
    !subjectType ||

    !selectedFile ||
    !excel
    // ||
    // ((actvRow && !actvRow.image_cover_url) || 

    // !agree
  )
    bulkUploadDisabled = true;

  console.log(`allsubjects in upload modal ${JSON.stringify(allSubjects)}`)

  return (
    <div>
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
          style={{ fontSize: 16, fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          Update Price [MATHEMATICS]
        </Typography>

      </Toolbar>
      <Divider style={{ marginBottom: 10 }} />

      {/* </AppBar> */}


      {/* BULK UPLOAD PANEL */}
      
        <div
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            display: "block",
          }}
        >
          


          <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center" }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Enter Pricing
              </InputLabel>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="outlined-basic"
                label="Enter Teach Price"
                variant="outlined"
                style={{
                  width: 350,
                  marginLeft: 10,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                type="number"
                defaultValue={teachPrice}
                onChange={handleTeachPriceChange}
              />
            </FormControl>
              
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="outlined-basic"
                label="Enter Learn Price"
                variant="outlined"
                style={{
                  width: 350,
                  marginLeft: 10,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                type="number"
                defaultValue={learnPrice}
                onChange={handleLearnPriceChange}
              />
            </FormControl>
              
          </div>



          {/* UPLOAD BUTTON */}
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: 15,
                marginRight: 20,
                marginBottom: 20
              }}
            >

              <label
                // style={{
                //   fontFamily: "Poppins, Helvetica, sans-serif",
                //   backgroundColor: "steelblue",
                //   color: "white",
                //   padding: 5,
                //   borderRadius: 4,
                //   cursor: "pointer",
                //   width: '80%',
                //   textAlign: "center",
                // }}

                onClick={() => {
                  document.getElementById('bulkfile').click()
                }}
              >
                
                {/* <FileUploader
                  hidden
                  // accept="image/*"
                  storageRef={firebase.storage().ref("images")}
                  onUploadStart={handleUploadStartBulk}
                  onUploadError={handleUploadErrorBulk}
                  onUploadSuccess={handleUploadSuccess}
                  onProgress={handleProgressBulk}
                /> */}

              </label>

              <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" type="file" className="form-control" name="upload_file" onChange={handleExcelChange} style={{ display: 'none' }} id="bulkfile" />
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {excel &&
                  <CheckCircleOutlinedIcon style={{ color: "green", fontSize: 30 }} />
                  // <img src={bookUpload} alt="preview Image" width="200px" style={{ alignSelf: "center", display: "flex" }} />
                }
              </div>
            </div>
            
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {bulkProgress ? <CircularProgress /> : <></>}
          </div>

          


          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: 15
            }}
          >
            {/* <Link to="/publisher/mapping" style={{ textDecoration: "none", color: "#fff" }}> */}
            <Button
              variant="contained"
              onClick={() => props.onCloseClicked(props.anchor, true)}
            >Cancel</Button>

            <Button
              disabled={bulkUploadDisabled}
              variant="contained"
              color="primary"
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                textTransform: "none",
                // width: '100%'
                // backgroundColor: "#3699FF",
                // color: '#FFFFFF'
              }}
              onClick={() => {
                // handleSnackOpen(true)

                handleBulkContentUpload();
              }}
            >
              Save
            </Button>
            {/* 
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                This is a success message!
              </Alert>
            </Snackbar>
 */}
            <Snackbar
              open={snackOpen}
              // autoHideDuration={6000}
              onClose={() => handleSnackOpen(false)}
            >
              <Alert onClose={() => handleSnackOpen(false)} severity="warning">
                Please fill up mandatory fields
              </Alert>
            </Snackbar>


            {/* </Link> */}
          </div>
        </div>
      
    </div>
  );
}
