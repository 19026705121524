import axios from "axios";
import Constants from "../../resource/Constants";
import Cookie from "js-cookie";
import { TOPDETAILS_LIST_REQUEST, TOPDETAILS_LIST_SUCCESS, TOPDETAILS_LIST_FAIL } from "../constants/topicConstants";

const listTopGet = (chpId) => async (dispatch) => {
    const userId = Cookie.get("econtent.cont.id");

    try {
        dispatch({ type: TOPDETAILS_LIST_REQUEST })

        const URL = Constants.Application.PARTNER_END_URL + `/getContentBySubTopics/?prov_id=${userId}&chapter_id=${chpId}`
        console.log('url in list topics get ', URL)
        const { data } = await axios.get(URL)
        console.log("data in list topics  get", data)
        dispatch({ type: TOPDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: TOPDETAILS_LIST_FAIL, payload: error.message })

    }
}

const listTopGet2 = (chpId) => async (dispatch) => {
    try {
        dispatch({ type: TOPDETAILS_LIST_REQUEST })

        const URL = Constants.Application.PLATFORM_END_URL + `/chapter-topic/?chapterid=${chpId}`
        console.log('url in list topics get ', URL)
        const { data } = await axios.get(URL)
        console.log("data in list topics  get", data)
        dispatch({ type: TOPDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: TOPDETAILS_LIST_FAIL, payload: error.message })

    }
}

const listTopReset = () => async(dispatch) => {
    const userId = Cookie.get("econtent.cont.id");

    try {
        dispatch({ type: TOPDETAILS_LIST_REQUEST })

        dispatch({ type: TOPDETAILS_LIST_SUCCESS, payload: [] })

    } catch (error) {
        dispatch({ type: TOPDETAILS_LIST_FAIL, payload: error.message })

    }
}

export { listTopGet, listTopReset }