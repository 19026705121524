import axios from "axios";
import Constants from "../../resource/Constants"
import Cookie from 'js-cookie';
import { DASHBOARDHEADERDETAILS_LIST_REQUEST, DASHBOARDHEADERDETAILS_LIST_SUCCESS, DASHBOARDHEADERDETAILS_LIST_FAIL, DASHBOARDSCHOOLSDETAILS_LIST_REQUEST, DASHBOARDSCHOOLSDETAILS_LIST_SUCCESS, DASHBOARDSCHOOLSDETAILS_LIST_FAIL, DASHBOARDPUBLISHERDETAILS_LIST_REQUEST, DASHBOARDPUBLISHERDETAILS_LIST_SUCCESS, DASHBOARDPUBLISHERDETAILS_LIST_FAIL, DASHBOARDCONTPROVDETAILS_LIST_REQUEST, DASHBOARDCONTPROVDETAILS_LIST_SUCCESS, DASHBOARDCONTPROVDETAILS_LIST_FAIL, DASHBOARDPUBGRAPHS_LIST_REQUEST, DASHBOARDPUBGRAPHS_LIST_FAIL, DASHBOARDPUBGRAPHS_LIST_SUCCESS, DASHBOARDSCHOOLSCONTDETAILS_LIST_REQUEST, DASHBOARDSCHOOLSCONTDETAILS_LIST_SUCCESS, DASHBOARDSCHOOLSCONTDETAILS_LIST_FAIL, DASHBOARDCONTGRAPHS_LIST_REQUEST, DASHBOARDCONTGRAPHS_LIST_SUCCESS, DASHBOARDCONTGRAPHS_LIST_FAIL, DASHBOARDPUBDEVICES_LIST_REQUEST, DASHBOARDPUBDEVICES_LIST_SUCCESS, DASHBOARDPUBDEVICES_LIST_FAIL, DASHBOARDCONTDEVICES_LIST_REQUEST, DASHBOARDPCONTDEVICES_LIST_SUCCESS, DASHBOARDCONTDEVICES_LIST_FAIL, DASHBOARDCONTHEADERDETAILS_LIST_REQUEST, DASHBOARDCONTHEADERDETAILS_LIST_SUCCESS, DASHBOARDCONTHEADERDETAILS_LIST_FAIL } from "../constants/DashboardConstants";


const listDashBoardHead = () => async (dispatch) => {

    try {
        
        dispatch({ type: DASHBOARDHEADERDETAILS_LIST_REQUEST })
        const ProvId = Cookie.get('econtent.user.id')
        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/school-ebook-price/?publisher_id=${ProvId}`)
        console.log("dashboard Details at actions", data)
        dispatch({ type: DASHBOARDHEADERDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: DASHBOARDHEADERDETAILS_LIST_FAIL, payload: error.message })

    }
}

const listDashBoardContHead = () => async (dispatch) => {

    try {
        
        dispatch({ type: DASHBOARDCONTHEADERDETAILS_LIST_REQUEST })
        const ProvId = Cookie.get('econtent.user.id')
        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/prov-ebook-price/?cont_prov_id=${ProvId}`)
        console.log("dashboard Details at actions", data)
        dispatch({ type: DASHBOARDCONTHEADERDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: DASHBOARDCONTHEADERDETAILS_LIST_FAIL, payload: error.message })

    }
}


const listDashBoardPubSchools = () => async (dispatch) => {

    try {
        
        dispatch({ type: DASHBOARDSCHOOLSDETAILS_LIST_REQUEST })
        const ProvId = Cookie.get('econtent.user.id')
        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/school-list/?publisher_id=${ProvId}`)
        console.log("dashboard Details at actions", data)
        dispatch({ type: DASHBOARDSCHOOLSDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: DASHBOARDSCHOOLSDETAILS_LIST_FAIL, payload: error.message })

    }
}

const listDashBoardContSchools = () => async (dispatch) => {

    try {
        
        dispatch({ type: DASHBOARDSCHOOLSCONTDETAILS_LIST_REQUEST })
        const ProvId = Cookie.get('econtent.user.id')
        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/school-list/?publisher_id=${ProvId}`)
        console.log("dashboard Details at actions", data)
        dispatch({ type: DASHBOARDSCHOOLSCONTDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: DASHBOARDSCHOOLSCONTDETAILS_LIST_FAIL, payload: error.message })

    }
}

const listDashBoardPublishers = () => async (dispatch) => {

    try {
        dispatch({ type: DASHBOARDPUBLISHERDETAILS_LIST_REQUEST })
        const ProvId = Cookie.get('econtent.user.id')
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/get-all-pub/?cont_prov_id=${ProvId}`)
        console.log("dashboard Details publishers at actions", data)
        dispatch({ type: DASHBOARDPUBLISHERDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: DASHBOARDPUBLISHERDETAILS_LIST_FAIL, payload: error.message })

    }
}

const listDashBoardContProv = () => async (dispatch) => {

    try {
        
        dispatch({ type: DASHBOARDCONTPROVDETAILS_LIST_REQUEST})
        const ProvId = Cookie.get('econtent.user.id')
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/get-all-contprov/?publisher_id=${ProvId}`)
        console.log("dashboard Details publishers at actions", data)
        dispatch({ type: DASHBOARDCONTPROVDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: DASHBOARDCONTPROVDETAILS_LIST_FAIL, payload: error.message })
    }
}



const listDashBoardPubGraphs = () => async (dispatch) => {

    try {
        
        dispatch({ type: DASHBOARDPUBGRAPHS_LIST_REQUEST})
        const ProvId = Cookie.get('econtent.user.id')
        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/ebook-graph/?publisher_id=${ProvId}`)
        console.log("dashboard Details publishers at actions", data)
        dispatch({ type: DASHBOARDPUBGRAPHS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: DASHBOARDPUBGRAPHS_LIST_FAIL, payload: error.message })
    }

}


const listDashBoardContGraphs = () => async (dispatch) => {

    try {
        
        dispatch({ type: DASHBOARDCONTGRAPHS_LIST_REQUEST})
        const ProvId = Cookie.get('econtent.user.id')
        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/prov-ebook-graph/?cont_prov_id=${ProvId}`)
        console.log("dashboard Details cont graphs at actions", data)
        dispatch({ type: DASHBOARDCONTGRAPHS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: DASHBOARDCONTGRAPHS_LIST_FAIL, payload: error.message })
    }

}



const listDashBoardPubDevices = () => async (dispatch) => {

    try {
        
        dispatch({ type: DASHBOARDPUBDEVICES_LIST_REQUEST})
        const ProvId = Cookie.get('econtent.user.id')
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/device/?publisher_id=${ProvId}`)
        console.log("dashboard Details pub devices at actions", data)
        dispatch({ type: DASHBOARDPUBDEVICES_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: DASHBOARDPUBDEVICES_LIST_FAIL, payload: error.message })
    }

}


const listDashBoardContDevices = () => async (dispatch) => {

    try {
        
        dispatch({ type: DASHBOARDCONTDEVICES_LIST_REQUEST})
        const ProvId = Cookie.get('econtent.user.id')
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/prov-device/?cont_prov_id=${ProvId}`)
        console.log("dashboard Details contdevices at actions", data)
        dispatch({ type: DASHBOARDPCONTDEVICES_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: DASHBOARDCONTDEVICES_LIST_FAIL, payload: error.message })
    }

}



export { listDashBoardHead,
    listDashBoardContHead,
    listDashBoardPubSchools,
    listDashBoardPublishers ,
    listDashBoardContProv,
    listDashBoardPubGraphs,
    listDashBoardContGraphs,
    listDashBoardContSchools,
    listDashBoardPubDevices,
    listDashBoardContDevices,
}