import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import Cookie from 'js-cookie';
import { listProfilUpdateCont, listProfilUpdatePub } from '../../../redux/actions/ProfileAction';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { listCompanyDetContGet, listCompanyDetContPost, listCompanyDetPubGet, listCompanyDetPubPost } from '../../../redux/actions/CompanyProfile';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';

function Alert2(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));





const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

const AccountProfileDetails = (props) => {
  const classes = useStyles();
  const role = Cookie.get('econtent.user.role')
  const dispatch = useDispatch()

  // const [values, setValues] = useState({
  //   firstName: props.userName,
  //   lastName: props.userName,
  //   email: props.userEmail,
  //   phone: '',
  //   accountName: 'Alabama',
  //   accountNum: 'USA',
  //   accountIfsc:"",


  // });


  const userId = Cookie.get("econtent.user.id");
  const companyName = Cookie.get("econtent.company.name");
  const companyEmail = Cookie.get("econtent.company.email");
  const companyWebsite = Cookie.get("econtent.company.website");
  const companyLogo = Cookie.get("econtent.company.logo");
  const companyAddress = Cookie.get("econtent.company.address");
  const companyPan = Cookie.get("econtent.company.pan");
  const companyGst = Cookie.get("econtent.company.gst");
  const companyPhone = Cookie.get("econtent.company.phone");
  const companyBankAccName = Cookie.get("econtent.company.accname");
  const companyBankAccNum = Cookie.get("econtent.company.accnum");
  const companyBankAccIFSC = Cookie.get("econtent.company.accifsc");
  const companyBankAccBranch = Cookie.get("econtent.company.bankbranch");
  const companyBankName = Cookie.get("econtent.company.bankname");


  console.log(
    "company details at commpany account details",
    userId,
    companyName,
    companyEmail,
    companyWebsite,
    companyLogo,
    companyAddress,
    companyPan,
    companyGst,
    companyPhone,
    companyBankAccName,
    companyBankAccNum,
    companyBankAccIFSC,
    companyBankAccBranch,
    companyBankName
  )

  const [Id, setId] = useState(userId)
  const [firstName, setFirstName] = useState(companyName)
  const [email, setEmail] = useState(companyEmail)
  const [phone, setPhone] = useState(companyPhone)
  const [logo, setLogo] = useState(companyLogo)
  const [website, setWebsite] = useState(companyWebsite)
  const [address, setAddress] = useState(companyAddress)
  const [pan, setPan] = useState(companyPan)
  const [gst, setGst] = useState(companyGst)
  const [accName, setAccName] = useState(companyBankAccName)
  const [accNum, setAccNum] = useState(companyBankAccNum)
  const [accBank, setAccBank] = useState(companyBankName)
  const [accIfsc, setAccIfsc] = useState(companyBankAccIFSC)
  const [accBranch, setAccBranch] = useState(companyBankAccBranch)
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };











  useEffect(() => {

    if (role == "EBOOK_PROVIDER") {
      dispatch(listCompanyDetPubGet())
    }

    if (role == "CONTENT_PROVIDER") {
      dispatch(listCompanyDetContGet())
    }


    return () => {
      //
    };
  }, []);

  const companyDetCont = useSelector(state => state.companyDetContGet)
  const { loadingCompContGet, CompContGet, errorCompContGet } = companyDetCont

  {
    loadingCompContGet && companyDetCont ?
      console.log("loading company data")
      :
      console.log("company details at companyProfilePage", CompContGet)
  }

  const UpdateUserDetails = () => {



    let userId = 0
    let companyName = ""
    let companyEmail = ""
    let companyWebsite = ""
    let companyLogo = ""
    let companyAddress = ""
    let companyPan = ""
    let companyGst = ""
    let companyPhone = " "
    let companyBankAccName = ""
    let companyBankAccNum = ""
    let companyBankAccIFSC = ""
    let companyBankAccBranch = ""
    let companyBankName = ""







    if (role == "EBOOK_PROVIDER") {

      userId = Id
      companyName = firstName
      companyEmail = email
      companyWebsite = website
      companyLogo = logo
      companyAddress = address
      companyPan = pan
      companyGst = gst
      companyPhone = phone
      companyBankAccName = accName
      companyBankAccNum = accNum
      companyBankAccIFSC = accIfsc
      companyBankAccBranch = accBranch
      companyBankName = accBank


      const body = {
        publisher_id: userId,
        name: companyName,
        email: companyEmail,
        logo: companyLogo,
        website: companyWebsite,
        address: companyAddress,
        logo: companyLogo,
        pan: companyPan,
        gst: companyGst,
        bank_account_name: companyBankAccName,
        bank_account_number: companyBankAccNum,
        banck_account_IFSC: companyBankAccIFSC,
        bank_branch: companyBankAccBranch,
        bank_name: companyBankName,
        phone_number: companyPhone,
      }


      Cookie.set('econtent.company.name', companyName);
      Cookie.set('econtent.company.email', companyEmail);
      Cookie.set('econtent.company.website', companyWebsite);
      Cookie.set('econtent.company.logo', companyLogo);
      Cookie.set('econtent.company.address', companyAddress);
      Cookie.set('econtent.company.pan', companyPan);
      Cookie.set('econtent.company.gst', companyGst);
      Cookie.set('econtent.company.phone', companyPhone);
      Cookie.set('econtent.company.accname', companyBankAccName);
      Cookie.set('econtent.company.accnum', companyBankAccNum);
      Cookie.set('econtent.company.accifsc', companyBankAccIFSC);
      Cookie.set('econtent.company.bankbranch', companyBankAccBranch);
      Cookie.set('econtent.company.bankname', companyBankName);
      // Cookie.set('econtent.user.pubuserid', pubUserId);
      // Cookie.set('econtent.user.Roles', userRoles)

      dispatch(listCompanyDetPubPost(body))

    }


    if (role == "CONTENT_PROVIDER") {

      userId = Id
      companyName = firstName
      companyEmail = email
      companyWebsite = website
      companyLogo = logo
      companyAddress = address
      companyPan = pan
      companyGst = gst
      companyPhone = phone
      companyBankAccName = accName
      companyBankAccNum = accNum
      companyBankAccIFSC = accIfsc
      companyBankAccBranch = accBranch
      companyBankName = accBank


      const body = {
        cont_prov_id: userId,
        name: companyName,
        email: companyEmail,
        logo: companyLogo,
        website: companyWebsite,
        address: companyAddress,
        logo: companyLogo,
        pan: companyPan,
        gst: companyGst,
        bank_account_name: companyBankAccName,
        bank_account_number: companyBankAccNum,
        banck_account_IFSC: companyBankAccIFSC,
        bank_branch: companyBankAccBranch,
        bank_name: companyBankName,
        phone_number: companyPhone,
      }

      Cookie.set('econtent.company.name', companyName);
      Cookie.set('econtent.company.email', companyEmail);
      Cookie.set('econtent.company.website', companyWebsite);
      Cookie.set('econtent.company.logo', companyLogo);
      Cookie.set('econtent.company.address', companyAddress);
      Cookie.set('econtent.company.pan', companyPan);
      Cookie.set('econtent.company.gst', companyGst);
      Cookie.set('econtent.company.phone', companyPhone);
      Cookie.set('econtent.company.accname', companyBankAccName);
      Cookie.set('econtent.company.accnum', companyBankAccNum);
      Cookie.set('econtent.company.accifsc', companyBankAccIFSC);
      Cookie.set('econtent.company.bankbranch', companyBankAccBranch);
      Cookie.set('econtent.company.bankname', companyBankName);


      dispatch(listCompanyDetContPost(body))

    }


    // Cookie.set('econtent.user.id', userId);
    // Cookie.set('econtent.user.name', userName);
    // Cookie.set('econtent.user.email', userEmail);
    // Cookie.set('econtent.user.photo', userPhoto);
    // Cookie.set('econtent.user.phone', userPhone);
    // Cookie.set('econtent.user.role', role);
    // Cookie.set('econtent.user.login', userLoggedIn);
    // Cookie.set('econtent.user.accname', userBankAccName);
    // Cookie.set('econtent.user.accnum', userBankAccNum);
    // Cookie.set('econtent.user.accifsc', userBankAccIFSC);
    // Cookie.set('econtent.user.bankbranch', userBankAccBranch);
    // Cookie.set('econtent.user.bankname', userBankName);
    // Cookie.set('econtent.user.pubuserid', pubUserId);
    // // Cookie.set('econtent.user.Roles', userRoles)


    setOpen(true)
  }

  useEffect(() => {


    dispatch(listCompanyDetContGet())
    return (() => {
      //
    })
  }, [])



  return (
    <form
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="Profile"

        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the full name"
                label="Company Name"
                name="company Name"
                placeholder="Ex: Pustak Publications"
                onChange={(e) => { setFirstName(e.target.value) }}
                required
                value={firstName}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                name="email"
                placeholder="Ex: pustak.publications@gmail.com"
                helperText="Please specify the email"
                onChange={(e) => { setEmail(e.target.value) }}
                required
                value={email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                placeholder="Ex: 9876543210"
                helperText="Please specify the phone number"
                onChange={(e) => { setPhone(e.target.value) }}
                type="text"
                value={phone}
                variant="outlined"
                required
              />


            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Website "
                name="Website"
                placeholder="Ex: https://www.pustakpublications.com"
                helperText="Please specify the phone number"
                onChange={(e) => { setWebsite(e.target.value) }}
                type="text"
                value={website}
                variant="outlined"
                required
              />


            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="PAN Number"
                name="PAN Number"
                placeholder="ABCDE1234F"
                helperText="Please specify the phone number"
                onChange={(e) => { setPan(e.target.value) }}
                type="text"
                value={pan}
                variant="outlined"
                required
              />


            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="GST Number"
                name="GST Number"
                placeholder="22ABCDE1234F1Z5"
                helperText="Please specify the phone number"
                onChange={(e) => { setGst(e.target.value) }}
                type="text"
                value={gst}
                variant="outlined"
                required
              />


            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Address"
                name="Address"
                placeholder="Ex: plotno:403,.....,hyderabad"
                helperText="Please specify the phone number"
                onChange={(e) => { setAddress(e.target.value) }}
                type="text"
                value={address}
                variant="outlined"
                multiline
                required
              />


            </Grid>
          </Grid>
        </CardContent>
        <div className={classes.root}>
          <Accordion >
            <AccordionSummary

              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Company Payment Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    helperText="Please specify the first name"
                    label="Account name"
                    name="AccountName"
                    placeholder="Ex: pustak publications"
                    onChange={(e) => { setAccName(e.target.value) }}
                    required
                    value={accName}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="AccountNum"
                    name="Account Number"
                    placeholder="345612307890"
                    helperText="Please specify the account number"
                    onChange={(e) => { setAccNum(e.target.value) }}
                    required
                    value={accNum}
                    variant="outlined"

                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Bank Name"
                    name="Bank Name"
                    placeholder="State Bank of India"
                    helperText="Please specify the bank name"
                    onChange={(e) => { setAccBank(e.target.value) }}
                    required
                    value={accBank}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="IFSC Code"
                    name="IFSC Code"
                    helperText="Please specify the IFSC Code"
                    placeholder="SBIN001001"
                    onChange={(e) => { setAccIfsc(e.target.value) }}
                    required
                    value={accIfsc}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Branch Name"
                    name="Branch Name"
                    placeholder="Jubilee Hills"
                    helperText="Please specify the Branch name"
                    onChange={(e) => { setAccBranch(e.target.value) }}
                    type="text"
                    value={accBranch}
                    variant="outlined"
                    required
                  />
                </Grid>

              </Grid>

            </AccordionDetails>
          </Accordion>

        </div>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          {
            firstName &&
              email &&
              phone &&
              website &&
              address &&
              pan &&
              gst &&
              accName &&
              accNum &&
              accBank &&
              accIfsc &&
              accBranch
              ?
              <Button
                color="primary"
                variant="contained"
                style={{ margin: 10 }}
                onClick={() => { UpdateUserDetails() }}
              >
                Save details
              </Button>
              :
              <Button
                disabled
                color="primary"
                variant="contained"
                style={{ margin: 10 }}
                onClick={() => { UpdateUserDetails() }}
              >
                Save details
              </Button>
          }


          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert2 onClose={handleClose} severity="success">
              Company details saved successfully...
            </Alert2>
          </Snackbar>
        </Box>
      </Card>
    </form>
  );
};

export default AccountProfileDetails;