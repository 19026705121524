import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@material-ui/core";
import CompanyAccountProfile from "./slots/CompanyAccountProfile";
import CompanyAccountProfileDetails from "./slots/CompanyAccountProfileDetails";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Cookie from "js-cookie";
import { useEffect } from "react";
import { listCompanyDetContGet } from "../../redux/actions/CompanyProfile";
import { useDispatch, useSelector } from "react-redux";

function CompanyProfilePage() {

  const userId = Cookie.get("econtent.user.id");
  const userName = Cookie.get("econtent.user.name");
  const userEmail = Cookie.get("econtent.user.email");
  const userPhoto = Cookie.get("econtent.user.photo");
  const userPhone = Cookie.get("econtent.user.phone");
  const userRole = Cookie.get("econtent.user.role");
  const userLogin = Cookie.get("econtent.user.login");
  const accname = Cookie.get("econtent.user.accname");
  const accnum = Cookie.get("econtent.user.accnum");
  const accifsc = Cookie.get("econtent.user.accifsc");
  const bankbranch = Cookie.get("econtent.user.bankbranch");
  const bankname = Cookie.get("econtent.user.bankname");
  const pubuserid = Cookie.get("econtent.user.pubuserid");



  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(listCompanyDetContGet())

    return () => {
      //
    };
  }, []);

  const companyDetCont = useSelector(state => state.companyDetContGet)
  const { loadingCompContGet, CompContGet, errorCompContGet } = companyDetCont

  {
    loadingCompContGet && companyDetCont ?
      console.log("loading company data")
      :
      console.log("company details at companyProfilePage", CompContGet)
  }
  return (
    <>
      <Helmet>
        <title>Company Profile | KGtoPG Partner </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <CompanyAccountProfile
                userId={userId}
                userName={userName}
                userEmail={userEmail}
                userPhoto={userPhoto}
                userRole={userRole}
                userLogin={userLogin}
                accname={accname}
                accnum={accnum}
                accifsc={accifsc}
                bankbranch={bankbranch}
                bankname={bankname}
                pubuserid={pubuserid}
                userPhone={userPhone}
              />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <CompanyAccountProfileDetails
                userId={userId}
                userName={userName}
                userEmail={userEmail}
                userPhoto={userPhoto}
                userRole={userRole}
                userLogin={userLogin}
                accname={accname}
                accnum={accnum}
                accifsc={accifsc}
                bankbranch={bankbranch}
                bankname={bankname}
                pubuserid={pubuserid}
                userPhone={userPhone}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default CompanyProfilePage;
