import React from 'react';
import moment from "moment";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Cookie from "js-cookie";
import { useEffect, useState } from "react";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import Constants from "../../../resource/Constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { listProfilUpdateCont, listProfilUpdatePub } from "../../../redux/actions/ProfileAction";
import { useDispatch } from "react-redux";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function Alert2(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  city: "Los Angeles",
  country: "USA",
  jobTitle: "Senior Developer",
  name: "Katarina Smith",
  timezone: "GTM-7",
};


const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

function AccountProfile(props) {
  const [crop, setCrop] = useState({ aspect: 16 / 9 });

  const userId = Cookie.get("econtent.user.id");
  const userName = Cookie.get("econtent.user.name");
  const userEmail = Cookie.get("econtent.user.email");
  const userPhoto = Cookie.get("econtent.user.photo");
  const userPhone = Cookie.get("econtent.user.phone");
  const userRole = Cookie.get("econtent.user.role");
  const userLogin = Cookie.get("econtent.user.login");
  const userCompanyId = Cookie.get("econtent.user.companyid");
  const accname = Cookie.get("econtent.user.accname");
  const accnum = Cookie.get("econtent.user.accnum");
  const accifsc = Cookie.get("econtent.user.accifsc");
  const bankbranch = Cookie.get("econtent.user.bankbranch");
  const bankname = Cookie.get("econtent.user.bankname");
  const contuserid = Cookie.get("econtent.user.contuserid");
  const pubuserid = Cookie.get("econtent.user.pubuserid");


  const [snackOpen, setSnackOpen] = React.useState(false);

  const handleSnackClick = () => {
    setOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const history = useHistory();
  const dispatch = useDispatch()
  // console.log("props at AccountProfile", props);


  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("")

  const [Id, setId] = useState(userId)
  const [firstName, setFirstName] = useState(userName)
  const [email, setEmail] = useState(userEmail)
  const [phone, setPhone] = useState(userPhone)
  const [photo, setPhoto] = useState(userPhoto)
  const [Role, setRole] = useState(userRole)
  const [login, setLogin] = useState(userLogin)
  const [companyId, setCompanyId] = useState(userCompanyId)
  const [accName, setAccName] = useState(accname)
  const [accNum, setAccNum] = useState(accnum)
  const [accBank, setAccBank] = useState(bankname)
  const [accIfsc, setAccIfsc] = useState(accifsc)
  const [accBranch, setAccBranch] = useState(bankbranch)
  const [contId, setContId] = useState(contuserid)
  const [pubId, setPubId] = useState(pubuserid)


  const role = Cookie.get('econtent.user.role')
  // const pubuserid = props.pubuserid


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadStart = () => {
    setIsUploading(true);

    console.log(`progress at ${0}`)
    setProgress(0)
  }

  const handleUploadError = (error) => {
    console.log(`upload error ${JSON.stringify(error)}`)

    setSnackOpen(true)
    setIsUploading(false)
    setProgress(0)
  }

  const handleProgress = (progress) => {
    console.log(`progress at ${progress}`)
    setProgress(progress)
  }

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("ebook")
      .child(filename)
      .getDownloadURL();

    console.log("download url is =====", downloadURL);

    if (downloadURL) {
      setProgress(0)
    }

    setImageDownloadUrls(downloadURL);


    if (downloadURL) {
      let userId = 0
      let userName = ""
      let userEmail = ""
      let userPhoto = ""
      let userPhone = ""
      let userCompanyId = ""
      let userBankAccName = ""
      let userBankAccNum = ""
      let userBankAccIFSC = ""
      let userBankAccBranch = ""
      let userBankName = ""
      let pubUserId = 0
      let contuserid = 0
      let userLoggedIn = false
      let userRoles = ""






      if (role == "EBOOK_PROVIDER") {

        userId = Id
        userName = firstName
        userEmail = email
        userPhoto = downloadURL
        userPhone = phone
        userLoggedIn = true
        userCompanyId = companyId
        userBankAccName = accName
        userBankAccNum = accNum
        userBankAccIFSC = accifsc
        userBankAccBranch = accBranch
        userBankName = accBank
        userRoles = Role




        const body = {


          "user_id": pubuserid,
          "pub_name_text": firstName,
          "pub_email_text": email,
          "pub_desc": "some",
          "pub_logo": downloadURL,
          "company_id": companyId,
          "bank_account_name": accName,
          "bank_account_number": accNum,
          "banck_account_IFSC": accIfsc,
          "bank_branch": accBranch,
          "bank_name": accBank,
          "phone_number": phone

        }
        Cookie.set('econtent.user.id', userId);
        Cookie.set('econtent.user.name', userName);
        Cookie.set('econtent.user.email', userEmail);
        Cookie.set('econtent.user.photo', userPhoto);
        Cookie.set('econtent.user.phone', userPhone);
        Cookie.set('econtent.user.companyid', userCompanyId);
        Cookie.set('econtent.user.accname', userBankAccName);
        Cookie.set('econtent.user.accnum', userBankAccNum);
        Cookie.set('econtent.user.accifsc', userBankAccIFSC);
        Cookie.set('econtent.user.bankbranch', userBankAccBranch);
        Cookie.set('econtent.user.bankname', userBankName);



 
        dispatch(listProfilUpdatePub(body))


      }


      if (role == "CONTENT_PROVIDER") {

        userId = Id
        userName = firstName
        userEmail = email
        userPhoto = downloadURL
        userPhone = phone
        userLoggedIn = true
        userCompanyId = companyId
        contuserid = contId
        userBankAccName = accName
        userBankAccNum = accNum
        userBankAccIFSC = accifsc
        userBankAccBranch = accBranch
        userBankName = accBank
        userRoles = Role


        const body = {
          "prov_name_text": firstName,
          "prov_email_text": email,
          "prov_cover_url": downloadURL,
          "company_id": companyId,
          "bank_account_name": accName,
          "bank_account_number": accNum,
          "banck_account_IFSC": accIfsc,
          "bank_branch": accBranch,
          "bank_name": accBank,
          "phone_number": phone,
          "user_id": contId,
        }

        Cookie.set('econtent.user.id', userId);
        Cookie.set('econtent.user.name', userName);
        Cookie.set('econtent.user.email', userEmail);
        Cookie.set('econtent.user.photo', userPhoto);
        Cookie.set('econtent.user.phone', userPhone);
        Cookie.set('econtent.user.companyid', userCompanyId);
        Cookie.set('econtent.user.accname', userBankAccName);
        Cookie.set('econtent.user.accnum', userBankAccNum);
        Cookie.set('econtent.user.accifsc', userBankAccIFSC);
        Cookie.set('econtent.user.bankbranch', userBankAccBranch);
        Cookie.set('econtent.user.bankname', userBankName);

        console.log("download url in if condition at content provider", body)

        dispatch(listProfilUpdateCont(body))

      }



      // Cookie.set('econtent.user.id', userId);
      // Cookie.set('econtent.user.name', userName);
      // Cookie.set('econtent.user.email', userEmail);
      // Cookie.set('econtent.user.photo', userPhoto);
      // Cookie.set('econtent.user.phone', userPhone);
      // Cookie.set('econtent.user.accname', userBankAccName);
      // Cookie.set('econtent.user.accnum', userBankAccNum);
      // Cookie.set('econtent.user.accifsc', userBankAccIFSC);
      // Cookie.set('econtent.user.bankbranch', userBankAccBranch);
      // Cookie.set('econtent.user.bankname', userBankName);
      // Cookie.set('econtent.user.pubuserid', pubUserId);

      // Cookie.set('econtent.user.Roles', userRoles)




    }
    // let userPhoto = downloadURL


    // Cookie.set('econtent.user.photo', userPhoto);



  }


  const LogoutUser = (props) => {
    const role = "";
    let userId = 0;
    let userName = "";
    let userEmail = "";
    let userPhoto = "";
    let userPhone = "";
    let userLoggedIn = false;
    let pubUserId = 0;
    let userRoles = ""

    //company details
    let companyName = ""
    let companyEmail = ""
    let companyWebsite = ""
    let companyLogo = ""
    let companyAddress = ""
    let companyPan = ""
    let companyGst = ""
    let companyPhone = ""
    let companyBankAccName = ""
    let companyBankAccNum = ""
    let companyBankAccIFSC = ""
    let companyBankAccBranch = ""
    let companyBankName = ""
    // let SUPERADMIN = ""
    // let CONTENTUPLOAD = ""
    // let QUALITYASSURANCE = ""
    // let CONTENTMAP = ""
    // let CURRICULUMMAP = ""
    // let TRAINER = ""
    // let EBOOKUPLOAD =""




    Cookie.set("econtent.user.id", userId);
    Cookie.set("econtent.user.name", userName);
    Cookie.set("econtent.user.email", userEmail);
    Cookie.set("econtent.user.photo", userPhoto);
    Cookie.set("econtent.user.phone", userPhone);
    Cookie.set("econtent.user.role", role);
    Cookie.set("econtent.user.login", userLoggedIn);
    Cookie.set("econtent.user.pubuserid", pubUserId);
    Cookie.set("econtent.user.Roles", userRoles);


    Cookie.set('econtent.company.name', companyName);
    Cookie.set('econtent.company.email', companyEmail);
    Cookie.set('econtent.company.logo', companyLogo);
    Cookie.set('econtent.company.phone', companyPhone);
    Cookie.set('econtent.company.website', companyWebsite);
    Cookie.set('econtent.company.address', companyAddress);
    Cookie.set('econtent.company.pan', companyPan);
    Cookie.set('econtent.company.gst', companyGst);
    Cookie.set('econtent.company.accname', companyBankAccName);
    Cookie.set('econtent.company.accnum', companyBankAccNum);
    Cookie.set('econtent.company.accifsc', companyBankAccIFSC);
    Cookie.set('econtent.company.bankbranch', companyBankAccBranch);
    Cookie.set('econtent.company.bankname', companyBankName);

    // Cookie.set('econtent.user.SUPERADMIN', SUPERADMIN);
    // Cookie.set('econtent.user.CONTENTUPLOAD', CONTENTUPLOAD);
    // Cookie.set('econtent.user.QUALITYASSURANCE', QUALITYASSURANCE);
    // Cookie.set('econtent.user.CONTENTMAP', CONTENTMAP);
    // Cookie.set('econtent.user.EBOOKUPLOAD', EBOOKUPLOAD);
    // Cookie.set('econtent.user.CURRICULUMMAP', CURRICULUMMAP);
    // Cookie.set('econtent.user.TRAINER', TRAINER);



    console.log(
      "cookies data id+++++++++++++++++",
      Cookie.get("econtent.user.id")
    );
    console.log(
      "cookies data name+++++++++++++++++",
      Cookie.get("econtent.user.name")
    );
    console.log(
      "cookies data email+++++++++++++++++",
      Cookie.get("econtent.user.email")
    );
    console.log(
      "cookies data photo+++++++++++++++++",
      Cookie.get("econtent.user.photo")
    );
    console.log(
      "cookies data role+++++++++++++++++",
      Cookie.get("econtent.user.role")
    );
    console.log(
      "cookies data login+++++++++++++++++",
      Cookie.get("econtent.user.login")
    );

    console.log(
      "cookies data Roles at logout+++++++++++++++++",
      Cookie.get("econtent.user.Roles")
    );
    // console.log("cookies data SUPERADMIN+++++++++++++++++", Cookie.get('econtent.user.SUPERADMIN'))
    // console.log("cookies data CONTENTUPLOAD+++++++++++++++++", Cookie.get('econtent.user.CONTENTUPLOAD'))
    // console.log("cookies data QUALITYASSURANCE+++++++++++++++++", Cookie.get('econtent.user.QUALITYASSURANCE'))
    // console.log("cookies data CONTENTMAP+++++++++++++++++", Cookie.get('econtent.user.CONTENTMAP'))
    // console.log("cookies data EBOOKUPLOAD+++++++++++++++++", Cookie.get('econtent.user.EBOOKUPLOAD'))
    // console.log("cookies data CURRICULUMMAP+++++++++++++++++", Cookie.get('econtent.user.CURRICULUMMAP'))
    // console.log("cookies data TRAINER+++++++++++++++++", Cookie.get('econtent.user.TRAINER'))

    history.push("/");
  };





  return (
    <div data-tut="profile-step">
      <Card {...props}>
        <CardContent>
          <Box
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {props.userPhoto ? (
              <ReactCrop src={(ImageDownloadUrls) ? ImageDownloadUrls : props.userPhoto}
                crop={crop} onChange={newCrop => setCrop(newCrop)} />

              /* 
                            <img
                              src={(ImageDownloadUrls) ? ImageDownloadUrls : props.userPhoto}
                              style={{
                                height: 225,
                                width: 225,
                                borderRadius: 10,
                                objectFit: "contain",
                              }}
                            />
                             */
            ) : (
              <Avatar
                src={props.userPhoto}
                style={{
                  height: 225,
                  width: 225,
                  borderRadius: 10,
                  objectFit: "contain",
                }}
              />
            )}

            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
              style={{ marginTop: 10 }}
            >
              {props.userName}
            </Typography>

            {/* <Typography
              color="textSecondary"
              variant="body1"
            >
              {`${user.city} ${user.country}`}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              {`${moment().format('hh:mm A')} ${user.timezone}`}
            </Typography> */}
          </Box>
        </CardContent>
        <Divider />
        <CardActions style={{ display: "flex", justifyContent: "center" }}>


          <div style={{ display: "flex", justifyContent: "flex-end", marginLeft: 20 }}>
            {progress ?

              <CircularProgress />
              :
              <label style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: 'steelblue', borderRadius: 4, cursor: 'pointer', textAlign: "center" }}>
                UPLOAD PICTURE
                <FileUploader
                  hidden
                  // accept="image/*"
                  accept="image/x-png,image/gif,image/jpeg"
                  storageRef={firebase.storage().ref('ebook')}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={handleUploadSingleSuccess}
                  onProgress={handleProgress}
                />

                <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                  <Alert2 onClose={handleSnackClose} severity="error">
                    There was an error uploading the image.... try another!
                  </Alert2>
                </Snackbar>
              </label>
            }
          </div>


        </CardActions>
      </Card>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: 15,
        }}
        data-tut="logout-step"
      >
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
          onClick={handleClickOpen}
          fullWidth
        >
          Logout
        </Button>
      </div>

      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Logout"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure,do you want to logout ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button
              onClick={(props) => {
                LogoutUser(props);
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default AccountProfile;
