import React from 'react';
import moment from "moment";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Cookie from "js-cookie";
import { useEffect, useState } from "react";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import Constants from "../../../resource/Constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { listProfilUpdateCont, listProfilUpdatePub } from "../../../redux/actions/ProfileAction";
import { useDispatch } from "react-redux";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


function Alert2(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  city: "Los Angeles",
  country: "USA",
  jobTitle: "Senior Developer",
  name: "Katarina Smith",
  timezone: "GTM-7",
};


const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

function AccountProfile(props) {

  const role = Cookie.get('econtent.user.role')
  const history = useHistory();
  const dispatch = useDispatch()



  const userId = Cookie.get("econtent.user.id");
  const companyName = Cookie.get("econtent.company.name");
  const companyEmail = Cookie.get("econtent.company.email");
  const companyWebsite = Cookie.get("econtent.company.website");
  const companyLogo = Cookie.get("econtent.company.logo");
  const companyAddress = Cookie.get("econtent.company.address");
  const companyPan = Cookie.get("econtent.company.pan");
  const companyGst = Cookie.get("econtent.company.gst");
  const companyPhone = Cookie.get("econtent.company.phone");
  const companyBankAccName = Cookie.get("econtent.company.accname");
  const companyBankAccNum = Cookie.get("econtent.company.accnum");
  const companyBankAccIFSC = Cookie.get("econtent.company.accifsc");
  const companyBankAccBranch = Cookie.get("econtent.company.bankbranch");
  const companyBankName = Cookie.get("econtent.company.bankname");


  console.log(
    "company details at commpany account details",
    userId,
    companyName,
    companyEmail,
    companyWebsite,
    companyLogo,
    companyAddress,
    companyPan,
    companyGst,
    companyPhone,
    companyBankAccName,
    companyBankAccNum,
    companyBankAccIFSC,
    companyBankAccBranch,
    companyBankName
  )

  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [snackOpen, setSnackOpen] = React.useState(false);

  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("")

  const [Id, setId] = useState(userId)
  const [firstName, setFirstName] = useState(companyName)
  const [email, setEmail] = useState(companyEmail)
  const [phone, setPhone] = useState(companyPhone)
  const [logo, setLogo] = useState(companyLogo)
  const [website, setWebsite] = useState(companyWebsite)
  const [address, setAddress] = useState(companyAddress)
  const [pan, setPan] = useState(companyPan)
  const [gst, setGst] = useState(companyGst)
  const [accName, setAccName] = useState(companyBankAccName)
  const [accNum, setAccNum] = useState(companyBankAccNum)
  const [accBank, setAccBank] = useState(companyBankName)
  const [accIfsc, setAccIfsc] = useState(companyBankAccIFSC)
  const [accBranch, setAccBranch] = useState(companyBankAccBranch)


  console.log("cropped image is ",crop)


  const handleSnackClick = () => {
    setOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };


  // console.log("props at AccountProfile", props);


  // const pubuserid = props.pubuserid


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadStart = () => {
    setIsUploading(true);

    console.log(`progress at ${0}`)
    setProgress(0)
  }

  const handleUploadError = (error) => {
    console.log(`upload error ${JSON.stringify(error)}`)

    setSnackOpen(true)
    setIsUploading(false)
    setProgress(0)
  }

  const handleProgress = (progress) => {
    console.log(`progress at ${progress}`)
    setProgress(progress)
  }

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("ebook")
      .child(filename)
      .getDownloadURL();

    console.log("download url is =====", downloadURL);

    if (downloadURL) {
      setProgress(0)
    }

    setImageDownloadUrls(downloadURL);


    // if (downloadURL) {


    //   let userId = 0
    //   let userName = ""
    //   let userEmail = ""
    //   let userPhoto = ""
    //   let userPhone = ""
    //   let userBankAccName = ""
    //   let userBankAccNum = ""
    //   let userBankAccIFSC = ""
    //   let userBankAccBranch = ""
    //   let userBankName = ""
    //   let pubUserId = 0
    //   let userLoggedIn = false
    //   let userRoles = ""





    //   if (role == "EBOOK_PROVIDER") {

    //     userId = Id
    //     userName = firstName
    //     userEmail = email
    //     userPhoto = downloadURL
    //     userPhone = phone
    //     userLoggedIn = true
    //     userBankAccName = accName
    //     userBankAccNum = accNum
    //     userBankAccIFSC = accifsc
    //     userBankAccBranch = accBranch
    //     userBankName = accBank
    //     userRoles = Role




    //     const body = {


    //       "user_id": pubuserid,
    //       "pub_name_text": firstName,
    //       "pub_email_text": email,
    //       "pub_desc": "some",
    //       "pub_logo": downloadURL,
    //       "bank_account_name": accName,
    //       "bank_account_number": accNum,
    //       "banck_account_IFSC": accIfsc,
    //       "bank_branch": accBranch,
    //       "bank_name": accBank,
    //       "phone_number": phone

    //     }

    //     console.log("download url in if condition at ebbok provider", body)

    //     dispatch(listProfilUpdatePub(body))

    //   }


      // if (role == "CONTENT_PROVIDER") {

      //   userId = Id
      //   userName = firstName
      //   userEmail = email
      //   userPhoto = downloadURL
      //   userPhone = phone
      //   userLoggedIn = true
      //   userBankAccName = accName
      //   userBankAccNum = accNum
      //   userBankAccIFSC = accifsc
      //   userBankAccBranch = accBranch
      //   userBankName = accBank
      //   userRoles = Role


      //   const body = {
      //     "prov_name_text": firstName,
      //     "prov_email_text": email,
      //     "prov_cover_url": downloadURL,
      //     "bank_account_name": accName,
      //     "bank_account_number": accNum,
      //     "banck_account_IFSC": accIfsc,
      //     "bank_branch": accBranch,
      //     "bank_name": accBank,
      //     "phone_number": phone,
      //   }

      //   console.log("download url in if condition at content provider", body)

      //   dispatch(listProfilUpdateCont(body))

      // }


    //   Cookie.set('econtent.user.id', userId);
    //   Cookie.set('econtent.user.name', userName);
    //   Cookie.set('econtent.user.email', userEmail);
    //   Cookie.set('econtent.user.photo', downloadURL);
    //   Cookie.set('econtent.user.phone', userPhone);
    //   Cookie.set('econtent.user.role', role);
    //   Cookie.set('econtent.user.login', userLoggedIn);
    //   Cookie.set('econtent.user.accname', userBankAccName);
    //   Cookie.set('econtent.user.accnum', userBankAccNum);
    //   Cookie.set('econtent.user.accifsc', userBankAccIFSC);
    //   Cookie.set('econtent.user.bankbranch', userBankAccBranch);
    //   Cookie.set('econtent.user.bankname', userBankName);
    //   Cookie.set('econtent.user.pubuserid', pubUserId);
    //   // Cookie.set('econtent.user.Roles', userRoles)




    // }
    // let userPhoto = downloadURL


    // Cookie.set('econtent.user.photo', userPhoto);



  }




  return (
    <div>
      <Card {...props}>
        <CardContent>
          <Box
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {props.userPhoto ? (
              <ReactCrop src={(ImageDownloadUrls) ? ImageDownloadUrls : props.userPhoto} 
              crop={crop} onChange={newCrop => setCrop(newCrop)} />

/* 
              <img
                src={(ImageDownloadUrls) ? ImageDownloadUrls : props.userPhoto}
                style={{
                  height: 225,
                  width: 225,
                  borderRadius: 10,
                  objectFit: "contain",
                }}
              />
 */

            ) : (
              <Avatar
                src={logo}
                style={{
                  height: 225,
                  width: 225,
                  borderRadius: 10,
                  objectFit: "contain",
                }}
              />
            )}

            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
              style={{ marginTop: 10 }}
            >
              {firstName}
            </Typography>

            {/* <Typography
              color="textSecondary"
              variant="body1"
            >
              {`${user.city} ${user.country}`}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              {`${moment().format('hh:mm A')} ${user.timezone}`}
            </Typography> */}
          </Box>
        </CardContent>
        <Divider />
        <CardActions style={{ display: "flex", justifyContent: "center" }}>


          <div style={{ display: "none", justifyContent: "flex-end", marginLeft: 20 }}>
            {progress ?

              <CircularProgress />
              :
              <label style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: 'grey', borderRadius: 4, cursor: 'normal', textAlign: "center" }}>
                UPLOAD PICTURE

                
               <FileUploader
                  hidden
                  // accept="image/*"
                  accept="image/x-png,image/gif,image/jpeg"
                  storageRef={firebase.storage().ref('ebook')}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={handleUploadSingleSuccess}
                  onProgress={handleProgress}
                />

                <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                  <Alert2 onClose={handleSnackClose} severity="error">
                    There was an error uploading the image.... try another!
                  </Alert2>
                </Snackbar>
               
              </label>
            }
          </div>


        </CardActions>
      </Card>



    </div>
  );
}

export default AccountProfile;
