import axios from "axios";
import Constants from "../../resource/Constants";
import { PUBUSERSDETAILS_LIST_REQUEST, PUBUSERSDETAILS_LIST_SUCCESS, PUBUSERSDETAILS_LIST_FAIL } from "../constants/PubUsersListConstants";
import Cookie from 'js-cookie';
import { CONTUSERSDETAILS_LIST_REQUEST, CONTUSERSDETAILS_LIST_SUCCESS, CONTUSERSDETAILS_LIST_FAIL } from "../constants/PubUsersListConstants";





const listPubUsers = (instId, boardId, stdId, subjectId) => async (dispatch) => {
    try {
        const ProvId = Cookie.get('econtent.user.id')
        dispatch({ type: PUBUSERSDETAILS_LIST_REQUEST })
        // const url = Constants.Application.PLATFORM_END_URL +`/ebook-class/?publisher_id=${ProvId}&inst_board_id=${boardId}&subject_id=${subjectId}&inst_type_id=${instId}&cls_std=${stdId}`
        const url = Constants.Application.PLATFORM_END_URL + `/list-schools/?publisher_id=${ProvId}&inst_type_id=${instId}&inst_board_id=${boardId}&subject_id=${subjectId}&cls_std=${stdId}`

        /*
        const body = {
            publisher_id: `${ProvId}`,
            inst_type_id: `${instId}`,
            inst_board_id: `${boardId}`,
            subject_id: `${subjectId}`,
            cls_std: `${stdId}`
        }
        */


        console.log("details at list schools url", url, ProvId)
        const { data } = await axios.get(url)
        console.log("data in list schools  get", data)
        dispatch({ type: PUBUSERSDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: PUBUSERSDETAILS_LIST_FAIL, payload: error.message })

    }
}



const listPubSubs = () => async (dispatch) => {
    try {
        const ProvId = Cookie.get('econtent.user.id')
        dispatch({ type: PUBUSERSDETAILS_LIST_REQUEST })
        // const url = Constants.Application.PLATFORM_END_URL +`/ebook-class/?publisher_id=${ProvId}&inst_board_id=${boardId}&subject_id=${subjectId}&inst_type_id=${instId}&cls_std=${stdId}`
        const url = Constants.Application.PLATFORM_END_URL + `/list-subscribers/?publisher_id=${ProvId}&inst_type_id=&inst_board_id=&subject_id=&cls_std=`

        /*
        const body = {
            publisher_id: `${ProvId}`,
            inst_type_id: `${instId}`,
            inst_board_id: `${boardId}`,
            subject_id: `${subjectId}`,
            cls_std: `${stdId}`
        }
        */


        console.log("details at list subscribers url", url, ProvId)
        const { data } = await axios.get(url)
        console.log("data in list subscribers  get", data)
        dispatch({ type: PUBUSERSDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: PUBUSERSDETAILS_LIST_FAIL, payload: error.message })

    }
}


const listContUsers = (instId, boardId, stdId, subjectId) => async (dispatch) => {
    try {
        const ProvId = Cookie.get('econtent.user.id')
        dispatch({ type: CONTUSERSDETAILS_LIST_REQUEST })
        const url = Constants.Application.PLATFORM_END_URL + `/prov-users/?cont_prov_id=${ProvId}&inst_board_id=${boardId}&subject_id=${subjectId}&inst_type_id=${instId}&cls_std=${stdId}`
        console.log("details at url", url, ProvId)
        const { data } = await axios.get(url)
        console.log("data in list subjects  get", data)
        dispatch({ type: CONTUSERSDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: CONTUSERSDETAILS_LIST_FAIL, payload: error.message })

    }
}


export { listPubUsers, listPubSubs, listContUsers }