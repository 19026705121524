

import React, { Component } from "react";
import { ReactReader } from "react-reader";
import book from "../../assets/famouspaintings.epub"
import Fab from '@material-ui/core/Fab';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import Draggable from 'react-draggable';
// import book1 from "../../assets/AlicesAdventures.epub"
import Button from '@material-ui/core/Button';
import { Route, Link, BrowserRouter, Switch } from 'react-router-dom'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Drawer, Paper } from "@material-ui/core";
import UploadEbookScreen from "./UploadEbookScreen"

import API from '../../http/http'
import PARTNERAPI from '../../http/httppartner'
import PUBLISHERAPI from '../../http/publisher'
import axios from "axios";
import book2 from "../../assets/AroundtheWorld.epub"
import { ContactMailSharp, ContactSupportOutlined } from "@material-ui/icons";
import ReactPlayer from 'react-player'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LeftCard from "./slots/LeftCard"
import Leftbar from "../contentmaker/slots/Leftbar";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const drawerWidth = 250;








const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue"
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize: 14

    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0
    },
}));








const data = [
    {
        color: "red",
        value: "#f00",
        page: 2,
        title: "introduction2",
        x_axis: 300,
        y_axis: 1000,
    },
    {
        color: "green",
        value: "#0f0",
        page: 4,
        title: "introduction5",
        x_axis: 300,
        y_axis: 400,
    },
    {
        color: "blue",
        value: "#00f",
        page: 6,
        title: "introduction10",
        x_axis: 650,
        y_axis: 700,
    },
    {
        color: "cyan",
        value: "#0ff",
        page: 8,
        title: "introduction12",
        x_axis: 800,
        y_axis: 710,
    },
    {
        color: "magenta",
        value: "#f0f",
        page: 10,
        title: "introduction14",
        x_axis: 700,
        y_axis: 900,
    },
    {
        color: "yellow",
        value: "#ff0",
        page: 12,
        title: "introduction16",
        x_axis: 900,
        y_axis: 500,
    },
    {
        color: "black",
        value: "#000",
        page: 14,
        title: "introduction18",
        x_axis: 600,
        y_axis: 800,
    }
]


const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}


const DraggableDialog = () => {


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };




    return (

        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Open form dialog
        </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Subscribe
          </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To subscribe to this website, please enter your email address here. We will send updates
                        occasionally.
            </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Cancel
            </Button>
                    <Button onClick={handleClose} color="primary">
                        Subscribe
            </Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}




class EpubView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            location: "epubcfi(/6/2[cover]!/6)",
            data: null,
            showButton: true,
            right: false,
            book_id: null,
            ebookContentURL: null,
            ebookContent: null,
            // showButton:true
            book1: "http://www.africau.edu/images/default/sample.pdf",
            x_pos: null,
            y_pos: null,
            page_num: null,
            sub_id: null,
            cont_id: null,
            showMapButton: false,
            providers: null,
            mappedData: "",
            open: false,

        }

    }


    epubLocation(epubcifi) {
        console.log("location changing at epubLocation function", epubcifi)
        this.setState({ location: epubcifi, showMapButton: false, showButton: false })
    }


    updateMouseposition() {
        this.setState({ location: "epubcfi(/6/" + data[0].page + "[cover]!/6)" })
    }




    async getEbookContent(book_id) {
        const resp = await PUBLISHERAPI.GetEbookContent(book_id)
        console.log("response", (resp.data.data.ebook_location_url, resp.data.data))
        this.setState({ ebookContentURL: resp.data.data.ebook_location_url, ebookContent: resp.data.data })
    }


    async postEbookContent(bookid, contid, subid, xpos, ypos, pagenum, chaptername, contenturl) {


        console.log("post ebookcontent", bookid, contid, subid, xpos, ypos, pagenum, chaptername, contenturl)

        let DataObj = {
            content_id: contid,
            subject_id: subid,
            book_id: bookid,
            x_pos: xpos,
            y_pos: ypos,
            page_num: pagenum,
            chapter_name: chaptername,
            content_location: contenturl
        }


        console.log("book details =======================================", DataObj)

        const resp = PUBLISHERAPI.postEbookMappedContent(DataObj)
        console.log(`response ${JSON.stringify(resp)}`)

        if (resp) {
            this.getEbookMappedContent(bookid)
        }


    }


    async getEbookMappedContent(book_id) {
        const resp = await PUBLISHERAPI.getEbookMappedContent(book_id)
        console.log(`response ${JSON.stringify(resp.data)}`)
        if (resp && resp.data.data) {
            console.log("ebook dataa ++++++++++", resp.data)

            this.setState({ mappedData: resp.data })
        }


    }


    handleClickOpen() {
        console.log("handle click open is working")
        this.setState({ open: true })
    }

    CloseHandleClick() {
        console.log("handle click close is working")
        this.setState({ open: false })
    }


    componentDidMount() {
        console.log("data in epub componenet", data[0])
        // this.updateMouseposition()
        // this.getebookdata()

        this.setState({ showButton: this.props.showButtonStatus })
        console.log("state status", this.state.showButton)
        const query = new URLSearchParams(window.location.search)
        // console.log("query-=============", query.get('book_id'))
        this.setState({ book_id: query.get('book_id') },
            () => {
                console.log("book id in state", this.state.book_id)

                this.getEbookContent(this.state.book_id)
                this.getEbookMappedContent(this.state.book_id)
            }
        )

    }


    eventLogger = (e: MouseEvent, data: Object) => {

        console.log('Event: ', e);
        console.log('Data: ', data);
        console.log('Data x: ', data.x);
        console.log('Data y: ', data.y);
        this.setState({ x_pos: data.x, y_pos: data.y, showMapButton: false },
            () => {
                console.log("state x y data ", this.state.x_pos, this.state.y_pos)
            }
        )

        // this.setState({ showMapButton: false })

    };


    handleStop = () => {

        // let DataObj = {
        //     content_id: contid,
        //     subject_id: subid,
        //     book_id: bookid,
        //     x_pos: xpos,
        //     y_pos: ypos,
        //     page_num: pagenum,
        //     chapter_name: chaptername,
        //     content_location: contenturl
        // }

        // console.log("final x y pos", bookid, contid, subid, xpos, ypos, pagenum, chaptername, contenturl)

        this.setState({ showMapButton: true })
        console.log("stopping the button ")
    }


    handleStart = () => {
        this.setState({ showMapButton: false })
    }


    toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ right: true })

    }








    render() {


        const { location, showButton, ebookContent, mappedData, showMapButton } = this.state;
        console.log("data at render", data[0])
        console.log("props at epub reader", this.props)


        return (
            <div>
                <div>

                    {(this.props && this.props.showButtonStatus) ?
                        <div style={{ position: "absolute", zIndex: 2 }} >
                            <Draggable
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: 200, y: 0 }}
                                position={null}
                                grid={[50, 50]}
                                scale={1}
                                onStart={this.handleStart}
                                onDrag={this.eventLogger}
                                onStop={this.handleStop}
                                style={{ zIndex: 2, position: "absolute" }}
                            >

                                <div>
                                    <div className="handle">
                                        <Fab aria-label="add" style={{ backgroundColor: "#ffffff" }}>
                                            <PlayArrowOutlinedIcon color="disabled" style={{ fontSize: 30, color: "grey" }} />
                                        </Fab>
                                    </div>


                                </div>
                            </Draggable>
                        </div>
                        :

                        <div></div>
                    }


                    <div style={{ height: 650, justifyContent: "center", alignItems: "center", position: "relative" }}>


                        {location && mappedData && mappedData.length != 0 ?

                            mappedData.data.map((list, index) => {
                                return (

                                    list.page_num == location ?

                                        <div style={{ position: "absolute", top: list.y_pos, left: list.x_pos, display: "flex", zIndex: 3 }}>

                                            <Fab aria-label="add" style={{ backgroundColor: "#ffffff" }} onClick={() => { this.handleClickOpen() }}>
                                                <PlayArrowOutlinedIcon color="disabled" style={{ fontSize: 30, color: "grey" }} />
                                            </Fab>

                                        </div>

                                        :
                                        console.log("mapping data is not mapped",)
                                )
                            })
                            :
                            <></>

                        }



                        <Dialog
                            open={this.state.open}
                            onClose={() => { this.CloseHandleClick() }}
                            PaperComponent={PaperComponent}
                            aria-labelledby="draggable-dialog-title"
                        >
                            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                                Subscribe
                                </DialogTitle>
                            <DialogContent style={{ width: "100%" }}>
                                <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' width={"550px"} />
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => { this.CloseHandleClick() }} color="primary">
                                    Close
                                    </Button>

                            </DialogActions>
                        </Dialog>


                        <ReactReader
                            url={this.state.ebookContentURL}
                            // url={book2}
                            // title={"Alice in wonderland"}
                            location={location}
                            locationChanged={epubcifi => { this.epubLocation(epubcifi) }}
                            style={{ height: 800, alignItems: "center" }}
                        />

                    </div>






                    <div style={{ display: "flex", justifyContent: "flex-end" }}>

                        {showMapButton ?
                            <div style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: 10
                            }}
                                onClick={() => {
                                    this.postEbookContent(
                                        this.state.book_id,
                                        this.props.readerDataList.id,
                                        this.props.readerDataList.subject_id,
                                        this.state.x_pos,
                                        this.state.y_pos,
                                        this.state.location,
                                        this.state.location,
                                        this.props.readerDataList.content_location,
                                    )
                                }}>


                                <Button variant="contained" color="primary" style={{ fontFamily: "Poppins, Helvetica, sans-serif", textTransform: "none" }}>
                                    Map content
                                </Button>
                            </div>
                            :
                            <></>

                        }


                    </div>
                </div>
            </div>

        );
    }
}

export default EpubView;

