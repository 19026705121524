import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import HelpIcon from "@material-ui/icons/Help";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Divider, Grid, Toolbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  ArrowBack,
  CloudDoneOutlined,
  FilterTiltShiftRounded,
  MonetizationOn,
  Refresh,
} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { useDispatch, useSelector } from "react-redux";
import { listBoardGet } from "../../redux/actions/boardDetailsAction";
import { boardDetailsReducer } from "../../redux/reducers/boardDetailsReducer";
import { listInstGet } from "../../redux/actions/InstituteDetailsAction";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import IconButton from "@material-ui/core/IconButton";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Cookie from "js-cookie";
import { useHistory } from "react-router-dom";

import clsx from "clsx";
import { lighten } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Container from "@material-ui/core/Container";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import Constants from "../../resource/Constants";
import SidebarContentCont from "./SidebarScreenCont";
import Leftbar from "./slots/Leftbar";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import WifiIcon from "@material-ui/icons/Wifi";
import BluetoothIcon from "@material-ui/icons/Bluetooth";
import { Helmet } from "react-helmet";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ProfilePage from "../user/ProfilePage";

import CompanyProfilePage from "../user/CompanyProfilePage";
import AdminControls from "./slots/AdminControls/AdminControlsPage";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import Copyright from "../user/slots/Copyright";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import Tour from 'reactour'
import ChangePasswordCont from "./slots/Settings/ChangePassword";
import ChangeEmailCont from "./slots/Settings/ChangeEmail";
import ChangeMobileCont from "./slots/Settings/ChangeMobile";

import PropTypes2 from 'prop-types';
import Tabs2 from '@mui/material/Tabs';
import Tab2 from '@mui/material/Tab';
import Typography2 from '@mui/material/Typography';
import Box2 from '@mui/material/Box';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Breadcrumbs2 from '@mui/material/Breadcrumbs';
import Link2 from '@mui/material/Link';

const steps = [
  {
    selector: '[data-tut="first-step"]',
    content: 'The tabs separate actions for the settings',
  },

  // ...
];

const useStyles3 = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    // backgroundColor: theme.palette.backgr ound.paper,
  },
}));

const rows = [
  createData("Expert Level"),
  createData("Intermediate Level"),
  createData("Basic Level"),
];

const rows1 = [
  createData("Expert Level"),
  createData("Intermediate Level"),
  createData("Beginner Level"),
];

function Copyright2() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        KgtoPg
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function createData(name) {
  return { name };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Standards" },
];

const drawerWidth = 250;

const useStyles2 = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbar1: {
    paddingRight: 24,
    height: 40,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon1: {
    top: "45vh",
    bottom: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // marginLeft:70,
    backgroundColor: "#1976d2",
    color: "#ffffff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "blue",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#ffffff",
    scrollbarColor: "green",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  large: {
    width: "80%",
    height: 50,
    borderRadius: 0,
  },
}));

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          Teaching
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton
            aria-label="filter list"
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const EnhancedTableToolbar1 = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          Learning
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton
            aria-label="filter list"
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const EnhancedTable = () => {
  const classes = useStyles1();
  const classes2 = useStyles2();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                      >
                        {row.name}
                      </TableCell>
                      {/* <TableCell align="right">{row.calories}</TableCell>
                                            <TableCell align="right">{row.fat}</TableCell>
                                            <TableCell align="right">{row.carbs}</TableCell>
                                            <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 35]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        />
      </Paper>
    </div>
  );
};

const EnhancedTable1 = () => {
  const classes = useStyles1();
  const classes2 = useStyles2();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar1 numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                      >
                        {row.name}
                      </TableCell>
                      {/* <TableCell align="right">{row.calories}</TableCell>
                                            <TableCell align="right">{row.fat}</TableCell>
                                            <TableCell align="right">{row.carbs}</TableCell>
                                            <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 35]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        />
      </Paper>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}



const ServicesStatus = (props) => {
  const [disabled, setDisabled] = useState(false)
  const handleChange = (event) => {
    const { name, value, checked } = event.target;

    console.log('event after change ', event.target)
    console.log('name ', name)
    console.log('value ', value)
    console.log('checked ', checked)

    setDisabled(checked)
  }
  return (
    <>
      <Alert severity="warning">
        <AlertTitle>Warning</AlertTitle>
        Please be aware that this will stop services to all the schools and it may cause inconvenience — <strong>proceed with caution!</strong>
      </Alert>


      <FormControlLabel control={<Switch disabled={true} color="warning"
        onChange={handleChange} />} label="Disable Services" style={{ color: 'red', marginTop: 50 }} />


      {disabled &&
        <Alert variant="outlined" severity="error">
          Please note the stoppage of services will commence 30 days from now.<br /> If you change your mind, you can always switch services back on!
        </Alert>
      }

    </>
  )
}

function handleClick2(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

function BasicBreadcrumbs() {
  return (
    <div role="presentation" onClick={handleClick2}>
      <Breadcrumbs2 aria-label="breadcrumb">
        <Link2 underline="hover" color="inherit" href="/">
          MUI
        </Link2>
        <Link2
          underline="hover"
          color="inherit"
          href="/getting-started/installation/"
        >
          Core
        </Link2>
        <Typography color="text.primary">Breadcrumbs</Typography>
      </Breadcrumbs2>
    </div>
  );
}

{/* vertical tabs setup */ }
function TabPanelVert(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box2 sx={{ p: 3 }}>
          <Typography2>{children}</Typography2>
        </Box2>
      )}
    </div>
  );
}

TabPanelVert.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps2(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box2
      sx={{
        flexGrow: 1, bgcolor: 'background.paper', display: 'flex',
        width: '100%'
      }}
    >
      <Tabs2
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab2 label="Change Password" {...a11yProps2(0)} />
        <Tab2 label="Change Email" {...a11yProps2(1)} />
        <Tab2 label="Change Mobile" {...a11yProps2(2)} />
        <Tab2 label="Services Status" {...a11yProps2(3)} />
        {/* 
        <Tab2 label="Item Five" {...a11yProps2(4)} />
        <Tab2 label="Item Six" {...a11yProps2(5)} />
        <Tab label="Item Seven" {...a11yProps2(6)} />
         */}
      </Tabs2>
      <TabPanelVert value={value} index={0}>
        <ChangePasswordCont />
      </TabPanelVert>
      <TabPanelVert value={value} index={1}>
        <ChangeEmailCont />
      </TabPanelVert>
      <TabPanelVert value={value} index={2}>
        <ChangeMobileCont />
      </TabPanelVert>
      <TabPanelVert value={value} index={3}
        style={{ width: '80%' }}>
        <ServicesStatus />
      </TabPanelVert>

      {/*       
      <TabPanelVert value={value} index={4}>
        Item Five
      </TabPanelVert>
      <TabPanelVert value={value} index={5}>
        Item Six
      </TabPanelVert>
      <TabPanelVert value={value} index={6}>
        Item Seven
      </TabPanelVert>
       */}
    </Box2>
  );

}


export default function Settings() {
  const history = useHistory();
  const classes = useStyles2();
  const classes3 = useStyles3();
  const [value, setValue] = React.useState(0);
  const [age, setAge] = React.useState("");
  const [showInputField, SetShowInputField] = useState(false);
  const [intialValue1, setInitialValue1] = useState(0);
  const [selectBoardDetails, setSelectedBoardDetails] = useState("");
  const [newfieldCount, setnewfieldCount] = useState(4);
  const [open, setOpen] = React.useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const newFieldArr = [];

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTourOpen = () => {
    setIsTourOpen(true)
  }

  const handleChangeSwitch = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const AddnewField = () => {
    let intialValue = newfieldCount;
    let count = parseInt(intialValue + 1);
    setnewfieldCount(count);
    console.log("count", newfieldCount);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      height: 30,
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      width: 300,
      padding: "10px 26px 10px 12px",
      placeholder: "Title",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",

        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const showInput = () => {
    SetShowInputField(true);
  };

  const boardDetailsGet = useSelector((state) => state.boardDetails);
  const { loading, boardDetails, error } = boardDetailsGet;

  const instDetailsGet = useSelector((state) => state.instDetails);
  const { loadingInst, instDetails, errorInst } = instDetailsGet;

  console.log(
    "board Details",
    boardDetails ? boardDetails.data : "not yet fetched"
  );
  console.log(
    "instDetails ",
    instDetails ? instDetailsGet : "not fetched inst details"
  );

  useEffect(() => {
    dispatch(listBoardGet(), listInstGet());

    return () => {
      //
    };
  }, []);

  const deleteField = (i) => {
    console.log("i", i);
    newFieldArr.splice(i, 1);

    console.log("newFieldArr", newFieldArr);
  };

  const FieldOptions = (props) => {
    console.log("props at field options", props);
    let i = props.numb;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          alignSelf: "center",
        }}
      >
        <FormControl className={classes.margin}>
          <BootstrapInput id="demo-customized-textbox" />
          <Typography>
            <span
              style={{
                fontSize: 12,
                color: "#464E5F",
                marginLeft: 5,
                fontFamily: "Poppins, Helvetica, sans-serif",
              }}
            >
              Please enter{" "}
              <span
                style={{
                  color: "#464E5F",
                  fontWeight: "600",
                  fontFamily: "Poppins, Helvetica, sans-serif",
                  fontSize: 12,
                }}
              >
                Label
              </span>
            </span>
          </Typography>
        </FormControl>
        {/* <span style={{ display: "flex", marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                    :
                </span>
                */}

        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ alignSelf: "center" }}
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            style={{
              fontSize: 13,
              color: "#464E5F",
              fontFamily: "Poppins, Helvetica, sans-serif",
            }}
          >
            Field Types
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={age}
            onChange={handleChange}
            label="Age"
            size="small"
            style={{
              fontSize: 13,
              color: "#464E5F",
              fontFamily: "Poppins, Helvetica, sans-serif",
            }}
          >
            <MenuItem
              value="0"
              style={{
                fontSize: 13,
                color: "#464E5F",
                fontFamily: "Poppins, Helvetica, sans-serif",
              }}
            >
              <em>None</em>
            </MenuItem>
            <MenuItem
              value="1"
              style={{
                fontSize: 13,
                color: "#464E5F",
                fontFamily: "Poppins, Helvetica, sans-serif",
              }}
            >
              Input
            </MenuItem>
            <MenuItem
              value="2"
              style={{
                fontSize: 13,
                color: "#464E5F",
                fontFamily: "Poppins, Helvetica, sans-serif",
              }}
            >
              Dropdown
            </MenuItem>
            <MenuItem
              value="3"
              style={{
                fontSize: 13,
                color: "#464E5F",
                fontFamily: "Poppins, Helvetica, sans-serif",
              }}
            >
              switch
            </MenuItem>
          </Select>
          <span
            style={{
              fontSize: 12,
              color: "#464E5F",
              marginLeft: 5,
              fontFamily: "Poppins, Helvetica, sans-serif",
            }}
          >
            Please select{" "}
            <span
              style={{
                color: "#464E5F",
                fontWeight: "600",
                fontFamily: "Poppins, Helvetica, sans-serif",
                fontSize: 12,
              }}
            >
              type of Field
            </span>
          </span>
        </FormControl>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            style={{ fontSize: 14, marginTop: -10 }}
          >
            <EditOutlinedIcon />
          </IconButton>

          <IconButton
            color="secondary"
            aria-label="add an alarm"
            style={{ fontSize: 14, marginTop: -10 }}
            onClick={() => {
              deleteField(i);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  let FIELD_OPTIONS = [
    <FieldOptions />,
    <FieldOptions />,
    <FieldOptions />,
    <FieldOptions />,
  ];

  const MasterFieldOptions = () => {
    const count = newfieldCount;
    const items = [];
    // _.times(newfieldCount, () => {
    for (let i = 0; i < newfieldCount; i++) {
      newFieldArr.push(<FieldOptions numb={i} />);
    }

    return newFieldArr;
  };

  const LogoutUser = (props) => {
    const role = "";
    let userId = 0;
    let userName = "";
    let userEmail = "";
    let userPhoto = "";
    let userLoggedIn = false;
    let userRoles = " "
    // let SUPERADMIN = ""
    // let CONTENTUPLOAD = ""
    // let QUALITYASSURANCE = ""
    // let CONTENTMAP = ""
    // let CURRICULUMMAP = ""
    // let TRAINER = ""

    Cookie.set("econtent.user.id", userId);
    Cookie.set("econtent.user.name", userName);
    Cookie.set("econtent.user.email", userEmail);
    Cookie.set("econtent.user.photo", userPhoto);
    Cookie.set("econtent.user.role", role);
    Cookie.set("econtent.user.login", userLoggedIn);
    Cookie.set("econtent.user.userRoles", userRoles);

    // Cookie.set('econtent.user.SUPERADMIN', SUPERADMIN);
    // Cookie.set('econtent.user.CONTENTUPLOAD', CONTENTUPLOAD);
    // Cookie.set('econtent.user.QUALITYASSURANCE', QUALITYASSURANCE);
    // Cookie.set('econtent.user.CONTENTMAP', CONTENTMAP);
    // Cookie.set('econtent.user.EBOOKUPLOAD', EBOOKUPLOAD);
    // Cookie.set('econtent.user.CURRICULUMMAP', CURRICULUMMAP);
    // Cookie.set('econtent.user.TRAINER', TRAINER);



    console.log(
      "cookies data id+++++++++++++++++",
      Cookie.get("econtent.user.id")
    );
    console.log(
      "cookies data name+++++++++++++++++",
      Cookie.get("econtent.user.name")
    );
    console.log(
      "cookies data email+++++++++++++++++",
      Cookie.get("econtent.user.email")
    );
    console.log(
      "cookies data photo+++++++++++++++++",
      Cookie.get("econtent.user.photo")
    );
    console.log(
      "cookies data role+++++++++++++++++",
      Cookie.get("econtent.user.role")
    );
    console.log(
      "cookies data login+++++++++++++++++",
      Cookie.get("econtent.user.login")
    );
    console.log(
      "cookies data userRoles+++++++++++++++++",
      Cookie.get("econtent.user.Roles")
    );

    history.push("/");
  };




  // const CONTENTUPLOAD = Cookie.get('econtent.user.CONTENTUPLOAD')
  // const QUALITYASSURANCE = Cookie.get('econtent.user.QUALITYASSURANCE')
  // const SUPERADMIN = Cookie.get('econtent.user.SUPERADMIN')
  // const CONTENTMAP = Cookie.get('econtent.user.CONTENTMAP')
  // const CURRICULUMMAP = Cookie.get('econtent.user.CURRICULUMMAP')
  // const EBOOKUPLOAD = Cookie.get('econtent.user.EBOOKUPLOAD')
  // const TRAINER = Cookie.get('econtent.user.TRAINER')


  const roles = Cookie.get('econtent.user.Roles')
  const CONTENTUPLOAD = roles.includes("CONTENTUPLOAD")
  const QUALITYASSURANCE = roles.includes("QUALITYASSURANCE")
  const SUPERADMIN = roles.includes("SUPERADMIN")
  const CONTENTMAP = roles.includes("CONTENTMAP")
  const CURRICULUMMAP = roles.includes("CURRICULUMMAP")
  const EBOOKUPLOAD = roles.includes("EBOOKUPLOAD")
  const TRAINER = roles.includes("TRAINER")

  console.log("role status",
    CONTENTUPLOAD,
    QUALITYASSURANCE,
    SUPERADMIN,
    CONTENTMAP,
    CURRICULUMMAP,
    EBOOKUPLOAD,
    TRAINER
  )


  return (
    <div className={classes.root}>
      {/* <div style={{
        position: 'absolute',
        top: 0,
        right: 0
      }}>
        <LiveHelpIcon
          onClick={handleTourOpen} />
      </div> */}
      <Leftbar />

      <main className={classes.content}>
        {/* <div className={classes.appBarSpacer} /> */}
        <Helmet>
          <title> Settings | Pustak Publish</title>
        </Helmet>

        <Container
          className={classes.container}
          style={{
            minHeight: "100%",
            minWidth: "100%",
          }}
        >
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Toolbar className={classes.toolbar}>
                {/* <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  className={classes.title}
                  style={{
                    fontSize: 16,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Settings
                </Typography> */}
                <div>
                  <Typography
                    id="tableTitle" component="div"
                    variant="h3"
                    style={{

                    }}>
                    Settings
                  </Typography>
                </div>

              </Toolbar>
              <Divider style={{ marginBottom: 10 }} />

              {/* <AppBar position="static" color="default" style={{ marginTop: "20" }}> */}
              <div>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="on"
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="scrollable force tabs example"
                >
                  <Tab
                    label="General"
                    {...a11yProps(0)}
                    style={{ fontSize: 12 }}
                  />
                  {/* <Tab label="Fields" {...a11yProps(1)} style={{ fontSize: 12 }} /> */}
                  {
                    SUPERADMIN ?
                      <Tab
                        label="Access Controls"
                        {...a11yProps(1)}
                        style={{ fontSize: 12 }}
                      />
                      :
                      <></>
                  }

                  <Tab
                    label="Company Profile"
                    {...a11yProps(2)}
                    style={{ fontSize: 12 }}
                  />

                  <Tab
                    label="My Profile"
                    {...a11yProps(3)}
                    style={{ fontSize: 12 }}
                  />

                  <Tab
                    label="Security"
                    {...a11yProps(4)}
                    style={{ fontSize: 12 }}
                  />

                </Tabs>
              </div>

              {/* </AppBar> */}

              <TabPanel value={value} index={0} >
                <List
                  subheader={<ListSubheader>Settings</ListSubheader>}
                  className={classes3.root}
                >

                  {/* <ListItem>
                    <ListItemIcon>
                      <LanguageOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary="Company Profile"
                    />
                    <ListItemSecondaryAction>

                    </ListItemSecondaryAction>
                  </ListItem> */}
{/* 
                  <ListItem>
                    <ListItemIcon>
                      <MonetizationOnIcon />
                    </ListItemIcon>

                    <Link to="/publisher-content/price-settings" style={{ textDecoration: "none", color: '#000000' }}>
                      <ListItemText
                        id="switch-list-label-wifi"
                        primary="Subscription Setup For Components"
                      />
                    </Link>
                    <ListItemSecondaryAction>
                      
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <MonetizationOnIcon />
                    </ListItemIcon>

                    <Link to="/publisher-content/price-settings/ilc" style={{ textDecoration: "none", color: '#000000' }}>
                      <ListItemText
                        id="switch-list-label-wifi"
                        primary="Subscription Setup For ILC"
                      />
                    </Link>
                    <ListItemSecondaryAction>
                      
                    </ListItemSecondaryAction>
                  </ListItem> */}

                  <ListItem>
                    <Link to="/publisher-content/settings/help"
                      style={{
                        textDecoration: "none",
                        color: "#000",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <ListItemIcon>
                        <HelpOutlineOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        // id="switch-list-label-wifi"
                        primary="Help &amp; Support"
                      />

                    </Link>
                  </ListItem>

                  <ListItem>
                    <Link to="/publisher-content/settings/terms-conditions" style={{ textDecoration: "none", color: "#000", display: "flex", justifyContent: "center" }}>
                      <ListItemIcon>
                        <InfoOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        id="switch-list-label-wifi"
                        primary="Terms &amp; Conditions"
                      />
                    </Link>
                    <ListItemSecondaryAction>
                      {/* <Switch
                              edge="end"
                              checked={state.checkedB}
                              onChange={handleChangeSwitch}
                              name="checkedB"
                              inputProps={{
                                "aria-labelledby": "switch-list-label-wifi",
                              }}
                            /> */}
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <WifiIcon />
                    </ListItemIcon>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary="Notifications"
                    />
                    <ListItemSecondaryAction>
                      <Switch
                        edge="end"
                        checked={state.checkedA}
                        onChange={handleChangeSwitch}
                        name="checkedA"
                        inputProps={{
                          "aria-labelledby": "switch-list-label-wifi",
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>


                </List>

                {/*                                 
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 10 }}>
                                    <div style={{ display: "flex", width: "50%", justifyContent: "flex-end", marginRight: 10 }}>
                                        <span style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: " #80808F", backgroundColor: "#F3F6F9", fontSize: 14 }}>Notifications:</span>
                                    </div>
                                    <div style={{ display: "flex", width: "50%", justifyContent: "flex-start" }}>
                                        <Switch
                                            checked={state.checkedA}
                                            onChange={handleChangeSwitch}
                                            name="checkedA"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    </div>
                                </div> */}

                {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 10 }}>
                                    <div style={{ display: "flex", width: "50%", justifyContent: "flex-end", marginRight: 10 }}>
                                        <span style={{ fontFamily: "Poppins, Helvetica, sans-serif", color: " #80808F", backgroundColor: "#F3F6F9", fontSize: 14 }}>Custom Fields:</span>
                                    </div>
                                    <div style={{ display: "flex", width: "50%", justifyContent: "flex-start" }} >
                                        <Switch
                                            checked={state.checkedA}
                                            onChange={handleChangeSwitch}
                                            name="checkedA"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    </div>



                                </div>
                            */}

                {/* 
                                <div style={{ display: "flex", justifyContent: "center", margin: 10 }}>
                                    <Link to="/contentmaker/content" style={{ textDecoration: "none" }}>
                                        <Button variant="contained" color="primary" style={{ fontFamily: "Poppins, Helvetica, sans-serif", textTransform: "none" }}>
                                            Submit
                                        </Button>
                                    </Link>
                                </div> */}
              </TabPanel>

              <TabPanel value={value}>
                <MasterFieldOptions />
                {/* <FieldOptions />
                        <FieldOptions />
                        <FieldOptions />
                        <FieldOptions /> */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      AddnewField();
                    }}
                  >
                    Add New Field
                  </Button>
                </div>
              </TabPanel>

              {
                SUPERADMIN ?
                  <TabPanel value={value} index={1}>
                    <AdminControls />
                  </TabPanel>
                  :
                  <></>
              }

              <TabPanel value={value} index={2}>
                <CompanyProfilePage />

              </TabPanel>

              <TabPanel value={value} index={3}>
                <ProfilePage />
              </TabPanel>

              <TabPanel value={value} index={4}>



                {/* vertical tabs */}


                <VerticalTabs />


              </TabPanel>
            </Grid>
          </Grid>

          {/* <div style={{ justifyContent: "flex-end", minWidth: "100%", display: "flex" }}>
                        <Fab
                            aria-label="add"
                            style={{
                                alignSelf: "flex-end",
                                position: "absolute",
                                right: 5,
                                backgroundColor: "#ffffff",
                                borderRadius: 10,
                                top: 200,
                                zIndex: 2,
                            }}
                        >
                            <Link to="/contentmaker/settings" style={{ textDecoration: "none" }}>
                                <div style={{
                                    minWidth: 35,
                                    minHeight: 20,
                                    backgroundColor: "gray",
                                    justifyContent: "center",
                                    alignSelf: "center",
                                    backgroundColor: "#F3F6F9",
                                    borderRadius: 10
                                }}
                                >
                                    <SettingsOutlinedIcon color="primary" style={{ marginTop: 5 }} />
                                </div>
                            </Link>

                        </Fab>
                    </div> */}

          <Box pb={4}>

            <Divider style={{ marginBottom: 20 }} />

            <Copyright />
          </Box>
        </Container>
      </main>

      {/* 
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      /> */}
    </div>
  );
}
