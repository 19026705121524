import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import { boardDetailsReducer } from './redux/reducers/boardDetailsReducer';
import { instDetailsReducer } from './redux/reducers/instDetailsReducer';
import { subDetailsReducer } from './redux/reducers/subjectReducer';
import { stdDetailsReducer } from "./redux/reducers/standardReducer"
import { chpDetailsReducer } from './redux/reducers/chapterReducer';
import { TopDetailsReducer } from "./redux/reducers/topicReducers";
import { UploadContReducer } from './redux/reducers/uploadContReducer';
import { ContTypeReducer } from "./redux/reducers/contentTypeReducer";
import { CurriculumFilterMapReducer, CurriculumMapReducer } from './redux/reducers/CurriculumMapReducer';
import { adminRolesReducer } from "./redux/reducers/adminRolesReducer";
// import { CurriculumMapReducer } from './redux/reducers/CurriculumMapReducer';
import { filteredMapReducer } from './redux/reducers/filteredMapReducer';
import currMapReducer from './redux/reducers/currMapReducer';
import { UnitMapReducer } from './redux/reducers/unitMapReducer';
import { SubjectIdReducer } from './redux/reducers/subjectIdReducer';
import { UnitLessonsReducer } from './redux/reducers/unitLessonsReducer';
import { LessonPlansReducer } from './redux/reducers/lessonPlansReducer';
import { EbookContentPostReducer, EbookContentReducer } from './redux/reducers/EbookContentReducer';
import contentReducer from './redux/reducers/contentReducer';
import { searchEbookReducer } from './redux/reducers/searchEbookReducer';
import currUnitReducer from './redux/reducers/currUnitReducer';
import currLessonReducer from './redux/reducers/currLessonReducer';
import currPlanReducer from './redux/reducers/currPlanReducer';
import currMapId from './redux/reducers/currMapIdReducer';
import { userSigninReducer } from './redux/reducers/UserAuthReducer';
import { ContUsersReducer, PubUsersReducer } from './redux/reducers/PubUsersReducer';
import { DashboardContProvReducer, DashboardContDetailsReducer, DashboardDetailsReducer, DashboardPubGraphsReducers, DashboardPublisherReducer, DashboardSchoolsReducer, DashboardContGraphsReducers, DashboardContSchoolsReducer, DashboardPubDevicesReducers, DashboardContDevicesReducers } from './redux/reducers/DashboardReducer';
import { profileDetailsContReducer, profileDetailsPubReducer } from './redux/reducers/ProfileReducer';
import { publisherTeachReducer } from './redux/reducers/TeachPreviewReducer';
import { contRolesGetReducer, contRolesSaveReducer, contSendMailReducer, pubRolesGetReducer, pubRolesPutReducer, pubRolesSaveReducer, pubSendMailReducer } from './redux/reducers/AdminControlReducer';
import { activityContUpdtReducer, activityListContGetReducer, activityListPubGetReducer, activityPubUpdtReducer } from './redux/reducers/ActivityReducer';
import { allEventsGetFilReducer, allEventsGetReducer, allEventsGetSrcReducer, allVideosGetReducer, singleEventGetReducer, singleVideoGetReducer, subscribersGetReducer, trainTeachVideosGetReducer, trainTeachVideosTopGetReducer } from './redux/reducers/Trainer/EventGetReducer';
import { singleEventPostReducer, singleVideoPostReducer, subscribersPostReducer, trainTeachVidPostReducer } from './redux/reducers/Trainer/EventPostReducer';
import { singleEventDeleteReducer, singleVideoDeleteReducer, subscribersDeleteReducer, trainTeachVidDelReducer } from './redux/reducers/Trainer/EventDeleteReducer';
import { singleEventPutReducer, singleVideoPutReducer, trainTeachVidPutReducer } from './redux/reducers/Trainer/EventPutReducer';
import { companyDetContGetReducer, companyDetPubGetReducer, companyDetPubPostreducer, companyDevContPostreducer } from './redux/reducers/CompanyProfile';
import { trashbinGetReducer, trashbinPostReducer, trashbinRestoreReducer } from './redux/reducers/TrashBinReducer';
import { allBooksGetReducer, allContentGetReducer, allCurriculumGetReducer } from './redux/reducers/GetAllFilesReducer';

const initialState = {};

const reducer = combineReducers({
    userSigninAuth: userSigninReducer,
    boardDetails: boardDetailsReducer,
    instDetails: instDetailsReducer,
    subDetails: subDetailsReducer,
    stdDetails: stdDetailsReducer,
    chpDetails: chpDetailsReducer,
    topDetails: TopDetailsReducer,
    uploadContent: UploadContReducer,
    contType: ContTypeReducer,
    adminRoles: adminRolesReducer,
    curriculumMap: CurriculumMapReducer,
    filteredMap: filteredMapReducer,
    unitMap: UnitMapReducer,
    unitLessons: UnitLessonsReducer,
    lessonPlans: LessonPlansReducer,
    ebookContent: EbookContentReducer,
    ebookContentPost: EbookContentPostReducer,
    currMap: currMapReducer,
    content: contentReducer, // get latest data
    searchEbook: searchEbookReducer,
    currUnit: currUnitReducer,
    currLesson: currLessonReducer,
    currPlan: currPlanReducer,
    currMapId: currMapId,
    curriculumMapFil: CurriculumFilterMapReducer,
    pubUsersList: PubUsersReducer,
    dashboardDetails: DashboardDetailsReducer,
    dashboardContDetails: DashboardContDetailsReducer,
    dashboardSchools: DashboardSchoolsReducer,
    dashboardContSchools: DashboardContSchoolsReducer,
    dashboardPublishers: DashboardPublisherReducer,
    dashboardContProv: DashboardContProvReducer,
    dashboardPubGraphs: DashboardPubGraphsReducers,
    dashboardContGraphs: DashboardContGraphsReducers,
    dashboardPubDevices: DashboardPubDevicesReducers,
    dashboardContDevices: DashboardContDevicesReducers,
    profileDetailsPub: profileDetailsPubReducer,
    profileDetailsCont: profileDetailsContReducer,
    publisherTeach: publisherTeachReducer,
    pubRolesGet: pubRolesGetReducer,
    pubRolesPut: pubRolesPutReducer,
    pubRolesSave: pubRolesSaveReducer,
    contRolesGet: contRolesGetReducer,
    contRolesPut: contRolesSaveReducer,
    contRolesSave: contRolesSaveReducer,
    pubSendMail: pubSendMailReducer,
    contSendMail: contSendMailReducer,
    actListCont: activityListContGetReducer,
    actListPub: activityListPubGetReducer,
    actContUpd: activityContUpdtReducer,
    actPubPut: activityPubUpdtReducer,
    contUsersGet: ContUsersReducer,
    allEventsGet: allEventsGetReducer,
    allVideosGet: allVideosGetReducer,
    allEventsFilGet: allEventsGetFilReducer,
    allEventsSrcGet: allEventsGetSrcReducer,
    singleEventGet: singleEventGetReducer,
    singleEventPost: singleEventPostReducer,
    singleEventPut: singleEventPutReducer,
    singleEventDelete: singleEventDeleteReducer,
    singleVideoGet: singleVideoGetReducer,
    singleVideoPost: singleVideoPostReducer,
    singleVideoDel: singleVideoDeleteReducer,
    singleVideoPut: singleVideoPutReducer,
    subscribersGet: subscribersGetReducer,
    subscribersDel: subscribersDeleteReducer,
    subscribersPost: subscribersPostReducer,
    trainTeachVideoGet: trainTeachVideosGetReducer,
    trainTeachVideoPost: trainTeachVidPostReducer,
    trainTeachVideoPut: trainTeachVidPutReducer,
    trainTeachVideosTopGet: trainTeachVideosTopGetReducer,
    trainTeachVidDel: trainTeachVidDelReducer,
    companyDetPubGet: companyDetPubGetReducer,
    companyDetContGet: companyDetContGetReducer,
    companyDetPubPost: companyDetPubPostreducer,
    companyDevContPost: companyDevContPostreducer,
    trashbinGet: trashbinGetReducer,
    trashbinPost:trashbinPostReducer,
    trashbinRestore:trashbinRestoreReducer,
    allContentGet:allContentGetReducer,
    allBooksGet:allBooksGetReducer,
    allCurriculumGet:allCurriculumGetReducer,
    subjectId: SubjectIdReducer

})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)));


export default store;
