import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Divider, Grid, List, Toolbar } from "@material-ui/core";
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { useDispatch, useSelector } from "react-redux";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase";
import Constants from "../../../resource/Constants";
import FileUploader from "react-firebase-file-uploader";
import { listBoardGet } from "../../../redux/actions/boardDetailsAction";
import { listInstGet } from "../../../redux/actions/InstituteDetailsAction";
import { listSubGet } from "../../../redux/actions/subjectsAction";
import { listStdGet } from "../../../redux/actions/standardAction";
import { listChpGet } from "../../../redux/actions/chapterAction";
import { listTopGet, listTopReset } from "../../../redux/actions/topicAction";
// import { Alert } from "@material-ui/lab";
import { Snackbar, TextField } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Cookie from "js-cookie";
import Checkbox from '@material-ui/core/Checkbox';

import API from "../../../http/http";
import PARTNERAPI from "../../../http/httppartner";
import PUBLISHERAPI from "../../../http/publisher";
import { listContTypeGet } from "../../../redux/actions/contentTypeAction";
import CircularProgress from "@material-ui/core/CircularProgress";
// import image from "../../../assets/book-na-1.jpg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import ChipInput from 'material-ui-chip-input'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import uploadScreen from '../../../assets/bulk_upload_screen2.png';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Divider2 from '@mui/material/Divider';


import Box2 from '@mui/material/Box';
import Collapse2 from '@mui/material/Collapse';
import IconButton2 from '@mui/material/IconButton';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TextField2 from '@mui/material/TextField';

import Alert2 from '@mui/material/Alert';
import AlertTitle2 from '@mui/material/AlertTitle';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import Chip2 from '@mui/material/Chip';

import axios from 'axios';
var fileDownload = require('js-file-download');

function createData2(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row, rowIdx } = props;
  const [open, setOpen] = React.useState(true);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton2
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton2>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.topic_title}
        </TableCell>
        {/* <TableCell align="right">Topic Name</TableCell> */}

        {/*
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        */}
        <TableCell align="center">
          {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" size="small" /> */}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse2 in={open} timeout="auto" unmountOnExit>
            <Box2 sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                History
              </Typography> */}
              <Table size="small" aria-label="purchases">
                {/* <TableHead>
                  <TableRow>
                    <TableCell>Sub Topic</TableCell>
                    <TableCell> */}
                {/* URL */}
                {/* </TableCell> */}
                {/* <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Total price ($)</TableCell> */}
                {/* </TableRow>
                </TableHead> */}
                <TableBody>
                  {row.sub_topics && row.sub_topics.map((historyRow, sIdx) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {/* {historyRow.date} */}
                        {historyRow.topic_sub_name}
                      </TableCell>
                      <TableCell>

                        <TextField
                          onChange={(e) => props.onURLChanged(e, (rowIdx + sIdx), row.topic_id, historyRow.topic_sub_id, row.topic_title, historyRow.topic_sub_name)} // to maintain the main large array
                          defaultValue={historyRow.content_location}
                          // value={}
                          id="outlined-basic" label="Paste Resource URL" variant="outlined"
                          style={{
                            width: '100%'
                          }}
                          size="small"
                          helperText="Please enter MP4 / MP3 / PDF / JPEG / Vimeo direct link"
                        />
                      </TableCell>
                      {/* <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box2>
          </Collapse2>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows2 = [
  createData2('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData2('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData2('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData2('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData2('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

function CollapsibleTable(props) {

  const topDetailsGet = useSelector((state) => state.topDetails);
  const { loadingTop, TopDetails, errorTop } = topDetailsGet;
  const { topicIds, subTopicIds, urls } = props;
  const { topIds, subTopIds, uls } = props;
  const [addTopIds, setAddTopIds] = useState(0);
  const [totTopIds, setTotTopIds] = useState(0);

  console.log('TopDetails are ', TopDetails);

  useEffect(() => {

    let totTpIds = 0;
    let addTpIds = 0;
    if (TopDetails && TopDetails.data) {
      TopDetails.data.map((tp) => {
        console.log('inside each tp ', tp)

        if (tp.sub_topics) {
          tp.sub_topics.map((subTp) => {
            totTpIds++;

            if (subTp.content_location)
              addTpIds++;
          })
        }
      })
    }

    setAddTopIds(addTpIds);
    setTotTopIds(totTpIds);

  }, [])

  return (
    TopDetails && TopDetails.data && TopDetails.data.length ?
      (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 20
            }}>
            <Typography
              variant="h4">
              Enter the exact media URL in the Topic text box
            </Typography>

            <Chip2 label={`Added ${addTopIds}/${totTopIds}`} variant="outlined" size="large"
              style={{
                fontSize: 20
              }} />

            <IconButton aria-label="delete" size="large"
              onClick={() => props.onCloseClicked(props.anchor, true)}>
              <CancelIcon fontSize="inherit" />
            </IconButton>
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                {/* <TableRow>
                  <TableCell style={{
                    width: '5%'
                  }} />
                  <TableCell
                  >
                  
                  </TableCell>
            
                  <TableCell align="center"
                    style={{
                      width: '50%'
                    }}>
                    &nbsp;
                  </TableCell>
                </TableRow> */}
              </TableHead>
              <TableBody>
                {TopDetails && TopDetails.data && TopDetails.data.map((row, rowIdx) => (
                  <Row key={row.name} row={row} onURLChanged={(e, sIdx, topicId, subTopicId, topicName, subTopicName) => props.onURLChanged(e, sIdx, topicId, subTopicId, topicName, subTopicName)} rowIdx={rowIdx} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: "flex", justifyContent: "space-between",
              padding: 20, marginTop: 20, width: '100%'
            }}
          >
            {/* <Link to="/publisher/mapping" style={{ textDecoration: "none", color: "#fff" }}> */}
            <Button
              variant="contained"
              onClick={() => props.onCloseClicked(props.anchor, true)}
            >Cancel</Button>
            <Button
              disabled={props.singleUploadDisabled}
              variant="contained"
              color="primary"
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                textTransform: "none",
                // width: '100%'
                // backgroundColor: "#3699FF",
                // color: '#FFFFFF'
              }}
              onClick={() => {
                props.handleSingleContentUpload();
              }}
            >
              Save
            </Button>
            <Snackbar open={props.snackOpen} autoHideDuration={6000} onClose={props.handleSnackClose}>
              <Alert onClose={props.handleSnackClose} severity="warning">
                Something is not right with your input!
              </Alert>
            </Snackbar>

            {/* </Link> */}
          </div>
        </>
      )
      :
      <>
        <div
          style={{
            margin: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Alert2 severity="info">
            <AlertTitle2>No Topics found!</AlertTitle2>
            You have to select a chapter to choose topics and map URL to them - <strong>choose a chapter on the left panel!</strong>
          </Alert2>


          <IconButton aria-label="delete" size="large"
            onClick={() => props.onCloseClicked(props.anchor, true)}>
            <CancelIcon fontSize="inherit" />
          </IconButton>
        </div>

      </>
  );
}

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];


function BasicTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabPanel1(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

TabPanel1.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function a11yProps1(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 100,
    width: '33%',
    minHeight: 30,
  },
  formControl1: {
    margin: theme.spacing(1),
    minWidth: 350,
    minHeight: 30,
  },
  formControl3: {
    margin: theme.spacing(1),
    // minWidth: 100,
    width: '100%',
    minHeight: 30,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  toolbar: {
    marginRight: 24,
    justifyContent: "space-between",
  },
  button: {
    margin: theme.spacing(1),
    justifyContent: "flex-end",
    alignSelf: "center",
  },
  button1: {
    margin: theme.spacing(1),
    justifyContent: "flex-end",
    justifyItems: "flex-end",
    justifySelf: "flex-end",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    alignContent: "flex-end",
  },
}));

function BoardsBox(props) {
  const [autoOpen, setAutoOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const [brdName, setBrdName] = useState('Board / Uni')

  const { autoBoards, actvRow } = props

  const onBoardChanged = (event, value) => {
    console.log('board changed value ', value)

    if (value) {
      const brdId = parseInt(value.id);

      // set localstorage
      // localStorage.setItem('publish.pustak.board_type', brdId);
      localStorage.setItem('pustak.kgtopg.board_type', brdId);
      props.setBoardType(brdId)
      setBrdName(value.label)
    } else {

      // setAutoOpen(false);

    }
  }

  const setBrd = (brd) => {
    if (brd) {
      setBrdName(brd.institute_board_name)
      props.setBoardType(brd.institute_board_id)
      setInputValue(brd.institute_board_name)
    }
  }

  useEffect(() => {

    const brdId = localStorage.getItem('pustak.kgtopg.board_type');

    if (brdId) {
      console.log('autoBoards in useeffect ', autoBoards);
      autoBoards.map((brd) => {
        if (brd.id == brdId) {
          console.log('got brd  in useeffect ', brd);
          console.log('got brdId  in useeffect ', brdId);
          const brdName = brd.label;
          setInputValue(brdName);
          setBrdName(brdName)
        }
      })

      props.setBoardType(brdId)

    }

    console.log('actvRow inside boards box ', actvRow);
    if (actvRow) {
      setBrd(actvRow)
    }
    return {

    }
  }, [])

  return (
    (autoBoards && autoBoards.length) ?
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={autoBoards}
        sx={{ width: '90%' }}
        style={{
          width: '90%',
          alignSelf: 'center',
          margin: '0 auto',
          // marginBottom: 20
        }}
        onOpen={() => {
          // only open when in focus and inputValue is not empty
          if (inputValue) {
            setAutoOpen(true);
          }
        }}
        onClose={() => setAutoOpen(false)}
        onInputChange={(e, value, reason) => {
          // setSubName(value);
          console.log('setting inputvalue ', value)
          setInputValue(value);

          // only open when inputValue is not empty after the user typed something
          if (!value) {
            setAutoOpen(false);
          }
        }}
        inputValue={inputValue}
        getOptionLabel={(option) => option.label.toString()}
        onChange={onBoardChanged}
        open={autoOpen}
        renderInput={(params) => <TextField {...params} label={brdName} />}
      />
      :
      <></>
  );
}


function SubjectsBox(props) {
  const [autoOpen, setAutoOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const [subName, setSubName] = useState('Subject')

  const { autoSubs, actvRow } = props

  const onSubjectChanged = (event, value) => {
    console.log('subject changed value ', value)

    if (value) {
      const subId = parseInt(value.id);

      // set localstorage
      //      localStorage.setItem('publish.pustak.current_subject', JSON.stringify(subId));
      // localStorage.setItem('publish.pustak.subject', subId);
      localStorage.setItem('pustak.kgtopg.subject', subId);
      if (subId) {
        autoSubs.map((sub) => {
          if (sub.subject_id == subId) {
            console.log('sub ', sub);
            console.log('subId ', subId);
            setInputValue(sub.subject_name);
          }
        })
      }

      props.setSubjectType(subId)
      setSubName(value.label)
      props.loadChapters(subId);
    } else {

      // setAutoOpen(false);

    }
  }

  const setSub = (sub) => {
    if (sub) {
      setSubName(sub.subject_name)
      props.setSubjectType(sub.subject_id)
      props.loadChapters(sub.subject_id)
      setInputValue(sub.subject_name)
    }
  }

  useEffect(() => {

    const subId = localStorage.getItem('pustak.kgtopg.subject');

    if (subId) {
      console.log('autosubs in useeffect ', autoSubs);
      autoSubs.map((sub) => {
        if (sub.id == subId) {
          console.log('got sub  in useeffect ', sub);
          console.log('got subId  in useeffect ', subId);
          const subName = sub.label;
          setInputValue(subName);
          setSubName(subName)
        }
      })

      props.setSubjectType(subId)

    }

    console.log('actvRow inside subjects box ', actvRow);
    if (actvRow) {
      setSub(actvRow)
    }
    return {

    }
  }, [])

  return (
    (autoSubs && autoSubs.length) ?
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={autoSubs}
        sx={{ width: '90%' }}
        style={{
          width: '90%',
          alignSelf: 'center',
          margin: '0 auto',
          // marginBottom: 20
        }}
        onOpen={() => {
          // only open when in focus and inputValue is not empty
          if (inputValue) {
            setAutoOpen(true);
          }
        }}
        onClose={() => setAutoOpen(false)}
        onInputChange={(e, value, reason) => {
          // setSubName(value);
          console.log('setting inputvalue ', value)
          setInputValue(value);

          // only open when inputValue is not empty after the user typed something
          if (!value) {
            setAutoOpen(false);
          }
        }}
        inputValue={inputValue}
        getOptionLabel={(option) => option.label.toString()}
        onChange={onSubjectChanged}
        open={autoOpen}
        renderInput={(params) => <TextField {...params} label={subName} />}
      />
      :
      <></>
  );
}


export default function UploadContent(props) {
  const boardDetailsGet = useSelector((state) => state.boardDetails);
  const { loading, boardDetails, error } = boardDetailsGet;

  const instDetailsGet = useSelector((state) => state.instDetails);
  const { loadingInst, instDetails, errorInst } = instDetailsGet;

  const subDetailsGet = useSelector((state) => state.subDetails);
  const { loadingSub, subDetails, errorSub } = subDetailsGet;

  const stdDetailsGet = useSelector((state) => state.stdDetails);
  const { loadingStd, stdDetails, errorStd } = stdDetailsGet;

  const chpDetailsGet = useSelector((state) => state.chpDetails);
  const { loadingChap, chapDetails, errorChp } = chpDetailsGet;

  const topDetailsGet = useSelector((state) => state.topDetails);
  const { loadingTop, TopDetails, errorTop } = topDetailsGet;

  const contTypeDetailsGet = useSelector((state) => state.contType);
  const { loadingCont, contTypeDetails, errorCont } = contTypeDetailsGet;

  const userId = Cookie.get("econtent.cont.id");

  console.log(`props in upload content modal ${JSON.stringify(props)}`)
  console.log(`props ilc ${props.ilc}`)
  const { actvRow, allSubjects, allBoards } = props

  // console.log('all boards ', JSON.stringify(allBoards));

  const classes = useStyles();
  const classes2 = useStyles2();
  const [value, setValue] = React.useState(0);
  const [age, setAge] = React.useState("");
  const [showInputField, SetShowInputField] = useState(false);
  const [selectBoardDetails, setSelectedBoardDetails] = useState("");
  const [boardDetailsState, setBoardDetailsState] = useState(null);
  const [filenames, setFileNames] = useState([]);
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [instType, setInstType] = useState((actvRow) ? actvRow.institute_type_id : "");
  const [boardType, setBoardType] = useState((actvRow) ? actvRow.institute_board_id : "");
  const [subjectType, setSubjectType] = useState((actvRow) ? actvRow.subject_id : "");
  const [standardType, setStandardType] = useState((actvRow) ? actvRow.classroom_std : '');
  const [contType, setContType] = useState("");
  const [ImageDownloadUrls, setImageDownloadUrls] = useState((actvRow) ? actvRow.image_cover_url : "");
  console.log(`actvRow image url ${(actvRow) ? actvRow.image_cover_url : 'n/a'}`)

  const [chap, setChap] = useState((actvRow) ? actvRow.chapter_id : "");
  const [tpc, setTpc] = useState((actvRow) ? actvRow.topic_id : 0);
  const [title, setTitle] = useState((actvRow) ? actvRow.content_name : "");
  const [video, setVideo] = useState((actvRow) ? actvRow.content_location : "");
  const [snackOpen, setSnackOpen] = useState(false);
  const [chaptername, setChapterName] = useState((actvRow) ? actvRow.chapter_name : "n/a");
  const [topicname, setTopicName] = useState((actvRow) ? actvRow.topic_name : "n/a");
  const [progress, setProgress] = useState(0);
  const [bulkProgress, setBulkProgress] = useState(0);
  const [bulkIsUploading, setBulkIsUploading] = useState(false);
  const [agree, setAgree] = useState(false)

  const [tags, setTags] = useState('')
  const [yourChips, setYourChips] = useState((actvRow) ? actvRow.tags.split(',') : [])
  const [excel, setExcel] = useState(false)
  const [selectedFile, setSelectedFile] = useState("")
  const [open, setOpen] = React.useState(false);

  let topIds = [] // every subtopid has a topid
  let topNames = []
  let subTopIds = []
  let subTopNames = []
  let uls = []

  console.log('checkk topdetails are ', TopDetails)
  if (TopDetails && TopDetails.data) {

    console.log('checkk topdetails inside are ', TopDetails)
    TopDetails.data.map((tp, ix) => {
      if (tp.sub_topics) {
        tp.sub_topics.map((subtp, six) => {

          console.log('got subtopic ', six, ix)

          topIds.push(tp.topic_id)
          topNames.push(tp.topic_title)
          subTopIds.push(subtp.topic_sub_id)
          subTopNames.push(subtp.topic_sub_name)
          uls.push(subtp.content_location) // empty first

        })
      }
    })
  }

  const oSubj = Cookie.get("publish.pustak.current_subject")
  const oSubj2 = JSON.parse(localStorage.getItem("publish.pustak.current_subject"));
  const cSubj = useSelector((state) => state.subjectId.subject);

  let subj = null;
  if (!oSubj2) {
    console.log('cookie does not exist ', oSubj2);
    subj = cSubj
  } else {
    console.log('cookie exists ', oSubj2)
    subj = oSubj2 // already selected
  }

  console.log()

  const subjId = (subj) ? subj.subject_id : 0;
  const subjName = (subj) ? subj.subject_name : 'Subject';

  // set cookie
  Cookie.set("publish.pustak.current_subject", subj)

  // set localstorage
  localStorage.setItem('publish.pustak.current_subject', JSON.stringify(subj));

  console.log('subjId ************************', subjId)

  console.log("checkk chapterDetails", chpDetailsGet);
  console.log("checkk TopicDetails", topDetailsGet);
  console.log("checkk contentDetailsget", contTypeDetails);

  const [topicIds, setTopicIds] = useState([])
  const [topicNames, setTopicNames] = useState([]);
  const [subTopicIds, setSubTopicIds] = useState([])
  const [subTopicNames, setSubTopicNames] = useState([])
  const [urls, setUrls] = useState(uls)

  console.log('checkk topids ', topIds)
  console.log('checkk topnames ', topNames)
  console.log('checkk subtopids ', subTopIds)
  console.log('checkk subtopnames ', subTopNames)
  console.log('checkk uls ', uls)

  console.log('checkk topicds state ', topicIds)
  console.log('checkk subtopicds state ', subTopicIds)
  console.log('checkk urls state ', urls)

  const autoSubs = []
  const autoBoards = []
  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
    {
      label: 'The Lord of the Rings: The Return of the King',
      year: 2003,
    },
    { label: 'The Good, the Bad and the Ugly', year: 1966 },
    { label: 'Fight Club', year: 1999 },
    {
      label: 'The Lord of the Rings: The Fellowship of the Ring',
      year: 2001,
    },
    {
      label: 'Star Wars: Episode V - The Empire Strikes Back',
      year: 1980,
    },
    { label: 'Forrest Gump', year: 1994 },
    { label: 'Inception', year: 2010 },
    {
      label: 'The Lord of the Rings: The Two Towers',
      year: 2002,
    },
    { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { label: 'Goodfellas', year: 1990 },
    { label: 'The Matrix', year: 1999 },
    { label: 'Seven Samurai', year: 1954 },
    {
      label: 'Star Wars: Episode IV - A New Hope',
      year: 1977,
    },
    { label: 'City of God', year: 2002 },
    { label: 'Se7en', year: 1995 },
    { label: 'The Silence of the Lambs', year: 1991 },
    { label: "It's a Wonderful Life", year: 1946 },
    { label: 'Life Is Beautiful', year: 1997 },
    { label: 'The Usual Suspects', year: 1995 },
    { label: 'Léon: The Professional', year: 1994 },
    { label: 'Spirited Away', year: 2001 },
    { label: 'Saving Private Ryan', year: 1998 },
    { label: 'Once Upon a Time in the West', year: 1968 },
    { label: 'American History X', year: 1998 },
    { label: 'Interstellar', year: 2014 },
    { label: 'Casablanca', year: 1942 },
    { label: 'City Lights', year: 1931 },
    { label: 'Psycho', year: 1960 },
    { label: 'The Green Mile', year: 1999 },
    { label: 'The Intouchables', year: 2011 },
    { label: 'Modern Times', year: 1936 },
    { label: 'Raiders of the Lost Ark', year: 1981 },
    { label: 'Rear Window', year: 1954 },
    { label: 'The Pianist', year: 2002 },
    { label: 'The Departed', year: 2006 },
    { label: 'Terminator 2: Judgment Day', year: 1991 },
    { label: 'Back to the Future', year: 1985 },
    { label: 'Whiplash', year: 2014 },
    { label: 'Gladiator', year: 2000 },
    { label: 'Memento', year: 2000 },
    { label: 'The Prestige', year: 2006 },
    { label: 'The Lion King', year: 1994 },
    { label: 'Apocalypse Now', year: 1979 },
    { label: 'Alien', year: 1979 },
    { label: 'Sunset Boulevard', year: 1950 },
    {
      label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
      year: 1964,
    },
    { label: 'The Great Dictator', year: 1940 },
    { label: 'Cinema Paradiso', year: 1988 },
    { label: 'The Lives of Others', year: 2006 },
    { label: 'Grave of the Fireflies', year: 1988 },
    { label: 'Paths of Glory', year: 1957 },
    { label: 'Django Unchained', year: 2012 },
    { label: 'The Shining', year: 1980 },
    { label: 'WALL·E', year: 2008 },
    { label: 'American Beauty', year: 1999 },
    { label: 'The Dark Knight Rises', year: 2012 },
    { label: 'Princess Mononoke', year: 1997 },
    { label: 'Aliens', year: 1986 },
    { label: 'Oldboy', year: 2003 },
    { label: 'Once Upon a Time in America', year: 1984 },
    { label: 'Witness for the Prosecution', year: 1957 },
    { label: 'Das Boot', year: 1981 },
    { label: 'Citizen Kane', year: 1941 },
    { label: 'North by Northwest', year: 1959 },
    { label: 'Vertigo', year: 1958 },
    {
      label: 'Star Wars: Episode VI - Return of the Jedi',
      year: 1983,
    },
    { label: 'Reservoir Dogs', year: 1992 },
    { label: 'Braveheart', year: 1995 },
    { label: 'M', year: 1931 },
    { label: 'Requiem for a Dream', year: 2000 },
    { label: 'Amélie', year: 2001 },
    { label: 'A Clockwork Orange', year: 1971 },
    { label: 'Like Stars on Earth', year: 2007 },
    { label: 'Taxi Driver', year: 1976 },
    { label: 'Lawrence of Arabia', year: 1962 },
    { label: 'Double Indemnity', year: 1944 },
    {
      label: 'Eternal Sunshine of the Spotless Mind',
      year: 2004,
    },
    { label: 'Amadeus', year: 1984 },
    { label: 'To Kill a Mockingbird', year: 1962 },
    { label: 'Toy Story 3', year: 2010 },
    { label: 'Logan', year: 2017 },
    { label: 'Full Metal Jacket', year: 1987 },
    { label: 'Dangal', year: 2016 },
    { label: 'The Sting', year: 1973 },
    { label: '2001: A Space Odyssey', year: 1968 },
    { label: "Singin' in the Rain", year: 1952 },
    { label: 'Toy Story', year: 1995 },
    { label: 'Bicycle Thieves', year: 1948 },
    { label: 'The Kid', year: 1921 },
    { label: 'Inglourious Basterds', year: 2009 },
    { label: 'Snatch', year: 2000 },
    { label: '3 Idiots', year: 2009 },
    { label: 'Monty Python and the Holy Grail', year: 1975 },
  ];

  if (allSubjects && allSubjects.data && allSubjects.data.length) {
    allSubjects.data.map(sub2 => {
      console.log('sub2 ', sub2)
      const sub = {
        label: sub2.subject_name, id: sub2.subject_id.toString()
      }

      autoSubs.push(sub)
    })
  }

  console.log('allboards ', JSON.stringify(allBoards))

  if (allBoards && allBoards.length) {
    allBoards.map(brd2 => {
      console.log('brd2 ', brd2)
      const brd = {
        label: brd2.institute_board_name, id: brd2.institute_board_id.toString()
      }

      autoBoards.push(brd)
    })
  }

  console.log('allboards autoboards ', JSON.stringify(autoBoards))

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDownloadFormat = async () => {

    const subId3 = subjectType;
    const brdId3 = boardType;
    const clsStd3 = standardType;

    const resp = await PUBLISHERAPI.getContFormat(subId3, brdId3, clsStd3);
    console.log('response for download format ', JSON.stringify(resp));

    const fileUrl = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png'
    const fileName = `1200px-PDF_file_icon.svg.png`

    if (resp) {
      const fileUrl2 = resp.data.file_location;
      const fileName2 = resp.data.file_name;

      handleDownload(fileUrl2, fileName2);

      handleClickOpen()
    }

  }

  const handleDownload = (fileUrl, fileName) => {

    axios.get(fileUrl, {
      responseType: 'blob',
    })
      .then((res) => {
        console.log('blob response ', res)
        fileDownload(res.data, fileName)
      })


    /*
    if (resp) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute(
        'download',
        // `FileName.pdf`,
        `1200px-PDF_file_icon.svg.png`,
        // resp.file_location
      );
      
      link.setAttribute(
        'target',
        '_blank'
      )

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
    */
  }

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const handleSnackOpen = (open) => {
    setSnackOpen(open);
  };

  const handleChipChange = (chips) => {
    console.log('chip change called ', chips)
    setYourChips(chips)
  }

  const handleAddChip = (chip) => {
    console.log('onAdd chip called')
    let chips = yourChips
    chips.push(chip)
    setYourChips(chips)
  }

  const handleDeleteChip = (chip, index) => {
    console.log('onDelete chip called')

    // let chips = yourChips.filter(item => item != chip)
    let chips = yourChips.filter((item, idx) => idx !== index)
    setYourChips(chips)
  }

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleTagsChange = (e) => {
    const { name, value } = e.target

    setTags(value)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAgreeToggle = (e) => {
    console.log(e.target.checked)

    setAgree(e.target.checked)
  }

  const handleTitleChange = (event) => {
    console.log(`new title ${event}`);
    const { name, value } = event.target;
    setTitle(value);
  };

  const handleVideoChange = (event) => {
    console.log(`new video ${event}`);

    const { name, value } = event.target;
    setVideo(value);
  };

  const onPrefillData = () => {
    dispatch(listBoardGet(actvRow.institute_type_id)); // school boards
    dispatch(listStdGet(actvRow.institute_type_id));

    dispatch(listSubGet(actvRow.institute_board_id));
    dispatch(listChpGet(actvRow.subject_id, actvRow.institute_board_id));

    dispatch(listTopGet(actvRow.chapter_id));
  }

  const handleInstChange = (event) => {
    console.log("called handleInstChange");
    console.log(`new institute type ${event}`);
    const name = event.target.name;
    const value = event.target.value;
    // setState({
    // ...state,
    // [name]: event.target.value,
    // });


    // update to local storage
    localStorage.setItem("pustak.kgtopg.inst_type", value)

    setInstType(value);
    dispatch(listBoardGet(value));
    dispatch(listStdGet(value));

  };

  const handleBoardChange = (event) => {
    console.log("called handleBoardChange");
    console.log(`new board  ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log(`board selected value ${value}`);

    // update to local storage
    localStorage.setItem("pustak.kgtopg.board_type", value)

    setBoardType(value);
    // get subjects
    dispatch(listSubGet(value));
  };

  const handleStdChange = (event) => {
    console.log("called handleStdChange");
    console.log(`new standard ${event}`);

    const name = event.target.name;
    const value = event.target.value;

    // update to local storage
    localStorage.setItem("pustak.kgtopg.standard_type", value)
    setStandardType(value);
  };

  const handleSubChange = (event) => {
    console.log("called handleSubChange");
    console.log(`new subject ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setSubjectType(value);
    dispatch(listChpGet(value, boardType));
  };

  const loadChapters = (val) => {
    dispatch(listChpGet(val, boardType));
  }

  const handleChpChange = (event, chaptername) => {
    console.log("called handleChpChange");
    console.log(`new chapter ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log("chapter name", chaptername);

    // update to local storage
    localStorage.setItem("pustak.kgtopg.chapter", value)
    setChap(value);

    dispatch(listTopGet(value));
  };

  const handleTpcChange = (event) => {
    console.log("called handleTpcChange");
    console.log(`new topic ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setTpc(value);
  };

  const handleContTypeChange = (event) => {
    console.log("called handleTpcChange");
    console.log(`new topic ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setContType(value);
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      height: 30,
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      width: 310,
      padding: "10px 26px 10px 12px",
      placeholder: "Title",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",

        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const showInput = () => {
    SetShowInputField(true);
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadStartBulk = () => {
    setBulkIsUploading(true);
    setBulkProgress(0);
  };

  const handleProgressBulk = (progress) => {
    setBulkProgress(progress);
  };

  const handleUploadErrorBulk = (error) => {
    setBulkIsUploading(false);
  };

  const setSelections = async () => {
    const insType = localStorage.getItem("pustak.kgtopg.inst_type");
    const brdType = localStorage.getItem("pustak.kgtopg.board_type");
    const stdType = localStorage.getItem("pustak.kgtopg.standard_type");
    const subType = localStorage.getItem("pustak.kgtopg.subject");
    const chp = localStorage.getItem("pustak.kgtopg.chapter");

    console.log('setSelections inside uploadcontent insType ', insType)
    console.log('setSelections inside uploadcontent brdType ', brdType)
    console.log('setSelections inside uploadcontent stdType ', stdType)
    console.log('setSelections inside uploadcontent subType ', subType)
    console.log('setSelections inside uploadcontent chp ', chp)

    // update state
    if (insType) {
      setInstType(insType)
      // get boards filled up
      dispatch(listBoardGet(insType));
      dispatch(listStdGet(insType));

    }

    if (brdType) {
      setBoardType(brdType)

      await new Promise((resolve, reject) => {
        dispatch(listSubGet(brdType));
        resolve(null)
      })

      if (stdType) {
        setStandardType(stdType)

      }

    }

    if (subType) {
      setSubjectType(subType)

      await new Promise((resolve, reject) => {
        dispatch(listChpGet(subType, brdType));
        resolve(null)
      })

      if (chp)
        setChap(chp)


      await new Promise((resolve, reject) => {
        dispatch(listTopGet(chp));
        resolve(null)
      })

    }

  }

  const updateTopicsData = () => {
    console.log('checkk useeffect called and updated data...')
    // setTopicIds(topIds)
    // setSubTopicIds(subTopIds)
    // setUrls(uls)
  }

  /* called after render completed.. */
  useEffect(() => {
    dispatch(listInstGet());

    // get from local storage
    setSelections()

    // override if editing a record
    if (actvRow) {
      onPrefillData()
    }

    getAllDetails();

    updateTopicsData()
    // setTopicIds(topIds)
    // setSubTopicIds(subTopIds)
    // setUrls(uls)
    return () => {
      //
    };
  }, [/*topIds, subTopIds, uls*/]);

  const getAllDetails = async () => {
    await new Promise((resolve, reject) => {
      dispatch(listBoardGet(10001));
      resolve();
    });

    const firstBoardId =
      boardDetails && boardDetails.data && boardDetails.data.length
        ? boardDetails.data[0].institute_board_id
        : 0;

    // we got boards
    await new Promise((resolve, reject) => {
      dispatch(listSubGet(firstBoardId));
      resolve();
    });

    dispatch(listContTypeGet());
  };

  const handleUploadSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("ebook")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    // setFileNames(filename)
    setDownloadUrls(downloadURL);
    if (downloadURL) {
      setBulkProgress(0);
      setBulkIsUploading(false);
    }
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleBulkContentUpload = async () => {
    console.log(`ebook url ${downloadUrls}`);

    if (
      !excel ||
      // !instType ||
      // !boardType ||
      !subjectType ||
      // !standardType
      !selectedFile
      // ||
      // !agree
    ) {
      handleSnackOpen(true);
    } else {
      // send data

      // const body = {
      //     institute_type_id: 10001,
      //     institute_board_id: 1001,
      //     classroom_std: 10,
      //     course: 'M.P.C',
      //     subject_id: 108,

      //     content_location: downloadUrls
      // }

      const body2 = {
        institute_type_id: instType,
        institute_board_id: boardType,
        classroom_std: standardType,
        course: "",
        subject_id: subjectType,
        content_name: video,
        chapter_id: chap,
        chapter_name: chaptername,
        topic_id: tpc,
        topic_name: topicname,
        content_f_desc: topicname,
        content_length: "3",
        content_location:
          "https://www.youtube.com/watch?v=TMubSggUOVE&ab_channel=TheOrganicChemistryTutor",
        content_size: 300,
      };


      const body = new FormData()
      body.append('institute_type_id', (props.ilc) ? instType : 0)
      body.append('institute_board_id', (props.ilc) ? boardType : 0)
      body.append('classroom_std', (props.ilc) ? standardType : 0)
      body.append('course', "")
      body.append('subject_id', subjectType)
      // body.append('file', selectedFile)
      body.append('excel_file', selectedFile)
      body.append('content_prov_id', userId)

      console.log('upload bulk form data ', body);

      const resp = await PUBLISHERAPI.postSaveBulkContent2(body);
      console.log(`response ${JSON.stringify(resp)}`);


      // close the dialog..
      console.log(`props anchor ${props.anchor}`);
      console.log(`props oncloseclicked ${props.onCloseClicked}`);
      props.onCloseClicked(props.anchor);

      // also call search content
      // props.onSearchContent("");

      // call reload
      props.onReload()

      props.handleReloadData()
    }

  };

  const handleExcelChange = (event) => {
    setProgress(0)
    console.log("selected file in handleExcelChange target", event.target.files[0])
    setSelectedFile(event.target.files[0])
    if (event.target.files[0] && selectedFile != null) {
      setExcel(true)
      setProgress(100)
      console.log(`set excel true`)

    }

    console.log("selected file in handleExcelChange", selectedFile, event.target.files[0])
  }

  const onURLChanged = (event, sIdx, topicId, subTopicId, topicName, subTopicName) => {
    console.log('onurlchanged event', event)
    console.log('onurlchanged sidx ', sIdx)
    const { value } = event.target;

    /*
    const topId = topIds[sIdx];
    const topName = topNames[sIdx];
    const subTopId = subTopIds[sIdx];
    const subTopName = subTopNames[sIdx];
    const ul = uls[sIdx];
    */
    const topId = topicId;
    const subTopId = subTopicId;
    const ul = value;
    console.log('onurlchanged topId ', topId);
    console.log('onurlchanged subtopid ', subTopId);
    console.log('onurlchanged ul ', ul);


    // check if index does not exist in state, only then save new or update
    const idex = subTopicIds.indexOf(subTopId)

    // assign from permanent data
    const topName = topicName;
    const subTopName = subTopicName;

    // assign from temporary state
    let urlsNew = urls;
    let topicIdsNew = topicIds;//.filter(item => parseInt(item) !== parseInt(topId));
    let topicNamesNew = topicNames;
    let subTopicIdsNew = subTopicIds;//.filter(item => parseInt(item) !== parseInt(subTopId));
    let subTopicNamesNew = subTopicNames;

    console.log('onurlchanged idex is ', idex)

    if (idex == -1) { // new record

      topicIdsNew.push(topId);
      topicNamesNew.push(topName);
      subTopicIdsNew.push(subTopId);
      subTopicNamesNew.push(subTopName);
      urlsNew.push(value);
    } else {
      // update the array from state
      topicNamesNew[idex] = topName;
      subTopicNamesNew[idex] = subTopName;
      urlsNew[idex] = value;
    }

    // update state
    setTopicIds(topicIdsNew);
    setTopicNames(topicNamesNew);
    setSubTopicIds(subTopicIdsNew);
    setSubTopicNames(subTopicNamesNew);
    setUrls(urlsNew);

    console.log(`onurlchanged value entered ${value}`);

    console.log('onurlchanged checkk topic ids onurlchanged are ', topicIds);

    console.log('onurlchanged topic id in index is ', topId)
    console.log('onurlchanged subtopic id in index is ', subTopId)
    console.log('onurlchanged url in index is ', ul)

    console.log('onurlchanged topicIds onurlchanged ', topicIds);
    console.log('onurlchanged topicIdsNew onurlchanged ', topicIdsNew);

    console.log('onurlchanged subTopicIds onurlchanged ', subTopicIds);
    console.log('onurlchanged subTopicIdsNew onurlchanged ', subTopicIdsNew);
  }

  const handleSingleContentUpload = async () => {
    console.log(`ebook url ${ImageDownloadUrls}`);
    console.log(`chapter name ${chaptername}`)
    console.log(`video ${video}`)
    console.log(`chap ${chap}`)
    console.log(`tpc ${tpc}`)
    console.log(`instType ${instType}`)
    console.log(`boardType ${boardType}`)
    console.log(`subjectType ${subjectType}`)
    console.log(`stdType ${standardType}`)
    console.log(`title ${title}`)
    console.log(`contType ${contType}`)

    console.log(`checking params...`)

    console.log('handleSingleContentUpload checkk save topicds state ', topicIds)
    console.log('handleSingleContentUpload checkk save topicnames state ', topicNames)
    console.log('handleSingleContentUpload checkk save subtopicds state ', subTopicIds)
    console.log('handleSingleContentUpload checkk save subtopicnames state ', subTopicNames)
    console.log('handleSingleContentUpload checkk save urls state ', urls)

    if (
      // !instType ||
      // !boardType ||
      !subjectType ||
      // !standardType ||
      !chap ||
      // !topicIds.length ||
      // !topicNames.length ||
      // !subTopicIds.length ||
      // !subTopicNames.length ||
      !topIds.length ||
      !subTopIds.length ||
      !uls.length
      // !urls.length
      // !tpc ||
      // !contType ||
      // !title ||
      // !ImageDownloadUrls ||
      // !chaptername ||
      // !yourChips ||

      /*
      !video ||
      (!video.includes('.mp4') && !video.includes('.mp3')  // video or audio not?
        && (!video.includes('.jpg') && !video.includes('.jpeg') && !video.includes('.png') && !video.includes('.gif')) // or image not?
        && (!video.includes('vimeo')) // not vimeo?
      )
      */

    ) {
      console.log(`showing snackbar..`)
      handleSnackOpen(true);
    } else {
      // send data

      console.log("calling api =========================================");
      // const body = {
      //     institute_type_id: 10001,
      //     institute_board_id: 1001,
      //     classroom_std: 10,
      //     course: 'M.P.C',
      //     subject_id: 108,
      //     content_location: downloadUrls
      // }

      console.log(
        "parameters",
        instType,
        boardType,
        subjectType,
        standardType,
        chap,
        tpc,
        contType,
        title,
        video,
        ImageDownloadUrls,
        chaptername
      );

      let topicData = []
      // use state vars which are updated
      topicIds.map((tp, idx) => {
        const tpId = tp;
        const tpName = topicNames[idx];
        const subTpId = subTopicIds[idx];
        const subTpName = subTopicNames[idx];
        const url = urls[idx]

        const tpObj = {
          topic_id: tpId,
          topic_name: tpName,
          topic_sub_id: subTpId,
          topic_sub_name: subTpName,
          url: url
        }

        topicData.push(tpObj)
      })


      const body = {
        institute_type_id: (props.ilc) ? instType : 0,
        institute_board_id: (props.ilc) ? boardType : 0,
        classroom_std: (props.ilc) ? standardType : 0,
        course: `class ${standardType}`,
        subject_id: subjectType,
        content_name: title,
        chapter_id: chap,
        chapter_name: chaptername,
        topic_id: tpc,
        topic_name: topicname,
        content_f_desc: topicname,
        content_length: 0,
        content_location: video,
        content_size: 0,
        // content_type_id: contType,
        content_prov_id: userId,
        tags: yourChips.join(','),
        // image_cover_url: encodeURI(ImageDownloadUrls),
        image_cover_url: ImageDownloadUrls
      };

      const body2 = {
        prov_id: userId,
        institute_type_id: (props.ilc) ? instType : 0,
        institute_board_id: (props.ilc) ? boardType : 0,
        classroom_std: (props.ilc) ? standardType : 0,
        subject_id: subjectType,
        chapter_id: chap,
        topics: topicData
      }

      console.log(`handleSingleContentUpload content api body ${JSON.stringify(body2)}`)
      let resp;
      if (!actvRow) {
        resp = await new Promise(async (resolve, reject) => {
          // await PUBLISHERAPI.postSaveSingleContent(body);
          await PUBLISHERAPI.postSaveSingleContent2(body2)

          resolve();
        });
      } else {
        resp = await new Promise(async (resolve, reject) => {
          await PUBLISHERAPI.updateSingleContent(actvRow.id, body);

          resolve();
        });
      }

      // reset
      setTopicIds([])
      setSubTopicIds([])
      setTopicNames([])
      setSubTopicNames([])
      setUrls([])

      // clear topics in redux state
      dispatch(listTopReset());

      console.log(`handleSingleContentUpload response ${JSON.stringify(resp)}`);

      // close the dialog..
      console.log(`props anchor ${props.anchor}`);
      console.log(`props oncloseclicked ${props.onCloseClicked}`);
      props.onCloseClicked(props.anchor);

      // also call search content
      // props.onSearchContent("");

      // call reload
      props.onReload()
      props.handleReloadData()
    }
  }; // end of function

  console.log(`actvRow image ${(ImageDownloadUrls)}`)
  console.log(`instType ${instType}`)
  console.log(`boardType ${boardType}`)
  console.log(`subjectType ${subjectType}`)
  console.log(`standardType ${standardType}`)
  console.log(`chap ${chap}`)
  console.log(`tpc ${tpc}`)
  console.log(`title ${title}`)
  console.log(`ImageDownloadUrls ${ImageDownloadUrls}`)
  console.log(`chaptername ${chaptername}`)
  console.log(`video ${video}`)
  console.log(`agree ${agree}`)

  let singleUploadDisabled = false;
  let bulkUploadDisabled = false;
  let openUploadDisabled = false;

  console.log('checkk topicds state ', topicIds)
  console.log('checkk subtopicds state ', subTopicIds)
  console.log('checkk urls state ', urls)

  if (
    // !instType ||
    // !boardType ||
    // !subjectType ||
    // !standardType ||
    // !chap ||
    // !tpc ||
    // !contType ||
    // !title ||
    // ((actvRow && !actvRow.image_cover_url) || 
    // !ImageDownloadUrls ||
    // !chaptername ||
    // !yourChips ||
    // !video
    // ||
    // !agree
    !TopDetails ||
    !TopDetails.data
  )
    singleUploadDisabled = true;


  if (
    !subjectType

    // !title ||
    // ((actvRow && !actvRow.image_cover_url) || 
    // !ImageDownloadUrls ||
    // !video
    // ||
    // !agree
  )
    openUploadDisabled = true;


  if (
    !subjectType ||

    !selectedFile ||
    !excel
    // ||
    // ((actvRow && !actvRow.image_cover_url) || 

    // !agree
  )
    bulkUploadDisabled = true;

  console.log(`allsubjects in upload modal ${JSON.stringify(allSubjects)}`)

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row'
    }}>
      <div
        style={{
          width: '27%'
        }}>
        <Toolbar className={classes.toolbar}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
            style={{ fontSize: 16, fontFamily: "Poppins, Helvetica, sans-serif" }}
          >
            Upload Content
          </Typography>

        </Toolbar>
        <Divider style={{ marginBottom: 10 }} />

        {/* <AppBar position="static" color="default" style={{ marginTop: "20" }}> */}


        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab
            label="Single Upload"
            {...a11yProps(0)}
            style={{ fontSize: 14 }}
          />
          {/* {(!actvRow && !props.ilc) && */}
          {/* {(!actvRow) && */}
          <Tab
            label="Bulk Upload"
            {...a11yProps(0)}
            style={{ fontSize: 14 }}
          />
          {/* } */}


          {/* <Tab
          label="Open Content Upload"
          {...a11yProps(0)}
          style={{ fontSize: 12 }}
        /> */}
        </Tabs>

        {/* </AppBar> */}


        {/* BULK UPLOAD PANEL */}
        {/* {(!actvRow && !props.ilc) && */}
        {(!actvRow) &&
          <TabPanel value={value} index={1}>
            <div
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                display: "block",
              }}
            >



              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}>





                {/* only show when in ilc mode */}
                {(props.ilc && instDetailsGet) ? (
                  loadingInst && instDetailsGet ? (
                    <div style={{
                      display: "flex",
                      justifyContent: "center", width: '95%'
                    }}>
                      <FormControl
                        variant="standard"
                        className={classes.formControl3}

                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Institute type
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={instType}
                          onChange={handleInstChange}
                          label="Select Institute Type"
                          size="small"
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          <MenuItem
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            No Institutes
                          </MenuItem>
                        </Select>

                      </FormControl>
                    </div>
                  ) : (
                    <div style={{
                      display: "flex",
                      justifyContent: "center", width: '95%'
                    }}>
                      <FormControl
                        variant="standard"
                        className={classes.formControl3}

                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Institute type
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={instType}
                          onChange={handleInstChange}
                          label="Select Institute Type"
                          size="small"
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          {loadingInst && instDetailsGet ? (
                            console.log("loading trueeee")
                          ) : instDetails.length != 0 ? (
                            instDetails.data.map((list, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={list.institute_type_id}
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                  }}
                                >
                                  {list.institute_type_name}
                                </MenuItem>
                                // <option key={index} value={list.institute_type_id}
                                //     style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                // >{list.institute_type_name}</option>
                              );
                            })
                          ) : (
                            <MenuItem
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              No Institutes
                            </MenuItem>
                          )}
                        </Select>

                      </FormControl>
                    </div>
                  )
                ) :
                  props.ilc && (
                    <div style={{
                      display: "flex",
                      justifyContent: "center", width: '95%'
                    }}>
                      <FormControl
                        variant="standard"
                        className={classes.formControl3}

                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Institute type
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={instType}
                          onChange={handleInstChange}
                          label="Select Institute Type"
                          size="small"
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          <MenuItem
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            No Institutes
                          </MenuItem>
                        </Select>

                      </FormControl>
                    </div>
                  )}


                {/* college disciplines */}
                {props.ilc && instType == 10002 && (
                  <div style={{
                    display: "flex",
                    justifyContent: "center", width: '90%'
                  }}>
                    <FormControl variant="standard"
                      className={classes.formControl3}>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Discipline
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue="71"
                        value="71"
                        label="Discipline"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        <option value="71">Agriculture</option>
                        <option value="64">Allied Health Science</option>
                        <option value="68">Animation, Film Studies&#160; Visual Arts</option>
                        <option value="8">Architecture</option>
                        <option value="9">Arts</option>
                        <option value="72">Ayurvedic Pharmaceutical Sciences</option>
                        <option value="73">Biotechnology</option>
                        <option value="74">Botany</option>
                        <option value="67">Buddhist Studies</option>
                        <option value="10">Commerce</option>
                        <option value="75">Computer Applications</option>
                        <option value="69">Computing</option>
                        <option value="60">Dental</option>
                        <option value="61">Design</option>
                        <option value="76">Economics</option>
                        <option value="77">Education</option>
                        <option value="11">Engineering</option>
                        <option value="78">Fashion Design</option>
                        <option value="79">Fine Arts</option>
                        <option value="80">Forensic Sciences</option>
                        <option value="81">Hotel Management &amp; Tourism</option>
                        <option value="12">Humanities</option>
                        <option value="82">Indian Languages</option>
                        <option value="83">Interior &amp; Furniture Design</option>
                        <option value="84">Journalism &amp; Film Production</option>
                        <option value="13">Law</option>
                        <option value="96">Liberal Arts</option>
                        <option value="85">Library Science</option>
                        <option value="14">Management</option>
                        <option value="86">Mathematics</option>
                        <option value="70">Media Studies</option>
                        <option value="15">Medical</option>
                        <option value="87">Medical Laboratory Sciences</option>
                        <option value="88">Microbiology</option>
                        <option value="89">Multimedia &amp; Animation</option>
                        <option value="65">Nursing</option>
                        <option value="62">Optometry</option>
                        <option value="90">Performing Arts</option>
                        <option value="16">Pharmacy</option>
                        <option value="91">Physical Education</option>
                        <option value="66">Physiotherapy</option>
                        <option value="92">Planning</option>
                        <option value="94">Psychology</option>
                        <option value="17">Science</option>
                        <option value="63">Vocational</option>
                        <option value="95">Zoology</option>
                      </Select>

                    </FormControl>
                  </div>
                )}



                {/* college program level */}
                {props.ilc && instType == 10002 && (
                  <div style={{
                    display: "flex",
                    justifyContent: "center", width: '90%'
                  }}>
                    <FormControl variant="standard"
                      className={classes.formControl3}>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Program Level
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue="13"
                        value="13"
                        label="Discipline"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        <option value="13">Diploma</option>
                        <option value="43">Doctor of Pharmacy (Post Baccalaureate)</option>
                        <option value="18">Dual Bachelors And Masters Degree</option>
                        <option value="15">Dual Bachelors Degree</option>
                        <option value="19">Dual Masters</option>
                        <option value="21">Integrated PhD</option>
                        <option value="20">PhD</option>
                        <option value="16">Post Graduate (PG)</option>
                        <option value="17">Post Graduate Diplomas</option>
                        <option value="14">Under Graduate (UG)</option>
                      </Select>

                    </FormControl>
                  </div>
                )}



                {/* college nature of courses */}
                {props.ilc && instType == 10002 && (
                  <div style={{
                    display: "flex",
                    justifyContent: "center", width: '90%'
                  }}>
                    <FormControl variant="standard"
                      className={classes.formControl3}>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Nature Of Courses
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue="199"
                        value="199"
                        label="Nature Of Courses"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        <option value="199">	Bachelor of Law (LLB)</option>
                        <option value="120">B. Pharma + MBA (Integrated)</option>
                        <option value="172">B. Sc. Blended</option>
                        <option value="157">B.A + L.L B</option>
                        <option value="158">B.B.A. + L.L.B.</option>
                        <option value="89">B.Com + LLB (Hons. Integrated)</option>
                        <option value="166">B.Sc (Hospitality Management)</option>
                        <option value="179">B.Sc. - Animation, Gaming &amp; VFX</option>
                        <option value="181">B.Sc. - Film Studies/Television Studies</option>
                        <option value="91">B.Tech + M.Tech (Integrated)</option>
                        <option value="92">B.Tech + MBA (Integrated)</option>
                        <option value="93">BA + B.Ed (Intergrated)</option>
                        <option value="94">BA + LLB (Hons. Integrated)</option>
                        <option value="95">BA + LLB (Integrated)</option>
                        <option value="183">BA - Liberal Arts(Hons)</option>
                        <option value="184">BA - Psychology</option>
                        <option value="160">Bachelor in Clinical Optometry (B.Optom.)</option>
                        <option value="170">Bachelor In Communication And Journalism</option>
                        <option value="8">Bachelor in Multimedia Communication(BMMC)</option>
                        <option value="9">Bachelor of Architecture (B.Arch)</option>
                        <option value="13">Bachelor of Arts (BA - 3 Continent)</option>
                        <option value="11">Bachelor of Arts (BA)</option>
                        <option value="202">Bachelor of Arts (BA) / Bachelors of Science (BSc)</option>
                        <option value="14">Bachelor of Arts (Honors)</option>
                        <option value="15">Bachelor of Business Administration (BBA)</option>
                        <option value="126">Bachelor of Business Administration (BBA) (Honors)</option>
                        <option value="16">Bachelor of Commerce (B.Com)</option>
                        <option value="17">Bachelor of Commerce (Honors)</option>
                        <option value="20">Bachelor of Computer Application (BCA - Evening)</option>
                        <option value="21">Bachelor of Computer Application (BCA)</option>
                        <option value="22">Bachelor of Design (B.Des)</option>
                        <option value="24">Bachelor of Education (B.Ed)</option>
                        <option value="192">Bachelor of Education (B.Ed)</option>
                        <option value="26">Bachelor of Engineering (B.E.)</option>
                        <option value="27">Bachelor of Environment Management (BEM)</option>
                        <option value="28">Bachelor of Fashion Technology (BFT)</option>
                        <option value="29">Bachelor of Fine Arts (BFA)</option>
                        <option value="30">Bachelor of Hotel Management</option>
                        <option value="31">Bachelor of Hotel Management &amp; Catering Technology (BHM &amp; CT)</option>
                        <option value="32">Bachelor of Interior Design</option>
                        <option value="33">Bachelor of Law (LLB)</option>
                        <option value="193">Bachelor of Library &amp; Information Sciences (B. Lib. I.Sc.)</option>
                        <option value="127">Bachelor of Library and Information Science (BLIS)</option>
                        <option value="34">Bachelor of Management Studies (BMS)</option>
                        <option value="201">Bachelor of Medical Laboratory Technology (BMLT)</option>
                        <option value="35">Bachelor of Occupational Therapy</option>
                        <option value="195">Bachelor of Performing Arts (BPA)</option>
                        <option value="36">Bachelor of Pharmacy (B.Pharm)</option>
                        <option value="37">Bachelor of Physical Education &amp; Sports</option>
                        <option value="194">Bachelor of Physical Education &amp; Sports (BPES)</option>
                        <option value="38">Bachelor of Physiotherapy</option>
                        <option value="39">Bachelor of Planning</option>
                        <option value="191">Bachelor of Planning (B. Plan.)</option>
                        <option value="124">Bachelor of Public Health</option>
                        <option value="40">Bachelor of Science (B.Sc)</option>
                        <option value="41">Bachelor of Science (Honors)</option>
                        <option value="43">Bachelor of Social Work (BSW)</option>
                        <option value="44">Bachelor of Statistics</option>
                        <option value="47">Bachelor of Technology (B.Tech - Evening)</option>
                        <option value="48">Bachelor of Technology (B.Tech - International)</option>
                        <option value="45">Bachelor of Technology (B.Tech)</option>
                        <option value="49">Bachelor of Vocation (B.Voc.)</option>
                        <option value="97">BBA + LLB  (Integrated)</option>
                        <option value="98">BBA + LLB (Hons. Integrated)</option>
                        <option value="99">BBA + MBA (Integrated)</option>
                        <option value="100">BCA + MCA (Intergrated)</option>
                        <option value="101">BSC + MSC (Intergrated)</option>
                        <option value="152">Diploma</option>
                        <option value="196">Diploma</option>
                        <option value="187">Diploma (Engineering) </option>
                        <option value="3">Diploma in Elementary Teacher Training</option>
                        <option value="4">Diploma in Food Production</option>
                        <option value="159">Doctor of Pharmacy</option>
                        <option value="154">Doctor of Pharmacy (Post Baccalaureate)</option>
                        <option value="50">Executive MBA (EMBA)</option>
                        <option value="197">Integrated B.Sc. - B.Ed.</option>
                        <option value="174">Integrated PhD</option>
                        <option value="171">M.A./M.Sc.</option>
                        <option value="102">M.Pharm + MBA (Integrated)</option>
                        <option value="103">M.Phil. + Ph.D (Integrated)</option>
                        <option value="176">M.S. (Pharm)</option>
                        <option value="189">M.Sc. (Hons.)</option>
                        <option value="185">M.Sc. - Animation &amp; Multimedia Technology</option>
                        <option value="188">M.Sc. Agriculture</option>
                        <option value="156">M.Tech + PhD (Integrated)</option>
                        <option value="51">Master Degree in Hospital Management (MDHM)</option>
                        <option value="52">Master of Architecture (M.Arch)</option>
                        <option value="53">Master of Arts (M.A.)</option>
                        <option value="203">Master of Arts (MA) / Master of Science (MSc)</option>
                        <option value="55">Master of Business Administration (MBA - International)</option>
                        <option value="56">Master of Business Administration (MBA)</option>
                        <option value="57">Master of Commerce (M.Com)</option>
                        <option value="58">Master of Computer Application (MCA)</option>
                        <option value="190">Master of Computer Application (MCA) - Hons.</option>
                        <option value="60">Master of Design (M.Des)</option>
                        <option value="61">Master of Education (M.Ed)</option>
                        <option value="168">Master of Engineering</option>
                        <option value="62">Master of Fine Arts (MFA)</option>
                        <option value="63">Master of Hospital Administration</option>
                        <option value="64">Master of Human Resource Management</option>
                        <option value="65">Master of Interior Design</option>
                        <option value="67">Master of Law (LLM)</option>
                        <option value="68">Master of Library and Information Science (MLIS)</option>
                        <option value="69">Master of Management Programme (M.Mgt)</option>
                        <option value="167">Master of Management Studies (MMS)</option>
                        <option value="161">Master of Optometry (M.Optom.)</option>
                        <option value="70">Master of Pharmacy (M.Pharm)</option>
                        <option value="71">Master of Philosophy (M.Phil)</option>
                        <option value="72">Master of Physical Education (MPEd)</option>
                        <option value="73">Master of Planning</option>
                        <option value="74">Master of Public Health (MPH)</option>
                        <option value="198">Master of Science (Ag. Horticulture)</option>
                        <option value="75">Master of Science (M.Sc)</option>
                        <option value="76">Master of Social Work (MSW)</option>
                        <option value="79">Master of Technology (M.Tech)</option>
                        <option value="80">Master of Travel &amp; Tourism Management</option>
                        <option value="81">Masters (MS)-Research</option>
                        <option value="82">Masters in Mass Communication (MMC)</option>
                        <option value="84">Masters of Physiotherapy (MP)</option>
                        <option value="169">ME + Ph.D. (Integrated)</option>
                        <option value="87">Ph.D</option>
                        <option value="164">Pharma.D</option>
                        <option value="162">pharmacy</option>
                        <option value="204">Post Basic B.sc</option>
                        <option value="107">Post Graduate Diploma in Management(PGDM)</option>
                        <option value="108">Post Graduate Diploma(PGD)</option>
                      </Select>

                    </FormControl>
                  </div>
                )}


                {/* college subjects / branch */}
                {props.ilc && instType == 10002 && (
                  <div style={{
                    display: "flex",
                    justifyContent: "center", width: '90%'
                  }}>
                    <FormControl variant="standard"
                      className={classes.formControl3}>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Branch / Subjects
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue="1762"
                        value="1762"
                        label="Branch / Subjects"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        <option value="1762"> Traffic &amp; Transportation Planning</option>
                        <option value="1569">(Environment, Climate Change and Sustainability Studies) – Mumbai Campus</option>
                        <option value="1646">&#160;Microbiology</option>
                        <option value="1678">&#160;Obstetric and Gynecological nursing</option>
                        <option value="1671">&#160;Oral Medicine &amp; Radiology</option>
                        <option value="1670">&#160;Oral Pathology and Microbiology</option>
                        <option value="1645">&#160;Pathology</option>
                        <option value="1644">&#160;Physiology&#160;</option>
                        <option value="1727">&#160;Psychology</option>
                        <option value="1675">&#160;Public Health Dentistry</option>
                        <option value="1165">Access to Justice</option>
                        <option value="1601">Accident and Trauma Care Technology</option>
                        <option value="1403">Accounting</option>
                        <option value="2">Accounting and Finance</option>
                        <option value="1544">Actuarial Management</option>
                        <option value="3">Actuarial Science</option>
                        <option value="1636">Additive Manufacturing</option>
                        <option value="1524">Advance Diploma in Buddhist Studies</option>
                        <option value="1428">ADVANCED COST AND MANAGAMENT ACCOUNTING</option>
                        <option value="1777">Advanced Manufacturing</option>
                        <option value="1287">Advanced Manufacturing and Design</option>
                        <option value="1423">ADVERTISING AND MARKETING</option>
                        <option value="8">Advertising and Marketing Management</option>
                        <option value="10">Aeronautical Engineering</option>
                        <option value="13">Aerospace Engineering</option>
                        <option value="1514">African Peace &amp; Security</option>
                        <option value="17">Agri Business</option>
                        <option value="18">Agricultural and Food Engineering</option>
                        <option value="24">Agricultural Economics</option>
                        <option value="25">Agricultural Engineering</option>
                        <option value="1106">Agricultural Extension and Communication</option>
                        <option value="26">Agricultural Meteorology</option>
                        <option value="1114">Agricultural Microbiology</option>
                        <option value="27">Agriculture</option>
                        <option value="1185">Agriculture &amp; Food Business</option>
                        <option value="29">Agriculture and Food Business</option>
                        <option value="30">Agriculture Engineering</option>
                        <option value="1735">Agriculture Sciences</option>
                        <option value="1778">Agriculture with Secialisation in Agricultural Extension Education</option>
                        <option value="1781">Agriculture with Specialisation in Agronomy</option>
                        <option value="1779">Agriculture with specialisation in Soil Science</option>
                        <option value="1780">Agriculture with specialisation Plant Breeding &amp; Genetics</option>
                        <option value="31">Agroforestry</option>
                        <option value="32">Agronomy</option>
                        <option value="1432">Airlines &amp; Airport Management</option>
                        <option value="1743">Airlines, Tourism &amp; Hospitality</option>
                        <option value="35">Airport and Aviation Business Management</option>
                        <option value="1321">Alternate Hydro Energy Systems</option>
                        <option value="51">Alternative Dispute Resolution</option>
                        <option value="1511">Ambedkar Thoughts in National Security</option>
                        <option value="1602">Anaesthesia Technology</option>
                        <option value="52">Analytical Chemistry</option>
                        <option value="1814">Analytics and Big Data</option>
                        <option value="1096">Anasthesia Techology</option>
                        <option value="53">Anatomy</option>
                        <option value="54">Ancient Indian History, Culture and Archealogy</option>
                        <option value="1782">Ancient Indian Wisdom and Yoga</option>
                        <option value="1655">Anesthesia</option>
                        <option value="56">Animal Biology and Biotechnology</option>
                        <option value="57">Animation</option>
                        <option value="1725">Animation &amp; Multimedia Technology</option>
                        <option value="58">Animation and Graphic Design</option>
                        <option value="59">Animation with International Moving Image Society, UK (Chartered Body)</option>
                        <option value="60">Anthropology</option>
                        <option value="61">Applicable Mathematics</option>
                        <option value="63">Applied Chemistry</option>
                        <option value="64">Applied Chemistry and Polymer Technology</option>
                        <option value="1776">Applied Economics</option>
                        <option value="66">Applied Electronics</option>
                        <option value="68">Applied Geochemistry</option>
                        <option value="69">Applied Geology</option>
                        <option value="70">Applied Linguistics</option>
                        <option value="71">Applied Mathematics</option>
                        <option value="1687">Applied Mechanics</option>
                        <option value="72">Applied Microbiology</option>
                        <option value="73">Applied Philosophy</option>
                        <option value="74">Applied Physics</option>
                        <option value="75">Applied Psychology</option>
                        <option value="1566">Applied Psychology (Clinical &amp; Counseling Practice) – Mumbai Campus</option>
                        <option value="76">Applied Psychology (Clinical Psychology)</option>
                        <option value="78">Applied Psychology (Counseling Psychology)</option>
                        <option value="1315">Applied Science &amp; Engineering</option>
                        <option value="81">Applied Statistics</option>
                        <option value="82">Apprel and Textile Science</option>
                        <option value="83">Aquaculture</option>
                        <option value="84">Arabic</option>
                        <option value="1737">Architectural Assistantship</option>
                        <option value="86">Architectural Design</option>
                        <option value="87">Architecture</option>
                        <option value="88">Architecture and Planning</option>
                        <option value="89">Architecture and Regional Planning</option>
                        <option value="93">Artificial Intelligence</option>
                        <option value="1767">Artificial Intelligence  &amp; Data Science</option>
                        <option value="1729">Artificial Intelligence &amp; Machine Learning (AI&amp;ML)</option>
                        <option value="1783">Artificial Intelligence (Summit Research Program)</option>
                        <option value="94">Arts</option>
                        <option value="1444">Association of Chartered Certified Accountants (ACCA)</option>
                        <option value="96">Astronomy</option>
                        <option value="1194">Astronomy, Astrophysics and Space Engineering</option>
                        <option value="97">Atmosphere and Ocean Sciences</option>
                        <option value="1341">Audiology</option>
                        <option value="1363">AUDIOLOGY AND SPEECH LANGUAGE PATHOLOGY (BASLP)</option>
                        <option value="1427">AUDITING AND TAXATION</option>
                        <option value="1728">Augmented Reality &amp; Virtual Reality (AR &amp; VR)</option>
                        <option value="99">Automobile Engineering</option>
                        <option value="101">Automotive Electronics</option>
                        <option value="102">Automotive Engineering</option>
                        <option value="104">Automotive Technology</option>
                        <option value="1274">Autonomous Unmanned Vehicle Technologies</option>
                        <option value="105">Aviation Management</option>
                        <option value="106">Avionics</option>
                        <option value="1738">Ayurveda</option>
                        <option value="107">B. Design - (Product design / Interior design)</option>
                        <option value="116">Bachelor of Arts and Bachelor of Laws</option>
                        <option value="119">Bachelor of Arts and Bachelor of Laws Honors</option>
                        <option value="1528">Bachelor Of Business Administration (International Business)</option>
                        <option value="120">Bachelor of Business Administration and Bachelor of Laws</option>
                        <option value="122">Bachelor of Business Administration and Bachelor of Laws Honors</option>
                        <option value="123">Banking and Finance</option>
                        <option value="124">Banking and Insurance</option>
                        <option value="127">Basic Sciences</option>
                        <option value="1547">Beauty Therapy &amp; Aesthetics</option>
                        <option value="131">Bengali</option>
                        <option value="1091">Bharathanatyam</option>
                        <option value="1071">Big Data</option>
                        <option value="132">Big Data Analytics</option>
                        <option value="135">Bio Science</option>
                        <option value="136">Bio Technology</option>
                        <option value="137">Bio-Informatics</option>
                        <option value="138">Bio-Medical Engineering</option>
                        <option value="139">Biochemical</option>
                        <option value="140">Biochemical Engineering</option>
                        <option value="141">Biochemistry</option>
                        <option value="1474">Biochemistry, Genetics and Biotechnology (BcGBt)</option>
                        <option value="143">Bioinformatics</option>
                        <option value="144">Biological Sciences</option>
                        <option value="1768">Biology</option>
                        <option value="1784">Biology Research</option>
                        <option value="145">Biomedical Engineering</option>
                        <option value="146">Biomedical Engineering (BME)</option>
                        <option value="147">Biomedical Genetics</option>
                        <option value="148">Biomedical Instrumentation</option>
                        <option value="1397">Biomedical Signal Processing &amp; Instrumentation</option>
                        <option value="1338">Bioprocess Engineering</option>
                        <option value="149">Bioprocess Technology</option>
                        <option value="150">BioScience and Bio Engineering</option>
                        <option value="151">Biosciences and Biomedical Engineering</option>
                        <option value="152">Biostatistics</option>
                        <option value="1685">Biostatistics &amp; Population Sciences</option>
                        <option value="153">Biotechnology</option>
                        <option value="155">Biotechnology (spec. in Genetic Engineering )</option>
                        <option value="156">Biotechnology (spec. in Regenerative Medicine )</option>
                        <option value="1785">Biotechnology (Summit Research Program)</option>
                        <option value="157">Biotechnology and Biochemical Engineering</option>
                        <option value="1786">Biotechnology Research</option>
                        <option value="1387">Biotechnology with Artificial Intelligence &amp; Machine Learning</option>
                        <option value="163">Botany</option>
                        <option value="1787">Botany Research</option>
                        <option value="1507">Buddhist Studies</option>
                        <option value="1502">Buddhist Studies, Philosophy &amp; Comparative Religions</option>
                        <option value="166">Business Administration</option>
                        <option value="1446">Business Analysis</option>
                        <option value="167">Business Analytics</option>
                        <option value="1429">Business Analytics-IBM</option>
                        <option value="168">Business Development</option>
                        <option value="170">Business Management</option>
                        <option value="1543">Business Process Management</option>
                        <option value="172">Business Process Services (BPS)</option>
                        <option value="165">Business Studies</option>
                        <option value="173">CA</option>
                        <option value="174">CAD</option>
                        <option value="175">CAD / CAM</option>
                        <option value="1330">CAD, CAM &amp; Robotics</option>
                        <option value="1788">Cancer Biology</option>
                        <option value="1095">Cardiac Care Technology</option>
                        <option value="1355">Cardiac Perfusion Technology (CPT)</option>
                        <option value="1603">Cardiac Technology (ECHO)</option>
                        <option value="176">Cardiothoracic and Pulmonary Disorders</option>
                        <option value="1771">Cardiothorasic </option>
                        <option value="1136">Cardiovascular Technology</option>
                        <option value="1604">Cardiovascular Technology (CVTS)</option>
                        <option value="177">Career Guidance</option>
                        <option value="178">Career Guidance and Councelling</option>
                        <option value="179">Catering Science and Hotel Management</option>
                        <option value="180">Cellular and Molecular Oncology</option>
                        <option value="181">Centre for Oceans, Rivers, Atmosphere and Land Sciences Earth System Science and Technology</option>
                        <option value="1312">Centre for Transportation (CTRANS)</option>
                        <option value="182">Ceramic</option>
                        <option value="1445">Certified Management Accountants (CMA)</option>
                        <option value="1454">Certified Public Accountant (CPA)</option>
                        <option value="1515">Chattrapati Shivaji&#39;s Vision &amp; Nation Building</option>
                        <option value="187">Chemical and Biochemical Engineering</option>
                        <option value="188">Chemical Engineering</option>
                        <option value="191">Chemical Sciences</option>
                        <option value="1516">Chemical, Biological, Radiation, Nuclear &amp; National Security (CBRNNS)</option>
                        <option value="193">Cheminformatics</option>
                        <option value="194">Chemistry</option>
                        <option value="1789">Chemistry Research</option>
                        <option value="1464">Chemistry, Microbiology, Biotechnology (CMBt)</option>
                        <option value="1679">Child health nursing</option>
                        <option value="1166">Children &amp; Families</option>
                        <option value="200">Cinema and Television</option>
                        <option value="201">Civil</option>
                        <option value="203">Civil and Environmental Engineering</option>
                        <option value="204">Civil and Infrastructure Engineering</option>
                        <option value="205">Civil Engineering</option>
                        <option value="1837">Civil Engineering ( Water Resources / Structural Dynamics and  Earthquake / Transportation / Structural / Geotechnical Engg.)</option>
                        <option value="1148">Civil Engineering (Infrastructure Engineering &amp; Management)</option>
                        <option value="1441">Civil Engineering - Geo-Informatics</option>
                        <option value="208">Civil Engineering and Structural Engineering</option>
                        <option value="209">Civil Engineering and Transportation Engineering</option>
                        <option value="210">Civil Engineering Construction Engineering and Management</option>
                        <option value="1491">Civil Engineering Environment Engineering</option>
                        <option value="211">Civil Engineering Environmental Engineering and Management</option>
                        <option value="212">Civil Engineering Geotechnical Engineering</option>
                        <option value="213">Civil Engineering Hydraulic and Water Resources Engineering</option>
                        <option value="215">Civil Engineering Structural Engineering</option>
                        <option value="216">Civil Engineering Transportation Engineering</option>
                        <option value="1440">Civil Engineering- Energy and Environmental Technologies</option>
                        <option value="1386">Civil with Artificial Intelligence &amp; Machine Learning</option>
                        <option value="1642">Climate Change</option>
                        <option value="1163">Climate Change and Sustainability Studies</option>
                        <option value="217">Climate Sciences</option>
                        <option value="1256">Climate Studies</option>
                        <option value="1663">Clinical Biochemistry</option>
                        <option value="1271">Clinical Embryology</option>
                        <option value="1611">Clinical Embryology</option>
                        <option value="1664">Clinical Microbiology</option>
                        <option value="1599">Clinical Nutrition &amp; Dietetics</option>
                        <option value="1373">Clinical Nutrition and Food Science</option>
                        <option value="218">Clinical Psychology</option>
                        <option value="219">Clinical Research</option>
                        <option value="221">Cloud and Information Security integrated with KPMG Certified Cyber Security Professional</option>
                        <option value="1730">Cloud Computing &amp; DevOps</option>
                        <option value="227">Cognitive Sciences</option>
                        <option value="228">Commerce</option>
                        <option value="1376">Commerce and Management</option>
                        <option value="1395">Commercial Photography</option>
                        <option value="1399">Commercial Photography &amp; Cinematography</option>
                        <option value="229">Communication</option>
                        <option value="230">Communication and Journalism</option>
                        <option value="231">Communication and Signal Processing (EE)</option>
                        <option value="1690">Communication Design</option>
                        <option value="232">Communication Engineering</option>
                        <option value="233">Communication System Engineering</option>
                        <option value="1132">Community Development</option>
                        <option value="1680">Community health nursing</option>
                        <option value="1652">Community Medicine</option>
                        <option value="235">Community Science (Honors)</option>
                        <option value="236">Comparative Literature</option>
                        <option value="239">Computational Design</option>
                        <option value="1368">Computational Engineering &amp; Networking</option>
                        <option value="1377">Computational Neuroscience</option>
                        <option value="1790">Computational Science</option>
                        <option value="241">Computer</option>
                        <option value="1351">Computer Aided Structural Analysis and Design</option>
                        <option value="242">Computer and Communication Engineering</option>
                        <option value="244">Computer Applications</option>
                        <option value="245">Computer Engineering</option>
                        <option value="246">Computer Engineering (Cloud Technology and Mobile Application)</option>
                        <option value="248">Computer Integrated Design and Manufacturing</option>
                        <option value="251">Computer Science</option>
                        <option value="1468">Computer Science &amp; Business System in association with TCS</option>
                        <option value="1740">Computer Science &amp; Engineering - Android Application Development</option>
                        <option value="1741">Computer Science &amp; Engineering - Machine Learning</option>
                        <option value="1342">Computer Science &amp; Engineering with a Specialization in Blockchain Technology</option>
                        <option value="1348">Computer Science &amp; Engineering with specialization in Cyber Physical Systems</option>
                        <option value="252">Computer Science (Data Science)</option>
                        <option value="1184">Computer Science and Business System</option>
                        <option value="1706">Computer Science and Business systems (CSBS)</option>
                        <option value="253">Computer Science and Engineering</option>
                        <option value="1841">Computer Science and Engineering (Computer Science &amp; Engg. / Artificial Intelligence)</option>
                        <option value="1470">Computer Science and Engineering (Hons) -Big Data &amp; Analytics in association with IBM</option>
                        <option value="1469">Computer Science and Engineering (Hons) -Cloud Computing in association with IBM</option>
                        <option value="1471">Computer Science and Engineering (Hons)- Information Security in association with IBM</option>
                        <option value="1698">Computer Science and Engineering (International Campus Diu (IIITV-ICD))</option>
                        <option value="254">Computer Science and Engineering (Spec. in Artificial Intelligence and Machine Learning)</option>
                        <option value="255">Computer Science and Engineering (Spec. in Big Data Analytics)</option>
                        <option value="256">Computer Science and Engineering (Spec. in Bioinformatics)</option>
                        <option value="257">Computer Science and Engineering (Spec. in Cloud Computing)</option>
                        <option value="259">Computer Science and Engineering (Spec. in Cyber Security)</option>
                        <option value="260">Computer Science and Engineering (Spec. in Data Analytics)</option>
                        <option value="261">Computer Science and Engineering (Spec. in Information Security)</option>
                        <option value="262">Computer Science and Engineering (Spec. in Information Technology and Entrepreneurship)</option>
                        <option value="263">Computer Science and Engineering (Spec. in Information Technology)</option>
                        <option value="264">Computer Science and Engineering (Spec. in Internet of Things (IOT))</option>
                        <option value="266">Computer Science and Engineering (Spec. in Software Engineering)</option>
                        <option value="268">Computer Science and Engineering with Specialization in Artificial Intelligence</option>
                        <option value="1473">Computer Science and Engineering with specialization in DevOps</option>
                        <option value="1807">Computer Science and Engineering with specialization in Internet of Things</option>
                        <option value="1791">Computer Science and Entrepreneurship </option>
                        <option value="1375">Computer Science and IT</option>
                        <option value="1494">Computer Science and Systems Engineering</option>
                        <option value="276">Computer Science and Technology</option>
                        <option value="1350">Computer Science with cognitive systems</option>
                        <option value="1734">Computer Science, Stats and Mathematics&#160;</option>
                        <option value="278">Computer Technology</option>
                        <option value="281">Computing and Electrical Engineering</option>
                        <option value="1672">Conservative Dentistry</option>
                        <option value="283">Constitutional Law</option>
                        <option value="285">Construction Engineering and Management</option>
                        <option value="290">Construction Technology and Management</option>
                        <option value="291">Control and Automation</option>
                        <option value="292">Control and Instrumentation</option>
                        <option value="294">Control Systems</option>
                        <option value="295">Corporate Accounting with CMA USA integration</option>
                        <option value="297">Corporate Finance Integrated with CPA, USA + EA, USA</option>
                        <option value="298">Corporate Law</option>
                        <option value="299">Corporate Secretaryship</option>
                        <option value="300">Costume Design and Fashion</option>
                        <option value="1177">Counselling</option>
                        <option value="1517">Counter Terrorism Studies</option>
                        <option value="1167">Criminology &amp; Justice</option>
                        <option value="1635">Criminology Specialization in Forensic Psychology</option>
                        <option value="1489">Critical Care Technology</option>
                        <option value="303">Cryogenic Engineering</option>
                        <option value="1467">CSE (Artificial Intelligence &amp; Machine Learning) in association with IBM)</option>
                        <option value="1466">CSE (Graphics &amp; Gaming)</option>
                        <option value="1465">CSE (Internet of Things) in association with IBM</option>
                        <option value="1447">CSE - Cyber Security &amp; Digital Forensics</option>
                        <option value="1476">CSE with specialization in Cloud Computing (in collaboration with Virtusa)</option>
                        <option value="1442">CSE- Machine Learning &amp; Computing</option>
                        <option value="304">Culinary Arts</option>
                        <option value="1518">Cyber &amp;&#160; India’s National Security</option>
                        <option value="305">Cyber Forensics and Information Security</option>
                        <option value="1285">Cyber Physical Systems</option>
                        <option value="306">Cyber Security</option>
                        <option value="1262">Cyber Security and Cyber Law</option>
                        <option value="1378">Cyber Security Systems and Networks</option>
                        <option value="1168">Dalit &amp; Tribal Studies and Action</option>
                        <option value="309">Dance</option>
                        <option value="311">Data Analytics</option>
                        <option value="310">Data Analytics integrated with Foundation Certification Big Data from Micro Focus (Formerly HPE)</option>
                        <option value="1286">Data and Computational Science</option>
                        <option value="312">Data Science</option>
                        <option value="1481">Data Science and Analytics</option>
                        <option value="1492">Data Science and Artificial Intelligence</option>
                        <option value="314">Data Science and Business Analytics</option>
                        <option value="315">Data Sciences and Data Analytics</option>
                        <option value="1508">Defence &amp; Strategic Studies</option>
                        <option value="1519">Defence Analyst and National Security</option>
                        <option value="1792">Defence Technology</option>
                        <option value="1374">Deglutology and Swallowing Disorders</option>
                        <option value="1276">Dental Mechatronics</option>
                        <option value="1278">Dental Operating Room Assistant</option>
                        <option value="1662">Dermatology, Venereology &amp; Leprosy</option>
                        <option value="317">Design</option>
                        <option value="1419">Design &amp; Manufacturing</option>
                        <option value="1755">Design - Gaming</option>
                        <option value="1756">Design - Graphics</option>
                        <option value="1748">Design - Interior &amp; Furniture</option>
                        <option value="1754">Design - Multimedia</option>
                        <option value="1692">Design courses</option>
                        <option value="1693">Design Courses (Industrial Design, Communication Design Textile and Apparel Design)</option>
                        <option value="319">Design Engineering</option>
                        <option value="1174">Development Policy, Planning and Practice</option>
                        <option value="1581">Development Policy, Planning and Practice - Tuljapur Campus</option>
                        <option value="320">Development Studies</option>
                        <option value="1564">Development Studies – Mumbai Campus</option>
                        <option value="1357">Diabetes Sciences (DBS)</option>
                        <option value="1085">Dialysis Technology</option>
                        <option value="1356">Dialysis Therapy (DIT)</option>
                        <option value="1594">Dialysis Therapy Technology</option>
                        <option value="1793">Digital</option>
                        <option value="1318">Digital &amp; Cyber Forensic</option>
                        <option value="322">Digital Animation</option>
                        <option value="1815">Digital Business</option>
                        <option value="323">Digital Film Making with International Moving Image Society, UK (Chartered Body)</option>
                        <option value="1625">Digital Forensic and Information Security</option>
                        <option value="1265">Digital Humanities</option>
                        <option value="1732">Digital Marketing&#160;</option>
                        <option value="324">Digital System</option>
                        <option value="325">Digital Systems and Signal Processing</option>
                        <option value="1298">Diploma of Pharmacy</option>
                        <option value="1169">Disability Studies &amp; Action</option>
                        <option value="1173">Disaster Management</option>
                        <option value="1573">Disaster Management – Mumbai Campus</option>
                        <option value="1520">Disaster Management And National security</option>
                        <option value="1314">Disaster Mitigation &amp; Management</option>
                        <option value="1337">Disaster Mitigation and Management</option>
                        <option value="1523">Dr. Babasaheb Ambedkars Thoughts on Indias National Security</option>
                        <option value="339">Drug Regulatory Affairs</option>
                        <option value="342">Dyestuff Technology</option>
                        <option value="343">E-Commerce</option>
                        <option value="344">E-Learning Technology</option>
                        <option value="1637">E-Waste Resource and Engineering Management</option>
                        <option value="346">Earth Science</option>
                        <option value="347">Earth Sciences</option>
                        <option value="1302">Earthquake Engineering</option>
                        <option value="1450">ECE - Atmospheric Science</option>
                        <option value="1449">ECE - Radar and Communication (R&amp;C)</option>
                        <option value="1448">ECE - Very Large-Scale Integration</option>
                        <option value="1410">ECE with Internet of Things (IoT)</option>
                        <option value="1358">Echocardiography Technology (ECT)</option>
                        <option value="1101">Ecocardiography</option>
                        <option value="1501">Ecology and Environment Studies</option>
                        <option value="1178">Ecology, Environment and Sustainable Development</option>
                        <option value="1584">Ecology, Environment and Sustainable Development– Guwahati Campus</option>
                        <option value="349">Econometrics</option>
                        <option value="350">Economics</option>
                        <option value="1720">Economics and Finance</option>
                        <option value="1196">Economics, Psychology, English, Philosophy, Sociology, History</option>
                        <option value="1408">Economics, Statistics, Mathematics</option>
                        <option value="1126">Ecosophical Aesthetics</option>
                        <option value="355">Education</option>
                        <option value="356">Education (Elementary)</option>
                        <option value="1560">Education (Elementary) – Mumbai Campus</option>
                        <option value="1561">Education – Mumbai Campus</option>
                        <option value="358">Education Studies</option>
                        <option value="1451">EEE - Power Systems</option>
                        <option value="1411">EEE with Robotics and Automation</option>
                        <option value="1328">Electric Drives &amp; Power Electronics</option>
                        <option value="1707">Electrical and Computer Engineering</option>
                        <option value="365">Electrical and Electronics Engineering</option>
                        <option value="366">Electrical Engineering</option>
                        <option value="1839">Electrical Engineering (Control &amp; Industrial Automation / Power &amp; Energy Systems Engg)  </option>
                        <option value="1480">Electrical Engineering (Electric Vehicles)</option>
                        <option value="367">Electrical Engineering (Instrumentation Engineering)</option>
                        <option value="1495">Electrical Engineering (Power Electronics &amp; Drives)</option>
                        <option value="1146">Electrical Engineering (Power System)</option>
                        <option value="368">Electrical Engineering (Spec. in VLSI)</option>
                        <option value="369">Electrical Engineering and Power Electronics and Drives</option>
                        <option value="370">Electrical Engineering Control System Engineering</option>
                        <option value="371">Electrical Engineering Instrumentation and Signal Processing</option>
                        <option value="373">Electrical Engineering Power and Energy Systems</option>
                        <option value="1193">Electrical Engineering with specialisation in Communication &amp; Signal Processing</option>
                        <option value="1794">Electrical Engineering with Specialisation in Internet of Things</option>
                        <option value="1421">Electrical Engineering with specialisation in Signal Processing and Communication</option>
                        <option value="1192">Electrical Engineering with specialisation in VLSI Design and Nanoelectronics</option>
                        <option value="375">Electronics</option>
                        <option value="1349">Electronics &amp; Computer Engineering</option>
                        <option value="1499">Electronics &amp; Control System Engineering</option>
                        <option value="1181">Electronics - VLSI Design</option>
                        <option value="376">Electronics and Communication</option>
                        <option value="378">Electronics and Communication Engineering</option>
                        <option value="1809">Electronics and Communication Engineering  with specialization in Machine Learning and Signal Processing</option>
                        <option value="1810">Electronics and Communication Engineering  with specialization in Microelectronic systems and Internet of Things</option>
                        <option value="1808">Electronics and Communication Engineering  with specialization in Wireless Communication</option>
                        <option value="1742">Electronics and Communication Engineering (ECE)</option>
                        <option value="1593">Electronics and Communication Engineering (Spec. in Communication System Design)</option>
                        <option value="379">Electronics and Communication Engineering (Spec. in Communication System)</option>
                        <option value="1290">Electronics and Communication Engineering (Spec. in Electronics Systems Design)</option>
                        <option value="1389">Electronics and Communication Engineering (Spec. in Medical Electronics)</option>
                        <option value="381">Electronics and Communication Engineering (spl. In Biomedical Engineering)</option>
                        <option value="384">Electronics and Communication Engineering (spl. In Micro Electronic Systems and Embedded Technology)</option>
                        <option value="1388">Electronics and Communication Engineering with Artificial Intelligence &amp; Machine Learning</option>
                        <option value="1795">Electronics and Communication Engineering With Specialisation in Embeded System</option>
                        <option value="1796">Electronics and Communication Engineering With Specialisation in Optical Communication</option>
                        <option value="385">Electronics and communication systems</option>
                        <option value="386">Electronics and Communication with (spl. In Embedded Systems)</option>
                        <option value="387">Electronics and Communication with (spl. In VLSI)</option>
                        <option value="1840">Electronics and Communications ( Microelectronics &amp; VLSI Design / Communication &amp; Signal Processing Engg)</option>
                        <option value="389">Electronics and Control Engineering</option>
                        <option value="390">Electronics and Design</option>
                        <option value="392">Electronics and Electrical Communication Engineering Microelectronics and VLSI Design</option>
                        <option value="393">Electronics and Electrical Communication Engineering RF and Microwave Engineering</option>
                        <option value="396">Electronics and Electrical Engineering</option>
                        <option value="397">Electronics and Instrumentation</option>
                        <option value="398">Electronics and Instrumentation Engineering</option>
                        <option value="1842">Electronics and Instrumentation Engineering (Instrumentation Engineering)</option>
                        <option value="399">Electronics and Telecommunication</option>
                        <option value="400">Electronics and Telecommunication Engineering</option>
                        <option value="1497">Electronics and Telecommunications Engineering (RF &amp; Microwave)</option>
                        <option value="1496">Electronics and Telecommunications Engineering (Spl-VLSI Design &amp; Embedded System)</option>
                        <option value="1072">Electronics Design &amp; Technology</option>
                        <option value="401">Electronics Engineering</option>
                        <option value="403">Electronics Science</option>
                        <option value="404">Elementary Teacher Training</option>
                        <option value="407">Embedded Control System</option>
                        <option value="408">Embedded System and Technologies</option>
                        <option value="410">Embedded Systems Engineering</option>
                        <option value="1362">Emergency Medical Tech</option>
                        <option value="1267">Emergency Medicine</option>
                        <option value="1719">Emergency Medicine Technology (EMT)</option>
                        <option value="1401">Emerging Technologies</option>
                        <option value="1404">Emerging Technologies in Management</option>
                        <option value="411">Energy</option>
                        <option value="413">Energy and Environmental Engineering</option>
                        <option value="414">Energy and Infrastructure Management</option>
                        <option value="415">Energy Engineering</option>
                        <option value="416">Energy Engineering (Spec. in Materials)</option>
                        <option value="417">Energy Science and Engineering</option>
                        <option value="418">Energy Science and Technology</option>
                        <option value="419">Energy Studies</option>
                        <option value="420">Energy Systems (Focused on Solar Energy)</option>
                        <option value="1797">Energy Technology</option>
                        <option value="1703">Energy Technology and Management</option>
                        <option value="422">Engineering</option>
                        <option value="424">Engineering Design</option>
                        <option value="427">Engineering Physics</option>
                        <option value="428">Engineering Science</option>
                        <option value="430">English</option>
                        <option value="1379">English and Languages</option>
                        <option value="434">English Language Studies</option>
                        <option value="435">Entomology</option>
                        <option value="436">Entrepreneurship</option>
                        <option value="1145">Entrepreneurship &amp; International Trade</option>
                        <option value="437">Entrepreneurship and Family Business</option>
                        <option value="1134">Environment &amp; Occupational Health</option>
                        <option value="439">Environment and Water Resource Engineering</option>
                        <option value="440">Environment Science</option>
                        <option value="1075">Environmental chemistry</option>
                        <option value="441">Environmental Engineering</option>
                        <option value="442">Environmental Management</option>
                        <option value="1322">Environmental Management of Rivers and Lakes</option>
                        <option value="1117">Environmental Science</option>
                        <option value="443">Environmental Science and Engineering</option>
                        <option value="445">Environmental Sciences</option>
                        <option value="446">Environmental Studies</option>
                        <option value="1653">Epidemiology under Community Medicine</option>
                        <option value="1100">Epidemology</option>
                        <option value="1521">European Peace &amp; Security</option>
                        <option value="447">European Studies</option>
                        <option value="1684">Excavation Engineering</option>
                        <option value="1137">Exercise &amp; Sports Sciences</option>
                        <option value="450">Extension Education</option>
                        <option value="451">Extension Education and Communication Management</option>
                        <option value="1681">Faculty of Physiotherapy</option>
                        <option value="453">Family Business and Entrepreneurship</option>
                        <option value="455">Family Resource Management</option>
                        <option value="456">Farm Machinery and Power Engineering</option>
                        <option value="1709">Fashion and Apparel Design with specialization in Fashion Marketing and User Experience Design</option>
                        <option value="459">Fashion Design</option>
                        <option value="462">Fashion Retail Management</option>
                        <option value="463">Fashion Technology</option>
                        <option value="464">Fibres and Textile Processing Technology</option>
                        <option value="465">Film and Television Production</option>
                        <option value="466">Film and TV Production</option>
                        <option value="1724">Film Studies/Television Studies</option>
                        <option value="467">Finance</option>
                        <option value="468">Finance and Accounting</option>
                        <option value="470">Finance and Computer Applications</option>
                        <option value="1456">Finance and Control</option>
                        <option value="471">Finance and HR</option>
                        <option value="1772">Financial Analysis and Control</option>
                        <option value="473">Financial Analysis with KPMG International Accounting Practices</option>
                        <option value="474">Financial Economics</option>
                        <option value="1402">Financial Investment Analysis</option>
                        <option value="475">Financial Management</option>
                        <option value="476">Financial Markets</option>
                        <option value="478">Financial Services</option>
                        <option value="479">Financial Technology</option>
                        <option value="480">Fine Arts</option>
                        <option value="1619">Fingerprint Science</option>
                        <option value="1504">Five Years Integrated Cours in Defence and Strategic Studies</option>
                        <option value="481">Flim Technology</option>
                        <option value="482">Floriculture and Landscape Architecture</option>
                        <option value="483">Floriculture and Landscaping</option>
                        <option value="484">Fluids and Thermal Engineering</option>
                        <option value="485">Food and Nutrition</option>
                        <option value="486">Food and Nutritional Biotechnology</option>
                        <option value="487">Food Biotechnology</option>
                        <option value="488">Food Engineering and Technology</option>
                        <option value="490">Food Processing Technology</option>
                        <option value="1546">Food Processing Technology &amp; Management</option>
                        <option value="491">Food Production</option>
                        <option value="492">Food Safety and Quality Management</option>
                        <option value="1113">Food Science and Nutrition</option>
                        <option value="494">Food Science and Technology</option>
                        <option value="495">Food Science Technology</option>
                        <option value="496">Food Technology</option>
                        <option value="497">Food Technology</option>
                        <option value="1798">Food Technology Research</option>
                        <option value="1799">Food Technology with Summit Research Program</option>
                        <option value="1293">Food, Nutrition &amp; Dietetics</option>
                        <option value="1354">Food, Nutrition and Dietetics</option>
                        <option value="1530">Footwear - Design &amp; Production</option>
                        <option value="1125">Foreign Languages &amp; Intercultural Studies</option>
                        <option value="1819">Foreign Trade</option>
                        <option value="1774">Foreign Trade Practices and Sales Management</option>
                        <option value="1624">Forensic Accounting</option>
                        <option value="1618">Forensic Biotechnology</option>
                        <option value="1621">Forensic Document Examination</option>
                        <option value="1647">Forensic Medicine</option>
                        <option value="1634">Forensic Nanotechnology</option>
                        <option value="1272">Forensic Odontology</option>
                        <option value="1630">Forensic Pharmacy</option>
                        <option value="1631">Forensic Psychology</option>
                        <option value="499">Forensic Science</option>
                        <option value="500">Forensic Sciences</option>
                        <option value="1622">Forensic Toxicology</option>
                        <option value="501">Forest Genetic Resources</option>
                        <option value="502">Forestry</option>
                        <option value="1426">FOREX MANAGEMENT</option>
                        <option value="504">French</option>
                        <option value="506">Fruit Science</option>
                        <option value="1461">Gaming</option>
                        <option value="1548">Garment Designing</option>
                        <option value="1506">Gender Culture and Development Studies</option>
                        <option value="507">General</option>
                        <option value="508">General Management</option>
                        <option value="1648">General Medicine</option>
                        <option value="509">Genetic Engineering</option>
                        <option value="510">Genetics</option>
                        <option value="511">Genetics and Plant Breeding</option>
                        <option value="1800">Genetics Research</option>
                        <option value="513">Geo Informatics and Remote Sensing</option>
                        <option value="1200">Geo-exploration and Petroleum Geo-sciences</option>
                        <option value="514">Geo-informatics</option>
                        <option value="1156">Geo-Technical and Geo-Environmental Engineering</option>
                        <option value="517">Geography</option>
                        <option value="518">Geology</option>
                        <option value="519">Geology and Geophysics</option>
                        <option value="1323">Geomatics Engineering</option>
                        <option value="522">Geophysics</option>
                        <option value="523">Geotechical Engineering</option>
                        <option value="524">German</option>
                        <option value="525">Global Accounting Practices with Association of International Accounts</option>
                        <option value="1135">Global Health</option>
                        <option value="528">Global Logistics and Supply Chain Management</option>
                        <option value="531">Global Management Accountant Integrated with CIMA, UK + IMA, USA + CMA Australia</option>
                        <option value="1512">Global Peace, Security and Strategic Studies</option>
                        <option value="1144">Global studies</option>
                        <option value="532">Globalisation and Labour</option>
                        <option value="533">Governance and Public Administration</option>
                        <option value="534">Graphic Design</option>
                        <option value="1753">Graphics</option>
                        <option value="1082">Graphics and mutlimedia</option>
                        <option value="1823">Green Energy and Sustainability</option>
                        <option value="535">Green Technology</option>
                        <option value="1353">Ground Water Hydrology</option>
                        <option value="536">Guidance and Counselling</option>
                        <option value="1666">Head &amp; Neck Oncology</option>
                        <option value="537">Health Administration</option>
                        <option value="1759">Health and Physical Education</option>
                        <option value="1103">Health Care Quality and  Safety</option>
                        <option value="1124">Health Economics</option>
                        <option value="1102">Health Economics and  Outcomes Research</option>
                        <option value="1598">Health Information Management</option>
                        <option value="538">Health Policy, Economics and Finance</option>
                        <option value="1577">Health Policy, Economics and Finance – Mumbai Campus</option>
                        <option value="1479">Health Safety and Environment Engineering</option>
                        <option value="1529">Health Sciences</option>
                        <option value="1487">Healthcare</option>
                        <option value="540">Healthcare Management</option>
                        <option value="1488">Healthcare Tourism &amp; Hospitality</option>
                        <option value="541">Heat and Power</option>
                        <option value="1683">Heat Power Engineering</option>
                        <option value="542">Herbal technology</option>
                        <option value="1526">Higher Diploma In Buddhist Studies</option>
                        <option value="1525">Higher Diploma In Pali</option>
                        <option value="543">Hindi</option>
                        <option value="1503">Historical Studies</option>
                        <option value="544">History</option>
                        <option value="545">History and Tourism</option>
                        <option value="547">Home Science - Clinical Nutrition and Dietetics</option>
                        <option value="548">Home Science - Nutrition Food Service Management and Dietetics</option>
                        <option value="1623">Homeland Security</option>
                        <option value="550">Horticulture</option>
                        <option value="1299">Hospital &amp; Health System Management</option>
                        <option value="1300">Hospital Administration</option>
                        <option value="552">Hospital and Health Care Management</option>
                        <option value="553">Hospital Management</option>
                        <option value="1527">Hospitality And Facilities Management</option>
                        <option value="554">Hospitality and Hotel Administration (H. and H.A.)</option>
                        <option value="555">Hospitality Management</option>
                        <option value="557">Hotel Management</option>
                        <option value="558">Hotel Management and Catering Technology (BHM and CT)</option>
                        <option value="1643">Human Anatomy</option>
                        <option value="560">Human Development and Family Studies</option>
                        <option value="561">Human Genetics</option>
                        <option value="562">Human Genetics and Molecular Biology</option>
                        <option value="563">Human Physiology</option>
                        <option value="1460">Human Resource</option>
                        <option value="565">Human Resource Management</option>
                        <option value="1131">Human resource Management &amp; Industrial Relations</option>
                        <option value="1558">Human Resource Management &amp; Labour Relations – Mumbai Campus</option>
                        <option value="566">Human Resource Management and Labour Relations</option>
                        <option value="1127">Humanities</option>
                        <option value="568">Humanities and Social Sciences</option>
                        <option value="1620">Humantarian Forensics</option>
                        <option value="1324">Hydraulic Engineering</option>
                        <option value="569">Hydraulics and Water Resource Engineering</option>
                        <option value="1412">Hydro and Renewable Energy</option>
                        <option value="1076">Hydrogeology</option>
                        <option value="1305">Hydrology</option>
                        <option value="570">I C Technology</option>
                        <option value="571">Immunology</option>
                        <option value="573">Inductrial Biotechnology</option>
                        <option value="575">Industrial and Production</option>
                        <option value="579">Industrial Biotechnology</option>
                        <option value="1736">Industrial Chemistry</option>
                        <option value="580">Industrial Engineering</option>
                        <option value="1253">Industrial Engineering &amp; Operation Research</option>
                        <option value="581">Industrial Engineering and Management</option>
                        <option value="583">Industrial Metallurgy</option>
                        <option value="584">Industrial Microbiology</option>
                        <option value="585">Industrial Pharmacy</option>
                        <option value="1458">Industrial Product Design</option>
                        <option value="587">Industrial Safety Engineering</option>
                        <option value="589">Information and Communication Technology</option>
                        <option value="591">Information and Cyber security</option>
                        <option value="592">Information Science and Engineering</option>
                        <option value="593">Information Security</option>
                        <option value="594">Information Security and Cyber Forensics</option>
                        <option value="595">Information Security integrated with KPMG Advanced Certified Cyber Security Professional**</option>
                        <option value="596">Information System (IS)</option>
                        <option value="598">Information Technology</option>
                        <option value="603">Infrastructure Engineering and Management</option>
                        <option value="1541">Infrastructure Engineering with Specialization in Energy Engineering</option>
                        <option value="1540">Infrastructure Engineering with Specialization in Environmental Engineering</option>
                        <option value="1313">Infrastructure Systems</option>
                        <option value="607">Innovation and Entrepreneurship</option>
                        <option value="1074">Inorganic Chemistry</option>
                        <option value="1311">Institute Instrumentation Centre</option>
                        <option value="609">Instrumentation and Control Engineering</option>
                        <option value="1763">Instrumentation and Control Systems</option>
                        <option value="612">Insurance and Banking Management</option>
                        <option value="1424">INSURANCE AND RISK MANAGEMENT</option>
                        <option value="615">Insurance, Banking and Finance</option>
                        <option value="616">Integrated M. Phil.-Ph. D. Programme in Education</option>
                        <option value="617">Integrated M. Phil.-Ph. D. Programme in Social Sciences</option>
                        <option value="618">Integrated M.Phil Ph.D. in Social Sciences with Specialisation in Sociology of Education</option>
                        <option value="620">Integrated M.Phil-Ph.D programme School of Habitat Studies</option>
                        <option value="624">Integrated M.Phil.-Ph.D. in Inclusive Development and Social Justice</option>
                        <option value="1068">Integrated M.Phil.-Ph.D. Programme in Development Studies</option>
                        <option value="635">Integrated M.Phil.-Ph.D. Programme in Management and Labour Studies</option>
                        <option value="636">Integrated M.Phil.-Ph.D. Programme in Public Health</option>
                        <option value="625">Integrated M.Phil.-Ph.D. Programme in Rural Development</option>
                        <option value="637">Integrated M.Phil.-Ph.D. Programme in Social Work</option>
                        <option value="638">Integrated M.Phil.-Ph.D. Programme in Women&#39;s Studies</option>
                        <option value="626">Integrated Power System</option>
                        <option value="1638">Integrated Sensor System</option>
                        <option value="628">Intellectual Property Rights</option>
                        <option value="1406">Intellectual Property Rights and Cyber Law</option>
                        <option value="1364">Intensive Care Technology</option>
                        <option value="1380">Interdisciplinary Studies</option>
                        <option value="1747">Interior &amp; Furniture</option>
                        <option value="639">Interior Design</option>
                        <option value="640">Interior Design (Honors) with Chartered Institute of Architectural Technologists, UK</option>
                        <option value="641">Interior Design with Chartered Society of Designers, UK</option>
                        <option value="1708">Interior Design with Specialization in Accessories and Furniture Design &amp; User Experience Design</option>
                        <option value="1439">Interior Environment and Design</option>
                        <option value="1826">International Accounting</option>
                        <option value="642">International Accounting and Finance with ACCA UK integration#</option>
                        <option value="643">International Banking and Finance</option>
                        <option value="644">International Business</option>
                        <option value="646">International Business integrated with Chartered Institute of Marketing, UK</option>
                        <option value="1750">International Finance</option>
                        <option value="650">International Finance with CGMA-UK</option>
                        <option value="651">International Law</option>
                        <option value="652">International Marketing integrated with Digital Marketing Institute, Ireland</option>
                        <option value="654">International Relations</option>
                        <option value="657">International Trade and Economic Law</option>
                        <option value="1538">Internet of Things and Applications</option>
                        <option value="1827">Investment Management</option>
                        <option value="1277">IOT &amp; Applications</option>
                        <option value="660">IOT integrated with Foundation Certification in Cloud Technology from Micro Focus (Formerly HPE)</option>
                        <option value="1333">Irrigation Water Management</option>
                        <option value="661">Islamic Studies</option>
                        <option value="662">IT Business Management</option>
                        <option value="1486">IT for Healthcare</option>
                        <option value="665">Journalism</option>
                        <option value="666">Journalism and Communication</option>
                        <option value="667">Journalism and Mass Communication</option>
                        <option value="1143">Labour Law</option>
                        <option value="1562">Labour Studies &amp; Practices – Mumbai Campus</option>
                        <option value="672">Labour Studies and Social Protection</option>
                        <option value="1585">Labour Studies and Social Protection– Guwahati Campus</option>
                        <option value="674">Law</option>
                        <option value="1532">Leather Goods and Accessories Design</option>
                        <option value="676">Liberal Arts</option>
                        <option value="1749">Library &amp; Information Sciences (D. Lib.I.Sc.)</option>
                        <option value="677">Library and Information Science</option>
                        <option value="1567">Library and Information Science – Mumbai Campus</option>
                        <option value="678">Life Sciences</option>
                        <option value="681">Linguistics</option>
                        <option value="1493">Literature</option>
                        <option value="1170">Livelihoods and Social Entrepreneurship</option>
                        <option value="682">Logistics</option>
                        <option value="1443">Logistics &amp; Supply Chain Management (LSCM)</option>
                        <option value="683">Logistics and Supply Chain Management</option>
                        <option value="1818">Logistics Management</option>
                        <option value="684">LSCM+Chartered Institute of Procurement and Supply (CIPS)-UK</option>
                        <option value="1319">M.Arch</option>
                        <option value="1595">M.R.I.T. (Medical Radiology Imaging Technology)</option>
                        <option value="689">Machine Design</option>
                        <option value="1629">Malware Analysis &amp; Reverse Engineering</option>
                        <option value="1766">Man made Textile Technology</option>
                        <option value="690">Management</option>
                        <option value="691">Management Accounting</option>
                        <option value="1739">Management Accounting and International Finance</option>
                        <option value="692">Management Sciences</option>
                        <option value="693">Management Studies</option>
                        <option value="694">Manufacturing and Automation</option>
                        <option value="695">Manufacturing Engineering</option>
                        <option value="697">Manufacturing Technology</option>
                        <option value="1398">Manufacturing Technology &amp; Automation</option>
                        <option value="1812">Marathi</option>
                        <option value="698">Marine Engineering</option>
                        <option value="701">Marketing</option>
                        <option value="703">Marketing and Finance</option>
                        <option value="704">Marketing and HR</option>
                        <option value="705">Marketing and Sales</option>
                        <option value="1813">Marketing Management</option>
                        <option value="706">Mass Communication</option>
                        <option value="707">Master of Business Administration</option>
                        <option value="718">Material Science and Technology</option>
                        <option value="719">Material Science Engineering (MEMS)</option>
                        <option value="1331">Materials Engineering</option>
                        <option value="720">Materials Science</option>
                        <option value="721">Materials Science and Engineering</option>
                        <option value="722">Materials Science and Metallurgical Engineering</option>
                        <option value="1133">Maternal &amp; Child Health</option>
                        <option value="724">Mathematical Sciences</option>
                        <option value="725">Mathematics</option>
                        <option value="1201">Mathematics &amp; Applied Statistics and Informatics</option>
                        <option value="1420">Mathematics &amp; Statistics</option>
                        <option value="726">Mathematics and Computing</option>
                        <option value="728">Maths with Computer Application</option>
                        <option value="1452">ME - Robotics and Mechatronics</option>
                        <option value="734">Mechanical</option>
                        <option value="1308">Mechanical &amp; Industrial Engineering</option>
                        <option value="736">Mechanical Engineering</option>
                        <option value="1150">Mechanical Engineering (Design)</option>
                        <option value="738">Mechanical Engineering (Manufactruring Engineering)</option>
                        <option value="1498">Mechanical Engineering (Manufacturing Process &amp; System)</option>
                        <option value="1704">Mechanical Engineering (Production)</option>
                        <option value="1392">Mechanical Engineering (Spec. Artificial Intelligence &amp; Machine Learning)</option>
                        <option value="1400">Mechanical Engineering (Spec. Computer Aided Engineering)</option>
                        <option value="740">Mechanical Engineering (spec. in Automotive Engineering)</option>
                        <option value="1393">Mechanical Engineering (Spec. in Electric &amp; Hybrid Vehicles)</option>
                        <option value="741">Mechanical Engineering (Spec. in Energy Systems)</option>
                        <option value="1391">Mechanical Engineering (Spec. in Smart Manufacturing)</option>
                        <option value="1838">Mechanical Engineering (Thermal Engg. / Design and Manufacturing / CAD-CAM &amp; Automation / Materials &amp; Manufacturing Technology / Renewable Energy)</option>
                        <option value="1149">Mechanical Engineering (Thermal)</option>
                        <option value="1453">Mechanical Engineering (with sp. in Machine design)</option>
                        <option value="743">Mechanical Engineering - CAD/CAM</option>
                        <option value="744">Mechanical Engineering and Manufacturing Engineering</option>
                        <option value="745">Mechanical Engineering and Mechanical System Design</option>
                        <option value="746">Mechanical Engineering and Thermal Science and Engineering</option>
                        <option value="747">Mechanical Engineering Manufacturing Sc. and Engg.</option>
                        <option value="748">Mechanical Engineering Mechanical systems Design</option>
                        <option value="1199">Mechanical Engineering with specialisation in Mechanical Systems Design</option>
                        <option value="1198">Mechanical Engineering with specialisation in Production &amp;  Industrial Engineering</option>
                        <option value="750">Mechanical System Design(ME)</option>
                        <option value="1344">Mechanical with specialization in Cyber Physical Systems</option>
                        <option value="752">Mechatronics</option>
                        <option value="1346">Mechatronics &amp; Automation</option>
                        <option value="753">Mechatronics Engineering</option>
                        <option value="756">Media and Communications</option>
                        <option value="757">Media and Cultural Studies</option>
                        <option value="1563">Media and Cultural Studies – Mumbai Campus</option>
                        <option value="760">Media Studies</option>
                        <option value="1099">Media Technology</option>
                        <option value="1130">Medical &amp; Psychiatric Social work</option>
                        <option value="1269">Medical Anatomy</option>
                        <option value="1608">Medical Anatomy</option>
                        <option value="1295">Medical Biochemistry</option>
                        <option value="762">Medical Biotechnology</option>
                        <option value="763">Medical Electronics</option>
                        <option value="1613">Medical Genetics</option>
                        <option value="1614">Medical Genetics and Genomics</option>
                        <option value="1087">Medical Imaging Technology</option>
                        <option value="1086">Medical Lab Technology</option>
                        <option value="1098">Medical Laboratory Technology</option>
                        <option value="1752">Medical Laboratory Technology (DMLT)</option>
                        <option value="1370">Medical Laboratory Technology (MLT)</option>
                        <option value="1294">Medical Microbiology</option>
                        <option value="1270">Medical Pharmacology</option>
                        <option value="764">Medical Physics</option>
                        <option value="765">Medical Physiology</option>
                        <option value="1718">Medical Radiation Technology (MRT)</option>
                        <option value="1482">Medical Record Technology</option>
                        <option value="1609">Medical Social Worker (MSW)</option>
                        <option value="1273">Medical Statistics</option>
                        <option value="767">Medical Technology</option>
                        <option value="768">Medicinal and Aromatic Plants</option>
                        <option value="1695">Medicinal Chemistry</option>
                        <option value="1171">Mental Health</option>
                        <option value="1676">Mental health nursing</option>
                        <option value="769">Metallurgical and Material Science Engineering</option>
                        <option value="770">Metallurgical and Materials</option>
                        <option value="771">Metallurgical and Materials Engineering</option>
                        <option value="772">Metallurgical and Materials Engineering Metal. and Materials Engineering</option>
                        <option value="1434">Metallurgical Engineering</option>
                        <option value="773">Metallurgy and Material Science Engineering</option>
                        <option value="1195">Metallurgy Engineering  and Materials Science</option>
                        <option value="1190">Metallurgy Engineering and Materials Science with specalisation in Metallurgy Engineering</option>
                        <option value="1197">Metallurgy Engineering and Materials Science with specialisation in Material Science and Engineering</option>
                        <option value="1606">MHA (Master in Hospital Administration)</option>
                        <option value="1764">Micro Electronics &amp; VLSI Design</option>
                        <option value="776">Microbiology</option>
                        <option value="1801">Microbiology Research</option>
                        <option value="777">Microwave and Optical Communication</option>
                        <option value="1522">Middle East &amp; South Asian Security</option>
                        <option value="1659">Minimal Access Surgery</option>
                        <option value="778">Mining Engineering</option>
                        <option value="1596">MLT (Medical Laboratory Technology)</option>
                        <option value="782">Mobile Applications and Cloud Technology integrated with Foundation Certification in Mobile Application from Micro Focus (Formerly HPE) **</option>
                        <option value="1297">Molecular Biology</option>
                        <option value="783">Molecular Biology and biotechnology</option>
                        <option value="784">Molecular Medicine</option>
                        <option value="1490">Molecular Medicine &amp; Stem Cell Technologies</option>
                        <option value="785">Molecular Microbiology</option>
                        <option value="1108">Molecular Plant Breeding</option>
                        <option value="1607">MPH (Master In Public Health)</option>
                        <option value="786">Multimedia</option>
                        <option value="1455">Multimedia (Visual Communication)</option>
                        <option value="787">Multimedia and Animation</option>
                        <option value="788">Multimedia and Communication</option>
                        <option value="1616">Multimedia Forensics</option>
                        <option value="1339">MURP</option>
                        <option value="789">Musculoskeletal Disorders</option>
                        <option value="790">Music</option>
                        <option value="1758">Music Vocal</option>
                        <option value="0">NA</option>
                        <option value="791">Nano Physics</option>
                        <option value="795">Nano Science</option>
                        <option value="792">Nano Science and Engineering</option>
                        <option value="793">Nano Science and Technology</option>
                        <option value="794">Nano Sciences</option>
                        <option value="796">Nano Technology</option>
                        <option value="1070">Nanotechnology</option>
                        <option value="1696">Natural Products</option>
                        <option value="797">Natural Resource and Governance</option>
                        <option value="1109">Nematology</option>
                        <option value="1639">Network and Information Security</option>
                        <option value="800">Network Engineering</option>
                        <option value="802">Neural and Cognitive Science</option>
                        <option value="1359">Neuro Electro Physiology (NEP)</option>
                        <option value="1632">Neuro Psychology</option>
                        <option value="1094">Neuro-Science Technology</option>
                        <option value="1600">Neuroelectro Physiology</option>
                        <option value="803">Neurological Disorders</option>
                        <option value="1769">Neurology</option>
                        <option value="805">Non- Medical</option>
                        <option value="1483">Nuclear Medicine Technology</option>
                        <option value="807">Nuclear Science and Technology</option>
                        <option value="809">Nursing</option>
                        <option value="810">Nutrition</option>
                        <option value="811">Nutrition and Dietetics</option>
                        <option value="1770">Obstetrics &amp; Gynecology</option>
                        <option value="1651">Obstetrics and Gynecology</option>
                        <option value="813">Occupational Therapy</option>
                        <option value="815">Ocean Engineering and Naval Architecture</option>
                        <option value="817">Ocean Engineering and Naval Architecutre Ocean Engg. and Naval Arch.</option>
                        <option value="1821">Oil and Gas Management</option>
                        <option value="1817">Oil and Gas Marketing</option>
                        <option value="819">Oils, Oleochemicals and Surfactants Technology</option>
                        <option value="1266">Operation &amp; Anaesthesia Technology</option>
                        <option value="1365">Operation Theatre Technology</option>
                        <option value="820">Operations and Systems</option>
                        <option value="821">Operations Management</option>
                        <option value="1657">Ophthalmology</option>
                        <option value="1462">Optional English, Pschology and Journalism</option>
                        <option value="1268">Optometry</option>
                        <option value="1361">Optometry (OPT)</option>
                        <option value="1669">Oral and Maxillofacial Surgery</option>
                        <option value="822">Organic Chemistry</option>
                        <option value="823">Organisation Development, Change and Leadership</option>
                        <option value="1733">Ortho/Sports</option>
                        <option value="1668">Orthodontics</option>
                        <option value="1658">Orthopedics</option>
                        <option value="1660">Oto-Rhino-Laryngology</option>
                        <option value="826">Packaging Technology</option>
                        <option value="828">Painting, Print Making, Sculpture</option>
                        <option value="1509">Pali</option>
                        <option value="1088">Patient Care Management</option>
                        <option value="829">Peace and Conflict Studies</option>
                        <option value="1586">Peace and Conflict Studies– Guwahati Campus</option>
                        <option value="1649">Pediatrics</option>
                        <option value="1673">Pedodontics and Preventive Dentistry</option>
                        <option value="1083">Performing Arts</option>
                        <option value="830">Perfumery and Flavour Technology</option>
                        <option value="111">Perfusion Technology</option>
                        <option value="1674">Periodontics</option>
                        <option value="831">Persian</option>
                        <option value="832">Petroleum Engineering</option>
                        <option value="1104">Petroleum Technology</option>
                        <option value="833">Pharma</option>
                        <option value="834">Pharmaceutical</option>
                        <option value="835">Pharmaceutical Analysis</option>
                        <option value="1283">Pharmaceutical Bio-Technology</option>
                        <option value="836">Pharmaceutical Chemistry</option>
                        <option value="1459">Pharmaceutical Chemistry and Technology</option>
                        <option value="837">Pharmaceutical Science and Technology</option>
                        <option value="838">Pharmaceutical Technology</option>
                        <option value="839">Pharmaceutics</option>
                        <option value="840">Pharmacognosy</option>
                        <option value="1409">Pharmacognosy &amp; Phytochemistry</option>
                        <option value="841">Pharmacology</option>
                        <option value="1694">Pharmacology and Toxicology</option>
                        <option value="842">Pharmacy</option>
                        <option value="843">Pharmacy</option>
                        <option value="1289">Pharmacy Administration</option>
                        <option value="844">Pharmacy Practice</option>
                        <option value="847">Pharmcology</option>
                        <option value="848">Philosophy</option>
                        <option value="850">Photography</option>
                        <option value="1396">Photography &amp; Cinematography</option>
                        <option value="1336">Photonics</option>
                        <option value="1123">Physical Chemistry</option>
                        <option value="853">Physical Education</option>
                        <option value="854">Physical Sciences</option>
                        <option value="1615">Physician Assistant</option>
                        <option value="1360">Physician Assistant (PA)</option>
                        <option value="855">Physics</option>
                        <option value="856">Physics Functional Materials and Devices</option>
                        <option value="1802">Physics Research</option>
                        <option value="1475">Physics, Mathematics, Computer Science</option>
                        <option value="857">Physiology</option>
                        <option value="858">Physiotherapy</option>
                        <option value="859">Planning</option>
                        <option value="860">Plant Biology and Biotechnology</option>
                        <option value="862">Plant Biology and Plant Technology</option>
                        <option value="863">Plant Breeding and Genetics</option>
                        <option value="1115">Plant Nematology</option>
                        <option value="864">Plant Pathology</option>
                        <option value="1107">Plant Physiology</option>
                        <option value="865">Plant Sciences</option>
                        <option value="1275">Plant Tissue Culture</option>
                        <option value="866">Plastic Engineering</option>
                        <option value="1257">Policy Studies</option>
                        <option value="867">Political Science</option>
                        <option value="870">Polymer and Chemical technology</option>
                        <option value="1317">Polymer and Process Engineering</option>
                        <option value="871">Polymer Engineering and Technology</option>
                        <option value="872">Polymer Science and Chemical Technology</option>
                        <option value="1640">Polymers and Biosystems Engineering</option>
                        <option value="1090">Population Studies</option>
                        <option value="874">Power and Energy Systems</option>
                        <option value="875">Power Electronics</option>
                        <option value="876">Power Electronics and Drives</option>
                        <option value="877">Power Electronics and Machine Drives</option>
                        <option value="878">Power Electronics and Power System</option>
                        <option value="1822">Power Management</option>
                        <option value="879">Power System Engineerig</option>
                        <option value="880">Power System Engineering</option>
                        <option value="881">Power Systems and Automation</option>
                        <option value="882">PR and Event Management</option>
                        <option value="1510">Prakrit</option>
                        <option value="1128">Print &amp; Media Technology</option>
                        <option value="883">Printing Technology</option>
                        <option value="885">Process Instrumentation</option>
                        <option value="886">Process Metallurgy</option>
                        <option value="887">Processing and Food Engineering</option>
                        <option value="889">Production and Industrial Engineering</option>
                        <option value="890">Production and Industrial Engineering (ME)</option>
                        <option value="891">Production Engineering</option>
                        <option value="892">Production Engineering And Industrial Management</option>
                        <option value="893">Professional Accounting (PA)</option>
                        <option value="894">Project Management</option>
                        <option value="1667">Prosthodontics</option>
                        <option value="1296">Psychiatric Social Work</option>
                        <option value="1650">Psychiatry</option>
                        <option value="897">Psychology</option>
                        <option value="1803">Psychology and Counseling</option>
                        <option value="1463">Psychology, Sociology, Economics</option>
                        <option value="898">Public Administration</option>
                        <option value="1513">Public and Social Policy</option>
                        <option value="899">Public Health</option>
                        <option value="1457">Public International Law &amp; WTO</option>
                        <option value="901">Public Policy and Governance</option>
                        <option value="1332">Pulp &amp; Paper</option>
                        <option value="1316">Pulp &amp; Paper Engineering</option>
                        <option value="1746">Punjabi</option>
                        <option value="904">Quality and Reliability</option>
                        <option value="906">Quality Assurance</option>
                        <option value="907">Quality Assurance Techniques</option>
                        <option value="1280">Quantum Information and Computation</option>
                        <option value="1656">Radio diagnosis</option>
                        <option value="1097">Radiology</option>
                        <option value="908">Radiotherapy</option>
                        <option value="1279">Regulatory Affairs</option>
                        <option value="1080">Regulatory Governance</option>
                        <option value="1570">Regulatory Policy and Governance – Mumbai Campus</option>
                        <option value="1369">Remote Sensing &amp; Wireless Sensor Networks</option>
                        <option value="910">Remote Sensing and Geographic Information Systems</option>
                        <option value="1292">Renal Dialysis Technology</option>
                        <option value="1139">Renal Replacement Therapy &amp; Dialysis Technology</option>
                        <option value="1111">Renewable Energy Engineering</option>
                        <option value="1477">Renewable Energy, Physics, Computer Science</option>
                        <option value="1430">Resort &amp; Event Management</option>
                        <option value="1250">Resources Engineering</option>
                        <option value="1291">Respiratory Care Technology</option>
                        <option value="1661">Respiratory Medicine</option>
                        <option value="1140">Respiratory Therapy</option>
                        <option value="1372">Respiratory Therapy (RPT)</option>
                        <option value="1431">Restaurant &amp; Catering Management</option>
                        <option value="1542">Retail &amp; Fashion Merchandise</option>
                        <option value="912">Retail Management</option>
                        <option value="914">RF and MW</option>
                        <option value="915">Risk Management with CIMA UK integration #</option>
                        <option value="916">Robotics</option>
                        <option value="1478">Robotics &amp; Automation</option>
                        <option value="917">Robotics and Automation</option>
                        <option value="1536">Robotics and Mobility Systems</option>
                        <option value="920">Rural Development</option>
                        <option value="922">Rural Management</option>
                        <option value="924">Russian</option>
                        <option value="1089">Sales &amp; Marketing</option>
                        <option value="927">Sanskrit</option>
                        <option value="928">Science</option>
                        <option value="1537">Science of Intelligence</option>
                        <option value="1381">Sciences</option>
                        <option value="932">Seed Science and Technology</option>
                        <option value="1327">Seismic Vulnerability and Risk Assessment</option>
                        <option value="934">Sensor System Technology</option>
                        <option value="1284">Sensors and Internet of Things</option>
                        <option value="935">Sericulture</option>
                        <option value="936">Signal Processing</option>
                        <option value="937">Signal Processing and Digital Design</option>
                        <option value="938">Silviculture</option>
                        <option value="1281">Smart Healthcare</option>
                        <option value="1592">Smart Manufacturing</option>
                        <option value="1641">Smart Mobility</option>
                        <option value="939">Social Entrepreneurship</option>
                        <option value="1559">Social Entrepreneurship – Mumbai Campus</option>
                        <option value="1576">Social Epidemiology – Mumbai Campus</option>
                        <option value="941">Social Exclusive and Inclusive Policy</option>
                        <option value="1176">Social Innovation and Entrepreneurship</option>
                        <option value="942">Social Innovation and Entrepreneurship - Tuljapur Campus</option>
                        <option value="944">Social Science</option>
                        <option value="335">Social Sciences</option>
                        <option value="1579">Social Sciences - Tuljapur Campus</option>
                        <option value="1583">Social Sciences – Guwahati Campus</option>
                        <option value="949">Social Work</option>
                        <option value="1549">Social Work (Children and Families) – Mumbai Campus</option>
                        <option value="1588">Social Work (Community Organisation &amp; Development Practice) – Guwahati Campus</option>
                        <option value="1550">Social Work (Community Organisation &amp; Development Practice) – Mumbai Campus</option>
                        <option value="1589">Social Work (Counseling) - Guwahati Campus</option>
                        <option value="1551">Social Work (Criminology &amp; Justice) – Mumbai Campus</option>
                        <option value="1552">Social Work (Dalit &amp; Tribal Studies and Action) – Mumbai Campus</option>
                        <option value="1553">Social Work (Disability Studies &amp; Action) – Mumbai Campus</option>
                        <option value="958">Social Work (Livelihoods and Social Entrepreneurship) - Guwahati Campus</option>
                        <option value="1554">Social Work (Livelihoods and Social Entrepreneurship) – Mumbai Campus</option>
                        <option value="1555">Social Work (Mental Health) – Mumbai Campus</option>
                        <option value="1591">Social Work (Public Health) – Guwahati Campus</option>
                        <option value="1556">Social Work (Public Health) – Mumbai Campus</option>
                        <option value="1580">Social Work (Rural Development) - Tuljapur Campus</option>
                        <option value="1557">Social Work (Women-Centered Practice) – Mumbai Campus</option>
                        <option value="966">Society and Culture</option>
                        <option value="967">Sociology</option>
                        <option value="968">Sociology and Social Anthropology</option>
                        <option value="1587">Sociology and Social Anthropology– Guwahati Campus</option>
                        <option value="969">Software Application Development, Software Testing</option>
                        <option value="971">Software Engineering</option>
                        <option value="1263">Software Technology</option>
                        <option value="972">Soil and Water Engineering</option>
                        <option value="1325">Soil Dynamics</option>
                        <option value="973">Soil Science</option>
                        <option value="1110">Soil Science and Agricultural Chemistry</option>
                        <option value="974">Solar and Alternative Energy</option>
                        <option value="1335">Solid State Electronic Materials</option>
                        <option value="977">South East Asian Studies</option>
                        <option value="1121">Southeast Asian and Pacific Studies</option>
                        <option value="1539">Space Science and Technologies</option>
                        <option value="1282">Space Technologies</option>
                        <option value="978">Spanish</option>
                        <option value="979">Special Education</option>
                        <option value="1340">Speech Language Pathology</option>
                        <option value="983">Spices, Plantation, Medicinal and Aromatic Plants</option>
                        <option value="1760">Sports</option>
                        <option value="984">Sports Management</option>
                        <option value="985">Sports Physiotherapy</option>
                        <option value="986">Statistics</option>
                        <option value="1751">Statistics and Data Analytics</option>
                        <option value="987">Statistics with Computer Applications</option>
                        <option value="988">Stem Cell Science and Technology</option>
                        <option value="989">Storage and Cloud Technology integrated with Advanced Certification in Cloud Technology from Micro Focus (Formerly HPE) **</option>
                        <option value="1485">Strategic HR</option>
                        <option value="990">Strategy and Leadership integrated with US CSCA and Institute of Leadership and Management, UK</option>
                        <option value="1154">Structural and Construction Engineering</option>
                        <option value="1326">Structural Dynamics</option>
                        <option value="1688">Structural Dynamics and Earthquake Engineering</option>
                        <option value="991">Structural Engineering</option>
                        <option value="992">Structural Engineering and Disaster Management</option>
                        <option value="995">Supply Chain Management</option>
                        <option value="996">Surface Engineering and Technology</option>
                        <option value="1352">Surface Water Hydrology</option>
                        <option value="1665">Surgical Oncology</option>
                        <option value="998">Sustainable Architecture</option>
                        <option value="1500">Sustainable Development and Management</option>
                        <option value="1175">Sustainable Livelihoods and Natural Resources Governance</option>
                        <option value="1582">Sustainable Livelihoods and Natural Resources Governance - Tuljapur Campus</option>
                        <option value="1000">System Security</option>
                        <option value="1254">Systems and Control Engineering</option>
                        <option value="1001">Tamil</option>
                        <option value="1773">Tax Procedure and Practice</option>
                        <option value="1775">Taxation</option>
                        <option value="1251">Technology Alternatives</option>
                        <option value="1003">Telecom Management</option>
                        <option value="1005">TELECOMMUNICATION ENGINEERING</option>
                        <option value="1009">Telugu</option>
                        <option value="1010">Textile Chemistry</option>
                        <option value="1011">Textile Design</option>
                        <option value="1012">Textile Engineering</option>
                        <option value="1155">Textile Engineering and Management</option>
                        <option value="1765">Textile Plant and Engineering </option>
                        <option value="1013">Textile Technology</option>
                        <option value="1014">Textiles and Apparel Design</option>
                        <option value="1757">Theatre</option>
                        <option value="1073">Theatre Arts</option>
                        <option value="1016">Thermal and Fluid Science</option>
                        <option value="1017">Thermal Engineering</option>
                        <option value="1018">Thermal Power Engineering</option>
                        <option value="1019">Thermal Science</option>
                        <option value="1288">Thermofluids Engineering</option>
                        <option value="1153">Three Subject Combination</option>
                        <option value="1804">Tourism</option>
                        <option value="1744">Tourism &amp; Hospitality</option>
                        <option value="1020">Tourism Administration</option>
                        <option value="1425">TOURISM AND EVENT MANAGEMENT</option>
                        <option value="1022">Tourism and Hospitality Management</option>
                        <option value="1023">Tourism and Hospitality Management integrated with Chartered Institute of Hospitality, UK</option>
                        <option value="1024">Town and Country planning</option>
                        <option value="1617">Toxicology</option>
                        <option value="1025">Translation Studies</option>
                        <option value="1682">Transport Engineering</option>
                        <option value="1422">Transportation and Infrastructure Engineering</option>
                        <option value="1026">Transportation Systems Engg</option>
                        <option value="1027">Travel and Tourism</option>
                        <option value="1028">Travel and Tourism Management</option>
                        <option value="1129">Tribology &amp; Maintenance</option>
                        <option value="1029">Urban Design</option>
                        <option value="1686">Urban Planning</option>
                        <option value="1081">Urban Policy and governance</option>
                        <option value="1571">Urban Policy and Governance – Mumbai Campus</option>
                        <option value="1252">Urban Science &amp; Engineering</option>
                        <option value="1030">Urdu</option>
                        <option value="1031">Vegetable Science</option>
                        <option value="1032">Virology</option>
                        <option value="1064">Visual Arts</option>
                        <option value="1034">Visual Communication</option>
                        <option value="1384">Visual Media and Communication</option>
                        <option value="1036">VLSI</option>
                        <option value="1037">VLSI and Embedded Systems</option>
                        <option value="1038">VLSI Design</option>
                        <option value="1039">VLSI Design and Embedded system</option>
                        <option value="1805">Water Management</option>
                        <option value="1164">Water Policy and Governance</option>
                        <option value="1572">Water Policy and Governance – Mumbai Campus</option>
                        <option value="1334">Water Resources Development</option>
                        <option value="1310">Water Resources Development &amp; Management - Irrigation Water Management</option>
                        <option value="1309">Water Resources Development &amp; Management - Water Resource Management</option>
                        <option value="1043">Water Resources Engineering</option>
                        <option value="1044">Water Resources Engineering and Management</option>
                        <option value="1045">Watershed Management</option>
                        <option value="1628">Web &amp; Mobile Security</option>
                        <option value="1046">Web Applications</option>
                        <option value="1047">Welding Engineering</option>
                        <option value="1051">Wired and Wireless Communication</option>
                        <option value="1366">Wireless Networks &amp; Applications</option>
                        <option value="1172">Women-Centred Practice</option>
                        <option value="1565">Women’s Studies – Mumbai Campus</option>
                        <option value="1056">Womens Studies</option>
                        <option value="1058">Wood Science and Technology</option>
                        <option value="1505">Yoga</option>
                        <option value="1059">Yoga Therapy</option>
                        <option value="1060">Zoology</option>
                        <option value="1806">Zoology Research</option>

                      </Select>
                    </FormControl>
                  </div>
                )}




                {/* 
              {props.ilc && boardDetailsGet &&
                boardDetails &&
                boardDetails.data &&
                boardDetails.data.length != 0 ? (
                loading && boardDetailsGet ? (
                  <div style={{
                    display: "flex",
                    justifyContent: "center", width: '90%'
                  }}>
                    <FormControl variant="standard"
                      className={classes.formControl3}>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Board/Uni
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={boardType}
                        onChange={handleBoardChange}
                        label="Board/Uni"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        <MenuItem
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Please Select Institute Type
                        </MenuItem>
                      </Select>

                    </FormControl>
                  </div>
                ) : (
                  <div style={{
                    display: "flex",
                    justifyContent: "center", width: '90%'
                  }}>
                    <FormControl variant="standard" className={classes.formControl3}>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Board/Uni
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={boardType}
                        onChange={handleBoardChange}
                        label="Board/Uni"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        {loading && boardDetailsGet ? (
                          console.log("loading trueeee")
                        ) : boardDetails &&
                          boardDetails.data &&
                          boardDetails.data.length != 0 ? (
                          boardDetails.data.map((list, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={list.institute_board_id}
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                              >
                                {list.institute_board_name}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            Please Select Institute Type
                          </MenuItem>
                        )}
                      </Select>

                    </FormControl>
                  </div>
                )
              ) :
                props.ilc &&
                (
                  <div style={{
                    display: "flex",
                    justifyContent: "center", width: '90%'
                  }}>
                    <FormControl variant="standard" className={classes.formControl3}>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Board/Uni
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={boardType}
                        onChange={handleBoardChange}
                        label="Board/Uni"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        <MenuItem
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          No Boards
                        </MenuItem>
                      </Select>

                    </FormControl>
                  </div>
                )} */}




                {props.ilc && (
                  <>
                    <BoardsBox
                      autoBoards={autoBoards}
                      setBoardType={setBoardType} />
                    <div style={{ height: 20, }}></div>
                  </>
                )}




                {props.ilc && stdDetailsGet ? (
                  loadingStd && stdDetailsGet ? (
                    <div style={{
                      display: "flex",
                      justifyContent: "center", width: '90%'
                    }}>
                      <FormControl variant="standard" className={classes.formControl3}>
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Standard/Stream
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={standardType}
                          onChange={handleStdChange}
                          label="Standard/Stream"
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          <MenuItem
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            Please Select Institute Type
                          </MenuItem>
                        </Select>


                      </FormControl>
                    </div>
                  ) : (
                    <div style={{
                      display: "flex",
                      justifyContent: "center", width: '90%'
                    }}>
                      <FormControl variant="standard" className={classes.formControl3}>
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Standard/Stream
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={standardType}
                          onChange={handleStdChange}
                          label="Standard/Stream"
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          {loadingStd && stdDetailsGet ? (
                            console.log("loading trueeee")
                          ) : stdDetails.length != 0 ? (
                            stdDetails.data.map((list, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={list.id}
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                  }}
                                >
                                  {list.standard}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              Please select Institute Type
                            </MenuItem>
                          )}
                        </Select>


                      </FormControl>
                    </div>
                  )
                ) :
                  props.ilc &&
                  (
                    <div style={{
                      display: "flex",
                      justifyContent: "center", width: '90%'
                    }}>
                      <FormControl variant="standard" className={classes.formControl3}>
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Standard/Stream
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={standardType}
                          onChange={handleStdChange}
                          label="Standard/Stream"
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          <MenuItem
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            No Standards
                          </MenuItem>
                        </Select>


                      </FormControl>
                    </div>
                  )}

              </div>

              <div style={{ height: 10, }}></div>
              <SubjectsBox
                autoSubs={autoSubs}
                setSubjectType={setSubjectType}
                loadChapters={loadChapters} />
              <div style={{ height: 20, }}></div>

              {/* 
          <div style={{ display: "flex", justifyContent: "center" }}>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Subject
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={subjectType}
                onChange={handleSubChange}
                label="Select Subject"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {allSubjects && allSubjects.data && allSubjects.data.length && (
                  allSubjects.data.map((list, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={list.subject_id}
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        {list.subject_name}
                      </MenuItem>
                    );
                  })
                )}

              </Select>
              
            </FormControl>
          </div> */}


              {/* DOWNLOAD FORMAT LINK */}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                    // marginTop: 10,
                    marginBottom: 10,
                    marginRight: 20,
                    marginTop: 20,
                    textDecoration: "none",
                  }}
                >
                  {/* <a
                    href="/assets/ContentFormat.xlsx"
                    download
                    style={{ textDecoration: "none", color: "steelblue" }}
                    onClick={handleClickOpen}
                  > */}
                  <a
                    href="javascript:void(0)"
                    style={{ textDecoration: "none", color: "steelblue" }}
                    onClick={handleDownloadFormat}
                  >
                    Download Format
                  </a>
                </Typography>

                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth='lg'
                >
                  <DialogTitle id="alert-dialog-title">{"Instructions to prepare bulk upload"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description"
                    style={{
                      height: 500,
                      overflow: 'hidden'
                    }}>
                      <img src={uploadScreen} width='100%' style={{height: 450}} />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          flexDirection: 'row'
                        }}>
                        <List>
                          {/* <ListItem>
                            <ListItemText
                              primary="Column #1"
                              secondary="Enter the title"
                            />
                          </ListItem> */}
                          <ListItem>
                            <ListItemText
                              primary="Column #7"
                              secondary="Enter the programmable query ID of your content"
                            />
                          </ListItem>
                          {/* <ListItem>
                            <ListItemText
                              primary="Column #3"
                              secondary="Enter the tags seperated by commas as shown in picture above"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="Column #4"
                              secondary="Enter the URL of the thumbnail image"
                            />
                          </ListItem> */}
                        </List>

                        <Typography
                          variant="h1"
                          style={{
                            opacity: '0.2',
                            fontSize: 50
                          }}>
                          EXAMPLE
                        </Typography>
                      </div>

                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    {/* <Button onClick={handleClose} color="primary">
                  Disagree
                </Button> */}
                    <Button onClick={handleClose} color="primary" autoFocus>
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>


              {/* UPLOAD BUTTON */}
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: 20,
                    marginLeft: 15,
                    marginRight: 20,
                    marginBottom: 20
                  }}
                >

                  <label
                    // style={{
                    //   fontFamily: "Poppins, Helvetica, sans-serif",
                    //   backgroundColor: "steelblue",
                    //   color: "white",
                    //   padding: 5,
                    //   borderRadius: 4,
                    //   cursor: "pointer",
                    //   width: '80%',
                    //   textAlign: "center",
                    // }}

                    onClick={() => {
                      document.getElementById('bulkfile').click()
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        cursor: 'pointer'
                      }}>
                      <AddBoxIcon style={{
                        color: 'steelblue'
                      }} />
                      <Typography
                        variant='body1'
                        style={{
                          marginLeft: 10,
                          color: "#464E5F",
                        }}>
                        Upload Excelsheet File
                      </Typography>

                    </div>
                    {/* <FileUploader
                  hidden
                  // accept="image/*"
                  storageRef={firebase.storage().ref("images")}
                  onUploadStart={handleUploadStartBulk}
                  onUploadError={handleUploadErrorBulk}
                  onUploadSuccess={handleUploadSuccess}
                  onProgress={handleProgressBulk}
                /> */}

                  </label>

                  <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" type="file" className="form-control" name="upload_file" onChange={handleExcelChange} style={{ display: 'none' }} id="bulkfile" />
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {excel &&
                      <CheckCircleOutlinedIcon style={{ color: "green", fontSize: 30 }} />
                      // <img src={bookUpload} alt="preview Image" width="200px" style={{ alignSelf: "center", display: "flex" }} />
                    }
                  </div>
                </div>
                {/* 
            <Typography style={{ marginLeft: 15 }}>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Excel Sheet
                </span>
              </span>
            </Typography> */}
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                {bulkProgress ? <CircularProgress /> : <></>}
              </div>

              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                        {downloadUrls &&
                            <img src={downloadUrls} alt="preview Image" width="200px" style={{ alignSelf: "center", display: "flex" }} />
                        }
                    </div> */}



              {/* 
          <div
            style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', width: '100%', marginTop: 20 }}
          >
            <Checkbox
              checked={agree}
              onChange={handleAgreeToggle}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />

            <Link to="/publisher-content/settings/terms-conditions" target="_blank" rel="noopener noreferrer">
              <Typography
                variant="body2">
                I agree to Terms &amp; Conditions
              </Typography>
            </Link>
          </div> */}



              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: 15
                }}
              >
                {/* <Link to="/publisher/mapping" style={{ textDecoration: "none", color: "#fff" }}> */}
                <Button
                  variant="contained"
                  onClick={() => props.onCloseClicked(props.anchor, true)}
                >Cancel</Button>

                <Button
                  disabled={bulkUploadDisabled}
                  variant="contained"
                  color="primary"
                  style={{
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    textTransform: "none",
                    // width: '100%'
                    // backgroundColor: "#3699FF",
                    // color: '#FFFFFF'
                  }}
                  onClick={() => {
                    // handleSnackOpen(true)

                    handleBulkContentUpload();
                  }}
                >
                  Save
                </Button>
                {/* 
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                This is a success message!
              </Alert>
            </Snackbar>
 */}
                <Snackbar
                  open={snackOpen}
                  // autoHideDuration={6000}
                  onClose={() => handleSnackOpen(false)}
                >
                  <Alert onClose={() => handleSnackOpen(false)} severity="warning">
                    Please fill up mandatory fields
                  </Alert>
                </Snackbar>


                {/* </Link> */}
              </div>
            </div>
          </TabPanel>
        }
        {/* SINGLE ABOVE COMMENTED OUT */}


        {/* SINGLE UPLOAD PANEL */}
        <TabPanel value={value} index={0}
          style={{
            padding: 0
          }}>
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "block",
            }}
          >



            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>

              {props.ilc && loadingInst && instDetailsGet ? (
                <div style={{
                  display: "flex", justifyContent: "center",
                  width: '95%'
                }}>
                  <FormControl
                    variant="standard"
                    className={classes.formControl3}
                    style={{ alignSelf: "center", width: '100%' }}
                  >
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Institute type
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={instType}
                      onChange={handleInstChange}
                      label=" Institute type"
                      size="small"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        width: '100%'
                      }}
                      fullWidth
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please select
                      </MenuItem>
                    </Select>

                  </FormControl>
                </div>
              ) :
                props.ilc &&
                (<div style={{
                  display: "flex", justifyContent: "center",
                  width: '95%'
                }}>
                  <FormControl
                    variant="standard"
                    className={classes.formControl}
                    style={{ alignSelf: "center", width: '100%' }}
                  >
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Institute type
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={instType}
                      onChange={handleInstChange}
                      label=" Institute type"
                      size="small"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                      fullWidth
                    >
                      {loadingInst && instDetailsGet ? (
                        console.log("loading trueeee")
                      ) : instDetails.length != 0 ? (
                        instDetails.data.map((list, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={list.institute_type_id}
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              {list.institute_type_name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          No Institutes
                        </MenuItem>
                      )}
                    </Select>

                  </FormControl>
                </div>

                )}









              {/* college disciplines */}
              {props.ilc && instType == 10002 && (
                <div style={{
                  display: "flex",
                  justifyContent: "center", width: '95%'
                }}>
                  <FormControl variant="standard"
                    className={classes.formControl3}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Discipline
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      defaultValue="71"
                      value="71"
                      label="Discipline"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      <option value="71">Agriculture</option>
                      <option value="64">Allied Health Science</option>
                      <option value="68">Animation, Film Studies&#160; Visual Arts</option>
                      <option value="8">Architecture</option>
                      <option value="9">Arts</option>
                      <option value="72">Ayurvedic Pharmaceutical Sciences</option>
                      <option value="73">Biotechnology</option>
                      <option value="74">Botany</option>
                      <option value="67">Buddhist Studies</option>
                      <option value="10">Commerce</option>
                      <option value="75">Computer Applications</option>
                      <option value="69">Computing</option>
                      <option value="60">Dental</option>
                      <option value="61">Design</option>
                      <option value="76">Economics</option>
                      <option value="77">Education</option>
                      <option value="11">Engineering</option>
                      <option value="78">Fashion Design</option>
                      <option value="79">Fine Arts</option>
                      <option value="80">Forensic Sciences</option>
                      <option value="81">Hotel Management &amp; Tourism</option>
                      <option value="12">Humanities</option>
                      <option value="82">Indian Languages</option>
                      <option value="83">Interior &amp; Furniture Design</option>
                      <option value="84">Journalism &amp; Film Production</option>
                      <option value="13">Law</option>
                      <option value="96">Liberal Arts</option>
                      <option value="85">Library Science</option>
                      <option value="14">Management</option>
                      <option value="86">Mathematics</option>
                      <option value="70">Media Studies</option>
                      <option value="15">Medical</option>
                      <option value="87">Medical Laboratory Sciences</option>
                      <option value="88">Microbiology</option>
                      <option value="89">Multimedia &amp; Animation</option>
                      <option value="65">Nursing</option>
                      <option value="62">Optometry</option>
                      <option value="90">Performing Arts</option>
                      <option value="16">Pharmacy</option>
                      <option value="91">Physical Education</option>
                      <option value="66">Physiotherapy</option>
                      <option value="92">Planning</option>
                      <option value="94">Psychology</option>
                      <option value="17">Science</option>
                      <option value="63">Vocational</option>
                      <option value="95">Zoology</option>
                    </Select>

                  </FormControl>
                </div>
              )}



              {/* college program level */}
              {props.ilc && instType == 10002 && (
                <div style={{
                  display: "flex",
                  justifyContent: "center", width: '95%'
                }}>
                  <FormControl variant="standard"
                    className={classes.formControl3}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Program Level
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      defaultValue="13"
                      value="13"
                      label="Discipline"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      <option value="13">Diploma</option>
                      <option value="43">Doctor of Pharmacy (Post Baccalaureate)</option>
                      <option value="18">Dual Bachelors And Masters Degree</option>
                      <option value="15">Dual Bachelors Degree</option>
                      <option value="19">Dual Masters</option>
                      <option value="21">Integrated PhD</option>
                      <option value="20">PhD</option>
                      <option value="16">Post Graduate (PG)</option>
                      <option value="17">Post Graduate Diplomas</option>
                      <option value="14">Under Graduate (UG)</option>
                    </Select>

                  </FormControl>
                </div>
              )}



              {/* college nature of courses */}
              {props.ilc && instType == 10002 && (
                <div style={{
                  display: "flex",
                  justifyContent: "center", width: '95%'
                }}>
                  <FormControl variant="standard"
                    className={classes.formControl3}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Nature Of Courses
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      defaultValue="199"
                      value="199"
                      label="Nature Of Courses"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      <option value="199">	Bachelor of Law (LLB)</option>
                      <option value="120">B. Pharma + MBA (Integrated)</option>
                      <option value="172">B. Sc. Blended</option>
                      <option value="157">B.A + L.L B</option>
                      <option value="158">B.B.A. + L.L.B.</option>
                      <option value="89">B.Com + LLB (Hons. Integrated)</option>
                      <option value="166">B.Sc (Hospitality Management)</option>
                      <option value="179">B.Sc. - Animation, Gaming &amp; VFX</option>
                      <option value="181">B.Sc. - Film Studies/Television Studies</option>
                      <option value="91">B.Tech + M.Tech (Integrated)</option>
                      <option value="92">B.Tech + MBA (Integrated)</option>
                      <option value="93">BA + B.Ed (Intergrated)</option>
                      <option value="94">BA + LLB (Hons. Integrated)</option>
                      <option value="95">BA + LLB (Integrated)</option>
                      <option value="183">BA - Liberal Arts(Hons)</option>
                      <option value="184">BA - Psychology</option>
                      <option value="160">Bachelor in Clinical Optometry (B.Optom.)</option>
                      <option value="170">Bachelor In Communication And Journalism</option>
                      <option value="8">Bachelor in Multimedia Communication(BMMC)</option>
                      <option value="9">Bachelor of Architecture (B.Arch)</option>
                      <option value="13">Bachelor of Arts (BA - 3 Continent)</option>
                      <option value="11">Bachelor of Arts (BA)</option>
                      <option value="202">Bachelor of Arts (BA) / Bachelors of Science (BSc)</option>
                      <option value="14">Bachelor of Arts (Honors)</option>
                      <option value="15">Bachelor of Business Administration (BBA)</option>
                      <option value="126">Bachelor of Business Administration (BBA) (Honors)</option>
                      <option value="16">Bachelor of Commerce (B.Com)</option>
                      <option value="17">Bachelor of Commerce (Honors)</option>
                      <option value="20">Bachelor of Computer Application (BCA - Evening)</option>
                      <option value="21">Bachelor of Computer Application (BCA)</option>
                      <option value="22">Bachelor of Design (B.Des)</option>
                      <option value="24">Bachelor of Education (B.Ed)</option>
                      <option value="192">Bachelor of Education (B.Ed)</option>
                      <option value="26">Bachelor of Engineering (B.E.)</option>
                      <option value="27">Bachelor of Environment Management (BEM)</option>
                      <option value="28">Bachelor of Fashion Technology (BFT)</option>
                      <option value="29">Bachelor of Fine Arts (BFA)</option>
                      <option value="30">Bachelor of Hotel Management</option>
                      <option value="31">Bachelor of Hotel Management &amp; Catering Technology (BHM &amp; CT)</option>
                      <option value="32">Bachelor of Interior Design</option>
                      <option value="33">Bachelor of Law (LLB)</option>
                      <option value="193">Bachelor of Library &amp; Information Sciences (B. Lib. I.Sc.)</option>
                      <option value="127">Bachelor of Library and Information Science (BLIS)</option>
                      <option value="34">Bachelor of Management Studies (BMS)</option>
                      <option value="201">Bachelor of Medical Laboratory Technology (BMLT)</option>
                      <option value="35">Bachelor of Occupational Therapy</option>
                      <option value="195">Bachelor of Performing Arts (BPA)</option>
                      <option value="36">Bachelor of Pharmacy (B.Pharm)</option>
                      <option value="37">Bachelor of Physical Education &amp; Sports</option>
                      <option value="194">Bachelor of Physical Education &amp; Sports (BPES)</option>
                      <option value="38">Bachelor of Physiotherapy</option>
                      <option value="39">Bachelor of Planning</option>
                      <option value="191">Bachelor of Planning (B. Plan.)</option>
                      <option value="124">Bachelor of Public Health</option>
                      <option value="40">Bachelor of Science (B.Sc)</option>
                      <option value="41">Bachelor of Science (Honors)</option>
                      <option value="43">Bachelor of Social Work (BSW)</option>
                      <option value="44">Bachelor of Statistics</option>
                      <option value="47">Bachelor of Technology (B.Tech - Evening)</option>
                      <option value="48">Bachelor of Technology (B.Tech - International)</option>
                      <option value="45">Bachelor of Technology (B.Tech)</option>
                      <option value="49">Bachelor of Vocation (B.Voc.)</option>
                      <option value="97">BBA + LLB  (Integrated)</option>
                      <option value="98">BBA + LLB (Hons. Integrated)</option>
                      <option value="99">BBA + MBA (Integrated)</option>
                      <option value="100">BCA + MCA (Intergrated)</option>
                      <option value="101">BSC + MSC (Intergrated)</option>
                      <option value="152">Diploma</option>
                      <option value="196">Diploma</option>
                      <option value="187">Diploma (Engineering) </option>
                      <option value="3">Diploma in Elementary Teacher Training</option>
                      <option value="4">Diploma in Food Production</option>
                      <option value="159">Doctor of Pharmacy</option>
                      <option value="154">Doctor of Pharmacy (Post Baccalaureate)</option>
                      <option value="50">Executive MBA (EMBA)</option>
                      <option value="197">Integrated B.Sc. - B.Ed.</option>
                      <option value="174">Integrated PhD</option>
                      <option value="171">M.A./M.Sc.</option>
                      <option value="102">M.Pharm + MBA (Integrated)</option>
                      <option value="103">M.Phil. + Ph.D (Integrated)</option>
                      <option value="176">M.S. (Pharm)</option>
                      <option value="189">M.Sc. (Hons.)</option>
                      <option value="185">M.Sc. - Animation &amp; Multimedia Technology</option>
                      <option value="188">M.Sc. Agriculture</option>
                      <option value="156">M.Tech + PhD (Integrated)</option>
                      <option value="51">Master Degree in Hospital Management (MDHM)</option>
                      <option value="52">Master of Architecture (M.Arch)</option>
                      <option value="53">Master of Arts (M.A.)</option>
                      <option value="203">Master of Arts (MA) / Master of Science (MSc)</option>
                      <option value="55">Master of Business Administration (MBA - International)</option>
                      <option value="56">Master of Business Administration (MBA)</option>
                      <option value="57">Master of Commerce (M.Com)</option>
                      <option value="58">Master of Computer Application (MCA)</option>
                      <option value="190">Master of Computer Application (MCA) - Hons.</option>
                      <option value="60">Master of Design (M.Des)</option>
                      <option value="61">Master of Education (M.Ed)</option>
                      <option value="168">Master of Engineering</option>
                      <option value="62">Master of Fine Arts (MFA)</option>
                      <option value="63">Master of Hospital Administration</option>
                      <option value="64">Master of Human Resource Management</option>
                      <option value="65">Master of Interior Design</option>
                      <option value="67">Master of Law (LLM)</option>
                      <option value="68">Master of Library and Information Science (MLIS)</option>
                      <option value="69">Master of Management Programme (M.Mgt)</option>
                      <option value="167">Master of Management Studies (MMS)</option>
                      <option value="161">Master of Optometry (M.Optom.)</option>
                      <option value="70">Master of Pharmacy (M.Pharm)</option>
                      <option value="71">Master of Philosophy (M.Phil)</option>
                      <option value="72">Master of Physical Education (MPEd)</option>
                      <option value="73">Master of Planning</option>
                      <option value="74">Master of Public Health (MPH)</option>
                      <option value="198">Master of Science (Ag. Horticulture)</option>
                      <option value="75">Master of Science (M.Sc)</option>
                      <option value="76">Master of Social Work (MSW)</option>
                      <option value="79">Master of Technology (M.Tech)</option>
                      <option value="80">Master of Travel &amp; Tourism Management</option>
                      <option value="81">Masters (MS)-Research</option>
                      <option value="82">Masters in Mass Communication (MMC)</option>
                      <option value="84">Masters of Physiotherapy (MP)</option>
                      <option value="169">ME + Ph.D. (Integrated)</option>
                      <option value="87">Ph.D</option>
                      <option value="164">Pharma.D</option>
                      <option value="162">pharmacy</option>
                      <option value="204">Post Basic B.sc</option>
                      <option value="107">Post Graduate Diploma in Management(PGDM)</option>
                      <option value="108">Post Graduate Diploma(PGD)</option>
                    </Select>

                  </FormControl>
                </div>
              )}


              {/* college subjects / branch */}
              {props.ilc && instType == 10002 && (
                <div style={{
                  display: "flex",
                  justifyContent: "center", width: '95%'
                }}>
                  <FormControl variant="standard"
                    className={classes.formControl3}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Branch / Subjects
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      defaultValue="1762"
                      value="1762"
                      label="Branch / Subjects"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      <option value="1762"> Traffic &amp; Transportation Planning</option>
                      <option value="1569">(Environment, Climate Change and Sustainability Studies) – Mumbai Campus</option>
                      <option value="1646">&#160;Microbiology</option>
                      <option value="1678">&#160;Obstetric and Gynecological nursing</option>
                      <option value="1671">&#160;Oral Medicine &amp; Radiology</option>
                      <option value="1670">&#160;Oral Pathology and Microbiology</option>
                      <option value="1645">&#160;Pathology</option>
                      <option value="1644">&#160;Physiology&#160;</option>
                      <option value="1727">&#160;Psychology</option>
                      <option value="1675">&#160;Public Health Dentistry</option>
                      <option value="1165">Access to Justice</option>
                      <option value="1601">Accident and Trauma Care Technology</option>
                      <option value="1403">Accounting</option>
                      <option value="2">Accounting and Finance</option>
                      <option value="1544">Actuarial Management</option>
                      <option value="3">Actuarial Science</option>
                      <option value="1636">Additive Manufacturing</option>
                      <option value="1524">Advance Diploma in Buddhist Studies</option>
                      <option value="1428">ADVANCED COST AND MANAGAMENT ACCOUNTING</option>
                      <option value="1777">Advanced Manufacturing</option>
                      <option value="1287">Advanced Manufacturing and Design</option>
                      <option value="1423">ADVERTISING AND MARKETING</option>
                      <option value="8">Advertising and Marketing Management</option>
                      <option value="10">Aeronautical Engineering</option>
                      <option value="13">Aerospace Engineering</option>
                      <option value="1514">African Peace &amp; Security</option>
                      <option value="17">Agri Business</option>
                      <option value="18">Agricultural and Food Engineering</option>
                      <option value="24">Agricultural Economics</option>
                      <option value="25">Agricultural Engineering</option>
                      <option value="1106">Agricultural Extension and Communication</option>
                      <option value="26">Agricultural Meteorology</option>
                      <option value="1114">Agricultural Microbiology</option>
                      <option value="27">Agriculture</option>
                      <option value="1185">Agriculture &amp; Food Business</option>
                      <option value="29">Agriculture and Food Business</option>
                      <option value="30">Agriculture Engineering</option>
                      <option value="1735">Agriculture Sciences</option>
                      <option value="1778">Agriculture with Secialisation in Agricultural Extension Education</option>
                      <option value="1781">Agriculture with Specialisation in Agronomy</option>
                      <option value="1779">Agriculture with specialisation in Soil Science</option>
                      <option value="1780">Agriculture with specialisation Plant Breeding &amp; Genetics</option>
                      <option value="31">Agroforestry</option>
                      <option value="32">Agronomy</option>
                      <option value="1432">Airlines &amp; Airport Management</option>
                      <option value="1743">Airlines, Tourism &amp; Hospitality</option>
                      <option value="35">Airport and Aviation Business Management</option>
                      <option value="1321">Alternate Hydro Energy Systems</option>
                      <option value="51">Alternative Dispute Resolution</option>
                      <option value="1511">Ambedkar Thoughts in National Security</option>
                      <option value="1602">Anaesthesia Technology</option>
                      <option value="52">Analytical Chemistry</option>
                      <option value="1814">Analytics and Big Data</option>
                      <option value="1096">Anasthesia Techology</option>
                      <option value="53">Anatomy</option>
                      <option value="54">Ancient Indian History, Culture and Archealogy</option>
                      <option value="1782">Ancient Indian Wisdom and Yoga</option>
                      <option value="1655">Anesthesia</option>
                      <option value="56">Animal Biology and Biotechnology</option>
                      <option value="57">Animation</option>
                      <option value="1725">Animation &amp; Multimedia Technology</option>
                      <option value="58">Animation and Graphic Design</option>
                      <option value="59">Animation with International Moving Image Society, UK (Chartered Body)</option>
                      <option value="60">Anthropology</option>
                      <option value="61">Applicable Mathematics</option>
                      <option value="63">Applied Chemistry</option>
                      <option value="64">Applied Chemistry and Polymer Technology</option>
                      <option value="1776">Applied Economics</option>
                      <option value="66">Applied Electronics</option>
                      <option value="68">Applied Geochemistry</option>
                      <option value="69">Applied Geology</option>
                      <option value="70">Applied Linguistics</option>
                      <option value="71">Applied Mathematics</option>
                      <option value="1687">Applied Mechanics</option>
                      <option value="72">Applied Microbiology</option>
                      <option value="73">Applied Philosophy</option>
                      <option value="74">Applied Physics</option>
                      <option value="75">Applied Psychology</option>
                      <option value="1566">Applied Psychology (Clinical &amp; Counseling Practice) – Mumbai Campus</option>
                      <option value="76">Applied Psychology (Clinical Psychology)</option>
                      <option value="78">Applied Psychology (Counseling Psychology)</option>
                      <option value="1315">Applied Science &amp; Engineering</option>
                      <option value="81">Applied Statistics</option>
                      <option value="82">Apprel and Textile Science</option>
                      <option value="83">Aquaculture</option>
                      <option value="84">Arabic</option>
                      <option value="1737">Architectural Assistantship</option>
                      <option value="86">Architectural Design</option>
                      <option value="87">Architecture</option>
                      <option value="88">Architecture and Planning</option>
                      <option value="89">Architecture and Regional Planning</option>
                      <option value="93">Artificial Intelligence</option>
                      <option value="1767">Artificial Intelligence  &amp; Data Science</option>
                      <option value="1729">Artificial Intelligence &amp; Machine Learning (AI&amp;ML)</option>
                      <option value="1783">Artificial Intelligence (Summit Research Program)</option>
                      <option value="94">Arts</option>
                      <option value="1444">Association of Chartered Certified Accountants (ACCA)</option>
                      <option value="96">Astronomy</option>
                      <option value="1194">Astronomy, Astrophysics and Space Engineering</option>
                      <option value="97">Atmosphere and Ocean Sciences</option>
                      <option value="1341">Audiology</option>
                      <option value="1363">AUDIOLOGY AND SPEECH LANGUAGE PATHOLOGY (BASLP)</option>
                      <option value="1427">AUDITING AND TAXATION</option>
                      <option value="1728">Augmented Reality &amp; Virtual Reality (AR &amp; VR)</option>
                      <option value="99">Automobile Engineering</option>
                      <option value="101">Automotive Electronics</option>
                      <option value="102">Automotive Engineering</option>
                      <option value="104">Automotive Technology</option>
                      <option value="1274">Autonomous Unmanned Vehicle Technologies</option>
                      <option value="105">Aviation Management</option>
                      <option value="106">Avionics</option>
                      <option value="1738">Ayurveda</option>
                      <option value="107">B. Design - (Product design / Interior design)</option>
                      <option value="116">Bachelor of Arts and Bachelor of Laws</option>
                      <option value="119">Bachelor of Arts and Bachelor of Laws Honors</option>
                      <option value="1528">Bachelor Of Business Administration (International Business)</option>
                      <option value="120">Bachelor of Business Administration and Bachelor of Laws</option>
                      <option value="122">Bachelor of Business Administration and Bachelor of Laws Honors</option>
                      <option value="123">Banking and Finance</option>
                      <option value="124">Banking and Insurance</option>
                      <option value="127">Basic Sciences</option>
                      <option value="1547">Beauty Therapy &amp; Aesthetics</option>
                      <option value="131">Bengali</option>
                      <option value="1091">Bharathanatyam</option>
                      <option value="1071">Big Data</option>
                      <option value="132">Big Data Analytics</option>
                      <option value="135">Bio Science</option>
                      <option value="136">Bio Technology</option>
                      <option value="137">Bio-Informatics</option>
                      <option value="138">Bio-Medical Engineering</option>
                      <option value="139">Biochemical</option>
                      <option value="140">Biochemical Engineering</option>
                      <option value="141">Biochemistry</option>
                      <option value="1474">Biochemistry, Genetics and Biotechnology (BcGBt)</option>
                      <option value="143">Bioinformatics</option>
                      <option value="144">Biological Sciences</option>
                      <option value="1768">Biology</option>
                      <option value="1784">Biology Research</option>
                      <option value="145">Biomedical Engineering</option>
                      <option value="146">Biomedical Engineering (BME)</option>
                      <option value="147">Biomedical Genetics</option>
                      <option value="148">Biomedical Instrumentation</option>
                      <option value="1397">Biomedical Signal Processing &amp; Instrumentation</option>
                      <option value="1338">Bioprocess Engineering</option>
                      <option value="149">Bioprocess Technology</option>
                      <option value="150">BioScience and Bio Engineering</option>
                      <option value="151">Biosciences and Biomedical Engineering</option>
                      <option value="152">Biostatistics</option>
                      <option value="1685">Biostatistics &amp; Population Sciences</option>
                      <option value="153">Biotechnology</option>
                      <option value="155">Biotechnology (spec. in Genetic Engineering )</option>
                      <option value="156">Biotechnology (spec. in Regenerative Medicine )</option>
                      <option value="1785">Biotechnology (Summit Research Program)</option>
                      <option value="157">Biotechnology and Biochemical Engineering</option>
                      <option value="1786">Biotechnology Research</option>
                      <option value="1387">Biotechnology with Artificial Intelligence &amp; Machine Learning</option>
                      <option value="163">Botany</option>
                      <option value="1787">Botany Research</option>
                      <option value="1507">Buddhist Studies</option>
                      <option value="1502">Buddhist Studies, Philosophy &amp; Comparative Religions</option>
                      <option value="166">Business Administration</option>
                      <option value="1446">Business Analysis</option>
                      <option value="167">Business Analytics</option>
                      <option value="1429">Business Analytics-IBM</option>
                      <option value="168">Business Development</option>
                      <option value="170">Business Management</option>
                      <option value="1543">Business Process Management</option>
                      <option value="172">Business Process Services (BPS)</option>
                      <option value="165">Business Studies</option>
                      <option value="173">CA</option>
                      <option value="174">CAD</option>
                      <option value="175">CAD / CAM</option>
                      <option value="1330">CAD, CAM &amp; Robotics</option>
                      <option value="1788">Cancer Biology</option>
                      <option value="1095">Cardiac Care Technology</option>
                      <option value="1355">Cardiac Perfusion Technology (CPT)</option>
                      <option value="1603">Cardiac Technology (ECHO)</option>
                      <option value="176">Cardiothoracic and Pulmonary Disorders</option>
                      <option value="1771">Cardiothorasic </option>
                      <option value="1136">Cardiovascular Technology</option>
                      <option value="1604">Cardiovascular Technology (CVTS)</option>
                      <option value="177">Career Guidance</option>
                      <option value="178">Career Guidance and Councelling</option>
                      <option value="179">Catering Science and Hotel Management</option>
                      <option value="180">Cellular and Molecular Oncology</option>
                      <option value="181">Centre for Oceans, Rivers, Atmosphere and Land Sciences Earth System Science and Technology</option>
                      <option value="1312">Centre for Transportation (CTRANS)</option>
                      <option value="182">Ceramic</option>
                      <option value="1445">Certified Management Accountants (CMA)</option>
                      <option value="1454">Certified Public Accountant (CPA)</option>
                      <option value="1515">Chattrapati Shivaji&#39;s Vision &amp; Nation Building</option>
                      <option value="187">Chemical and Biochemical Engineering</option>
                      <option value="188">Chemical Engineering</option>
                      <option value="191">Chemical Sciences</option>
                      <option value="1516">Chemical, Biological, Radiation, Nuclear &amp; National Security (CBRNNS)</option>
                      <option value="193">Cheminformatics</option>
                      <option value="194">Chemistry</option>
                      <option value="1789">Chemistry Research</option>
                      <option value="1464">Chemistry, Microbiology, Biotechnology (CMBt)</option>
                      <option value="1679">Child health nursing</option>
                      <option value="1166">Children &amp; Families</option>
                      <option value="200">Cinema and Television</option>
                      <option value="201">Civil</option>
                      <option value="203">Civil and Environmental Engineering</option>
                      <option value="204">Civil and Infrastructure Engineering</option>
                      <option value="205">Civil Engineering</option>
                      <option value="1837">Civil Engineering ( Water Resources / Structural Dynamics and  Earthquake / Transportation / Structural / Geotechnical Engg.)</option>
                      <option value="1148">Civil Engineering (Infrastructure Engineering &amp; Management)</option>
                      <option value="1441">Civil Engineering - Geo-Informatics</option>
                      <option value="208">Civil Engineering and Structural Engineering</option>
                      <option value="209">Civil Engineering and Transportation Engineering</option>
                      <option value="210">Civil Engineering Construction Engineering and Management</option>
                      <option value="1491">Civil Engineering Environment Engineering</option>
                      <option value="211">Civil Engineering Environmental Engineering and Management</option>
                      <option value="212">Civil Engineering Geotechnical Engineering</option>
                      <option value="213">Civil Engineering Hydraulic and Water Resources Engineering</option>
                      <option value="215">Civil Engineering Structural Engineering</option>
                      <option value="216">Civil Engineering Transportation Engineering</option>
                      <option value="1440">Civil Engineering- Energy and Environmental Technologies</option>
                      <option value="1386">Civil with Artificial Intelligence &amp; Machine Learning</option>
                      <option value="1642">Climate Change</option>
                      <option value="1163">Climate Change and Sustainability Studies</option>
                      <option value="217">Climate Sciences</option>
                      <option value="1256">Climate Studies</option>
                      <option value="1663">Clinical Biochemistry</option>
                      <option value="1271">Clinical Embryology</option>
                      <option value="1611">Clinical Embryology</option>
                      <option value="1664">Clinical Microbiology</option>
                      <option value="1599">Clinical Nutrition &amp; Dietetics</option>
                      <option value="1373">Clinical Nutrition and Food Science</option>
                      <option value="218">Clinical Psychology</option>
                      <option value="219">Clinical Research</option>
                      <option value="221">Cloud and Information Security integrated with KPMG Certified Cyber Security Professional</option>
                      <option value="1730">Cloud Computing &amp; DevOps</option>
                      <option value="227">Cognitive Sciences</option>
                      <option value="228">Commerce</option>
                      <option value="1376">Commerce and Management</option>
                      <option value="1395">Commercial Photography</option>
                      <option value="1399">Commercial Photography &amp; Cinematography</option>
                      <option value="229">Communication</option>
                      <option value="230">Communication and Journalism</option>
                      <option value="231">Communication and Signal Processing (EE)</option>
                      <option value="1690">Communication Design</option>
                      <option value="232">Communication Engineering</option>
                      <option value="233">Communication System Engineering</option>
                      <option value="1132">Community Development</option>
                      <option value="1680">Community health nursing</option>
                      <option value="1652">Community Medicine</option>
                      <option value="235">Community Science (Honors)</option>
                      <option value="236">Comparative Literature</option>
                      <option value="239">Computational Design</option>
                      <option value="1368">Computational Engineering &amp; Networking</option>
                      <option value="1377">Computational Neuroscience</option>
                      <option value="1790">Computational Science</option>
                      <option value="241">Computer</option>
                      <option value="1351">Computer Aided Structural Analysis and Design</option>
                      <option value="242">Computer and Communication Engineering</option>
                      <option value="244">Computer Applications</option>
                      <option value="245">Computer Engineering</option>
                      <option value="246">Computer Engineering (Cloud Technology and Mobile Application)</option>
                      <option value="248">Computer Integrated Design and Manufacturing</option>
                      <option value="251">Computer Science</option>
                      <option value="1468">Computer Science &amp; Business System in association with TCS</option>
                      <option value="1740">Computer Science &amp; Engineering - Android Application Development</option>
                      <option value="1741">Computer Science &amp; Engineering - Machine Learning</option>
                      <option value="1342">Computer Science &amp; Engineering with a Specialization in Blockchain Technology</option>
                      <option value="1348">Computer Science &amp; Engineering with specialization in Cyber Physical Systems</option>
                      <option value="252">Computer Science (Data Science)</option>
                      <option value="1184">Computer Science and Business System</option>
                      <option value="1706">Computer Science and Business systems (CSBS)</option>
                      <option value="253">Computer Science and Engineering</option>
                      <option value="1841">Computer Science and Engineering (Computer Science &amp; Engg. / Artificial Intelligence)</option>
                      <option value="1470">Computer Science and Engineering (Hons) -Big Data &amp; Analytics in association with IBM</option>
                      <option value="1469">Computer Science and Engineering (Hons) -Cloud Computing in association with IBM</option>
                      <option value="1471">Computer Science and Engineering (Hons)- Information Security in association with IBM</option>
                      <option value="1698">Computer Science and Engineering (International Campus Diu (IIITV-ICD))</option>
                      <option value="254">Computer Science and Engineering (Spec. in Artificial Intelligence and Machine Learning)</option>
                      <option value="255">Computer Science and Engineering (Spec. in Big Data Analytics)</option>
                      <option value="256">Computer Science and Engineering (Spec. in Bioinformatics)</option>
                      <option value="257">Computer Science and Engineering (Spec. in Cloud Computing)</option>
                      <option value="259">Computer Science and Engineering (Spec. in Cyber Security)</option>
                      <option value="260">Computer Science and Engineering (Spec. in Data Analytics)</option>
                      <option value="261">Computer Science and Engineering (Spec. in Information Security)</option>
                      <option value="262">Computer Science and Engineering (Spec. in Information Technology and Entrepreneurship)</option>
                      <option value="263">Computer Science and Engineering (Spec. in Information Technology)</option>
                      <option value="264">Computer Science and Engineering (Spec. in Internet of Things (IOT))</option>
                      <option value="266">Computer Science and Engineering (Spec. in Software Engineering)</option>
                      <option value="268">Computer Science and Engineering with Specialization in Artificial Intelligence</option>
                      <option value="1473">Computer Science and Engineering with specialization in DevOps</option>
                      <option value="1807">Computer Science and Engineering with specialization in Internet of Things</option>
                      <option value="1791">Computer Science and Entrepreneurship </option>
                      <option value="1375">Computer Science and IT</option>
                      <option value="1494">Computer Science and Systems Engineering</option>
                      <option value="276">Computer Science and Technology</option>
                      <option value="1350">Computer Science with cognitive systems</option>
                      <option value="1734">Computer Science, Stats and Mathematics&#160;</option>
                      <option value="278">Computer Technology</option>
                      <option value="281">Computing and Electrical Engineering</option>
                      <option value="1672">Conservative Dentistry</option>
                      <option value="283">Constitutional Law</option>
                      <option value="285">Construction Engineering and Management</option>
                      <option value="290">Construction Technology and Management</option>
                      <option value="291">Control and Automation</option>
                      <option value="292">Control and Instrumentation</option>
                      <option value="294">Control Systems</option>
                      <option value="295">Corporate Accounting with CMA USA integration</option>
                      <option value="297">Corporate Finance Integrated with CPA, USA + EA, USA</option>
                      <option value="298">Corporate Law</option>
                      <option value="299">Corporate Secretaryship</option>
                      <option value="300">Costume Design and Fashion</option>
                      <option value="1177">Counselling</option>
                      <option value="1517">Counter Terrorism Studies</option>
                      <option value="1167">Criminology &amp; Justice</option>
                      <option value="1635">Criminology Specialization in Forensic Psychology</option>
                      <option value="1489">Critical Care Technology</option>
                      <option value="303">Cryogenic Engineering</option>
                      <option value="1467">CSE (Artificial Intelligence &amp; Machine Learning) in association with IBM)</option>
                      <option value="1466">CSE (Graphics &amp; Gaming)</option>
                      <option value="1465">CSE (Internet of Things) in association with IBM</option>
                      <option value="1447">CSE - Cyber Security &amp; Digital Forensics</option>
                      <option value="1476">CSE with specialization in Cloud Computing (in collaboration with Virtusa)</option>
                      <option value="1442">CSE- Machine Learning &amp; Computing</option>
                      <option value="304">Culinary Arts</option>
                      <option value="1518">Cyber &amp;&#160; India’s National Security</option>
                      <option value="305">Cyber Forensics and Information Security</option>
                      <option value="1285">Cyber Physical Systems</option>
                      <option value="306">Cyber Security</option>
                      <option value="1262">Cyber Security and Cyber Law</option>
                      <option value="1378">Cyber Security Systems and Networks</option>
                      <option value="1168">Dalit &amp; Tribal Studies and Action</option>
                      <option value="309">Dance</option>
                      <option value="311">Data Analytics</option>
                      <option value="310">Data Analytics integrated with Foundation Certification Big Data from Micro Focus (Formerly HPE)</option>
                      <option value="1286">Data and Computational Science</option>
                      <option value="312">Data Science</option>
                      <option value="1481">Data Science and Analytics</option>
                      <option value="1492">Data Science and Artificial Intelligence</option>
                      <option value="314">Data Science and Business Analytics</option>
                      <option value="315">Data Sciences and Data Analytics</option>
                      <option value="1508">Defence &amp; Strategic Studies</option>
                      <option value="1519">Defence Analyst and National Security</option>
                      <option value="1792">Defence Technology</option>
                      <option value="1374">Deglutology and Swallowing Disorders</option>
                      <option value="1276">Dental Mechatronics</option>
                      <option value="1278">Dental Operating Room Assistant</option>
                      <option value="1662">Dermatology, Venereology &amp; Leprosy</option>
                      <option value="317">Design</option>
                      <option value="1419">Design &amp; Manufacturing</option>
                      <option value="1755">Design - Gaming</option>
                      <option value="1756">Design - Graphics</option>
                      <option value="1748">Design - Interior &amp; Furniture</option>
                      <option value="1754">Design - Multimedia</option>
                      <option value="1692">Design courses</option>
                      <option value="1693">Design Courses (Industrial Design, Communication Design Textile and Apparel Design)</option>
                      <option value="319">Design Engineering</option>
                      <option value="1174">Development Policy, Planning and Practice</option>
                      <option value="1581">Development Policy, Planning and Practice - Tuljapur Campus</option>
                      <option value="320">Development Studies</option>
                      <option value="1564">Development Studies – Mumbai Campus</option>
                      <option value="1357">Diabetes Sciences (DBS)</option>
                      <option value="1085">Dialysis Technology</option>
                      <option value="1356">Dialysis Therapy (DIT)</option>
                      <option value="1594">Dialysis Therapy Technology</option>
                      <option value="1793">Digital</option>
                      <option value="1318">Digital &amp; Cyber Forensic</option>
                      <option value="322">Digital Animation</option>
                      <option value="1815">Digital Business</option>
                      <option value="323">Digital Film Making with International Moving Image Society, UK (Chartered Body)</option>
                      <option value="1625">Digital Forensic and Information Security</option>
                      <option value="1265">Digital Humanities</option>
                      <option value="1732">Digital Marketing&#160;</option>
                      <option value="324">Digital System</option>
                      <option value="325">Digital Systems and Signal Processing</option>
                      <option value="1298">Diploma of Pharmacy</option>
                      <option value="1169">Disability Studies &amp; Action</option>
                      <option value="1173">Disaster Management</option>
                      <option value="1573">Disaster Management – Mumbai Campus</option>
                      <option value="1520">Disaster Management And National security</option>
                      <option value="1314">Disaster Mitigation &amp; Management</option>
                      <option value="1337">Disaster Mitigation and Management</option>
                      <option value="1523">Dr. Babasaheb Ambedkars Thoughts on Indias National Security</option>
                      <option value="339">Drug Regulatory Affairs</option>
                      <option value="342">Dyestuff Technology</option>
                      <option value="343">E-Commerce</option>
                      <option value="344">E-Learning Technology</option>
                      <option value="1637">E-Waste Resource and Engineering Management</option>
                      <option value="346">Earth Science</option>
                      <option value="347">Earth Sciences</option>
                      <option value="1302">Earthquake Engineering</option>
                      <option value="1450">ECE - Atmospheric Science</option>
                      <option value="1449">ECE - Radar and Communication (R&amp;C)</option>
                      <option value="1448">ECE - Very Large-Scale Integration</option>
                      <option value="1410">ECE with Internet of Things (IoT)</option>
                      <option value="1358">Echocardiography Technology (ECT)</option>
                      <option value="1101">Ecocardiography</option>
                      <option value="1501">Ecology and Environment Studies</option>
                      <option value="1178">Ecology, Environment and Sustainable Development</option>
                      <option value="1584">Ecology, Environment and Sustainable Development– Guwahati Campus</option>
                      <option value="349">Econometrics</option>
                      <option value="350">Economics</option>
                      <option value="1720">Economics and Finance</option>
                      <option value="1196">Economics, Psychology, English, Philosophy, Sociology, History</option>
                      <option value="1408">Economics, Statistics, Mathematics</option>
                      <option value="1126">Ecosophical Aesthetics</option>
                      <option value="355">Education</option>
                      <option value="356">Education (Elementary)</option>
                      <option value="1560">Education (Elementary) – Mumbai Campus</option>
                      <option value="1561">Education – Mumbai Campus</option>
                      <option value="358">Education Studies</option>
                      <option value="1451">EEE - Power Systems</option>
                      <option value="1411">EEE with Robotics and Automation</option>
                      <option value="1328">Electric Drives &amp; Power Electronics</option>
                      <option value="1707">Electrical and Computer Engineering</option>
                      <option value="365">Electrical and Electronics Engineering</option>
                      <option value="366">Electrical Engineering</option>
                      <option value="1839">Electrical Engineering (Control &amp; Industrial Automation / Power &amp; Energy Systems Engg)  </option>
                      <option value="1480">Electrical Engineering (Electric Vehicles)</option>
                      <option value="367">Electrical Engineering (Instrumentation Engineering)</option>
                      <option value="1495">Electrical Engineering (Power Electronics &amp; Drives)</option>
                      <option value="1146">Electrical Engineering (Power System)</option>
                      <option value="368">Electrical Engineering (Spec. in VLSI)</option>
                      <option value="369">Electrical Engineering and Power Electronics and Drives</option>
                      <option value="370">Electrical Engineering Control System Engineering</option>
                      <option value="371">Electrical Engineering Instrumentation and Signal Processing</option>
                      <option value="373">Electrical Engineering Power and Energy Systems</option>
                      <option value="1193">Electrical Engineering with specialisation in Communication &amp; Signal Processing</option>
                      <option value="1794">Electrical Engineering with Specialisation in Internet of Things</option>
                      <option value="1421">Electrical Engineering with specialisation in Signal Processing and Communication</option>
                      <option value="1192">Electrical Engineering with specialisation in VLSI Design and Nanoelectronics</option>
                      <option value="375">Electronics</option>
                      <option value="1349">Electronics &amp; Computer Engineering</option>
                      <option value="1499">Electronics &amp; Control System Engineering</option>
                      <option value="1181">Electronics - VLSI Design</option>
                      <option value="376">Electronics and Communication</option>
                      <option value="378">Electronics and Communication Engineering</option>
                      <option value="1809">Electronics and Communication Engineering  with specialization in Machine Learning and Signal Processing</option>
                      <option value="1810">Electronics and Communication Engineering  with specialization in Microelectronic systems and Internet of Things</option>
                      <option value="1808">Electronics and Communication Engineering  with specialization in Wireless Communication</option>
                      <option value="1742">Electronics and Communication Engineering (ECE)</option>
                      <option value="1593">Electronics and Communication Engineering (Spec. in Communication System Design)</option>
                      <option value="379">Electronics and Communication Engineering (Spec. in Communication System)</option>
                      <option value="1290">Electronics and Communication Engineering (Spec. in Electronics Systems Design)</option>
                      <option value="1389">Electronics and Communication Engineering (Spec. in Medical Electronics)</option>
                      <option value="381">Electronics and Communication Engineering (spl. In Biomedical Engineering)</option>
                      <option value="384">Electronics and Communication Engineering (spl. In Micro Electronic Systems and Embedded Technology)</option>
                      <option value="1388">Electronics and Communication Engineering with Artificial Intelligence &amp; Machine Learning</option>
                      <option value="1795">Electronics and Communication Engineering With Specialisation in Embeded System</option>
                      <option value="1796">Electronics and Communication Engineering With Specialisation in Optical Communication</option>
                      <option value="385">Electronics and communication systems</option>
                      <option value="386">Electronics and Communication with (spl. In Embedded Systems)</option>
                      <option value="387">Electronics and Communication with (spl. In VLSI)</option>
                      <option value="1840">Electronics and Communications ( Microelectronics &amp; VLSI Design / Communication &amp; Signal Processing Engg)</option>
                      <option value="389">Electronics and Control Engineering</option>
                      <option value="390">Electronics and Design</option>
                      <option value="392">Electronics and Electrical Communication Engineering Microelectronics and VLSI Design</option>
                      <option value="393">Electronics and Electrical Communication Engineering RF and Microwave Engineering</option>
                      <option value="396">Electronics and Electrical Engineering</option>
                      <option value="397">Electronics and Instrumentation</option>
                      <option value="398">Electronics and Instrumentation Engineering</option>
                      <option value="1842">Electronics and Instrumentation Engineering (Instrumentation Engineering)</option>
                      <option value="399">Electronics and Telecommunication</option>
                      <option value="400">Electronics and Telecommunication Engineering</option>
                      <option value="1497">Electronics and Telecommunications Engineering (RF &amp; Microwave)</option>
                      <option value="1496">Electronics and Telecommunications Engineering (Spl-VLSI Design &amp; Embedded System)</option>
                      <option value="1072">Electronics Design &amp; Technology</option>
                      <option value="401">Electronics Engineering</option>
                      <option value="403">Electronics Science</option>
                      <option value="404">Elementary Teacher Training</option>
                      <option value="407">Embedded Control System</option>
                      <option value="408">Embedded System and Technologies</option>
                      <option value="410">Embedded Systems Engineering</option>
                      <option value="1362">Emergency Medical Tech</option>
                      <option value="1267">Emergency Medicine</option>
                      <option value="1719">Emergency Medicine Technology (EMT)</option>
                      <option value="1401">Emerging Technologies</option>
                      <option value="1404">Emerging Technologies in Management</option>
                      <option value="411">Energy</option>
                      <option value="413">Energy and Environmental Engineering</option>
                      <option value="414">Energy and Infrastructure Management</option>
                      <option value="415">Energy Engineering</option>
                      <option value="416">Energy Engineering (Spec. in Materials)</option>
                      <option value="417">Energy Science and Engineering</option>
                      <option value="418">Energy Science and Technology</option>
                      <option value="419">Energy Studies</option>
                      <option value="420">Energy Systems (Focused on Solar Energy)</option>
                      <option value="1797">Energy Technology</option>
                      <option value="1703">Energy Technology and Management</option>
                      <option value="422">Engineering</option>
                      <option value="424">Engineering Design</option>
                      <option value="427">Engineering Physics</option>
                      <option value="428">Engineering Science</option>
                      <option value="430">English</option>
                      <option value="1379">English and Languages</option>
                      <option value="434">English Language Studies</option>
                      <option value="435">Entomology</option>
                      <option value="436">Entrepreneurship</option>
                      <option value="1145">Entrepreneurship &amp; International Trade</option>
                      <option value="437">Entrepreneurship and Family Business</option>
                      <option value="1134">Environment &amp; Occupational Health</option>
                      <option value="439">Environment and Water Resource Engineering</option>
                      <option value="440">Environment Science</option>
                      <option value="1075">Environmental chemistry</option>
                      <option value="441">Environmental Engineering</option>
                      <option value="442">Environmental Management</option>
                      <option value="1322">Environmental Management of Rivers and Lakes</option>
                      <option value="1117">Environmental Science</option>
                      <option value="443">Environmental Science and Engineering</option>
                      <option value="445">Environmental Sciences</option>
                      <option value="446">Environmental Studies</option>
                      <option value="1653">Epidemiology under Community Medicine</option>
                      <option value="1100">Epidemology</option>
                      <option value="1521">European Peace &amp; Security</option>
                      <option value="447">European Studies</option>
                      <option value="1684">Excavation Engineering</option>
                      <option value="1137">Exercise &amp; Sports Sciences</option>
                      <option value="450">Extension Education</option>
                      <option value="451">Extension Education and Communication Management</option>
                      <option value="1681">Faculty of Physiotherapy</option>
                      <option value="453">Family Business and Entrepreneurship</option>
                      <option value="455">Family Resource Management</option>
                      <option value="456">Farm Machinery and Power Engineering</option>
                      <option value="1709">Fashion and Apparel Design with specialization in Fashion Marketing and User Experience Design</option>
                      <option value="459">Fashion Design</option>
                      <option value="462">Fashion Retail Management</option>
                      <option value="463">Fashion Technology</option>
                      <option value="464">Fibres and Textile Processing Technology</option>
                      <option value="465">Film and Television Production</option>
                      <option value="466">Film and TV Production</option>
                      <option value="1724">Film Studies/Television Studies</option>
                      <option value="467">Finance</option>
                      <option value="468">Finance and Accounting</option>
                      <option value="470">Finance and Computer Applications</option>
                      <option value="1456">Finance and Control</option>
                      <option value="471">Finance and HR</option>
                      <option value="1772">Financial Analysis and Control</option>
                      <option value="473">Financial Analysis with KPMG International Accounting Practices</option>
                      <option value="474">Financial Economics</option>
                      <option value="1402">Financial Investment Analysis</option>
                      <option value="475">Financial Management</option>
                      <option value="476">Financial Markets</option>
                      <option value="478">Financial Services</option>
                      <option value="479">Financial Technology</option>
                      <option value="480">Fine Arts</option>
                      <option value="1619">Fingerprint Science</option>
                      <option value="1504">Five Years Integrated Cours in Defence and Strategic Studies</option>
                      <option value="481">Flim Technology</option>
                      <option value="482">Floriculture and Landscape Architecture</option>
                      <option value="483">Floriculture and Landscaping</option>
                      <option value="484">Fluids and Thermal Engineering</option>
                      <option value="485">Food and Nutrition</option>
                      <option value="486">Food and Nutritional Biotechnology</option>
                      <option value="487">Food Biotechnology</option>
                      <option value="488">Food Engineering and Technology</option>
                      <option value="490">Food Processing Technology</option>
                      <option value="1546">Food Processing Technology &amp; Management</option>
                      <option value="491">Food Production</option>
                      <option value="492">Food Safety and Quality Management</option>
                      <option value="1113">Food Science and Nutrition</option>
                      <option value="494">Food Science and Technology</option>
                      <option value="495">Food Science Technology</option>
                      <option value="496">Food Technology</option>
                      <option value="497">Food Technology</option>
                      <option value="1798">Food Technology Research</option>
                      <option value="1799">Food Technology with Summit Research Program</option>
                      <option value="1293">Food, Nutrition &amp; Dietetics</option>
                      <option value="1354">Food, Nutrition and Dietetics</option>
                      <option value="1530">Footwear - Design &amp; Production</option>
                      <option value="1125">Foreign Languages &amp; Intercultural Studies</option>
                      <option value="1819">Foreign Trade</option>
                      <option value="1774">Foreign Trade Practices and Sales Management</option>
                      <option value="1624">Forensic Accounting</option>
                      <option value="1618">Forensic Biotechnology</option>
                      <option value="1621">Forensic Document Examination</option>
                      <option value="1647">Forensic Medicine</option>
                      <option value="1634">Forensic Nanotechnology</option>
                      <option value="1272">Forensic Odontology</option>
                      <option value="1630">Forensic Pharmacy</option>
                      <option value="1631">Forensic Psychology</option>
                      <option value="499">Forensic Science</option>
                      <option value="500">Forensic Sciences</option>
                      <option value="1622">Forensic Toxicology</option>
                      <option value="501">Forest Genetic Resources</option>
                      <option value="502">Forestry</option>
                      <option value="1426">FOREX MANAGEMENT</option>
                      <option value="504">French</option>
                      <option value="506">Fruit Science</option>
                      <option value="1461">Gaming</option>
                      <option value="1548">Garment Designing</option>
                      <option value="1506">Gender Culture and Development Studies</option>
                      <option value="507">General</option>
                      <option value="508">General Management</option>
                      <option value="1648">General Medicine</option>
                      <option value="509">Genetic Engineering</option>
                      <option value="510">Genetics</option>
                      <option value="511">Genetics and Plant Breeding</option>
                      <option value="1800">Genetics Research</option>
                      <option value="513">Geo Informatics and Remote Sensing</option>
                      <option value="1200">Geo-exploration and Petroleum Geo-sciences</option>
                      <option value="514">Geo-informatics</option>
                      <option value="1156">Geo-Technical and Geo-Environmental Engineering</option>
                      <option value="517">Geography</option>
                      <option value="518">Geology</option>
                      <option value="519">Geology and Geophysics</option>
                      <option value="1323">Geomatics Engineering</option>
                      <option value="522">Geophysics</option>
                      <option value="523">Geotechical Engineering</option>
                      <option value="524">German</option>
                      <option value="525">Global Accounting Practices with Association of International Accounts</option>
                      <option value="1135">Global Health</option>
                      <option value="528">Global Logistics and Supply Chain Management</option>
                      <option value="531">Global Management Accountant Integrated with CIMA, UK + IMA, USA + CMA Australia</option>
                      <option value="1512">Global Peace, Security and Strategic Studies</option>
                      <option value="1144">Global studies</option>
                      <option value="532">Globalisation and Labour</option>
                      <option value="533">Governance and Public Administration</option>
                      <option value="534">Graphic Design</option>
                      <option value="1753">Graphics</option>
                      <option value="1082">Graphics and mutlimedia</option>
                      <option value="1823">Green Energy and Sustainability</option>
                      <option value="535">Green Technology</option>
                      <option value="1353">Ground Water Hydrology</option>
                      <option value="536">Guidance and Counselling</option>
                      <option value="1666">Head &amp; Neck Oncology</option>
                      <option value="537">Health Administration</option>
                      <option value="1759">Health and Physical Education</option>
                      <option value="1103">Health Care Quality and  Safety</option>
                      <option value="1124">Health Economics</option>
                      <option value="1102">Health Economics and  Outcomes Research</option>
                      <option value="1598">Health Information Management</option>
                      <option value="538">Health Policy, Economics and Finance</option>
                      <option value="1577">Health Policy, Economics and Finance – Mumbai Campus</option>
                      <option value="1479">Health Safety and Environment Engineering</option>
                      <option value="1529">Health Sciences</option>
                      <option value="1487">Healthcare</option>
                      <option value="540">Healthcare Management</option>
                      <option value="1488">Healthcare Tourism &amp; Hospitality</option>
                      <option value="541">Heat and Power</option>
                      <option value="1683">Heat Power Engineering</option>
                      <option value="542">Herbal technology</option>
                      <option value="1526">Higher Diploma In Buddhist Studies</option>
                      <option value="1525">Higher Diploma In Pali</option>
                      <option value="543">Hindi</option>
                      <option value="1503">Historical Studies</option>
                      <option value="544">History</option>
                      <option value="545">History and Tourism</option>
                      <option value="547">Home Science - Clinical Nutrition and Dietetics</option>
                      <option value="548">Home Science - Nutrition Food Service Management and Dietetics</option>
                      <option value="1623">Homeland Security</option>
                      <option value="550">Horticulture</option>
                      <option value="1299">Hospital &amp; Health System Management</option>
                      <option value="1300">Hospital Administration</option>
                      <option value="552">Hospital and Health Care Management</option>
                      <option value="553">Hospital Management</option>
                      <option value="1527">Hospitality And Facilities Management</option>
                      <option value="554">Hospitality and Hotel Administration (H. and H.A.)</option>
                      <option value="555">Hospitality Management</option>
                      <option value="557">Hotel Management</option>
                      <option value="558">Hotel Management and Catering Technology (BHM and CT)</option>
                      <option value="1643">Human Anatomy</option>
                      <option value="560">Human Development and Family Studies</option>
                      <option value="561">Human Genetics</option>
                      <option value="562">Human Genetics and Molecular Biology</option>
                      <option value="563">Human Physiology</option>
                      <option value="1460">Human Resource</option>
                      <option value="565">Human Resource Management</option>
                      <option value="1131">Human resource Management &amp; Industrial Relations</option>
                      <option value="1558">Human Resource Management &amp; Labour Relations – Mumbai Campus</option>
                      <option value="566">Human Resource Management and Labour Relations</option>
                      <option value="1127">Humanities</option>
                      <option value="568">Humanities and Social Sciences</option>
                      <option value="1620">Humantarian Forensics</option>
                      <option value="1324">Hydraulic Engineering</option>
                      <option value="569">Hydraulics and Water Resource Engineering</option>
                      <option value="1412">Hydro and Renewable Energy</option>
                      <option value="1076">Hydrogeology</option>
                      <option value="1305">Hydrology</option>
                      <option value="570">I C Technology</option>
                      <option value="571">Immunology</option>
                      <option value="573">Inductrial Biotechnology</option>
                      <option value="575">Industrial and Production</option>
                      <option value="579">Industrial Biotechnology</option>
                      <option value="1736">Industrial Chemistry</option>
                      <option value="580">Industrial Engineering</option>
                      <option value="1253">Industrial Engineering &amp; Operation Research</option>
                      <option value="581">Industrial Engineering and Management</option>
                      <option value="583">Industrial Metallurgy</option>
                      <option value="584">Industrial Microbiology</option>
                      <option value="585">Industrial Pharmacy</option>
                      <option value="1458">Industrial Product Design</option>
                      <option value="587">Industrial Safety Engineering</option>
                      <option value="589">Information and Communication Technology</option>
                      <option value="591">Information and Cyber security</option>
                      <option value="592">Information Science and Engineering</option>
                      <option value="593">Information Security</option>
                      <option value="594">Information Security and Cyber Forensics</option>
                      <option value="595">Information Security integrated with KPMG Advanced Certified Cyber Security Professional**</option>
                      <option value="596">Information System (IS)</option>
                      <option value="598">Information Technology</option>
                      <option value="603">Infrastructure Engineering and Management</option>
                      <option value="1541">Infrastructure Engineering with Specialization in Energy Engineering</option>
                      <option value="1540">Infrastructure Engineering with Specialization in Environmental Engineering</option>
                      <option value="1313">Infrastructure Systems</option>
                      <option value="607">Innovation and Entrepreneurship</option>
                      <option value="1074">Inorganic Chemistry</option>
                      <option value="1311">Institute Instrumentation Centre</option>
                      <option value="609">Instrumentation and Control Engineering</option>
                      <option value="1763">Instrumentation and Control Systems</option>
                      <option value="612">Insurance and Banking Management</option>
                      <option value="1424">INSURANCE AND RISK MANAGEMENT</option>
                      <option value="615">Insurance, Banking and Finance</option>
                      <option value="616">Integrated M. Phil.-Ph. D. Programme in Education</option>
                      <option value="617">Integrated M. Phil.-Ph. D. Programme in Social Sciences</option>
                      <option value="618">Integrated M.Phil Ph.D. in Social Sciences with Specialisation in Sociology of Education</option>
                      <option value="620">Integrated M.Phil-Ph.D programme School of Habitat Studies</option>
                      <option value="624">Integrated M.Phil.-Ph.D. in Inclusive Development and Social Justice</option>
                      <option value="1068">Integrated M.Phil.-Ph.D. Programme in Development Studies</option>
                      <option value="635">Integrated M.Phil.-Ph.D. Programme in Management and Labour Studies</option>
                      <option value="636">Integrated M.Phil.-Ph.D. Programme in Public Health</option>
                      <option value="625">Integrated M.Phil.-Ph.D. Programme in Rural Development</option>
                      <option value="637">Integrated M.Phil.-Ph.D. Programme in Social Work</option>
                      <option value="638">Integrated M.Phil.-Ph.D. Programme in Women&#39;s Studies</option>
                      <option value="626">Integrated Power System</option>
                      <option value="1638">Integrated Sensor System</option>
                      <option value="628">Intellectual Property Rights</option>
                      <option value="1406">Intellectual Property Rights and Cyber Law</option>
                      <option value="1364">Intensive Care Technology</option>
                      <option value="1380">Interdisciplinary Studies</option>
                      <option value="1747">Interior &amp; Furniture</option>
                      <option value="639">Interior Design</option>
                      <option value="640">Interior Design (Honors) with Chartered Institute of Architectural Technologists, UK</option>
                      <option value="641">Interior Design with Chartered Society of Designers, UK</option>
                      <option value="1708">Interior Design with Specialization in Accessories and Furniture Design &amp; User Experience Design</option>
                      <option value="1439">Interior Environment and Design</option>
                      <option value="1826">International Accounting</option>
                      <option value="642">International Accounting and Finance with ACCA UK integration#</option>
                      <option value="643">International Banking and Finance</option>
                      <option value="644">International Business</option>
                      <option value="646">International Business integrated with Chartered Institute of Marketing, UK</option>
                      <option value="1750">International Finance</option>
                      <option value="650">International Finance with CGMA-UK</option>
                      <option value="651">International Law</option>
                      <option value="652">International Marketing integrated with Digital Marketing Institute, Ireland</option>
                      <option value="654">International Relations</option>
                      <option value="657">International Trade and Economic Law</option>
                      <option value="1538">Internet of Things and Applications</option>
                      <option value="1827">Investment Management</option>
                      <option value="1277">IOT &amp; Applications</option>
                      <option value="660">IOT integrated with Foundation Certification in Cloud Technology from Micro Focus (Formerly HPE)</option>
                      <option value="1333">Irrigation Water Management</option>
                      <option value="661">Islamic Studies</option>
                      <option value="662">IT Business Management</option>
                      <option value="1486">IT for Healthcare</option>
                      <option value="665">Journalism</option>
                      <option value="666">Journalism and Communication</option>
                      <option value="667">Journalism and Mass Communication</option>
                      <option value="1143">Labour Law</option>
                      <option value="1562">Labour Studies &amp; Practices – Mumbai Campus</option>
                      <option value="672">Labour Studies and Social Protection</option>
                      <option value="1585">Labour Studies and Social Protection– Guwahati Campus</option>
                      <option value="674">Law</option>
                      <option value="1532">Leather Goods and Accessories Design</option>
                      <option value="676">Liberal Arts</option>
                      <option value="1749">Library &amp; Information Sciences (D. Lib.I.Sc.)</option>
                      <option value="677">Library and Information Science</option>
                      <option value="1567">Library and Information Science – Mumbai Campus</option>
                      <option value="678">Life Sciences</option>
                      <option value="681">Linguistics</option>
                      <option value="1493">Literature</option>
                      <option value="1170">Livelihoods and Social Entrepreneurship</option>
                      <option value="682">Logistics</option>
                      <option value="1443">Logistics &amp; Supply Chain Management (LSCM)</option>
                      <option value="683">Logistics and Supply Chain Management</option>
                      <option value="1818">Logistics Management</option>
                      <option value="684">LSCM+Chartered Institute of Procurement and Supply (CIPS)-UK</option>
                      <option value="1319">M.Arch</option>
                      <option value="1595">M.R.I.T. (Medical Radiology Imaging Technology)</option>
                      <option value="689">Machine Design</option>
                      <option value="1629">Malware Analysis &amp; Reverse Engineering</option>
                      <option value="1766">Man made Textile Technology</option>
                      <option value="690">Management</option>
                      <option value="691">Management Accounting</option>
                      <option value="1739">Management Accounting and International Finance</option>
                      <option value="692">Management Sciences</option>
                      <option value="693">Management Studies</option>
                      <option value="694">Manufacturing and Automation</option>
                      <option value="695">Manufacturing Engineering</option>
                      <option value="697">Manufacturing Technology</option>
                      <option value="1398">Manufacturing Technology &amp; Automation</option>
                      <option value="1812">Marathi</option>
                      <option value="698">Marine Engineering</option>
                      <option value="701">Marketing</option>
                      <option value="703">Marketing and Finance</option>
                      <option value="704">Marketing and HR</option>
                      <option value="705">Marketing and Sales</option>
                      <option value="1813">Marketing Management</option>
                      <option value="706">Mass Communication</option>
                      <option value="707">Master of Business Administration</option>
                      <option value="718">Material Science and Technology</option>
                      <option value="719">Material Science Engineering (MEMS)</option>
                      <option value="1331">Materials Engineering</option>
                      <option value="720">Materials Science</option>
                      <option value="721">Materials Science and Engineering</option>
                      <option value="722">Materials Science and Metallurgical Engineering</option>
                      <option value="1133">Maternal &amp; Child Health</option>
                      <option value="724">Mathematical Sciences</option>
                      <option value="725">Mathematics</option>
                      <option value="1201">Mathematics &amp; Applied Statistics and Informatics</option>
                      <option value="1420">Mathematics &amp; Statistics</option>
                      <option value="726">Mathematics and Computing</option>
                      <option value="728">Maths with Computer Application</option>
                      <option value="1452">ME - Robotics and Mechatronics</option>
                      <option value="734">Mechanical</option>
                      <option value="1308">Mechanical &amp; Industrial Engineering</option>
                      <option value="736">Mechanical Engineering</option>
                      <option value="1150">Mechanical Engineering (Design)</option>
                      <option value="738">Mechanical Engineering (Manufactruring Engineering)</option>
                      <option value="1498">Mechanical Engineering (Manufacturing Process &amp; System)</option>
                      <option value="1704">Mechanical Engineering (Production)</option>
                      <option value="1392">Mechanical Engineering (Spec. Artificial Intelligence &amp; Machine Learning)</option>
                      <option value="1400">Mechanical Engineering (Spec. Computer Aided Engineering)</option>
                      <option value="740">Mechanical Engineering (spec. in Automotive Engineering)</option>
                      <option value="1393">Mechanical Engineering (Spec. in Electric &amp; Hybrid Vehicles)</option>
                      <option value="741">Mechanical Engineering (Spec. in Energy Systems)</option>
                      <option value="1391">Mechanical Engineering (Spec. in Smart Manufacturing)</option>
                      <option value="1838">Mechanical Engineering (Thermal Engg. / Design and Manufacturing / CAD-CAM &amp; Automation / Materials &amp; Manufacturing Technology / Renewable Energy)</option>
                      <option value="1149">Mechanical Engineering (Thermal)</option>
                      <option value="1453">Mechanical Engineering (with sp. in Machine design)</option>
                      <option value="743">Mechanical Engineering - CAD/CAM</option>
                      <option value="744">Mechanical Engineering and Manufacturing Engineering</option>
                      <option value="745">Mechanical Engineering and Mechanical System Design</option>
                      <option value="746">Mechanical Engineering and Thermal Science and Engineering</option>
                      <option value="747">Mechanical Engineering Manufacturing Sc. and Engg.</option>
                      <option value="748">Mechanical Engineering Mechanical systems Design</option>
                      <option value="1199">Mechanical Engineering with specialisation in Mechanical Systems Design</option>
                      <option value="1198">Mechanical Engineering with specialisation in Production &amp;  Industrial Engineering</option>
                      <option value="750">Mechanical System Design(ME)</option>
                      <option value="1344">Mechanical with specialization in Cyber Physical Systems</option>
                      <option value="752">Mechatronics</option>
                      <option value="1346">Mechatronics &amp; Automation</option>
                      <option value="753">Mechatronics Engineering</option>
                      <option value="756">Media and Communications</option>
                      <option value="757">Media and Cultural Studies</option>
                      <option value="1563">Media and Cultural Studies – Mumbai Campus</option>
                      <option value="760">Media Studies</option>
                      <option value="1099">Media Technology</option>
                      <option value="1130">Medical &amp; Psychiatric Social work</option>
                      <option value="1269">Medical Anatomy</option>
                      <option value="1608">Medical Anatomy</option>
                      <option value="1295">Medical Biochemistry</option>
                      <option value="762">Medical Biotechnology</option>
                      <option value="763">Medical Electronics</option>
                      <option value="1613">Medical Genetics</option>
                      <option value="1614">Medical Genetics and Genomics</option>
                      <option value="1087">Medical Imaging Technology</option>
                      <option value="1086">Medical Lab Technology</option>
                      <option value="1098">Medical Laboratory Technology</option>
                      <option value="1752">Medical Laboratory Technology (DMLT)</option>
                      <option value="1370">Medical Laboratory Technology (MLT)</option>
                      <option value="1294">Medical Microbiology</option>
                      <option value="1270">Medical Pharmacology</option>
                      <option value="764">Medical Physics</option>
                      <option value="765">Medical Physiology</option>
                      <option value="1718">Medical Radiation Technology (MRT)</option>
                      <option value="1482">Medical Record Technology</option>
                      <option value="1609">Medical Social Worker (MSW)</option>
                      <option value="1273">Medical Statistics</option>
                      <option value="767">Medical Technology</option>
                      <option value="768">Medicinal and Aromatic Plants</option>
                      <option value="1695">Medicinal Chemistry</option>
                      <option value="1171">Mental Health</option>
                      <option value="1676">Mental health nursing</option>
                      <option value="769">Metallurgical and Material Science Engineering</option>
                      <option value="770">Metallurgical and Materials</option>
                      <option value="771">Metallurgical and Materials Engineering</option>
                      <option value="772">Metallurgical and Materials Engineering Metal. and Materials Engineering</option>
                      <option value="1434">Metallurgical Engineering</option>
                      <option value="773">Metallurgy and Material Science Engineering</option>
                      <option value="1195">Metallurgy Engineering  and Materials Science</option>
                      <option value="1190">Metallurgy Engineering and Materials Science with specalisation in Metallurgy Engineering</option>
                      <option value="1197">Metallurgy Engineering and Materials Science with specialisation in Material Science and Engineering</option>
                      <option value="1606">MHA (Master in Hospital Administration)</option>
                      <option value="1764">Micro Electronics &amp; VLSI Design</option>
                      <option value="776">Microbiology</option>
                      <option value="1801">Microbiology Research</option>
                      <option value="777">Microwave and Optical Communication</option>
                      <option value="1522">Middle East &amp; South Asian Security</option>
                      <option value="1659">Minimal Access Surgery</option>
                      <option value="778">Mining Engineering</option>
                      <option value="1596">MLT (Medical Laboratory Technology)</option>
                      <option value="782">Mobile Applications and Cloud Technology integrated with Foundation Certification in Mobile Application from Micro Focus (Formerly HPE) **</option>
                      <option value="1297">Molecular Biology</option>
                      <option value="783">Molecular Biology and biotechnology</option>
                      <option value="784">Molecular Medicine</option>
                      <option value="1490">Molecular Medicine &amp; Stem Cell Technologies</option>
                      <option value="785">Molecular Microbiology</option>
                      <option value="1108">Molecular Plant Breeding</option>
                      <option value="1607">MPH (Master In Public Health)</option>
                      <option value="786">Multimedia</option>
                      <option value="1455">Multimedia (Visual Communication)</option>
                      <option value="787">Multimedia and Animation</option>
                      <option value="788">Multimedia and Communication</option>
                      <option value="1616">Multimedia Forensics</option>
                      <option value="1339">MURP</option>
                      <option value="789">Musculoskeletal Disorders</option>
                      <option value="790">Music</option>
                      <option value="1758">Music Vocal</option>
                      <option value="0">NA</option>
                      <option value="791">Nano Physics</option>
                      <option value="795">Nano Science</option>
                      <option value="792">Nano Science and Engineering</option>
                      <option value="793">Nano Science and Technology</option>
                      <option value="794">Nano Sciences</option>
                      <option value="796">Nano Technology</option>
                      <option value="1070">Nanotechnology</option>
                      <option value="1696">Natural Products</option>
                      <option value="797">Natural Resource and Governance</option>
                      <option value="1109">Nematology</option>
                      <option value="1639">Network and Information Security</option>
                      <option value="800">Network Engineering</option>
                      <option value="802">Neural and Cognitive Science</option>
                      <option value="1359">Neuro Electro Physiology (NEP)</option>
                      <option value="1632">Neuro Psychology</option>
                      <option value="1094">Neuro-Science Technology</option>
                      <option value="1600">Neuroelectro Physiology</option>
                      <option value="803">Neurological Disorders</option>
                      <option value="1769">Neurology</option>
                      <option value="805">Non- Medical</option>
                      <option value="1483">Nuclear Medicine Technology</option>
                      <option value="807">Nuclear Science and Technology</option>
                      <option value="809">Nursing</option>
                      <option value="810">Nutrition</option>
                      <option value="811">Nutrition and Dietetics</option>
                      <option value="1770">Obstetrics &amp; Gynecology</option>
                      <option value="1651">Obstetrics and Gynecology</option>
                      <option value="813">Occupational Therapy</option>
                      <option value="815">Ocean Engineering and Naval Architecture</option>
                      <option value="817">Ocean Engineering and Naval Architecutre Ocean Engg. and Naval Arch.</option>
                      <option value="1821">Oil and Gas Management</option>
                      <option value="1817">Oil and Gas Marketing</option>
                      <option value="819">Oils, Oleochemicals and Surfactants Technology</option>
                      <option value="1266">Operation &amp; Anaesthesia Technology</option>
                      <option value="1365">Operation Theatre Technology</option>
                      <option value="820">Operations and Systems</option>
                      <option value="821">Operations Management</option>
                      <option value="1657">Ophthalmology</option>
                      <option value="1462">Optional English, Pschology and Journalism</option>
                      <option value="1268">Optometry</option>
                      <option value="1361">Optometry (OPT)</option>
                      <option value="1669">Oral and Maxillofacial Surgery</option>
                      <option value="822">Organic Chemistry</option>
                      <option value="823">Organisation Development, Change and Leadership</option>
                      <option value="1733">Ortho/Sports</option>
                      <option value="1668">Orthodontics</option>
                      <option value="1658">Orthopedics</option>
                      <option value="1660">Oto-Rhino-Laryngology</option>
                      <option value="826">Packaging Technology</option>
                      <option value="828">Painting, Print Making, Sculpture</option>
                      <option value="1509">Pali</option>
                      <option value="1088">Patient Care Management</option>
                      <option value="829">Peace and Conflict Studies</option>
                      <option value="1586">Peace and Conflict Studies– Guwahati Campus</option>
                      <option value="1649">Pediatrics</option>
                      <option value="1673">Pedodontics and Preventive Dentistry</option>
                      <option value="1083">Performing Arts</option>
                      <option value="830">Perfumery and Flavour Technology</option>
                      <option value="111">Perfusion Technology</option>
                      <option value="1674">Periodontics</option>
                      <option value="831">Persian</option>
                      <option value="832">Petroleum Engineering</option>
                      <option value="1104">Petroleum Technology</option>
                      <option value="833">Pharma</option>
                      <option value="834">Pharmaceutical</option>
                      <option value="835">Pharmaceutical Analysis</option>
                      <option value="1283">Pharmaceutical Bio-Technology</option>
                      <option value="836">Pharmaceutical Chemistry</option>
                      <option value="1459">Pharmaceutical Chemistry and Technology</option>
                      <option value="837">Pharmaceutical Science and Technology</option>
                      <option value="838">Pharmaceutical Technology</option>
                      <option value="839">Pharmaceutics</option>
                      <option value="840">Pharmacognosy</option>
                      <option value="1409">Pharmacognosy &amp; Phytochemistry</option>
                      <option value="841">Pharmacology</option>
                      <option value="1694">Pharmacology and Toxicology</option>
                      <option value="842">Pharmacy</option>
                      <option value="843">Pharmacy</option>
                      <option value="1289">Pharmacy Administration</option>
                      <option value="844">Pharmacy Practice</option>
                      <option value="847">Pharmcology</option>
                      <option value="848">Philosophy</option>
                      <option value="850">Photography</option>
                      <option value="1396">Photography &amp; Cinematography</option>
                      <option value="1336">Photonics</option>
                      <option value="1123">Physical Chemistry</option>
                      <option value="853">Physical Education</option>
                      <option value="854">Physical Sciences</option>
                      <option value="1615">Physician Assistant</option>
                      <option value="1360">Physician Assistant (PA)</option>
                      <option value="855">Physics</option>
                      <option value="856">Physics Functional Materials and Devices</option>
                      <option value="1802">Physics Research</option>
                      <option value="1475">Physics, Mathematics, Computer Science</option>
                      <option value="857">Physiology</option>
                      <option value="858">Physiotherapy</option>
                      <option value="859">Planning</option>
                      <option value="860">Plant Biology and Biotechnology</option>
                      <option value="862">Plant Biology and Plant Technology</option>
                      <option value="863">Plant Breeding and Genetics</option>
                      <option value="1115">Plant Nematology</option>
                      <option value="864">Plant Pathology</option>
                      <option value="1107">Plant Physiology</option>
                      <option value="865">Plant Sciences</option>
                      <option value="1275">Plant Tissue Culture</option>
                      <option value="866">Plastic Engineering</option>
                      <option value="1257">Policy Studies</option>
                      <option value="867">Political Science</option>
                      <option value="870">Polymer and Chemical technology</option>
                      <option value="1317">Polymer and Process Engineering</option>
                      <option value="871">Polymer Engineering and Technology</option>
                      <option value="872">Polymer Science and Chemical Technology</option>
                      <option value="1640">Polymers and Biosystems Engineering</option>
                      <option value="1090">Population Studies</option>
                      <option value="874">Power and Energy Systems</option>
                      <option value="875">Power Electronics</option>
                      <option value="876">Power Electronics and Drives</option>
                      <option value="877">Power Electronics and Machine Drives</option>
                      <option value="878">Power Electronics and Power System</option>
                      <option value="1822">Power Management</option>
                      <option value="879">Power System Engineerig</option>
                      <option value="880">Power System Engineering</option>
                      <option value="881">Power Systems and Automation</option>
                      <option value="882">PR and Event Management</option>
                      <option value="1510">Prakrit</option>
                      <option value="1128">Print &amp; Media Technology</option>
                      <option value="883">Printing Technology</option>
                      <option value="885">Process Instrumentation</option>
                      <option value="886">Process Metallurgy</option>
                      <option value="887">Processing and Food Engineering</option>
                      <option value="889">Production and Industrial Engineering</option>
                      <option value="890">Production and Industrial Engineering (ME)</option>
                      <option value="891">Production Engineering</option>
                      <option value="892">Production Engineering And Industrial Management</option>
                      <option value="893">Professional Accounting (PA)</option>
                      <option value="894">Project Management</option>
                      <option value="1667">Prosthodontics</option>
                      <option value="1296">Psychiatric Social Work</option>
                      <option value="1650">Psychiatry</option>
                      <option value="897">Psychology</option>
                      <option value="1803">Psychology and Counseling</option>
                      <option value="1463">Psychology, Sociology, Economics</option>
                      <option value="898">Public Administration</option>
                      <option value="1513">Public and Social Policy</option>
                      <option value="899">Public Health</option>
                      <option value="1457">Public International Law &amp; WTO</option>
                      <option value="901">Public Policy and Governance</option>
                      <option value="1332">Pulp &amp; Paper</option>
                      <option value="1316">Pulp &amp; Paper Engineering</option>
                      <option value="1746">Punjabi</option>
                      <option value="904">Quality and Reliability</option>
                      <option value="906">Quality Assurance</option>
                      <option value="907">Quality Assurance Techniques</option>
                      <option value="1280">Quantum Information and Computation</option>
                      <option value="1656">Radio diagnosis</option>
                      <option value="1097">Radiology</option>
                      <option value="908">Radiotherapy</option>
                      <option value="1279">Regulatory Affairs</option>
                      <option value="1080">Regulatory Governance</option>
                      <option value="1570">Regulatory Policy and Governance – Mumbai Campus</option>
                      <option value="1369">Remote Sensing &amp; Wireless Sensor Networks</option>
                      <option value="910">Remote Sensing and Geographic Information Systems</option>
                      <option value="1292">Renal Dialysis Technology</option>
                      <option value="1139">Renal Replacement Therapy &amp; Dialysis Technology</option>
                      <option value="1111">Renewable Energy Engineering</option>
                      <option value="1477">Renewable Energy, Physics, Computer Science</option>
                      <option value="1430">Resort &amp; Event Management</option>
                      <option value="1250">Resources Engineering</option>
                      <option value="1291">Respiratory Care Technology</option>
                      <option value="1661">Respiratory Medicine</option>
                      <option value="1140">Respiratory Therapy</option>
                      <option value="1372">Respiratory Therapy (RPT)</option>
                      <option value="1431">Restaurant &amp; Catering Management</option>
                      <option value="1542">Retail &amp; Fashion Merchandise</option>
                      <option value="912">Retail Management</option>
                      <option value="914">RF and MW</option>
                      <option value="915">Risk Management with CIMA UK integration #</option>
                      <option value="916">Robotics</option>
                      <option value="1478">Robotics &amp; Automation</option>
                      <option value="917">Robotics and Automation</option>
                      <option value="1536">Robotics and Mobility Systems</option>
                      <option value="920">Rural Development</option>
                      <option value="922">Rural Management</option>
                      <option value="924">Russian</option>
                      <option value="1089">Sales &amp; Marketing</option>
                      <option value="927">Sanskrit</option>
                      <option value="928">Science</option>
                      <option value="1537">Science of Intelligence</option>
                      <option value="1381">Sciences</option>
                      <option value="932">Seed Science and Technology</option>
                      <option value="1327">Seismic Vulnerability and Risk Assessment</option>
                      <option value="934">Sensor System Technology</option>
                      <option value="1284">Sensors and Internet of Things</option>
                      <option value="935">Sericulture</option>
                      <option value="936">Signal Processing</option>
                      <option value="937">Signal Processing and Digital Design</option>
                      <option value="938">Silviculture</option>
                      <option value="1281">Smart Healthcare</option>
                      <option value="1592">Smart Manufacturing</option>
                      <option value="1641">Smart Mobility</option>
                      <option value="939">Social Entrepreneurship</option>
                      <option value="1559">Social Entrepreneurship – Mumbai Campus</option>
                      <option value="1576">Social Epidemiology – Mumbai Campus</option>
                      <option value="941">Social Exclusive and Inclusive Policy</option>
                      <option value="1176">Social Innovation and Entrepreneurship</option>
                      <option value="942">Social Innovation and Entrepreneurship - Tuljapur Campus</option>
                      <option value="944">Social Science</option>
                      <option value="335">Social Sciences</option>
                      <option value="1579">Social Sciences - Tuljapur Campus</option>
                      <option value="1583">Social Sciences – Guwahati Campus</option>
                      <option value="949">Social Work</option>
                      <option value="1549">Social Work (Children and Families) – Mumbai Campus</option>
                      <option value="1588">Social Work (Community Organisation &amp; Development Practice) – Guwahati Campus</option>
                      <option value="1550">Social Work (Community Organisation &amp; Development Practice) – Mumbai Campus</option>
                      <option value="1589">Social Work (Counseling) - Guwahati Campus</option>
                      <option value="1551">Social Work (Criminology &amp; Justice) – Mumbai Campus</option>
                      <option value="1552">Social Work (Dalit &amp; Tribal Studies and Action) – Mumbai Campus</option>
                      <option value="1553">Social Work (Disability Studies &amp; Action) – Mumbai Campus</option>
                      <option value="958">Social Work (Livelihoods and Social Entrepreneurship) - Guwahati Campus</option>
                      <option value="1554">Social Work (Livelihoods and Social Entrepreneurship) – Mumbai Campus</option>
                      <option value="1555">Social Work (Mental Health) – Mumbai Campus</option>
                      <option value="1591">Social Work (Public Health) – Guwahati Campus</option>
                      <option value="1556">Social Work (Public Health) – Mumbai Campus</option>
                      <option value="1580">Social Work (Rural Development) - Tuljapur Campus</option>
                      <option value="1557">Social Work (Women-Centered Practice) – Mumbai Campus</option>
                      <option value="966">Society and Culture</option>
                      <option value="967">Sociology</option>
                      <option value="968">Sociology and Social Anthropology</option>
                      <option value="1587">Sociology and Social Anthropology– Guwahati Campus</option>
                      <option value="969">Software Application Development, Software Testing</option>
                      <option value="971">Software Engineering</option>
                      <option value="1263">Software Technology</option>
                      <option value="972">Soil and Water Engineering</option>
                      <option value="1325">Soil Dynamics</option>
                      <option value="973">Soil Science</option>
                      <option value="1110">Soil Science and Agricultural Chemistry</option>
                      <option value="974">Solar and Alternative Energy</option>
                      <option value="1335">Solid State Electronic Materials</option>
                      <option value="977">South East Asian Studies</option>
                      <option value="1121">Southeast Asian and Pacific Studies</option>
                      <option value="1539">Space Science and Technologies</option>
                      <option value="1282">Space Technologies</option>
                      <option value="978">Spanish</option>
                      <option value="979">Special Education</option>
                      <option value="1340">Speech Language Pathology</option>
                      <option value="983">Spices, Plantation, Medicinal and Aromatic Plants</option>
                      <option value="1760">Sports</option>
                      <option value="984">Sports Management</option>
                      <option value="985">Sports Physiotherapy</option>
                      <option value="986">Statistics</option>
                      <option value="1751">Statistics and Data Analytics</option>
                      <option value="987">Statistics with Computer Applications</option>
                      <option value="988">Stem Cell Science and Technology</option>
                      <option value="989">Storage and Cloud Technology integrated with Advanced Certification in Cloud Technology from Micro Focus (Formerly HPE) **</option>
                      <option value="1485">Strategic HR</option>
                      <option value="990">Strategy and Leadership integrated with US CSCA and Institute of Leadership and Management, UK</option>
                      <option value="1154">Structural and Construction Engineering</option>
                      <option value="1326">Structural Dynamics</option>
                      <option value="1688">Structural Dynamics and Earthquake Engineering</option>
                      <option value="991">Structural Engineering</option>
                      <option value="992">Structural Engineering and Disaster Management</option>
                      <option value="995">Supply Chain Management</option>
                      <option value="996">Surface Engineering and Technology</option>
                      <option value="1352">Surface Water Hydrology</option>
                      <option value="1665">Surgical Oncology</option>
                      <option value="998">Sustainable Architecture</option>
                      <option value="1500">Sustainable Development and Management</option>
                      <option value="1175">Sustainable Livelihoods and Natural Resources Governance</option>
                      <option value="1582">Sustainable Livelihoods and Natural Resources Governance - Tuljapur Campus</option>
                      <option value="1000">System Security</option>
                      <option value="1254">Systems and Control Engineering</option>
                      <option value="1001">Tamil</option>
                      <option value="1773">Tax Procedure and Practice</option>
                      <option value="1775">Taxation</option>
                      <option value="1251">Technology Alternatives</option>
                      <option value="1003">Telecom Management</option>
                      <option value="1005">TELECOMMUNICATION ENGINEERING</option>
                      <option value="1009">Telugu</option>
                      <option value="1010">Textile Chemistry</option>
                      <option value="1011">Textile Design</option>
                      <option value="1012">Textile Engineering</option>
                      <option value="1155">Textile Engineering and Management</option>
                      <option value="1765">Textile Plant and Engineering </option>
                      <option value="1013">Textile Technology</option>
                      <option value="1014">Textiles and Apparel Design</option>
                      <option value="1757">Theatre</option>
                      <option value="1073">Theatre Arts</option>
                      <option value="1016">Thermal and Fluid Science</option>
                      <option value="1017">Thermal Engineering</option>
                      <option value="1018">Thermal Power Engineering</option>
                      <option value="1019">Thermal Science</option>
                      <option value="1288">Thermofluids Engineering</option>
                      <option value="1153">Three Subject Combination</option>
                      <option value="1804">Tourism</option>
                      <option value="1744">Tourism &amp; Hospitality</option>
                      <option value="1020">Tourism Administration</option>
                      <option value="1425">TOURISM AND EVENT MANAGEMENT</option>
                      <option value="1022">Tourism and Hospitality Management</option>
                      <option value="1023">Tourism and Hospitality Management integrated with Chartered Institute of Hospitality, UK</option>
                      <option value="1024">Town and Country planning</option>
                      <option value="1617">Toxicology</option>
                      <option value="1025">Translation Studies</option>
                      <option value="1682">Transport Engineering</option>
                      <option value="1422">Transportation and Infrastructure Engineering</option>
                      <option value="1026">Transportation Systems Engg</option>
                      <option value="1027">Travel and Tourism</option>
                      <option value="1028">Travel and Tourism Management</option>
                      <option value="1129">Tribology &amp; Maintenance</option>
                      <option value="1029">Urban Design</option>
                      <option value="1686">Urban Planning</option>
                      <option value="1081">Urban Policy and governance</option>
                      <option value="1571">Urban Policy and Governance – Mumbai Campus</option>
                      <option value="1252">Urban Science &amp; Engineering</option>
                      <option value="1030">Urdu</option>
                      <option value="1031">Vegetable Science</option>
                      <option value="1032">Virology</option>
                      <option value="1064">Visual Arts</option>
                      <option value="1034">Visual Communication</option>
                      <option value="1384">Visual Media and Communication</option>
                      <option value="1036">VLSI</option>
                      <option value="1037">VLSI and Embedded Systems</option>
                      <option value="1038">VLSI Design</option>
                      <option value="1039">VLSI Design and Embedded system</option>
                      <option value="1805">Water Management</option>
                      <option value="1164">Water Policy and Governance</option>
                      <option value="1572">Water Policy and Governance – Mumbai Campus</option>
                      <option value="1334">Water Resources Development</option>
                      <option value="1310">Water Resources Development &amp; Management - Irrigation Water Management</option>
                      <option value="1309">Water Resources Development &amp; Management - Water Resource Management</option>
                      <option value="1043">Water Resources Engineering</option>
                      <option value="1044">Water Resources Engineering and Management</option>
                      <option value="1045">Watershed Management</option>
                      <option value="1628">Web &amp; Mobile Security</option>
                      <option value="1046">Web Applications</option>
                      <option value="1047">Welding Engineering</option>
                      <option value="1051">Wired and Wireless Communication</option>
                      <option value="1366">Wireless Networks &amp; Applications</option>
                      <option value="1172">Women-Centred Practice</option>
                      <option value="1565">Women’s Studies – Mumbai Campus</option>
                      <option value="1056">Womens Studies</option>
                      <option value="1058">Wood Science and Technology</option>
                      <option value="1505">Yoga</option>
                      <option value="1059">Yoga Therapy</option>
                      <option value="1060">Zoology</option>
                      <option value="1806">Zoology Research</option>

                    </Select>
                  </FormControl>
                </div>
              )}











              {/* 

            {props.ilc && boardDetailsGet ? (

              <div style={{
                display: "flex", justifyContent: "center",
                width: '33%'
              }}>
                <FormControl variant="outlined" className={classes.formControl}
                  style={{ width: '100%' }}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Board/Uni
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={boardType}
                    onChange={handleBoardChange}
                    label="Board/Uni"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                    fullWidth
                  >
                    {loading && boardDetailsGet ? (
                      console.log("loading trueeee")
                    ) : boardDetails &&
                      boardDetails.data &&
                      boardDetails.data.length != 0 ? (
                      boardDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.institute_board_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.institute_board_name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>

                </FormControl>
              </div>

            ) :
              props.ilc &&
              (
                <div style={{
                  display: "flex", justifyContent: "center",
                  width: '33%'
                }}>
                  <FormControl variant="outlined" className={classes.formControl}
                    style={{
                      width: '100%'
                    }}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Board/Uni
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={boardType}
                      onChange={handleBoardChange}
                      label="Board/Uni"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                      fullWidth
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        No Boards
                      </MenuItem>
                    </Select>

                  </FormControl>
                </div>
              )}
 */}





              {props.ilc && (
                <>
                  <BoardsBox
                    autoBoards={autoBoards}
                    setBoardType={setBoardType} />
                  <div style={{ height: 20, }}></div>
                </>
              )}







              {props.ilc && stdDetailsGet ? (

                <div style={{
                  display: "flex", justifyContent: "center",
                  width: '95%'
                }}>
                  <FormControl variant="standard" className={classes.formControl}
                    style={{
                      width: '100%'
                    }}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Standard/Stream
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={standardType}
                      onChange={handleStdChange}
                      label="Standard/Stream"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                      fullWidth
                    >
                      {loadingStd && stdDetailsGet ? (
                        console.log("loading trueeee")
                      ) : stdDetails.length != 0 ? (
                        stdDetails.data.map((list, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={list.id}
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              {list.standard}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Please Select Institute Type
                        </MenuItem>
                      )}
                    </Select>


                  </FormControl>
                </div>

              ) :
                props.ilc &&
                (
                  <div style={{
                    display: "flex", justifyContent: "center",
                    width: '95%'
                  }}>
                    <FormControl variant="standard" className={classes.formControl}
                      style={{
                        width: '100%'
                      }}>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Standard/Stream
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={standardType}
                        onChange={handleStdChange}
                        label="Standard/Stream"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                        fullWidth
                      >
                        <MenuItem
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          No Standards
                        </MenuItem>
                      </Select>


                    </FormControl>
                  </div>
                )}
            </div>



















            {/* {subDetailsGet ? (
            loadingSub && subDetailsGet ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={subjectType}
                    onChange={handleSubChange}
                    label="Subject"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Subject
                    </span>
                  </span>
                </FormControl>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={subjectType}
                    onChange={handleSubChange}
                    label="Subject"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    {loadingSub && subDetailsGet ? (
                      console.log("loading trueeee")
                    ) : subDetails.length != 0 ? (
                      subDetails.data.subjects.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.subject_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.subject_name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Subject
                    </span>
                  </span>
                </FormControl>
              </div>
            )
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={subjectType}
                  onChange={handleSubChange}
                  label="Subject"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please Select Institute Type
                  </MenuItem>
                </Select>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Subject
                  </span>
                </span>
              </FormControl>
            </div>
          )} */}










            <div style={{ height: 20 }}></div>

            <SubjectsBox
              autoSubs={autoSubs}
              setSubjectType={setSubjectType}
              loadChapters={loadChapters}
              actvRow={actvRow} />
            <div style={{ height: 20 }}></div>



            {/* 

          <div style={{ display: "flex", justifyContent: "center" }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Subject
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={subjectType}
                onChange={handleSubChange}
                label="Select Subject"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {allSubjects && allSubjects.data && allSubjects.data.length ? (
                  allSubjects.data.map((list, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={list.subject_id}
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        {list.subject_name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No subjects!
                  </MenuItem>
                )}
              </Select>
              
            </FormControl>
          </div> */}


















            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              {chpDetailsGet ? (
                loadingChap && chpDetailsGet ? (
                  <div style={{
                    display: "flex", justifyContent: "center",
                    width: '95%'
                  }}>
                    <FormControl variant="standard" className={classes.formControl}
                      style={{
                        width: '100%'
                      }}>
                      {/* <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Chapter
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={chap}
                    onChange={handleChpChange}
                    label="Chapter"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  > */}
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Chapter
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={chap}
                        onChange={handleChpChange}
                        label="Standard/Stream"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                        fullWidth
                      >

                        {/* <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Chapter
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={chap}
                      onChange={handleChpChange}
                      label="Chapter"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                      fullWidth
                    > */}
                        <MenuItem
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Please Select Subject
                        </MenuItem>
                      </Select>
                      {/* <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Chapter
                    </span>
                  </span> */}
                    </FormControl>
                  </div>
                ) : (
                  <div style={{
                    display: "flex", justifyContent: "center",
                    width: '95%'
                  }}>
                    <FormControl variant="standard" className={classes.formControl}
                      style={{
                        width: '100%'
                      }}>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Chapter
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={chap}
                        onChange={handleChpChange}
                        label="Standard/Stream"
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                        fullWidth
                      >
                        {loadingChap && chpDetailsGet ? (
                          console.log("loading trueeee")
                        ) : (chapDetails && chapDetails.data) ? (
                          chapDetails.data.map((list, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={list.chapter_id}
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                                onClick={() => {
                                  setChapterName(list.chapter_title);
                                }}
                              >
                                {list.chapter_title}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            Please Select Institute Type
                          </MenuItem>
                        )}
                      </Select>
                      {/* <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Chapter
                    </span>
                  </span> */}
                    </FormControl>
                  </div>
                )
              ) : (
                <div style={{
                  display: "flex", justifyContent: "center",
                  width: '95%'
                }}>
                  <FormControl variant="standard" className={classes.formControl}
                    style={{
                      width: '100%'
                    }}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Chapter
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={chap}
                      onChange={handleChpChange}
                      label="Standard/Stream"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                      fullWidth
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        No Chapters
                      </MenuItem>
                    </Select>
                    {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Chapter
                  </span>
                </span> */}
                  </FormControl>
                </div>
              )}
            </div>

            {/*

          {topDetailsGet ? (
            loadingTop && topDetailsGet ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="outlined-search"
                    label="Search field"
                    type="search"
                    variant="outlined"
                    style={{ width: 250 }}
                  />
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Topic
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={tpc}
                    onChange={handleTpcChange}
                    label="Topic"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Topic
                    </span>
                  </span>
                </FormControl>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Topic
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={tpc}
                    onChange={handleTpcChange}
                    label="Topic"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    {loadingTop && topDetailsGet ? (
                      console.log("loading trueeee")
                    ) : TopDetails.length != 0 ? (
                      TopDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.topic_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                            onClick={() => {
                              setTopicName(list.topic_title);
                            }}
                          >
                            {list.topic_title}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Topic
                    </span>
                  </span>
                </FormControl>
              </div>
            )
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Topic
                </InputLabel>

                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={tpc}
                  onChange={handleTpcChange}
                  label="Topic"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No Topics{" "}
                  </MenuItem>
                </Select>

                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Topic
                  </span>
                </span>
              </FormControl>
            </div>
          )} */}



















            {/* <div style={{ display: "flex", justifyContent: "center" }} >
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F" }}>Content Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={contType}
                                onChange={handleContTypeChange}
                                label="Content Type"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >

                                {loadingCont && contTypeDetailsGet ?
                                    console.log("loading trueeee")
                                    :
                                    contTypeDetails.length != 0 ?

                                        contTypeDetails.data.results.map((list, index) => {
                                            return (
                                                <MenuItem key={index} value={list.id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.content_type_name_text}</MenuItem>

                                            )
                                        })
                                        :

                                        <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                                }




                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12
                                    }}>
                                    Content Type
                            </span>
                            </span>
                        </FormControl>

                    </div> */}




            {/* 
            <div style={{
              display: "flex", justifyContent: "center",
              marginTop: 10,
              width: '100%'
            }}>
              <FormControl
                fullWidth
                style={{
                  width: '90%'
                }}>
                <TextField
                  id="outlined-basic"
                  label="Enter Title"
                  variant="standard"
                  style={{
                    // width: 350,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                  defaultValue={title}
                  onChange={handleTitleChange}
                  fullWidth
                />
                
              </FormControl>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
                width: '100%'
              }}
            >
              <FormControl
                style={{
                  width: '90%'
                }}>
                <TextField
                  id="outlined-basic"
                  label="Paste Resource URL"
                  variant="standard"
                  style={{
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                  defaultValue={video}
                  onChange={handleVideoChange}
                  fullWidth
                />
              </FormControl>
            </div>



            <div
              style={{
                marginBottom: 20,
                marginLeft: 10
              }}>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}>(MP4, MP3, Image, Vimeo)</span>
            </div>


            <div style={{
              paddingLeft: 20,
              paddingRight: 20
            }}>
              <ChipInput
                defaultValue={yourChips}
                onChange={(chips) => handleChipChange(chips)}
                fullWidth={true}
                fullWidthInput={true}
                placeholder={`Add tags`}
                multiline={true}
              />
              
            </div>

            <div
              style={{
                paddingLeft: 20,
                paddingRight: 20
              }}>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}>(Press Enter / Return to save tag)</span>
            </div> */}


            {/* <div>


              
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: 20,
                  marginLeft: 15,
                  marginRight: 20,
                }}
              >

                {!ImageDownloadUrls ? (
                  <>
                    <label
                    
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          cursor: 'pointer'
                        }}>
                        <AddBoxIcon style={{
                          color: 'steelblue'
                        }} />
                        <Typography
                          variant='body1'
                          style={{
                            marginLeft: 10,
                            color: "#464E5F",
                          }}>
                          Add a thumbnail to media
                        </Typography>
                        <FileUploader
                          hidden
                          accept="image/*"
                          storageRef={firebase.storage().ref("images")}
                          onUploadStart={handleUploadStart}
                          onUploadError={handleUploadError}
                          onUploadSuccess={handleUploadSingleSuccess}
                          onProgress={handleProgress}
                        />
                      </div>
                    </label>

                  </>
                )
                  : (
                    <></>
                  )
                }
              </div>




              <div style={{ marginTop: 10, display: "flex", justifyContent: "center" }}>
                {progress ? <CircularProgress /> : <></>}
              </div>
              <div style={{ display: "flex", justifyContent: "center", position: 'relative' }}>

                {ImageDownloadUrls && (
                  <>
                    <div
                      style={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        cursor: 'pointer',
                        zIndex: 3
                      }}>
                      <label>
                        <EditIcon />
                        <FileUploader
                          hidden
                          accept="image/*"
                          storageRef={firebase.storage().ref("images")}
                          onUploadStart={handleUploadStart}
                          onUploadError={handleUploadError}
                          onUploadSuccess={handleUploadSingleSuccess}
                          onProgress={handleProgress}
                        />

                      </label>
                    </div>
                    <img
                      src={ImageDownloadUrls}
                      alt="preview Image"
                      width="100px"
                      style={{
                        alignSelf: "center",
                        display: "flex",
                        width: 75,
                        width: 100,
                      }}
                    />
                  </>
                )}
                
              </div>
            </div>
             */}
            <div
              style={{
                display: "flex", justifyContent: "space-between",
                padding: 20, marginTop: 20, width: '100%'
              }}
            >
              {/* <Link to="/publisher/mapping" style={{ textDecoration: "none", color: "#fff" }}> */}
              <Button
                variant="contained"
                onClick={() => props.onCloseClicked(props.anchor, true)}
              >Cancel</Button>


              {/* 
              <Button
                disabled={singleUploadDisabled}
                variant="contained"
                color="primary"
                style={{
                  fontFamily: "Poppins, Helvetica, sans-serif",
                  textTransform: "none",
                  // width: '100%'
                  // backgroundColor: "#3699FF",
                  // color: '#FFFFFF'
                }}
                onClick={() => {
                  handleSingleContentUpload();
                }}
              >
                Save
              </Button> */}



              <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="warning">
                  Something is not right with your input!
                </Alert>
              </Snackbar>

              {/* </Link> */}
            </div>
          </div>
        </TabPanel>











        {/* OPEN UPLOAD PANEL */}
        {/* <TabPanel value={value} index={2}>
        <div
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            display: "block",
          }}
        >

          {subDetailsGet ? (
            loadingSub && subDetailsGet ? (
              <div style={{ display: "flex", 
              justifyContent: "center", width: '33% !important' }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={subjectType}
                    onChange={handleSubChange}
                    label="Subject"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Subject
                    </span>
                  </span>
                </FormControl>
              </div>
            ) : (
              <div style={{ display: "flex", 
              justifyContent: "center", width: '33% !important' }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={subjectType}
                    onChange={handleSubChange}
                    label="Subject"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    {loadingSub && subDetailsGet ? (
                      console.log("loading trueeee")
                    ) : subDetails.length != 0 ? (
                      subDetails.data.subjects.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.subject_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.subject_name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Subject
                    </span>
                  </span>
                </FormControl>
              </div>
            )
          ) : (
            <div style={{ display: "flex", 
            justifyContent: "center", width: '33% !important' }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={subjectType}
                  onChange={handleSubChange}
                  label="Subject"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please Select Institute Type
                  </MenuItem>
                </Select>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Subject
                  </span>
                </span>
              </FormControl>
            </div>
          )}







          

          <div style={{ display: "flex", justifyContent: "center" }}>
            <FormControl>
              <TextField
                id="outlined-basic"
                label="Enter Title"
                variant="outlined"
                style={{
                  width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={title}
                onChange={handleTitleChange}
              />
              <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please enter{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Title
                  </span>
                </span>
              </Typography>
            </FormControl>


          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 0,
            }}
          >
            <FormControl>
              <TextField
                id="outlined-basic"
                label="Paste Resource URL"
                variant="outlined"
                style={{
                  width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={video}
                onChange={handleVideoChange}
              />

              
              <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please paste{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    resource URL
                  </span>
                  (MP4, MP3, Image, Vimeo URL)
                </span>
              </Typography>
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <FormControl className={classes.margin}>
              <TextField
                id="outlined-basic"
                label="Enter Multiple Tags For Best Results"
                variant="outlined"
                style={{
                  width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={tags}
                onChange={handleTagsChange}
              />
              <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please enter <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Tags like #heart #parabellum #history
                  </span>
                </span>
              </Typography>
            </FormControl>
          </div>


          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
                marginLeft: 15,
                marginRight: 20,
              }}
            >
              <label
                style={{
                  fontFamily: "Poppins, Helvetica, sans-serif",
                  backgroundColor: "lightsteelblue",
                  color: "black",
                  padding: 5,
                  borderRadius: 4,
                  cursor: "pointer",
                  width: '100%',
                  textAlign: "center",
                }}
              >
                Upload Cover Image
                <FileUploader
                  hidden
                  // accept="image/*"
                  storageRef={firebase.storage().ref("images")}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={handleUploadSingleSuccess}
                  onProgress={handleProgress}
                />
              </label>
            </div>

            <Typography style={{ marginLeft: 15 }}>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Cover Image
                </span>
              </span>
            </Typography>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {progress ? <CircularProgress /> : <></>}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {ImageDownloadUrls && (
                <img
                  src={ImageDownloadUrls}
                  alt="preview Image"
                  width="100px"
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    width: 75,
                    width: 100,
                  }}
                />
              )}
              
            </div>
          </div>
          
          <div
            style={{ display: "flex", justifyContent: "center", padding: 20, marginTop: 0, width: '100%' }}
          >
            
            <Button
              disabled={openUploadDisabled}
              variant="contained"

              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                textTransform: "none",
                // width: '100%',
                backgroundColor: "#3699FF",
                color: '#FFFFFF'
              }}
              onClick={() => {
                handleSingleContentUpload();
              }}
            >
              Save
            </Button>
            

            <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
              <Alert onClose={handleSnackClose} severity="warning">
                Something is not right with your input!
              </Alert>
            </Snackbar>
          </div>
        </div>
      </TabPanel>
 */}

      </div>


      <Divider2 orientation="vertical" variant="middle" flexItem />

      <div
        style={{
          width: '69%',
          paddingLeft: 16
        }}>
        {/* <BasicTable /> */}
        <CollapsibleTable
          onCloseClicked={(anc) => props.onCloseClicked(anc)} anchor={props.anchor}
          singleUploadDisabled={singleUploadDisabled}
          bulkUploadDisabled={bulkUploadDisabled}
          handleSingleContentUpload={handleSingleContentUpload}
          onURLChanged={(e, sIdx, topicId, subTopicId, topicName, subTopicName) => onURLChanged(e, sIdx, topicId, subTopicId, topicName, subTopicName)}
          topicIds={topicIds}
          subTopicIds={subTopicIds}
          urls={urls}
          topIds={topIds}
          subTopIds={subTopIds}
          uls={uls}
        />
      </div>
    </div>

  );
}
