/***
 * Content list table ScreenI
 */

/**Import start here  */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

//table impots
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Box from "@material-ui/core/Box";
import Menu from '@material-ui/core/Menu';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { blue } from '@material-ui/core/colors';

import DeleteIcon from "@material-ui/icons/Delete";
import { Divider, Paper } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import Drawer from "@material-ui/core/Drawer";
import UpdatePrice from "./slots/UpdatePrice";
import CircularProgress from "@material-ui/core/CircularProgress";
import AudiotrackOutlinedIcon from "@material-ui/icons/AudiotrackOutlined";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SortIcon from '@material-ui/icons/Sort';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import UploadContent from "./slots/UploadContent";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Chip from '@mui/material/Chip';
import InfoIcon from '@mui/icons-material/Info';

//Api's
import API from "../../http/http";
import PARTNERAPI from "../../http/httppartner";
import PUBLISHERAPI from "../../http/publisher";

// redux imports
import { connect, useDispatch, useSelector } from "react-redux";
import { listBoardGet } from "../../redux/actions/boardDetailsAction";
import { listInstGet } from "../../redux/actions/InstituteDetailsAction";
import { listSubGet } from "../../redux/actions/subjectsAction";
import { listStdGet } from "../../redux/actions/standardAction";
import { subjectIdGet } from '../../redux/actions/subjectIdAction';
import {
  fetchContent,
  searchContent,
} from "../../redux/actions/contentActions";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { More, Title } from "@material-ui/icons";
import { listChpGet } from "../../redux/actions/chapterAction";
import Cookie, { get } from "js-cookie";
import Copyright from "../user/slots/Copyright";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Tour from 'reactour'
import Slide from '@material-ui/core/Slide';
import ReactPlayer from "react-player";
import DocViewer from "react-doc-viewer";
import { listContentGet } from "../../redux/actions/GetAllFilesAction";
import ChipInput from 'material-ui-chip-input'
import { useHistory } from "react-router-dom";

import Alert2 from '@mui/material/Alert';
import Button2 from '@mui/material/Button';
import Stack2 from '@mui/material/Stack';

const drawerWidth = 240;
const steps = [
  {
    selector: '[data-tut="first-step"]',
    content: 'The various filters help search the right content',
  },
  {
    selector: '[data-tut="second-step"]',
    content: 'The latest content displays recently uploaded items',
  },
  {
    selector: '[data-tut="third-step"]',
    content: 'The previous content displays older uploaded items',
  },
  {
    selector: '[data-tut="fourth-step"]',
    content: 'The button enables you to create defined content or open content',
  },
  // ...
];


const emails = ['username@gmail.com', 'user02@gmail.com'];

const MOCKSUBJECTS = [
  { "subject_id": 118, "institute_id": 10001, "classroom_std": 10, "subject_name": "Accountancy", "subject_rating": 1, "subject_status": 0, "created_date": "2020-11-19", "timestamp": "2020-11-19T08:04:04Z" },
  { "subject_id": 108, "institute_id": 10001, "classroom_std": 10, "subject_name": "Mathematics", "subject_rating": 1, "subject_status": 1, "created_date": "2020-11-19", "timestamp": "2020-11-19T08:04:04Z" },
  { "subject_id": 121, "institute_id": 10001, "classroom_std": 10, "subject_name": "Chemistry", "subject_rating": 1, "subject_status": 1, "created_date": "2020-11-19", "timestamp": "2020-11-19T08:04:04Z" },
  // { "subject_id": 114, "institute_id": 10001, "classroom_std": 10, "subject_name": "English", "subject_rating": 1, "subject_status": 1, "created_date": "2020-11-19", "timestamp": "2020-11-19T08:04:04Z" },
  // { "subject_id": 112, "institute_id": 10001, "classroom_std": 10, "subject_name": "Science", "subject_rating": 1, "subject_status": 1, "created_date": "2020-11-19", "timestamp": "2020-11-19T08:04:04Z" },

]

/**Imports end here */



function FileViewer(props) {
  const { url } = props
  const docs = [
    { uri: url },
  ];

  return <DocViewer documents={docs} />;
}


//Circular progress function
function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <CircularProgress /> */}
      <CircularProgress color="secondary" />
    </div>
  );
}



//creating table data starts here
function createData(Title, Board, Subject, Chapter, Topic, Type) {
  return { Title, Board, Subject, Chapter, Topic, Type };
}





/**table rows dummy data*/
const rows = [
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Spherical Mirrors",
    "ICSE",
    "Physics",
    "Mirrors",
    "Reflection",
    "Video"
  ),
  createData(
    " Chemical Reactions and Equations",
    "CBSE",
    "Chemistry",
    "Chemical Reactions",
    "Reactions",
    "Pdf"
  ),
  createData(
    "Acids, Bases and Salts",
    "CBSE",
    "Chemistry",
    "Acids, Bases and Salts",
    "Acids",
    "Image"
  ),
  createData(
    "Metals and Non-metals",
    "CBSE",
    "Chemistry",
    "Metals and Non-metals",
    "Metals",
    "Video"
  ),
  createData(
    "Resource and Development",
    "CBSE",
    "Geography",
    "Resouces and Development",
    "Resources",
    "Video"
  ),
  createData(
    "Footprints without Feet",
    "SSC",
    "English",
    "Footprints without Feet",
    "Chapter1",
    "Video"
  ),
  createData(
    " Polynomials",
    "ICSE",
    "Maths",
    " Polynomials",
    "quadratic polynomials",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Minerals and Energy Resources",
    "CBSE",
    "Geography",
    "Minerals and Energy Resources",
    "Minerals",
    "Video"
  ),
  createData(
    "Pairs of Linear Equations in Two Variables",
    "CBSE",
    "Maths",
    "Pairs of Linear Equations in Two Variables",
    "Linear Equations",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Footprints without Feet",
    "ICSE",
    "English",
    "Footprints without Feet",
    "Chapter1",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Agriculture",
    "CBSE",
    "Geography",
    "Agriculture",
    "Agriculture",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Metals and Non-metals",
    "ICSE",
    "Chemistry",
    "Metals and Non-metals",
    "Metals",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Refraction Of Light",
    "CBSE",
    "Physics",
    "Refraction of Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Pairs of Linear Equations in Two Variables",
    "ICSE",
    "Maths",
    "Pairs of Linear Equations in Two Variables",
    "Linear Equations",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Resource and Development",
    "ICSE",
    "Geography",
    "Resouces and Development",
    "Resources",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Pairs of Linear Equations in Two Variables",
    "ICSE",
    "Maths",
    "Pairs of Linear Equations in Two Variables",
    "Linear Equations",
    "Video"
  ),
  createData(
    "Water Resources",
    "CBSE",
    "Geography",
    "Water Resources",
    "Water",
    "Image"
  ),
  createData(
    "Resource and Development",
    "SSC",
    "Geography",
    "Resouces and Development",
    "Resources",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Forest and Wildlife Resources",
    "CBSE",
    "Geography",
    "Forest and Wildlife Resources",
    "Forest and Wildlife",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Light-reflection",
    "CBSE",
    "Physics",
    "Light",
    "Reflection",
    "Video"
  ),
  createData(
    "Metals and Non-metals",
    "SSC",
    "Chemistry",
    "Metals and Non-metals",
    "Metals",
    "Video"
  ),
  createData(
    "Footprints without Feet",
    "SSC",
    "English",
    "Footprints without Feet",
    "Chapter1",
    "Video"
  ),
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const useStyles5 = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles5();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Delete Content</DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="body1">
          Do you want to delete the content?
        </Typography>

      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => { props.onDeleteMap(); handleClose() }} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function SimpleDialogDemo(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  console.log(`props id ${props.id}`)

  return (
    <div>
      {/* <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
          <br />
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
              Open simple dialog
          </Button>
           */}
      {/* <IconButton aria-label="delete" color="secondary" size="small"
        // onClick={() => props.onDeleteCont(props.id)}
        onClick={handleClickOpen}>
        <DeleteOutlineOutlinedIcon fontSize="inherit" />
      </IconButton> */}
      <MenuItem
        onClick={() => props.onDeleteCont(props.id)}
        onClick={handleClickOpen}>Delete</MenuItem>
      <SimpleDialog selectedValue={selectedValue} onDeleteMap={() => props.onDeleteMap()} row={props.row} id={props.id} open={open} onClose={handleClose} />
    </div>
  );
}


// table ascending and descending
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

//table sorting
function stableSort(array, comparator) {
  const stabilizedThis =
    array && array.length > 0 ? array.map((el, index) => [el, index]) : [];
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// table headings array
const headCells = [
  { id: "Title", numeric: false, disablePadding: true, label: "Title" },
  // { id: 'Board', numeric: false, disablePadding: true, label: 'Board' },
  // { id: 'Subject', numeric: false, disablePadding: true, label: 'Subject' },
  // { id: "Chapter", numeric: false, disablePadding: true, label: "Chapter" },
  // { id: "Topic", numeric: false, disablePadding: true, label: "Tospic" },
  { id: "Type", numeric: false, disablePadding: true, label: "Type" },
  { id: 'Actions', numeric: false, disablePadding: true, label: 'Actions' }
  // { id: "image", numeric: false, disablePadding: false, label: "image(if)" }
];


//table heading component starts here
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontFamily: "Poppins, Helvetica, sans-serif",
              color: "#B5B5C3",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

//table heading component ends here

/***top toolbar of the table */

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, instType, board, subject } = props;
  const [snackOpen, setSnackOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [actvRow, setActvRow] = useState(null)
  const [display, setDisplay] = useState('block')

  const boardDetailsGet = useSelector((state) => state.boardDetails);
  const { loading, boardDetails, error } = boardDetailsGet;
  
  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  const handleSnackOpen = () => {
    setSnackOpen(true);
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const dispatch = useDispatch();

  const toggleDrawer = (anchor, open, row) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });

    // update edit
    setActvRow(row)
  };

  const insType = localStorage.getItem("pustak.kgtopg.inst_type");
  const brdType = localStorage.getItem("pustak.kgtopg.board_type");
  const stdType = localStorage.getItem("pustak.kgtopg.standard_type");
  const prm = localStorage.getItem('publish.pustak.media_type')

  console.log('prm in EnhancedTableToolbar ', prm)

  const { allSubjects } = props;

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            id="tableTitle"
            component="div"
            /* style={{
              fontSize: 16,
              fontWeight: "500",
              fontFamily: "Poppins, Helvetica, sans-serif",
            }} */
            variant="h3"
          >
            My Media <small>({(props.ilc) ? 'Recorded video lectures by topic' : 'Teacher Led Content'})</small>
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="New Content">
            <></>
          </Tooltip>
        )}



        {(prm == 'ilc') ?
          <Link to="/publisher-content/price-settings/ilc"
            role="button"
            style={{
              width: 250
            }}>

            <Button variant="contained" color="inverse"
              style={{
                textTransform: "none",
              }}>
              <LocalOfferIcon />&nbsp;
              Set Pricing
            </Button>
          </Link>
          :
          <Link to="/publisher-content/price-settings"
            role="button"
            style={{
              width: 250
            }}>

            <Button variant="contained" color="inverse"
              style={{
                textTransform: "none",
              }}>
              <LocalOfferIcon />&nbsp;
              Set Pricing
            </Button>
          </Link>
        }





        {["right"].map((anchor) => (
          <React.Fragment>
            <div data-tut="fourth-step"
              style={{
                width: "20%",
                alignSelf: 'right'
              }}>
              <Button
                onClick={toggleDrawer(anchor, true, false)}
                variant="contained"
                color="primary"
                className={classes.primary}
                style={{
                  alignSelf: 'right',
                  float: 'right',
                  backgroundColor: "#3699FF",
                  textTransform: "none",
                  width: '100%'
                }}
              >
                <AddPhotoAlternateIcon />
                Add Media
              </Button>
            </div>
            <Drawer
              width="40%"
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false, false)}
            >
              <UploadContent
                subject={subject}
                onSearchContent={(subject2) => {
                  props.onSearchContent(subject2);
                }}
                key="uploadContent"
                onCloseClicked={(anchor2, hideSnack) => {
                  //   toggleDrawer(anchor2, false)

                  setState({ ...state, [anchor]: false });

                  if (!hideSnack) {
                    // show snackbar
                    handleSnackOpen();
                  }

                  // get list of content back
                }}
                onReload={props.onReload}
                handleReloadData={props.handleReloadData}
                handleReloadData={props.handleReloadData}
                anchor={anchor}
                allSubjects={allSubjects}
                allBoards={(boardDetails && boardDetails.data) ? boardDetails.data : []}
                ilc={props.ilc}
              />
            </Drawer>
          </React.Fragment>
        ))}
      </Toolbar>

      {(props.ilc) ? 
      <div
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          marginBottom: 10,
          display: display
        }}>
        <Stack2 sx={{ width: '100%' }} spacing={2}>
          <Alert2 icon={<InfoIcon fontSize="inherit"
          />}
            action={
              <Button color="inherit" size="small"
              onClick={() => setDisplay('none')}>
                CLOSE
              </Button>
            }
            severity="info" 
          >
            A video lesson or lecture is a video which presents educational material for a topic which is to be learned. The format may vary. It might be a video of a teacher speaking to the camera, photographs and text about the topic or some mixture of these.
          </Alert2>
        </Stack2>


      </div>
      :
      <></>
      }


      {/* snackbar after content upload */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity="success">
          Content uploaded successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

//Styling for table
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    height: "100%",
    minWidth: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  drawer: {
    zIndex: theme.zIndex.drawer + 2,
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      width: drawerWidth,
      flexShrink: 0,
      zIndex: theme.zIndex.drawer
    }
  },
  table: {
    // marginTop: theme.spacing(3),
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  margin: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "15%",
  },
  primary: {
    width: "25%",
    height: 40,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControl1: {
    margin: theme.spacing(1),
    minWidth: 250,
    minHeight: 30,
  },
}));



const useStyles1 = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
}));

const path = window.location.pathname
let isIlc = false;

switch (path) {
  case "/publisher-content/subjects/media-components":
    isIlc = false;

    break;
  case "/publisher-content/subjects/instructor-led-content":
    isIlc = true;
    break;
}

console.log('isilc ', isIlc)

// content table starts here
const SubjectTable = ({
  dispatch,
  content,
  contentLoading,
  boardDetails,
  instDetails,
  subDetails,
}) => {
  const classes = useStyles();
  const classes1 = useStyles1();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [contentData, setContentData] = useState(null);
  const [instType, setInstType] = useState("");

  const [boardType, setBoardType] = useState("");
  const [subjectType, setSubjectType] = useState("");

  const [board, setBoard] = useState(0);
  const [subject, setSubject] = useState("");
  const [standardType, setStandardType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [chap, setChap] = useState("");
  const [age, setAge] = React.useState("");
  const [searchResOpen, setSearchResOpen] = useState(false);
  const [filtersResOpen, setFiltersResOpen] = useState(false)
  const [provId, setProvId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actvRow, setActvRow] = React.useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [openSrc, setOpenSrc] = React.useState(false);
  const [openLatest, setOpenLatest] = React.useState(false)
  const [stopVideo, setStopVideo] = React.useState(false)

  const [openLatestImg, setOpenLatestImg] = React.useState(false)
  const [openLatestPdf, setOpenLatestPdf] = React.useState(false)
  const [openLatestAudio, setOpenLatestAudio] = React.useState(false)

  const [openPrev, setOpenPrev] = React.useState(false)
  const [openLatestPrvImg, setOpenLatestPrvImg] = React.useState(false)
  const [openLatestPrvPdf, setOpenLatestPrvPdf] = React.useState(false)
  const [openLatestPrvAudio, setOpenLatestPrvAudio] = React.useState(false)
  const [isQuery, setIsQuery] = useState(false)
  const [mainRows, setMainRows] = useState([])


  const [openSrcImg, setOpenSrcImg] = React.useState(false)
  const [openSrcImgUrl, setOpenSrcImgUrl] = React.useState("")

  const [openPdfSrc, setOpenPdfSrc] = React.useState(false)
  const [openSrcPdfUrl, setOpenSrcPdfUrl] = React.useState("")

  const [openAudioSrc, setOpenAudioSrc] = React.useState(false)
  const [openSrcAudioUrl, setOpenSrcAudioUrl] = React.useState("")

  const [allSubjects, setAllSubjects] = React.useState(null)
  const [allBoards, setAllBoards] = React.useState(null)

  const [mySubjects, setMySubjects] = React.useState(null)
  const [ilc, setIlc] = React.useState(false)
  const history = useHistory();

  const boardDetailsGet = useSelector((state) => state.boardDetails);
  const { loading, boardDetails1, error } = boardDetailsGet;

  const instDetailsGet = useSelector((state) => state.instDetails);
  const { loadingInst, instDetails1, errorInst } = instDetailsGet;

  const subDetailsGet = useSelector((state) => state.subDetails);
  const { loadingSub, subDetails1, errorSub } = subDetailsGet;

  const stdDetailsGet = useSelector((state) => state.stdDetails);
  const { loadingStd, stdDetails, errorStd } = stdDetailsGet;


  const handleAddChip = (chip) => {

  }

  const handleDeleteChip = (chip, index) => {

  }

  const handleClickOpenSearch = () => {
    setOpenSrc(true);
  };

  const handleCloseSearch = () => {
    setOpenSrc(false);
  };


  const handleOpenSearchImg = (url) => {
    setOpenSrcImg(true);
    setOpenSrcImgUrl(url)
  };

  const handleCloseSearchImg = () => {
    setOpenSrcImg(false);
  };


  const handleOpenSearchPdf = (url) => {
    setOpenPdfSrc(true);
    setOpenSrcPdfUrl(url)
  };

  const handleCloseSearchPdf = () => {
    setOpenSrcImg(false);
  };


  const handleInstChange = (event) => {
    console.log("called handleInstChange");
    console.log(`new institute type ${event}`);
    const name = event.target.name;
    const value = event.target.value;
    // setState({
    // ...state,
    // [name]: event.target.value,
    // });


    // update to local storage
    localStorage.setItem("pustak.kgtopg.inst_type", value)

    setInstType(value);
    dispatch(listBoardGet(value));
    dispatch(listStdGet(value));


    // reload subjects
    getMySubjects(true);

  };

  const handleBoardChange = (event) => {
    console.log("called handleBoardChange");
    console.log(`new board  ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log(`board selected value ${value}`);

    // update to local storage
    localStorage.setItem("pustak.kgtopg.board_type", value)

    setBoardType(value);
    // get subjects
    dispatch(listSubGet(value));


    // reload subjects
    getMySubjects(true);
  };

  const handleStdChange = (event) => {
    console.log("called handleStdChange");
    console.log(`new standard ${event}`);

    const name = event.target.name;
    const value = event.target.value;

    // update to local storage
    localStorage.setItem("pustak.kgtopg.standard_type", value)
    setStandardType(value);


    // reload subjects
    getMySubjects(true);
  };


  const handleOpenSearchAudio = (url) => {
    setOpenAudioSrc(true);
    setOpenSrcAudioUrl(url)
  };

  const handleCloseSearchAudio = () => {
    setOpenSrcImg(false);
  };


  const handleClickOpenLatest = () => {
    setOpenLatest(true);
  };

  const handleCloseLatest = () => {
    setOpenLatest(false);
    setStopVideo(false)
  };

  const handleOpenLatestImg = () => {
    setOpenLatestImg(true);
  };

  const handleCloseLatestImg = () => {
    setOpenLatestImg(false);
    setStopVideo(false)
  };

  const handleOpenLatestPdf = () => {
    setOpenLatestPdf(true);
  };

  const handleCloseLatestPdf = () => {
    setOpenLatestPdf(false);
    setStopVideo(false)
  };

  const handleOpenLatestAudio = () => {
    setOpenLatestAudio(true);
  };

  const handleCloseLatestAudio = () => {
    setOpenLatestAudio(false);
    setStopVideo(false)
  };


  const handleClickOpenPrev = (row) => {
    console.log('setting row ', JSON.stringify(row))
    setActvRow(row)
    console.log('done setting row ', JSON.stringify(row))
    setOpenPrev(true)
  };

  const handleCloseLatestPrev = () => {
    setOpenPrev(false);
  };


  const handleOpenPrevImg = (row) => {
    setActvRow(row)
    setOpenLatestPrvImg(true);
  };

  const handleCloseLatestPrevImg = () => {
    setOpenLatestPrvImg(false);
  };

  const handleOpenPrevPdf = (row) => {
    setActvRow(row)
    setOpenLatestPrvPdf(true);
  };

  const handleCloseLatestPrevPdf = () => {
    setOpenLatestPrvPdf(false);
  };

  const handleOpenPrevAudio = (row) => {
    setActvRow(row)
    setOpenLatestPrvAudio(true);
  };

  const handleCloseLatestPrevAudio = () => {
    setOpenLatestPrvAudio(false);
  };


  const [state, setState] = React.useState({
    right: false,
  });


  const handleTourOpen = () => {
    setIsTourOpen(true)
  }

  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  const handleSnackOpen = () => {
    setSnackOpen(true);
  };

  const handleElClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setActvRow(row)
  };

  const handleElClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });

    // close menu
    handleElClose()
  };

  // clear old subject selection
  localStorage.removeItem("publish.pustak.current_subject")

  const isSelected = (Title) => selected.indexOf(Title) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  //update institute state
  const handleInstTypeChange = async (event) => {
    const { name, value } = event.target;
    // console.log(`setting insttype to ${value}`)
    setInstType(value);
    setIsQuery(true)
    // dispatch(listContentGet())
    dispatch(fetchContent(value, board, standardType, subject, chap, provId)); // main table data

    await new Promise((resolve, reject) => {
      dispatch(listBoardGet(value));
      resolve();
    });

    const firstBoardId =
      boardDetails && boardDetails.data && boardDetails.data.length
        ? boardDetails.data[0].institute_board_id
        : 0;

    // we got boards
    await new Promise((resolve, reject) => {
      dispatch(listSubGet(firstBoardId));
      resolve();
    });

    // now we got subjects
    dispatch(listStdGet(value));
    setFiltersResOpen(true)
  };

  //update board state
  const handleBoardChange1 = (event) => {
    const { name, value } = event.target;
    setBoard(value);
    setIsQuery(true)

    dispatch(listSubGet(value));
    // dispatch(listContentGet())
    dispatch(fetchContent(instType, value, standardType, subject, chap, provId)); // main table data
    setFiltersResOpen(true)
  };

  //update sub state
  const handleSubChange = (event) => {
    const { name, value } = event.target;
    const provId2 = Cookie.get('econtent.cont.id')
    setSubject(value);
    setSearchResOpen(false);

    setIsQuery(true)
    dispatch(listChpGet(value, board));
    // dispatch(listContentGet())
    dispatch(fetchContent(instType, board, standardType, value, chap, provId2)); // main table data
    setFiltersResOpen(true)
  };

  const handleStdChange1 = (event) => {
    console.log("called handleStdChange");
    console.log(`new standard ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setStandardType(value);
    // dispatch(listContentGet())
    dispatch(fetchContent(instType, board, value, subject, chap, provId)); // main table data

  };

  const handleChpChange = (event, chaptername) => {
    console.log("called handleChpChange");
    console.log(`new chapter ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log("chapter name", chaptername, value);
    setChap(value);

    // getPublContent()
    // dispatch(listContentGet())
    dispatch(fetchContent(instType, board, standardType, subject, value, provId)); // main table data
    setFiltersResOpen(true)
  }

  const onReload = () => {
    dispatch(listContentGet())

    // defined search
    if (standardType && subject && chap)
      dispatch(fetchContent(instType, board, standardType, subject, chap, provId)); // main table data
  }

  //update on change in search
  const handleSearchChange = async (event) => {
    const { name, value } = event.target;
    setSearchText(value);
    setSearchResOpen(true);

    if (value.length == 0) {
      console.log('calling all content')
      // get all first
      await new Promise((resolve, reject) => {

        dispatch(listContentGet())

      })

      // if combination is used
      dispatch(fetchContent());
    } else {

      // now call redux thunk api to filter content by name
      dispatch(searchContent(value));

    }
  };

  //table sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // select all rows function
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.Title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //slecting each row in table
  const handleClick = (event, Title) => {
    const selectedIndex = selected.indexOf(Title);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, Title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // changing table row
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // changing number of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelContent = async () => {
    // show dialog
    const provId2 = Cookie.get("econtent.cont.id");
    console.log(`now trying to delete content ${JSON.stringify(actvRow)}`)
    await PUBLISHERAPI.deleteContent(actvRow.id)

    // reload content
    await new Promise((resolve, reject) => {
      // dispatch(fetchContent(instType, board, standardType, subject, chap, provId2)); // main table data

      dispatch(listContentGet())
      resolve()
    })

  }

  // get publisher content from db
  const getPublContent = async () => {
    const response = await PUBLISHERAPI.GetPublisherContent();
    let responseData = response.data.data;
    setContentData(responseData);
    console.log("response at getpublcontent", responseData);
  };

  const loadData = async () => {

    await new Promise((resolve, reject) => {
      dispatch(listContentGet())
      resolve()
    })
    // get boards from api and set to store

    await new Promise((resolve, reject) => {
      dispatch(listInstGet());
    });

    // get subjects on load with stored board or selected board information
    dispatch(listStdGet());

  }

  const getAllSubjects = async () => {
    const response = await PUBLISHERAPI.getAllSubjects();
    const subs = response.data;
    console.log('all subjects ', subs)

    setAllSubjects(subs)
  }

  const getMySubjects = async (isIlc) => {
    const provId2 = Cookie.get("econtent.cont.id");
    let response = null
    const insType = localStorage.getItem("pustak.kgtopg.inst_type");
    const brdType = localStorage.getItem("pustak.kgtopg.board_type");
    const stdType = localStorage.getItem("pustak.kgtopg.standard_type");

    console.log('my subjects data 1 ', mySubjects)
    setMySubjects([])

    console.log('ilc in getmysubjects ', isIlc) // because state variable might not have been changed...

    console.log('my subjects data 2 ', mySubjects)
    if (isIlc) {
      console.log(`getting ilc subjects..`)
      response = await PUBLISHERAPI.getMySubjectsILC(provId2, insType, brdType, stdType);


      console.log('my subjects data 3x', response.data)
      const subs = response.data
      console.log(`subs 3x ${JSON.stringify(subs)}`)

      setMySubjects(subs);

    } else {
      console.log(`getting components subjects`)
      response = await PUBLISHERAPI.getMySubjects(provId2);


      console.log('my subjects data 3', response.data)
      const subs = response.data

      setMySubjects(subs);
    }

  }

  const setupIlc = () => {
    // const prm = props.match.params.type
    const prm = localStorage.getItem('publish.pustak.media_type')
    console.log(`content type check2 ${prm}`)

    if (prm == 'ilc') {
      setIlc(true)


      // get from local storage
      setSelections()

    } else {
      getMySubjects(false);
    }

  }

  const setSelections = () => {
    const insType = localStorage.getItem("pustak.kgtopg.inst_type");
    const brdType = localStorage.getItem("pustak.kgtopg.board_type");
    const stdType = localStorage.getItem("pustak.kgtopg.standard_type");

    // update state
    if (insType) {
      setInstType(insType)
      // get boards filled up
      dispatch(listBoardGet(insType));
      dispatch(listStdGet(insType));

    }

    if (brdType)
      setBoardType(brdType)

    if (stdType)
      setStandardType(stdType)

    // reload subjects

    getMySubjects(true);
    // getPublContent()
  }


  useEffect(() => {
    const provId = Cookie.get("econtent.cont.id");
    setProvId(provId);
    console.log('called useeffect() in subjectlist')

    getAllSubjects();

    // getMySubjects();
    // getPublContent()
    loadData();

    setupIlc()

    return () => {
      //
    };
  }, []);

  const chpDetailsGet = useSelector((state) => state.chpDetails);
  const { loadingChap, chapDetails, errorChp } = chpDetailsGet;

  const stdDetailsGet1 = useSelector((state) => state.stdDetails);
  const { loadingStd1, stdDetails1, errorStd1 } = stdDetailsGet1;

  const ContentGet = useSelector((state) => state.allContentGet)
  const { loadingAllContent, allContentGetData, errorAllContent } = ContentGet

  {
    loadingAllContent && allContentGetData ?
      console.log("data is loading....")
      :
      console.log("allContentGetData at contentList", allContentGetData)
  }
  console.log("chapterDeatils ===========", chapDetails);

  console.log("content data at content list table", content);
  console.log(`allsubjects ${JSON.stringify(allSubjects)}`)
  console.log(`mysubjectss ${(mySubjects && mySubjects.data && mySubjects.data.length)}`)

  function SimpleMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}
          style={{
            fontSize: 14,
            textTransform: 'none'
          }}>
          Type
        </Button>
        <SortIcon />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Video</MenuItem>
          <MenuItem onClick={handleClose}>Audio</MenuItem>
          <MenuItem onClick={handleClose}>Image</MenuItem>
        </Menu>
      </div>
    );
  }


  const handleReloadData = () => {
    // const provId = Cookie.get("econtent.cont.id");
    // const subId = subjId

    // dispatch(fetchContent(instType, board, standardType, subId, chap, provId)); // main table data


    // reload subjects
    getMySubjects(true);
  }


  function onSubjectSel(sub) {
    console.log('selected subject ', sub)


    dispatch(subjectIdGet(sub));
    history.push("/publisher-content/content/" + sub.subject_id);
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title> Multimedia Content | Pustak Publish</title>
      </Helmet>

      <div>
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0
        }}>
          <LiveHelpIcon
            onClick={handleTourOpen} />
        </div>


        {/* table top header */}
        <div>
          <EnhancedTableToolbar
            numSelected={selected.length}
            instType={instType}
            board={board}
            subject={subject}
            onSearchContent={(subject) => {
              dispatch(fetchContent());
            }}
            onReload={onReload}
            handleReloadData={handleReloadData}
            allSubjects={allSubjects}
            allBoards={allBoards}
            ilc={ilc}
          />
        </div>


        {/* ilc options */}
        {ilc &&
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 20
            }}>

            {loadingInst && instDetailsGet ? (
              <div style={{
                display: "flex", justifyContent: "center",
                width: '33%%'
              }}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ alignSelf: "center", width: '100%' }}
                >
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Institute type
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={instType}
                    onChange={handleInstChange}
                    label=" Institute type"
                    size="small"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      width: '100%'
                    }}
                    fullWidth
                  >
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select
                    </MenuItem>
                  </Select>

                </FormControl>
              </div>
            ) :

              (<div style={{
                display: "flex", justifyContent: "center",
                width: '33%'
              }}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ alignSelf: "center", width: '100%' }}
                >
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Institute type
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={instType}
                    onChange={handleInstChange}
                    label=" Institute type"
                    size="small"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                    fullWidth
                  >
                    {loadingInst && instDetailsGet ? (
                      console.log("loading trueeee")
                    ) : instDetails.length != 0 ? (
                      instDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.institute_type_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.institute_type_name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        No Institutes
                      </MenuItem>
                    )}
                  </Select>

                </FormControl>
              </div>

              )}

            {boardDetailsGet ? (

              <div style={{
                display: "flex", justifyContent: "center",
                width: '33%'
              }}>
                <FormControl variant="outlined" className={classes.formControl}
                  style={{ width: '100%' }}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Board/Uni
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={boardType}
                    onChange={handleBoardChange}
                    label="Board/Uni"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                    fullWidth
                  >
                    {loading && boardDetailsGet ? (
                      console.log("loading trueeee")
                    ) : boardDetails &&
                      boardDetails.data &&
                      boardDetails.data.length != 0 ? (
                      boardDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.institute_board_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.institute_board_name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>

                </FormControl>
              </div>

            ) :

              (
                <div style={{
                  display: "flex", justifyContent: "center",
                  width: '33%'
                }}>
                  <FormControl variant="outlined" className={classes.formControl}
                    style={{
                      width: '100%'
                    }}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Board/Uni
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={boardType}
                      onChange={handleBoardChange}
                      label="Board/Uni"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                      fullWidth
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        No Boards
                      </MenuItem>
                    </Select>

                  </FormControl>
                </div>
              )}





            {stdDetailsGet ? (

              <div style={{
                display: "flex", justifyContent: "center",
                width: '33%'
              }}>
                <FormControl variant="outlined" className={classes.formControl}
                  style={{
                    width: '100%'
                  }}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Standard/Stream
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={standardType}
                    onChange={handleStdChange}
                    label="Standard/Stream"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                    fullWidth
                  >
                    {loadingStd && stdDetailsGet ? (
                      console.log("loading trueeee")
                    ) : stdDetails.length != 0 ? (
                      stdDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.standard}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>


                </FormControl>
              </div>

            ) :

              (
                <div style={{
                  display: "flex", justifyContent: "center",
                  width: '33%'
                }}>
                  <FormControl variant="outlined" className={classes.formControl}
                    style={{
                      width: '100%'
                    }}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Standard/Stream
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={standardType}
                      onChange={handleStdChange}
                      label="Standard/Stream"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                      fullWidth
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        No Standards
                      </MenuItem>
                    </Select>


                  </FormControl>
                </div>
              )}
          </div>

        }

        {/* table main body  */}
        <div>
          <TableContainer>

            {/* <Divider light style={{ width: "100%", marginBottom: 10 }} /> */}

            {/* search Table */}
            <Paper elevation={2} >
              {/* {allSubjects ? ( */}
              {(mySubjects && mySubjects.data && mySubjects.data.length > 0) ? (
                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                    aria-label="enhanced table"
                  >

                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <div
                            style={{
                              marginLeft: 20
                            }}>
                            Subject
                          </div></TableCell>
                        <TableCell align="center">Videos</TableCell>
                        <TableCell align="center">Audios</TableCell>
                        <TableCell align="center">Images</TableCell>
                        <TableCell align="center">Documents</TableCell>
                        {/* <TableCell align="center">Teach Price p/m</TableCell>
                        <TableCell align="center">Learn Price p/m</TableCell>
                        <TableCell align="right">Actions</TableCell> */}
                      </TableRow>
                    </TableHead>


                    <TableBody>
                      {/* {stableSort(content, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          ) */}

                      {/* {allSubjects.data.map((sb, index) => { */}
                      {mySubjects.data.map((sb, index) => {
                        // {MOCKSUBJECTS.map((sb, index) => {
                        const isItemSelected = isSelected(sb.subject_name);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const coverImg = 'https://s29843.pcdn.co/blog/wp-content/uploads/sites/2/2021/02/video-thumbnails-social.png'

                        return true ? (
                          <TableRow
                            hover

                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={sb.subject_name}
                            selected={isItemSelected}
                            align="right"
                          >

                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                              style={{
                                padding: 0
                              }}
                            >
                              <div style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                              }}>
                                <div
                                  style={{
                                    margin: 20
                                  }}>

                                </div>

                                {/* 
                                <Link to={`/publisher-content/content/${sb.subject_id}`}
                                  style={{ color: 'black' }}>{sb.subject_name}</Link> */}
                                <Typography
                                  variant="body2"
                                  onClick={() => onSubjectSel(sb)}
                                  style={{
                                    cursor: 'pointer',

                                  }}
                                >
                                  {sb.subject_name}

                                  {/* {!sb.subject_status && */}
                                  {(index == 0) &&
                                    <>
                                      <Chip label="Set pricing" color="secondary" variant="contained"
                                        size="small"
                                        style={{
                                          background: 'orange',
                                          color: 'black',
                                          fontSize: 9,
                                          textTransform: 'uppercase',
                                          marginLeft: 20
                                        }} />
                                    </>
                                  }
                                </Typography>

                              </div>
                            </TableCell>

                            <TableCell align="center">
                              {sb.video}
                            </TableCell>

                            <TableCell align="center">
                              {sb.audio}
                            </TableCell>

                            <TableCell align="center">
                              {sb.image}
                            </TableCell>

                            <TableCell align="center">
                              {sb.document}
                            </TableCell>
                            {/* 
                            <TableCell align="center">
                              1000 /-
                            </TableCell>


                            <TableCell align="center">
                              1500 /-
                            </TableCell>
                            

                            <TableCell align="right"
                              style={{
                                padding: 0
                              }}>
                              <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleElClick(e, sb)}>
                                <MoreVertIcon />
                              </Button>

                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleElClose}
                              >
                                <MenuItem onClick={toggleDrawer('right', true)}>Payment Settings</MenuItem>
                                
                              </Menu>
                            </TableCell> */}

                          </TableRow>
                        ) : (
                          <></>
                        )
                        // }
                      })}

                      {emptyRows > 0 && (
                        <TableRow
                          style={{ height: (dense ? 33 : 53) * emptyRows }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>


















                </div>
              ) : (
                <div
                  style={{
                    // marginTop: 40,
                  }}
                >
                  {(
                    <Alert severity="error">
                      <AlertTitle>No Subjects Found!</AlertTitle>
                      No subjects found —{" "}
                      <strong>Add content to subjects!</strong>
                    </Alert>
                  )}
                </div>
              )
              }

            </Paper>

          </TableContainer>
        </div>

        {/* edit content drawer */}
        <Drawer
          width="30%"
          anchor={'right'}
          open={state['right']}
          onClose={toggleDrawer('right', false, false)}
        >
          <UpdatePrice
            subject={null}
            onSearchContent={(subject2) => {

            }}
            key="uploadContent"
            onCloseClicked={(anchor2, hideSnack) => {
              //   toggleDrawer(anchor2, false)

              setState({ ...state, ['right']: false });

              if (!hideSnack) {
                // show snackbar
                handleSnackOpen();
              }

              // get list of content back
            }}
            onReload={onReload}
            anchor={'right'}
            actvRow={null}
            allSubjects={allSubjects}

          />
        </Drawer>

        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          onClose={handleSnackClose}
        >
          <Alert onClose={handleSnackClose} severity="success">
            Content updated successfully!
          </Alert>
        </Snackbar>
      </div>

      <div>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        // style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}
        /> */}

        <Box mt={4} pb={4}>
          <Copyright />
        </Box>
      </div>

      {/* </Paper> */}
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}


      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </div>

  );
};

// map redux stte to react component props
const mapStateToProps = (state) => ({
  content: state.content.content,
  contentLoading: state.content.loading,
  boardDetails: state.boardDetails.boardDetails,
  instDetails: state.instDetails.instDetails,
  subDetails: state.subDetails.subDetails,
  stdDetails: state.stdDetails.stdDetails,
  chpDetails: state.chpDetails.chpDetails,
});

// Connect Redux to React Component
export default connect(mapStateToProps)(SubjectTable);
