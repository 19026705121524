import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { epubmainListItems, epubsecondaryListItems } from '../../ebook/SidebarList';
import TableModel from "../TableModel"
import { Route, Link, BrowserRouter as Router, Switch, Redirect, HashRouter, useHistory } from 'react-router-dom'
import ContentMaking from '../ContentMaking';
// import Footer from "./Footer"
import { Avatar, Button, MenuItem } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import ContentMaking1 from "../ContentMaking1"
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Settings from '../Settings';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import EpubView from '../../ebook/EbookReaderScreen';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SidebarContent from '../../ebook/SidebarScreen';
import contentTable from "../../ebook/MyBooksScreen"
import Contentlist from '../../ebook/MyBooksScreen';
import ebookContentMaking from "../../ebook/ebookAddingContentScreen"
import LessonPlan from "../../ebook/LessonPlanScreen";
import Quiz from "../../ebook/QuizScreen"
import Ebook from "../../ebook/UploadEbookScreen"
import { useState } from 'react';
import logo from "../../../assets/logo.png"
import publisherLogo from "../../../assets/PSON.jpeg"
import McgrawHill from "../../../assets/Mcgrawhill.jpg"
import "../../../css/sidebar.css"
// import MapList from '../../curriculummap/MapList';
// import UnitList from '../../curriculummap/UnitList';
import { ContactlessOutlined, SettingsInputAntennaTwoTone } from '@material-ui/icons';
import SidebarContent1 from "../../ebook/ContentSidebar";
import ContentTable from '../ContentListTable';
import UploadEbookScreen from './UploadContent';
import PublisherListSideBar from '../../ebook/PublisherListSideBar';
import SidebarContentCont from '../SidebarScreenCont';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PostAddIcon from '@material-ui/icons/PostAdd';
import pustakLogo from "../../../assets/PustakLogo.png"
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Cookie from "js-cookie";
import UserSession from '../../../resource/UserSession';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                E-Content
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue"
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize: 14

    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0
    },
}));








export default function Leftbar() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [showButton, setShowButton] = useState(false)
    const [AccordClose, setAccordClose] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [showLogo, setShowLogo] = useState(true)
    const [state, setState] = React.useState({
        right: false,
    })

    const [showSideCont, SetShowSideCont] = useState(false)
    const [path, setPath] = useState('')
    const [readerData, setReaderData] = useState("")
    const [isLoadingscr, setIsLoadingscr] = useState(false)
    const [userName, setUserName] = useState("")
    const [userPhoto, setUserPhoto] = useState("")


    const handleDrawerOpen = () => {
        setOpen(true);
        setAccordClose(true)
        setShowLogo(true)
        SetShowSideCont(false)
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setAccordClose(false)
        setShowLogo(false)
    }



    const hideFab = (list, index) => {
        console.log("hiding the button", list, index)
        setShowButton(true)
        setIsLoadingscr(false)
        console.log("show button at parent", showButton)
    }



    const showSideContent = (status, path) => {
        console.log("sidecontent status", status)
        SetShowSideCont(status)
        if (status == true) {
            SetShowSideCont(status)
            setOpen(false)
            setShowLogo(false)
        } else {
            SetShowSideCont(status)
            setOpen(true)
            setShowLogo(true)
        }
        setPath(window.location.pathname)

        console.log(" callback function status", status, window.location.pathname)
    }

    console.log("windows path in dashboard", window.location.pathname)

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    }



    useEffect(() => {

        const photo = (Cookie.get("econtent.user.photo")) ? Cookie.get("econtent.user.photo") : UserSession.userPhoto;
        const Name = (Cookie.get("econtent.user.name")) ? Cookie.get("econtent.user.name") : UserSession.userName;
        if (photo) {
            setUserPhoto(photo)
        }
        if (Name) {
            setUserName(Name)
        }

    }, [])




    const sendDataToReaderScreen = (list, status) => {
        setIsLoading(true)
        console.log("sending..... Data to Reader Screen", list)
        if (list) {
            setReaderData(list)
            setIsLoading(false)
            setShowButton(status)
        }
    }


    const pathname = window.location.pathname
    let currPath = "dashboard"
    let settSel = false;
    let helpSel = false;

    if (pathname === "/publisher-content/settings"
        || pathname === "/publisher-content/price-settings")
        settSel = true;
    else if (pathname === "/publisher-content/settings/help")
        helpSel = true

    return (

        <>


            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >



                {open ?

                    <div className={classes.toolbarIcon}>
                        <Avatar alt="logo" src={userPhoto} className={classes.small} style={{ display: "flex", justifyContent: "center" }} />
                        <Typography
                            variant="body2">
                            {userName}
                        </Typography>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon style={{ color: "#3f51b5", marginRight: -20 }} />
                            <ChevronLeftIcon style={{ color: "#3f51b5" }} />
                        </IconButton>
                    </div>
                    :
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon style={{ color: "gray", display: "flex", justifyContent: "center", alignContent: "flex-end", marginLeft: 40, padiing: 10 }} />
                    </IconButton>
                }

                <Divider />

                <List><SidebarContentCont buttonstatus={(list, index) => { hideFab(list, index) }} hideAccordian={AccordClose} showContent={(status) => { showSideContent(status, path) }} /></List>


                {/* <List>{epubsecondaryListItems}</List> */}

                <div>
                    <Link to="/publisher-content/settings/help" style={{ textDecoration: "none" }}>
                        <MenuItem selected={helpSel} button >
                            <ListItemIcon>
                                <div className="Finished">
                                    <HelpOutlineIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Help &amp; Support" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                            </div>
                        </MenuItem>
                    </Link>
                </div>

                <div>
                    <Link to="/publisher-content/settings" style={{ textDecoration: "none" }}>
                        <MenuItem selected={settSel} button >
                            <ListItemIcon>
                                <div className="Finished">
                                    <SettingsOutlinedIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Settings" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                            </div>
                        </MenuItem>
                    </Link>
                </div>

                {showLogo ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", width: "60%", alignSelf: "center", alignContent: "flex-end" }}>
                        <img alt="logo" src={pustakLogo} style={{ display: "flex", justifyContent: "center", height: 50, }} />
                        {/* <span style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 14, fontWeight: "500" }}>Publisher</span> */}

                    </div>
                    :
                    <></>
                }

            </Drawer>

            {/* {showSideCont ?
                        <div style={{ width: 300, height: "100vh", backgroundColor: "#fff" }}>
                            <List><SidebarContent1 buttonstatus={(list, index) => { hideFab(list, index) }} hideAccordian={AccordClose} sendDatatoReaderScreen={(list, status) => { sendDataToReaderScreen(list, status) }} /></List>
                        </div>
                        :
                        <></>

                    } */}


            {showSideCont ?
                <div style={{ width: 300, height: "100vh", backgroundColor: "#fff" }}>
                    <List><PublisherListSideBar buttonstatus={(list, index) => { hideFab(list, index) }} hideAccordian={AccordClose} sendDatatoReaderScreen={(list, status) => { sendDataToReaderScreen(list, status) }} /></List>
                </div>
                :
                <></>

            }

        </>
    )
}