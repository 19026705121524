import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch } from 'react-redux';
import Cookie from 'js-cookie';
import { listProfilUpdateCont, listProfilUpdatePub } from '../../../redux/actions/ProfileAction';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert2(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));





const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

const AccountProfileDetails = (props) => {
  const classes = useStyles();


  // const [values, setValues] = useState({
  //   firstName: props.userName,
  //   lastName: props.userName,
  //   email: props.userEmail,
  //   phone: '',
  //   accountName: 'Alabama',
  //   accountNum: 'USA',
  //   accountIfsc:"",


  // });

  const userId = Cookie.get("econtent.user.id");
  const userName = Cookie.get("econtent.user.name");
  const userEmail = Cookie.get("econtent.user.email");
  const userPhoto = Cookie.get("econtent.user.photo");
  const userPhone = Cookie.get("econtent.user.phone");
  const userRole = Cookie.get("econtent.user.role");
  const userLogin = Cookie.get("econtent.user.login");
  const accname = Cookie.get("econtent.user.accname");
  const accnum = Cookie.get("econtent.user.accnum");
  const accifsc = Cookie.get("econtent.user.accifsc");
  const bankbranch = Cookie.get("econtent.user.bankbranch");
  const bankname = Cookie.get("econtent.user.bankname");
  const pubuserid = Cookie.get("econtent.user.pubuserid");

  const [Id, setId] = useState(userId)
  const [firstName, setFirstName] = useState(userName)
  const [email, setEmail] = useState(userEmail)
  const [phone, setPhone] = useState(userPhone)
  const [photo, setPhoto] = useState(userPhoto)
  const [Role, setRole] = useState(userRole)
  const [login, setLogin] = useState(userLogin)
  const [accName, setAccName] = useState(accname)
  const [accNum, setAccNum] = useState(accnum)
  const [accBank, setAccBank] = useState(bankname)
  const [accIfsc, setAccIfsc] = useState(accifsc)
  const [accBranch, setAccBranch] = useState(bankbranch)
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // const profile_url =  Cookie.get('econtent.user.photo')
  const role = Cookie.get('econtent.user.role')
  // const pubuserid = props.pubuserid

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };



  const dispatch = useDispatch()

  const UpdateUserDetails = () => {

    let userId = 0
    let userName = ""
    let userEmail = ""
    let userPhoto = ""
    let userPhone = ""
    let userBankAccName = ""
    let userBankAccNum = ""
    let userBankAccIFSC = ""
    let userBankAccBranch = ""
    let userBankName = ""
    let pubUserId = 0
    let userLoggedIn = false
    let userRoles = ""





    if (role == "EBOOK_PROVIDER") {

      userId = Id
      userName = firstName
      userEmail = email
      userPhoto = photo
      userPhone = phone
      userLoggedIn = true
      userBankAccName = accName
      userBankAccNum = accNum
      userBankAccIFSC = accifsc
      userBankAccBranch = accBranch
      userBankName = accBank
      userRoles = Role




      const body = {


        "user_id": pubuserid,
        "pub_name_text": firstName,
        "pub_email_text": email,
        "pub_desc": "some",
        "pub_logo": photo,
        "bank_account_name": accName,
        "bank_account_number": accNum,
        "banck_account_IFSC": accIfsc,
        "bank_branch": accBranch,
        "bank_name": accBank,
        "phone_number": phone

      }

      dispatch(listProfilUpdatePub(body))

    }


    if (role == "CONTENT_PROVIDER") {

      userId = Id
      userName = firstName
      userEmail = email
      userPhoto = photo
      userPhone = phone
      userLoggedIn = true
      userBankAccName = accName
      userBankAccNum = accNum
      userBankAccIFSC = accifsc
      userBankAccBranch = accBranch
      userBankName = accBank
      userRoles = Role


      const body = {
        "prov_name_text": firstName,
        "prov_email_text": email,
        "prov_cover_url": photo,
        "bank_account_name": accName,
        "bank_account_number": accNum,
        "banck_account_IFSC": accIfsc,
        "bank_branch": accBranch,
        "bank_name": accBank,
        "phone_number": phone,
      }
      dispatch(listProfilUpdateCont(body))

    }


    Cookie.set('econtent.user.id', userId);
    Cookie.set('econtent.user.name', userName);
    Cookie.set('econtent.user.email', userEmail);
    Cookie.set('econtent.user.photo', userPhoto);
    Cookie.set('econtent.user.phone', userPhone);
    Cookie.set('econtent.user.role', role);
    Cookie.set('econtent.user.login', userLoggedIn);
    Cookie.set('econtent.user.accname', userBankAccName);
    Cookie.set('econtent.user.accnum', userBankAccNum);
    Cookie.set('econtent.user.accifsc', userBankAccIFSC);
    Cookie.set('econtent.user.bankbranch', userBankAccBranch);
    Cookie.set('econtent.user.bankname', userBankName);
    Cookie.set('econtent.user.pubuserid', pubUserId);
    // Cookie.set('econtent.user.Roles', userRoles)


    setOpen(true)
  }

  useEffect(() => {

    return (() => {
      //
    })
  }, [])



  return (
    <form
      autoComplete="off"
      noValidate
      {...props}
      data-tut="profile-details-step"
    >
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="Profile"

        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the full name"
                label="Full name"
                name="Full Name"
                onChange={(e) => { setFirstName(e.target.value) }}
                required
                value={firstName}
                variant="outlined"
              />
            </Grid>
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last name"
                helperText="Please specify the last name"
                name="lastName"
                onChange={(e) => {setLastName(e.target.value)}}
                required
                value={lastName}
                variant="outlined"
              />
            </Grid> */}
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                helperText="Please specify the email"
                onChange={(e) => { setEmail(e.target.value) }}
                required
                value={email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                helperText="Please specify the phone number"
                onChange={(e) => { setPhone(e.target.value) }}
                type="number"
                value={phone}
                variant="outlined"
                required
              />


            </Grid>
          </Grid>
        </CardContent>
        {/* <div className={classes.root}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Payment Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    helperText="Please specify the first name"
                    label="Account name"
                    name="AccountName"
                    onChange={(e) => { setAccName(e.target.value) }}
                    required
                    value={accName}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="AccountNum"
                    name="Account Number"
                    helperText="Please specify the account number"
                    onChange={(e) => { setAccNum(e.target.value) }}
                    required
                    value={accNum}
                    variant="outlined"

                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Bank Name"
                    name="Bank Name"
                    helperText="Please specify the bank name"
                    onChange={(e) => { setAccBank(e.target.value) }}
                    required
                    value={accBank}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="IFSC Code"
                    name="IFSC Code"
                    helperText="Please specify the IFSC Code"

                    onChange={(e) => { setAccIfsc(e.target.value) }}
                    required
                    value={accIfsc}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Branch Name"
                    name="Branch Name"
                    helperText="Please specify the Branch name"
                    onChange={(e) => { setAccBranch(e.target.value) }}
                    type="text"
                    value={accBranch}
                    variant="outlined"
                    required
                  />
                </Grid>

              </Grid>

            </AccordionDetails>
          </Accordion>

        </div> */}
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            style={{ margin: 10 }}
            onClick={() => { UpdateUserDetails() }}
          >
            Save details
          </Button>

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert2 onClose={handleClose} severity="success">
              Profile details saved successfully...
          </Alert2>
          </Snackbar>
        </Box>
      </Card>
    </form>
  );
};

export default AccountProfileDetails;