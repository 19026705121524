import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Route, Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router-dom";

import Cookie from 'js-cookie';
import AuthAPI from '../../../../http/auth';
import { useDispatch, useSelector } from 'react-redux';
import { signinAuth } from '../../../../redux/actions/UserAuthAction';
import Constants from '../../../../resource/Constants';
import { CircularProgress } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Copyright from '../../../user/slots/Copyright';




function Alert2(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright1() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const useStyles = makeStyles((theme) => ({
    paper: {
        // marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



export default function ChangePasswordCont() {
    const classes = useStyles();
    const [passw, setPassw] = useState("");
    const history = useHistory();
    const [confPassw, setConfPassw] = useState("");
    const [token, setToken] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [openErrSnack, setOpenErrSnack] = useState(false)

    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    const handleErrSnackClose = () => {
        setOpenErrSnack(false)
    }


    const changePassword = async () => {

        setIsLoading(true)
        if (passw && passw == confPassw) {
            const body = {
                token: token,
                user_password: passw
            }

            try {
                const resp = await AuthAPI.userChangePswrd(body)
                console.log(`response after login  ${JSON.stringify(resp)}`);

                showSuccessSnack(true)
            } catch (e) {
                console.log(`exception authenticating user ${e.message}`)
            }
        } else {
            showErrSnack()
        }

        setIsLoading(false)

    }

    const showSuccessSnack = () => {
        setOpenSnack(true)
    }

    const showErrSnack = () => {
        setOpenErrSnack(true)
    }


    const doAuth = async (resp) => {

        console.log("response at doAuth", resp)

        if (resp && resp.data) {
            const body = {
                user_email: resp.data.user_email,
                user_password: resp.data.user_password
            }
            // dispatch(signinAuth(body))


            const response = await AuthAPI.doAuth(body)
            console.log(`response after login Authentication ${JSON.stringify(response)}`);

            // navigateTo()

        }

    }

    const handlePassChanged = (event) => {
        const { name, value } = event.target;
        setPassw(value);
    }

    const handleConfPassChanged = (event) => {
        const { name, value } = event.target;
        setConfPassw(value);
    }

    // const logo = 'https://kgtopg.com/schooler/img/logo_small.png';


    const dispatch = useDispatch()


    const getUserAuthResp = useSelector(state => state.userSigninAuth)
    const { loadingAuth, userInfo, errorAuth } = getUserAuthResp


    {
        loadingAuth && userInfo ?
            <></>
            :
            console.log("authentication response", userInfo)

    }


    useEffect(() => {

        const userLoggedIn = Cookie.get('econtent.user.login')
        const userRole = Cookie.get('econtent.user.role')
        const userToken = Cookie.get('econtent.user.token')
        const query = new URLSearchParams(window.location.search);
        const tokenId = query.get("token");

        const tokenChk = tokenId ? tokenId : userToken
        setToken(tokenChk)


        return () => {
            //
        }

    }, [])




    return (
        <Container component="main" maxWidth="xs">
            {/* <CssBaseline /> */}
            <div className={classes.paper}>


                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        defaultValue={passw}
                        onChange={handlePassChanged}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                // doLogin()
                            }
                        }}
                        margin="normal"
                        required
                        fullWidth
                        type="password"
                        id="password"
                        label="New Password"
                        name="New Password"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        defaultValue={confPassw}
                        onChange={handleConfPassChanged}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                // doLogin()
                            }
                        }}
                        margin="normal"
                        required
                        fullWidth
                        name="Confirm Password"
                        label="Confirm Password"
                        type="password"
                        id="password"
                    />


                    <Button
                        disabled={isLoading}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => changePassword()}
                    >
                        {(!isLoading) ?
                            <span>Change Password</span>
                            :
                            <CircularProgress />
                        }
                    </Button>
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose}>
                        <Alert2 onClose={handleSnackClose} severity="success">
                            Password updated successfully..
                        </Alert2>
                    </Snackbar>

                    <Snackbar open={openErrSnack} autoHideDuration={6000} onClose={handleErrSnackClose}>
                        <Alert2 onClose={handleErrSnackClose} severity="warning">
                            There was a problem updating password!
                        </Alert2>
                    </Snackbar>

                </form>
            </div>

            {/* <Box mt={8}>
                <Copyright />
            </Box> */}

        </Container>
    );
}