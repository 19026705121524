import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Button, LinearProgress, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import logo from "../../../../assets/logo.png";
import publisherLogo from "../../../../assets/PSON.jpeg"
import McgrawHill from "../../../../assets/Mcgrawhill.jpg";
import "../../../../css/sidebar.css";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import Leftbar from "../Leftbar";
import Cookie from 'js-cookie';

import API from "../../../../http/http";
import PARTNERAPI from "../../../../http/httppartner";
import PUBLISHERAPI from "../../../../http/publisher";
import Copyright from "../../../user/slots/Copyright";

import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tour from 'reactour'
import MuiAlert from '@material-ui/lab/Alert';

function Alert2(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const steps = [
    {
        selector: '[data-tut="first-step"]',
        content: 'Please enter your details and the details of the message',
    },
    {
        selector: '[data-tut="second-step"]',
        content: 'Click the submit button to send the feedback',
    },

    // ...
];

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40,
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue",
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
        justifyContent: "space-between",
        minHeight: "100%",
        height: "100%",
        minWidth: "100%",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0,
    },
}));

export default function HelpSupportCont() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [showButton, setShowButton] = useState(false);
    const [AccordClose, setAccordClose] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [showLogo, setShowLogo] = useState(true);
    const [state, setState] = React.useState({
        right: false,
    });

    const [showSideCont, SetShowSideCont] = useState(false);
    const [path, setPath] = useState("");
    const [readerData, setReaderData] = useState("");
    const [isLoadingscr, setIsLoadingscr] = useState(false);

    const userName = Cookie.get('econtent.user.name')
    const userEmail = Cookie.get('econtent.user.email')
    const userPhone = Cookie.get('econtent.user.phone')

    const [name, setName] = useState(userName)
    const [email, setEmail] = useState(userEmail)
    const [phone, setPhone] = useState(userPhone)
    const [subject, setSubject] = useState("")
    const [desc, setDesc] = useState("")
    const [isTourOpen, setIsTourOpen] = useState(false);

    useEffect(() => { }, []);

    const handleTourOpen = () => {
        setIsTourOpen(true)
    }

    const sendDataToReaderScreen = (list, status) => {
        setIsLoading(true);
        console.log("sending..... Data to Reader Screen", list);
        if (list) {
            setReaderData(list);
            setIsLoading(false);
            setShowButton(status);
        }
    };
    
    const dispatch = useDispatch();
    
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const showSnack = () => {
        setOpen(true)
    }

    useEffect(() => {

        return () => {
            //
        };
    }, []);

    const postFeedback = async () => {

        const body = {
            email: email,
            name: name,
            contact_number: phone,
            subject: subject,
            description: desc
        }

        const response = await PUBLISHERAPI.postSaveFeedback(body)

        console.log("post feedback response", response)
        showSnack()
    }

    return (
        <div className={classes.root}>
            <div style={{
                position: 'absolute',
                top: 0,
                right: 0
            }}>
                <LiveHelpIcon
                    onClick={handleTourOpen} />
            </div>
            {isLoadingscr == false ? (
                <>
                    {/* <Router> */}

                    <CssBaseline />
                    <LinearProgress />

                    <Leftbar />

                    <main className={classes.content}>
                        <Helmet>
                            <title> Help &amp; Support | KGtoPG Partner</title>
                        </Helmet>

                        {/* <div className={classes.appBarSpacer} /> */}
                        <Container
                            className={classes.container}
                            style={{
                                minHeight: "auto",

                            }}
                        >
                            <div style={{ display: "flex" }}>
                                <div style={{ marginTop: 10 }}>
                                    <div style={{ color: "#19191b", fontWeight: 700, lineHeight: "46px", fontSize: "50px", justifyContent: "left", fontFamily: "Poppins, Helvetica, sans-serif" }}>
                                        <Typography
                                            variant="h3">Get in touch</Typography>
                                    </div>
                                    <div style={{ color: "#696871", fontSize: 14, fontWeight: 300, lineHeight: "39px", fontFamily: "Poppins, Helvetica, sans-serif" }}>
                                        <p>Feel free to contact us anytime and please do suggest us for any improvements to become better.</p>
                                    </div>
                                </div>

                            </div>

                            <div style={{ height: "auto", padding: 0, marginTop: 10, marginBottom: 30 }}>

                                <div style={{ color: "#19191b", fontWeight: 700, marginBottom: 10, lineHeight: "46px", fontSize: "20px", justifyContent: "left", fontFamily: "Poppins, Helvetica, sans-serif" }}>
                                    <Typography
                                        variant="h5">We are always open 24/7 for you.</Typography>
                                </div>


                                <div style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
                                    <div style={{ width: "50%" }}
                                        data-tut="first-step">
                                        <TextField
                                            id="outlined-full-width"
                                            label="Name"
                                            value={name}
                                            style={{ margin: 8 }}
                                            placeholder="Name"
                                            helperText="Please enter your name"
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            onChange={(e) => { setName(e.target.value) }}
                                        />
                                        <TextField
                                            id="outlined-full-width"
                                            label="Email"
                                            value={email}
                                            style={{ margin: 8 }}
                                            placeholder="Email"
                                            helperText="Please enter your email"
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            onChange={(e) => { setEmail(e.target.value) }}
                                        />
                                        <TextField
                                            id="outlined-full-width"
                                            label="Phone"
                                            value={phone}
                                            style={{ margin: 8 }}
                                            placeholder="Phone"
                                            helperText="Please enter your phone"
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            onChange={(e) => { setPhone(e.target.value) }}
                                        />
                                        <TextField
                                            id="outlined-full-width"
                                            label="Subject"
                                            value={subject}
                                            style={{ margin: 8 }}
                                            placeholder="subject"
                                            helperText="Please enter your subject"
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            onChange={(e) => { setSubject(e.target.value) }}
                                        />
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Description"
                                            value={desc}
                                            style={{ margin: 8 }}
                                            multiline
                                            rows={5}
                                            defaultValue="Enter Feedback"
                                            variant="outlined"
                                            fullWidth
                                            onChange={(e) => { setDesc(e.target.value) }}
                                        />
                                        <div style={{ margin: 8, alignSelf: 'right' }} data-tut="second-step">
                                            <Button style={{ alignSelf: 'right', float: 'right' }} variant="contained" color="secondary" onClick={() => { postFeedback() }}>
                                                Send Feedback
                                            </Button>
                                            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                                <Alert2 onClose={handleClose} severity="success">
                                                    Feedback sent successfully!
                                                </Alert2>
                                            </Snackbar>
                                        </div>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <div style={{ marginLeft: 50 }}>
                                            <div>
                                                <h1 style={{ color: "#19191b", fontSize: 15, fontWeight: 700, lineHeight: 1 }}> Call us </h1>
                                                <p style={{ color: "#696871", fontSize: 15, fontWeight: 300, }}>  +91 40235 49009</p>
                                                <p style={{ color: "#696871", fontSize: 15, fontWeight: 300, }}> +91 40235 49099</p>
                                            </div>

                                            <div style={{ marginTop: 20 }}>
                                                <h1 style={{ color: "#19191b", fontSize: 15, fontWeight: 700, lineHeight: 1 }}> Email us</h1>
                                                <p style={{ color: "#696871", fontSize: 15, fontWeight: 300, }}> support@kgtopg.com</p>
                                            </div>
                                            <div style={{ marginTop: 20 }}>
                                                <h1 style={{ color: "#19191b", fontSize: 15, fontWeight: 700, lineHeight: 1 }}> Visit us </h1>
                                                <p style={{ color: "#696871", fontSize: 15, fontWeight: 300, }}> 141-B, Prashasan Nagar, Jubilee Hills</p>
                                                <p style={{ color: "#696871", fontSize: 15, fontWeight: 300, }}> Hyderabad, Telangana - 500033</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div

                                    style={{
                                        padding: 20,
                                        width: '100%',
                                        marginTop: 40
                                    }}>
                                    <Copyright />
                                </div>
                            </div>

                        </Container>

                    </main>

                    {/* </Router> */}
                </>
            ) : (
                <></>
            )}


            <Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
            />
        </div>
    );
}
