import axios from "axios";
import Constants from "../../resource/Constants"
import { CONTENTDETAILS_LIST_FAIL, CONTENTDETAILS_LIST_REQUEST, CONTENTDETAILS_LIST_SUCCESS, CURRICULUMMAODETAILS_LIST_SUCCESS, CURRICULUMMAPDETAILS_LIST_FAIL, CURRICULUMMAPDETAILS_LIST_REQUEST, CURRICULUMMAPDETAILS_LIST_SUCCESS, EBOOKDETAILS_LIST_FAIL, EBOOKDETAILS_LIST_REQUEST, EBOOKDETAILS_LIST_SUCCESS } from "../constants/GetAllFIlesConstants";
import Cookie from 'js-cookie';

const ProvId = Cookie.get('econtent.cont.id')
const pubId = Cookie.get('econtent.user.id')

const listContentGet = () => async (dispatch) => {
    try {

        dispatch({ type: CONTENTDETAILS_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/content-by-prov/?prov_id=${ProvId}`)

        console.log("listContentGet ======================", data)
        dispatch({ type: CONTENTDETAILS_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: CONTENTDETAILS_LIST_FAIL, payload: error.message })

    }
}


const listEbooksGet = () => async (dispatch) => {
    try {

        dispatch({ type: EBOOKDETAILS_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/book-by-pub/?publisher_id=${pubId}`)
        console.log("listEbooksGet ======================", data)
        dispatch({ type: EBOOKDETAILS_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: EBOOKDETAILS_LIST_FAIL, payload: error.message })

    }
}


const listCurriculumAllGet = () => async (dispatch) => {
    try {

        dispatch({ type: CURRICULUMMAPDETAILS_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/map-by-pub/?publisher_id=${pubId}`)
        console.log("listCurriculumGet ======================", data)
        dispatch({ type: CURRICULUMMAPDETAILS_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: CURRICULUMMAPDETAILS_LIST_FAIL, payload: error.message })

    }
}


export { listContentGet, listEbooksGet, listCurriculumAllGet }