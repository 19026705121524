/**
 * User Session object
 */

import Cookie from 'js-cookie';

const UserSession = {
    userId: 0,
    userIdKey: "econtent.cont.id",
    userName: '',
    userEmail: '',
    userPhoto: '',
    role: null,
    userLoggedIn: false,
    doLogout: () => {

        const role = ""
        let userId = 0
        let userName = ""
        let userEmail = ""
        let userPhoto = ""
        let userLoggedIn = false



        Cookie.set('econtent.user.id', userId);
        Cookie.set('econtent.user.name', userName);
        Cookie.set('econtent.user.email', userEmail);
        Cookie.set('econtent.user.photo', userPhoto);
        Cookie.set('econtent.user.role', role);
        Cookie.set('econtent.user.login', userLoggedIn);


        console.log("cookies data id+++++++++++++++++", Cookie.get('econtent.user.id'))
        console.log("cookies data name+++++++++++++++++", Cookie.get('econtent.user.name'))
        console.log("cookies data email+++++++++++++++++", Cookie.get('econtent.user.email'))
        console.log("cookies data photo+++++++++++++++++", Cookie.get('econtent.user.photo'))
        console.log("cookies data role+++++++++++++++++", Cookie.get('econtent.user.role'))
        console.log("cookies data login+++++++++++++++++", Cookie.get('econtent.user.login'))

    }

}

export default UserSession